import React from "react";

const Tab = (props) => {
  return (
    <>
      <button
        style={props.active ? styles.tabOpen : styles.tabClose}
        onClick={props.onClick}
      >
        <p style={styles.p}>{props.label}</p>
      </button>
    </>
  );
};
const styles = {
  tabOpen: {
    border: "3px solid #1abc9c",
    borderBottom: "1px solid #1abc9c",
    width: window.innerWidth < 700 ? "100%" : "180px",
    height: "35px",
    borderRadius: "0px",
    borderTopLeftRadius: window.innerWidth < 700 ? "0px" : "6px",
    borderTopRightRadius: window.innerWidth < 700 ? "0px" : "6px",
    position: "relative",
    backgroundColor: "#1abc9c",
    color: "white",
  },
  tabClose: {
    border: "3px solid #1abc9c",
    borderBottom: "1px solid white",
    width: window.innerWidth < 700 ? "100%" : "170px",
    height: "35px",
    borderRadius: "0px",
    borderTopLeftRadius: window.innerWidth < 700 ? "0px" : "8px",
    borderTopRightRadius: window.innerWidth < 700 ? "0px" : "8px",
    position: "relative",
    backgroundColor: "white",
  },
  p: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
    margin: "0px",
    padding: "0px",
  },
};
export default Tab;
