import React from "react";

const styles = {
  point: {
    position: "absolute",
    height: "10px",
    width: "10px",
    background: "purple",
    borderRadius: "5px",
    zIndex: -50,
  },
  box: {
    position: "absolute",
    right: 0,
    top: 0,
    height: "100px",
    width: "150px",
    background: "white",
    border: "1px solid grey",
    borderRadius: "5px",
    boxShadow: "0 0 5px rgba(50,50,50,0.4)",
    zIndex: 99999,
    transform: "rotate(0deg)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontWeight: 600,
  },
};

const Box = (props) => {
  const [box, setBox] = React.useState();

  return (
    <>
      <div style={styles.box}>{props.val.name}</div>
    </>
  );
};

export default Box;
