import React from "react";
import "./UnsortedTable.css";

const UnsortedTable = (props) => {
  let tableShow = props.contents.map((content) => {
    return (
      <tr key={Math.random()}>
        {Object.keys(content).map((currKey) => {
          return <td key={currKey + content[currKey]}>{content[currKey]}</td>;
        })}
      </tr>
    );
  });
  return (
    <div>
      <table className="unsortedTable" style={{ width: "100%" }}>
        <thead>
          <tr>
            {Object.keys(props.contents[0]).map((contentKey) => {
              return (
                <th className="unsortedTable-header" key={contentKey}>
                  {contentKey}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>{tableShow}</tbody>
      </table>
    </div>
  );
};

export default UnsortedTable;
