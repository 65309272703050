import React, { useState, useEffect } from "react";
import "./EditUser.css";
import { Redirect } from "react-router-dom";
import ContainerHeader from "../../components/containerHeader/ContainerHeader";
import Paper from "../../components/whiteCard/whiteCard";
import Input from "../../components/inputComponents/Input";
import Button from "../../components/inputComponents/Button";
import DropDown from "../../components/inputComponents/DropDown";
import axios from "axios";
import Modal from "../../components/ui/modal/Modal";
import ConfirmContent from "../../components/ui/confirmContent/ConfirmContent";
import Loader from "../../components/spinner/Spinner";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import url from "../../constants/url";

const EditUser = (props) => {
  const [error, setError] = useState(false);
  const userTypes = [
    {
      _id: "Select User Type",
      name: "Select User Type",
    },
    {
      _id: "GM/DGM",
      name: "GM/DGM",
    },
    {
      _id: "Engineer",
      name: "Engineer",
    },
    {
      _id: "Jr. Engineer",
      name: "Jr. Engineer",
    },
    {
      _id: "Technician",
      name: "Technician ",
    },
  ];
  const status = [
    {
      _id: "Select Status",
      name: "Select Status",
    },
    {
      _id: "Active",
      name: "Active",
    },
    {
      _id: "Inactive",
      name: "Inactive",
    },
    {
      _id: "Removed",
      name: "Removed",
    },
  ];
  const [otherIpts, setOtherIpts] = useState({});
  const [areas, setAreas] = useState([]);
  const [show, setShow] = useState(false);
  const [ques, setQues] = useState("");
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState([]);
  const [file, setFile] = useState();
  const [existingAreas, setexistingAreas] = useState([]);
  const [functionToPass, setFunctionToPass] = useState(null);
  const [existingZones, setexistingZones] = useState([]);
  const [redirect, setRedirect] = useState(false);

  let final = [];
  let res = [];
  var arr = [];

  useEffect(() => {
    setLoading(true);
    setShow(true);
    axios
      .get(url + "/areas/getAreas")
      .then((res) => {
        res.data.map((area) => {
          area = { ...area, checked: false };
        });
        setAreas(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
    setAreas(res.data);
    modalClose();
    setLoading(false);
    axios
      .post(url + "/users/getUser", { email: props.match.params.email })
      .then((result) => {
        let tempAreas = [];
        let tempZones = [];

        let otherIptsTemp = {
          email: result.data[0]["email"],
          name: result.data[0]["name"],
          type: result.data[0]["designation"],
          // sal: result.data[0]["salaryCode"],
          res: result.data[0]["areaAndZone"],
          status: result.data[0]["status"],
          ucode: result.data[0]["userCode"],
          mobile: result.data[0]["mobileNo"],
          userId: result.data[0]["_id"],
        };
        tempAreas = result.data[0]["areaAndZone"].map((response) => {
          return response.area.areaName;
        });
        setexistingAreas(tempAreas);
        tempZones = result.data[0]["areaAndZone"].map((response) => {
          return response.zones.map((zone) => {
            return zone.zoneName;
          });
        });
        tempZones.forEach((zones) => {});
        setexistingZones(tempZones);
        setOtherIpts(otherIptsTemp);
        modalClose();
        setLoading(false);
      })
      .catch((error) => {
        setError(true);
        setLoading(false);
      });
  }, []);

  const existingAreasDisplay = existingAreas.map((areaName) => {
    return <span className="existingAreas">{areaName}</span>;
  });

  const existingZonesDisplay = existingZones.map((zonearr) => {
    return zonearr.map((zoneName) => {
      return <span className="existingZones">{zoneName}</span>;
    });
  });

  arr = areas
    ? areas.map((area) => (
        <div
          name="area selected"
          key={area._id}
          value={area.areaName}
          className="au-allareas"
        >
          <div className="au-area-head">{area.areaName}</div>
          <div className="au-zones">
            {area.zones.map((zone) => {
              return (
                <FormControlLabel
                  control={
                    <Checkbox
                      name={area._id}
                      onChange={(e) => {
                        let selectArr = [...selected];
                        const obj = selected.find(
                          (o) => o.zone === e.target.value
                        );
                        if (obj) {
                          selectArr.splice(selected.indexOf(obj), 1);
                          setSelected(selectArr);

                        } else {
                          selectArr.push({
                            zone: e.target.value,
                            area: e.target.name,
                            checked: true,
                          });
                          setSelected(selectArr);
                        }
                      }}
                      value={zone._id}
                    />
                  }
                  label={zone.zoneName}
                />
              );
            })}
          </div>
        </div>
      ))
    : null;

  //sending data
  const onClickHandle = () => {
    setLoading(true);
    for (var i = 0; i < selected.length; i++) {
      var search = selected[i].area;
      const w = res.find((p) => p.area === search);
      if (!w) {
        selected.forEach((func) => {
          if (func.area === search) {
            final.push(func.zone);
          }
        });
        res.push({ area: search, zones: final });
        final = [];
      }
    }
    const data = new FormData();
    data.append("email", otherIpts.email);
    data.append("name", otherIpts.name);
    data.append("designation", otherIpts.type);
    // salaryCode: otherIpts.sal,
    if (file) {
      data.append("file", file);
    }
    data.append("areaAndZone", JSON.stringify(res));
    data.append("status", otherIpts.status);
    data.append("userCode", otherIpts.ucode);
    data.append("mobileNo", otherIpts.mobile);
    axios
      .patch(url + "/users/" + otherIpts.userId, data)
      .then((resp) => {
        modalClose();
        setLoading(false);
        setRedirect(true);
      })
      .catch((e) => {
        setError(true);
        setLoading(false);
      });

    res = [];
    final = [];
  };

  useEffect(() => {}, []);

  const modalVisible = () => {
    setQues("Do you want to update  this user?");
    setFunctionToPass(() => () => onClickHandle());
    setShow(true);
  };

  const modalClose = () => {
    setShow(false);
  };

  return (
    <div id="add-user">
      {redirect ? <Redirect to="/users" /> : null}
      <Modal show={show} noHandler={modalClose}>
        {!loading ? (
          !error ? (
            <ConfirmContent
              yesHandler={functionToPass}
              noHandler={modalClose}
              question={ques}
            ></ConfirmContent>
          ) : (
            <p>Something went wrong!</p>
          )
        ) : (
          <Loader></Loader>
        )}
      </Modal>
      <ContainerHeader formName="Edit User"></ContainerHeader>
      <Paper>
        <div className="add-user-form">
          <div className="fields">
            <div className="field">
              <DropDown
                size="wide"
                label="User Type"
                options={userTypes}
                name={otherIpts.type}
                onChange={(e) => {
                  setOtherIpts({ ...otherIpts, type: e.target.value });
                }}
              />
            </div>
            <div className="field">
              <Input
                name="user-name"
                label="User Name"
                placeholder="Enter name"
                size="wide"
                value={otherIpts.name}
                onChange={(e) =>
                  setOtherIpts({ ...otherIpts, name: e.target.value })
                }
              />
            </div>
            {/* <div className="field"> */}
            {/* <Input
                name="user-sal-code"
                label="Salary Code"
                placeholder="Salary Code"
                size="wide"
                value={otherIpts.sal}
                onChange={(e) =>
                  setOtherIpts({ ...otherIpts, sal: e.target.value })
                }
              /> */}
            {/* </div> */}
            <div className="field">
              <Input
                name="user-code"
                label="User Code"
                placeholder="User Code"
                size="wide"
                value={otherIpts.ucode}
                onChange={(e) =>
                  setOtherIpts({ ...otherIpts, ucode: e.target.value })
                }
              />
            </div>
            <div className="field">
              <Input
                name="email"
                label="Email Id"
                placeholder="Enter Email Id"
                size="wide"
                value={otherIpts.email}
                onChange={(e) =>
                  setOtherIpts({ ...otherIpts, email: e.target.value })
                }
              />
            </div>
            <div className="field">
              <Input
                name="mobile"
                label="Mobile number"
                placeholder="Enter mobile number"
                size="wide"
                value={otherIpts.mobile}
                onChange={(e) =>
                  setOtherIpts({ ...otherIpts, mobile: e.target.value })
                }
              />
            </div>
            <div className="field">
              <DropDown
                size="wide"
                label="Status"
                options={status}
                onChange={(e) =>
                  setOtherIpts({ ...otherIpts, status: e.target.value })
                }
              />
            </div>
            <div className="field">
              <Input
                name="upload-photo"
                label="Upload photo"
                size="wide"
                type="file"
                onChange={(event) => {
                  const file = event.target.files[0];
                  setFile(file);
                }}
              />
            </div>
          </div>
          <span className="au-text">Select Areas & Zones</span>
          <div className="warning-container">
            <div className="warning">
              Warning: Select the areas of the user again
            </div>
            <div className="existing-arz">Existing areas:</div>
            {existingAreasDisplay}
            <div className="existing-arz">Existing Zones:</div>
            {existingZonesDisplay}
          </div>
          <div className="au-areascontainer">{arr}</div>
          <hr />
          <Button
            onClick={modalVisible}
            className="success"
            label="Update"
          ></Button>
        </div>
      </Paper>
    </div>
  );
};

export default EditUser;
