import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font,
} from "@react-pdf/renderer";
import letterHead from "../../assets/letterHead.png";
import source from "../../assets/fonts/Times New Roman.ttf";
import sourceBold from "../../assets/fonts/Times New Roman Bold.ttf";

Font.register({ family: "Times-New-Roman", src: source });
Font.register({ family: "Times-New-Roman-Bold", src: sourceBold });

const styles = StyleSheet.create({
  body: {
    paddingBottom: 15,
    paddingHorizontal: 35,
    flexDirection: "column",
  },
  image: {
    justifyContent: "center",
  },
  docName: {
    marginTop: 10,
    fontSize: 14,
    textAlign: "center",
    fontFamily: "Times-New-Roman",
    textDecoration: "underline",
    marginBottom: 15,
  },
  docNameSub: {
    marginTop: 10,
    fontSize: 14,
    textAlign: "center",
    fontFamily: "Times-New-Roman",
    // textDecoration: 'underline',
    marginBottom: 15,
  },
  section: {
    flexDirection: "row",
    justifyContent: "space-between",
    paddingBottom: 30,
  },
  sec: {
    paddingTop: 10,
    justifyContent: "flex-start",
    paddingBottom: 10,
  },
  col: {
    paddingTop: 20,
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "center",
    paddingBottom: 40,
  },
  sectionText: {
    fontSize: 14,
    fontFamily: "Times-New-Roman",
    paddingTop: 10,
  },
  sectionTextBold: {
    fontSize: 14,
    fontFamily: "Times-New-Roman-Bold",
  },
  docNum: {
    fontSize: 12,
    textAlign: "left",
    fontFamily: "Times-New-Roman",
  },
  textGreeting: {
    fontSize: 14,
    textAlign: "justify",
    fontFamily: "Times-New-Roman",
    marginBottom: 20,
  },
  textContent: {
    fontSize: 11,
    textAlign: "justify",
    fontFamily: "Times-New-Roman",
    marginBottom: 15,
  },
  textContent2: {
    fontSize: 13,
    textAlign: "justify",
    fontFamily: "Times-New-Roman",
  },
  textContentj: {
    fontSize: 13,
    textAlign: "justify",
    fontFamily: "Times-New-Roman",
    marginTop: 15,
  },
  textNot: {
    fontSize: 13,
    textAlign: "justify",
    fontFamily: "Times-New-Roman-Bold",
    marginBottom: 15,
  },
  textJob: {
    fontSize: 13,
    textAlign: "justify",
    fontFamily: "Times-New-Roman-Bold",
    marginBottom: 10,
  },
  sectionTextSmall: {
    fontSize: 14.25,
    fontFamily: "Times-New-Roman-Bold",
  },
  sectionTextSmallunb: {
    fontSize: 14.25,
    fontFamily: "Times-New-Roman",
  },
  sectionTextBigg: {
    fontSize: 10.25,
    fontFamily: "Times-New-Roman-Bold",
    paddingTop: "5vh",
  },
  section2: {
    paddingTop: 40,
    flexDirection: "row",
    justifyContent: "center",
    paddingBottom: 50,
  },
  section2Jc: {
    paddingTop: 40,
    flexDirection: "row",
    justifyContent: "flex-start",
    paddingBottom: 50,
  },
  subSection: {
    paddingLeft: 40,
    justifyContent: "center",
    paddingRight: 50,
    alignItems: "center",
  },
  subSectionJc: {
    paddingLeft: 0,
    justifyContent: "flex-start",
    alignItems: "flex-start",
    width: "50%",
  },
  subSectionRight: {
    justifyContent: "center",
    alignItems: "right",
  },
  sign: {
    fontSize: 10.25,
    justifyContent: "center",
  },
  nameDesignation: {
    fontSize: 14,
    fontFamily: "Times-New-Roman-Bold",
    textDecoration: "underline",
    paddingTop: 10,
  },
  designation: {
    marginTop: 10,
    fontSize: 11,
  },
  textContent3: {
    fontSize: 13,
    textAlign: "justify",
    fontFamily: "Times-New-Roman",
    marginBottom: 15,
  },
});
const formattedDate = (dateObj) => {
  const date = new Date(dateObj);
  const year = date.getFullYear();
  let month = date.getMonth() + 1;
  let dt = date.getDate();

  if (dt < 10) {
    dt = "0" + dt;
  }
  if (month < 10) {
    month = "0" + month;
  }
  return dt + "/" + month + "/" + year;
};
const WorkOrderPrint = (props) => (
  <Document>
    <Page size="A4" style={styles.body} wrap>
      <Image style={styles.image} src={letterHead} />
      {props.docName === "Work Order" ? (
        <Text style={styles.docName}>Provisional Work Order</Text>
      ) : null}

      {props.docName === "Job Completion Certificate" ? (
        <View style={styles.col}>
          <Text style={styles.docName}>To Whom It May Concern</Text>
          <Text style={styles.docNameSub}>Job Completion Certificate</Text>
        </View>
      ) : null}

      {props.docName === "Work Order" ? (
        <View style={styles.section}>
          <Text style={styles.sectionText}>
            Work Order No. : {props.projectDetails.workOrder.workOrderNumber}
          </Text>
          <Text style={styles.sectionText}>
            Date: {formattedDate(props.projectDetails.workOrder.workOrderDate)}{" "}
          </Text>
        </View>
      ) : props.docName === "Show Cause Notice" ? (
        <View style={styles.section}>
          <Text style={styles.sectionText}>
            SC No.: {props.projectDetails.showcauseId}
          </Text>
          <Text style={styles.sectionText}>
            Date: {formattedDate(props.projectDetails.date)}
          </Text>
        </View>
      ) : (
        <View>
          <View style={styles.section}>
            <Text style={styles.sectionTextBold}>
              JC No.: {props.projectDetails.jcId}
            </Text>
            <Text style={styles.sectionTextBold}>
              Date: {props.projectDetails.adc}{" "}
            </Text>
          </View>
          <Text style={styles.sectionText}>
            This is to certify that Mr. / Miss / Company / Business{" "}
            {props.projectDetails.vendorName} has successfully completed the job
            as per our job notification no:{" "}
            {props.projectDetails.notificationNo}
          </Text>
          <View style={styles.sec}>
            <Text style={styles.sectionText}>
              The work is completed on {props.projectDetails.adc} successfully.
            </Text>
          </View>
          <View style={styles.sec}>
            <Text style={styles.sectionText}>
              Thanking you and assuring you for our best services always.
            </Text>
          </View>
        </View>
      )}

      {props.docName === "Job Completion Certificate" ? (
        <>
          <View style={styles.sec}>
            <Text style={styles.textContentj}>
              Project Type: {props.projectDetails.projectType}
            </Text>
            <Text style={styles.textContentj}>
              {" "}
              Project Description: {props.projectDetails.description}{" "}
            </Text>

            <Text style={styles.textContentj}>
              Vendor: {props.projectDetails.vendorName}{" "}
            </Text>
            <Text style={styles.textContentj}>
              Engineer: {props.projectDetails.engineer}{" "}
            </Text>
            <Text style={styles.textContentj}>
              Work Period: {props.projectDetails.dos} To{" "}
              {props.projectDetails.adc}
            </Text>
          </View>
          <View style={styles.section2Jc}>
            <View style={styles.subSectionJc}>
              <Text style={styles.sectionTextSmall}>With Regards</Text>
              <Text style={styles.sectionTextSmall}>
                {props.projectDetails.recName}
              </Text>
              <Text style={styles.designation}>Authorized Signature</Text>
            </View>

            <View style={styles.subSectionJc}>
              <Text style={styles.sectionTextSmall}>
                Requisitioner Comment:{" "}
              </Text>
              <Text style={styles.sectionTextSmallunb}>
                {props.projectDetails.recCom}
              </Text>
              <Text style={styles.designation}>Signature</Text>
            </View>
          </View>
        </>
      ) : null}
      {props.docName === "Show Cause Notice" ? (
        <View>
          <Text style={styles.textContent2}>To,</Text>
          <Text style={styles.textContent2}>
            Vendor Code: {props.projectDetails.vendorCode}{" "}
          </Text>
          <Text style={styles.textContent2}>
            Vendor Name: {props.projectDetails.vendorName}{" "}
          </Text>
          <Text style={styles.textContent2}>
            Address: {props.projectDetails.vendorAddress}{" "}
          </Text>
        </View>
      ) : null}
      {props.docName === "Work Order" ? (
        <View>
          <Text style={styles.textContent2}>To,</Text>
          <Text style={styles.textContent2}>
            Vendor Code: {props.projectDetails.vendorCode}{" "}
          </Text>
          <Text style={styles.textContent2}>
            Vendor Name: {props.projectDetails.vendor}{" "}
          </Text>
          <Text style={styles.textContent2}>
            Vendor Address: {props.projectDetails.vendorAddress}{" "}
          </Text>
          <Text style={styles.textContent3}>
            Zone : {props.projectDetails.zoneCode}
          </Text>
        </View>
      ) : null}
      {props.docName === "Show Cause Notice" ? (
        <Text style={styles.docName}>{props.docName}</Text>
      ) : null}

      {props.docName === "Work Order" ? (
        <>
          <Text style={styles.textGreeting}>Dear Sir/Madam,</Text>
          <Text style={styles.textContent}>
            You are hereby advised to execute the work as mentioned below:
          </Text>
          <Text style={styles.textNot}>
            Notification No. / Contract No. :{" "}
            {props.projectDetails.notificationNum}
          </Text>
          <Text style={styles.textJob}>Job Description:</Text>
          <Text style={styles.textContent}>
            {" "}
            {props.projectDetails.description}{" "}
          </Text>
          <Text style={styles.textJob}>Special Instructions:</Text>
          <Text style={styles.textContent}>
            {" "}
            {props.projectDetails.workOrder.workContent}{" "}
          </Text>

          <View style={styles.section}>
            <Text style={styles.sectionTextSmall}>
              Date of commencement of work :{" "}
              {formattedDate(props.projectDetails.dos)}{" "}
            </Text>
            <Text style={styles.sectionTextSmall}>
              Expected Date of completion :{" "}
              {formattedDate(props.projectDetails.doc)}{" "}
            </Text>
          </View>

          <View style={styles.section2}>
            <View style={styles.subSection}>
              <Text style={styles.sectionTextSmall}>Signature</Text>
              <Text style={styles.nameDesignation}>
                {props.projectDetails.issuerName}
              </Text>
              <Text style={styles.designation}>
                {props.projectDetails.designation}
              </Text>
            </View>

            <View style={styles.subSection}>
              <Text style={styles.sectionTextSmall}>Signature</Text>
              <Text style={styles.nameDesignation}>
                {props.projectDetails.vendor}
              </Text>
              <Text style={styles.designation}>
                Vendor code: {props.projectDetails.vendorCode}{" "}
              </Text>
            </View>
          </View>
        </>
      ) : props.docName === "Show Cause Notice" ? (
        <>
          <Text style={styles.textNot}>
            Notification No. : {props.projectDetails.notificationNo}
          </Text>
          <Text style={styles.textNot}>
            Description of Work : {props.projectDetails.description}
          </Text>
          <Text style={styles.textGreeting}>Dear Sir/Madam,</Text>
          <Text style={styles.textContent}>
            {props.projectDetails.showCauseData}
          </Text>
          <View style={styles.subSectionRight}>
            <Text style={styles.textNot}>Thanking You</Text>
            <Text style={styles.sectionTextSmall}></Text>
            <Text style={styles.textContent2}>{props.projectDetails.name}</Text>
            <Text style={styles.textContent2}>Engineer</Text>
            <Text style={styles.textContent2}>Oil India Limited</Text>
          </View>
        </>
      ) : null}
    </Page>
  </Document>
);
export default WorkOrderPrint;
