import React, { Fragment } from "react";
import "./Inputs.css";
const TextArea = ({
  name,
  placeholder,
  onChange,
  className,
  size,
  value,
  label,
  disabled,
  required,
  rows,
  ...props
}) => {
  return (
    <Fragment>
      <label htmlFor={name}>{label}</label>
      <br></br>
      <textarea
        style={{ resize: "none", padding: "10px",height: rows }}
        id={name}
        name={name}
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        className={size}
        disabled={disabled}
        required={required}
        readOnly={disabled}
      />
    </Fragment>
  );
};

export default TextArea;
