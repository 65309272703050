import React from "react";
import { Switch } from "react-router-dom";
import ProtectedRoute from "../components/protectedRoute/ProtectedRoute";
import Dashboard from "../containers/dashboard/Dashboard";
import Profile from "../containers/userProfile/UserProfile";
import ProjectList from "../containers/projectList/ProjectList";
import ProjectDetails from "../containers/projectDetails/ProjectDetails";
import TallyBook from "../containers/tallyBook/TallyBook";
import AddNotification from "../containers/addNotification/AddNotification";
import ChangePassword from "../containers/changePassword/ChangePassword";
import NotificationList from "../containers/notificationList/NotificationList";
import AddProject from "../containers/addProject/AddProject";
import EditNotification from "../containers/editNotification/EditNotification";
import WorkOrder from "../containers/workOrder/WorkOrder";
import MaterialIssue from "../containers/materialIssue/MaterialIssue";
import DailyProgressDate from "../containers/dailyProgressDate/DailyProgressDate";
import EditProject from "../containers/editProject/EditProject";
import TechnicalDocs from "../containers/technicalDocs/TechnicalDocs";
import ShowCause from "../containers/forms/showCause/ShowCause";
import TallyDocs from "../containers/tallyBook/tallydocs/Tallydocs";
import JobCompletion from "../containers/forms/jobComplete/JobComplete";
import MaterialList from "../containers/materialList/MaterialList";
import SubmitJob from "../containers/projectList/submitJob/SubmitJob";
import Reports from "../containers/reports/Reports";
import ContractJobs from "../containers/reports/contractJobs/ContractJobs";
import VendorListReport from "../containers/reports/vendorList/VendorList";
import VendorJobs from "../containers/reports/vendorJobs/VendorJobs";
import SubcontractedJobs from "../containers/reports/subcontractedJobs/SubcontractedJobs";
import DelayedJobs from "../containers/reports/delayedJobs/DelayedJobs";
import CumulativeMaterialJob from "../containers/reports/cumulativeMaterialJob/CumulativeMaterialJob";
import ShowCauseReport from "../containers/reports/vendorShowCauseList/VendorShowCause";
import InProgressProjects from "../containers/reports/inProgressProjects/InProgressProjects";
import RemovedProjectsList from "../containers/reports/removedProjectsList/RemovedProjectsList";
import HaltedProjectsList from "../containers/reports/haltedProjectsList/HaltedProjectsList";
import JobComplete from "../containers/reports/jobComplete/JobComplete";
import AllProjects from "../containers/reports/allProjects/AllProjects";
import ProjectsShowCause from "../containers/reports/projectsShowCause/projectsShowCause";
import NoPr from "../containers/reports/allProjectsNoPr/AllProjectsNoPr";
import UserJobs from "../containers/reports/userJobs/UserJobs";
import JobsJE from "../containers/reports/jobsJE/JobsJE";
import JobsEng from "../containers/reports/jobsEng/JobsEng";
import JobsTech from "../containers/reports/jobsTech/JobsTech";
import JobsInst from "../containers/reports/jobsInst/JobsInst";
import ExtendedJobs from "../containers/reports/extendedJobs/ExtendedJobs";
import JobCertificate from "../containers/reports/jobCertificate/JobCertificate";
import TallyBookReport from "../containers/reports/tallyBookReport/TallyBookReport";
import Godown from "../containers/godown/Godown";
import VendorList from "../containers/reports/vendorList/VendorList";
import NonAssignedNotification from "../containers/nonAssignedNotifications/nonAssignedNotifications.js";
import CurrentMandatoryList from "../containers/projectList/currentmandatoryList/CurrentMandatoryList";
import CompletedMandatoryList from "../containers/projectList/completedmandatoryList/CompletedMandatoryList";
import CompletedProjectList from "../containers/projectList/CompletedProjectList";
import ViewNotification from "../containers/viewNotification/ViewNotification";
import NonEstimateProjects from "../containers/reports/nonEstimateProject/nonEstimateProject";

const GmRoutes = (props) => {
  const [isAuth, setIsAuth] = React.useState(true);
  return (
    <Switch>
      <ProtectedRoute
        path="/dashboard"
        component={Dashboard}
        isAuth={props.isAuth}
      />

      <ProtectedRoute
        path="/profile"
        component={Profile}
        isAuth={isAuth}
      />
      <ProtectedRoute
        path="/show-cause/:id"
        component={ShowCause}
        isAuth={isAuth}
      />
      <ProtectedRoute
        path="/job-completion/:id"
        component={JobCompletion}
        isAuth={isAuth}
      />
      <ProtectedRoute
        path="/currentProjects"
        component={CurrentMandatoryList}
        isAuth={isAuth}
      />
      <ProtectedRoute
        path="/completedProjects"
        component={CompletedMandatoryList}
        isAuth={isAuth}
      />
      <ProtectedRoute
        path="/project-details/:id"
        component={ProjectDetails}
        isAuth={isAuth}
      />
      <ProtectedRoute
        path="/notification"
        component={AddNotification}
        isAuth={isAuth}
      />
      <ProtectedRoute
        path="/notifications"
        component={NotificationList}
        isAuth={isAuth}
      />
      <ProtectedRoute
        path="/editNotification/:id"
        component={EditNotification}
        isAuth={isAuth}
      />
      <ProtectedRoute 
        path="/nonAssignedNotifications"
        component={NonAssignedNotification}
        isAuth={isAuth}
      />
      <ProtectedRoute
        path="/jobAssignment/:id"
        component={AddProject}
        isAuth={isAuth}
      />
      <ProtectedRoute
        path="/work-order/:id"
        component={WorkOrder}
        isAuth={isAuth}
      />
      <ProtectedRoute
        path="/materials/:projectId"
        component={MaterialIssue}
        isAuth={isAuth}
      />
      <ProtectedRoute
        path="/tally/:projectId/:dateId"
        component={TallyBook}
        isAuth={isAuth}
      />
      <ProtectedRoute
        path="/daily-progress/:projectId/:progressId"
        component={DailyProgressDate}
        isAuth={isAuth}
      />
      <ProtectedRoute
        path="/editProject/:projectId"
        component={EditProject}
        isAuth={isAuth}
      />
      <ProtectedRoute
        path="/techdocs"
        component={TechnicalDocs}
        isAuth={isAuth}
      />
      <ProtectedRoute
        path="/changePass"
        component={ChangePassword}
        isAuth={isAuth}
      />
            <ProtectedRoute 
        path="/reports/non-estimate-projects"
        component={NonEstimateProjects}
        exact={true}
        isAuth={isAuth}
      />
      <ProtectedRoute
        path="/tallyDocs"
        component={TallyDocs}
        isAuth={isAuth}
      ></ProtectedRoute>
      <ProtectedRoute
        path="/submitJob/:projectId"
        component={SubmitJob}
        isAuth={isAuth}
      />
      <ProtectedRoute
        path="/material-list/:projectId"
        component={MaterialList}
        isAuth={isAuth}
      />
      <ProtectedRoute
        path="/detailed-project-list"
        component={ProjectList}
        isAuth={isAuth}
      />
      <ProtectedRoute
        path="/completed-detailed-project-list"
        component={CompletedProjectList}
        isAuth={isAuth}
      />
      <ProtectedRoute
        path="/reports"
        component={Reports}
        exact={true}
        isAuth={isAuth}
      />
      <ProtectedRoute
        path="/reports/contract-jobs"
        component={ContractJobs}
        exact={true}
        isAuth={isAuth}
      />
      <ProtectedRoute
        path="/reports/report-vendor-list"
        component={VendorListReport}
        isAuth={isAuth}
      />
      <ProtectedRoute
        path="/reports/vendorJobs"
        component={VendorJobs}
        exact={true}
        isAuth={isAuth}
      />
      <ProtectedRoute
        path="/reports/subcontracted-jobs"
        component={SubcontractedJobs}
        exact={true}
        isAuth={isAuth}
      />
      <ProtectedRoute
        path="/reports/delayed-jobs"
        component={DelayedJobs}
        exact={true}
        isAuth={isAuth}
      />
      <ProtectedRoute
        path="/reports/cumulative-total-job"
        component={CumulativeMaterialJob}
        exact={true}
        isAuth={isAuth}
      />
      <ProtectedRoute
        path="/reports/report-show-cause"
        component={ShowCauseReport}
        isAuth={isAuth}
      />
      <ProtectedRoute
        path="/reports/in-progress-projects"
        component={InProgressProjects}
        exact={true}
        isAuth={isAuth}
      />
      <ProtectedRoute
        path="/reports/removed-projects"
        component={RemovedProjectsList}
        exact={true}
        isAuth={isAuth}
      />
      <ProtectedRoute
        path="/reports/halted-projects"
        component={HaltedProjectsList}
        exact={true}
        isAuth={isAuth}
      />
      <ProtectedRoute
        path="/reports/jobComplete"
        component={JobComplete}
        exact={true}
        isAuth={isAuth}
      />
      <ProtectedRoute 
        path="/viewNotification/:id"
        component={ViewNotification}
        isAuth={isAuth}
      />
      <ProtectedRoute
        path="/reports/all-projects"
        component={AllProjects}
        exact={true}
        isAuth={isAuth}
      />
      <ProtectedRoute
        path="/reports/project-show-cause"
        component={ProjectsShowCause}
        isAuth={isAuth}
      />
      <ProtectedRoute
        path="/reports/all-projects-nopr"
        component={NoPr}
        exact={true}
        isAuth={isAuth}
      />
      <ProtectedRoute
        path="/reports/userJobs"
        component={UserJobs}
        exact={true}
        isAuth={isAuth}
      />
      <ProtectedRoute
        path="/reports/JobsJE"
        component={JobsJE}
        exact={true}
        isAuth={isAuth}
      />
      <ProtectedRoute
        path="/reports/JobsEng"
        component={JobsEng}
        exact={true}
        isAuth={isAuth}
      />
      <ProtectedRoute
        path="/reports/JobsTech"
        component={JobsTech}
        exact={true}
        isAuth={isAuth}
      />
      <ProtectedRoute
        path="/reports/JobsInstallations"
        component={JobsInst}
        isAuth={isAuth}
      />
      <ProtectedRoute
        path="/reports/extended-jobs"
        component={ExtendedJobs}
        exact={true}
        isAuth={isAuth}
      />
      <ProtectedRoute
        path="/reports/jobComplete"
        component={JobComplete}
        exact={true}
        isAuth={isAuth}
      />
      <ProtectedRoute
        path="/reports/job-certificate"
        component={JobCertificate}
        exact={true}
        isAuth={isAuth}
      />
      <ProtectedRoute
        path="/reports/tallyBookReport"
        component={TallyBookReport}
        isAuth={isAuth}
      />
      <ProtectedRoute path="/godown" component={Godown} isAuth={isAuth} />
      <ProtectedRoute
        path="/vendor-list"
        component={VendorList}
        isAuth={isAuth}
      />
    </Switch>
  );
};

export default GmRoutes;
