import React, { useState } from 'react'
import './AddGodown.css'
import Paper from '../../components/whiteCard/whiteCard'
import Input from '../../components/inputComponents/Input'
import Dropdown from '../../components/inputComponents/DropDown'
import * as requests from '../../api/requests'
import Button from '../../components/inputComponents/Button'
import ContainerHeader from '../../components/containerHeader/ContainerHeader'
import Alert from "@material-ui/lab/Alert";
import Modal from "../../components/ui/modal/Modal";
import ConfirmContent from "../../components/ui/confirmContent/ConfirmContent";
import Loader from "../../components/spinner/Spinner";
import { Redirect } from 'react-router-dom'
import Typography from "@material-ui/core/Typography";
import axios from "axios";
import url from "../../constants/url";

const AddGodown = () => {
    const [formData, setFormData] = useState({})
    const [techList, setTechList] = useState([])
    const [show, setShow] = useState(false);
    const [ques, setQues] = useState("");
    const [loading, setLoading] = useState(false);
    const [formErrors, setFormErrors] = useState([]);
    const [redirect, setRedirect] = useState(false);
    const [error, setError] = useState(false);
    const [functionToPass, setFunctionToPass] = useState(null);

    const [upload, setUpload] = useState(false);
    const [file, setFile] = useState(null);
    const [update, setUpdate] = useState(false);
    var [isSubmit, setSubmit] = useState(false);
    
    const inputHandler = (e, property) => {
        setFormData({...formData, [property]: e.target.value})
    }
    React.useEffect(() => {
        setLoading(true)
        requests.get({url: "/users/getAllUsers"})
        .then(result => {
            let userArray = [
                {
                    _id: "Select Godown Incharge",
                    name: "Select Godown Incharge",
                  },
            ]
            result.forEach(user => {
                if(user.designation === "Technician"){
                    userArray = [
                        ...userArray,
                        {
                            _id: user._id,
                            name: user.name + ' (' + user.userCode + ')' 
                        }
                    ]
                }
            });
            setTechList(userArray)
            setLoading(false);
        }).catch(error => {
            setError(true);
            setLoading(false);
        })
    }, [update])
    const submitHandler = () => {
        setLoading(true)
        requests.post({
            url:"/godowns/createGodown",
            body: formData,
    })
        .then(result => {
            modalClose();
            setLoading(false);
            setRedirect(true);
        })
        .catch(error => {
            console.log(error)
            setError(true);
            setLoading(false);
        })
    }
    const modalVisible = () => {

        let errors = [];
        const toCheck = {
          godownName: "GODOWN NAME",
          godownCode: "GODOWN CODE",
          incharge: "GODOWN INCHARGE",
        };
        Object.keys(toCheck).map((key) => {
          if (!formData[key]) {
            errors.push(toCheck[key] + " is required");
          }
        });
        if (errors.length > 0) {
          setFormErrors(errors);
          return;
        }
        setFormErrors([]);
        setFunctionToPass(() => () => submitHandler());
        setQues("Do you want to add this Godown?");
        setShow(true);
    };

    const onBulkUpload = () => {
        const data = new FormData();
        data.append("name", "bulkUpload");
        data.append("file", file);
        modalClose();
        axios.post(`${url}/bulkUpload/godownUpload`, data)
        .then((res) => {
          setUpdate(!update);
        })
        .catch((err) => {
          setShow(true);
          setLoading(false);
          setUpload(false);
          setError(true);
        })
    }

    const modalBulkUpload = () => {
        setUpload(true);
        setFormErrors([]);  
        setShow(true);
    }

    const modalClose = () => {
        setShow(false);
        setUpload(false);
    };
    return (
        <div className="add-godown">
            {redirect ? <Redirect to="/godownList" /> : null}
            <Modal show={show} noHandler={modalClose}>
                {!loading ? 
                    !upload ? (
                    !error ? 
                        (
                            <ConfirmContent
                            yesHandler={functionToPass}
                            noHandler={modalClose}
                            question={ques}
                            ></ConfirmContent>
                        ) 
                        : 
                        (
                            <p>Something went wrong!</p>
                        )
                ) : 
                    <>
                    <Typography>Select a .xlsx file of not more than 2MB that contains the vendor details.</Typography>
                    <Input
                        type="file"
                        value={isSubmit ? "" : null }
                        onChange={(e) => {
                            setSubmit(false);
                            setFile(e.target.files[0])}
                        }
                        style={{marginBottom: '10px'}}
                    />
                    <Button 
                        className="primary"
                        type="button"
                        label="Bulk Upload"
                        onClick={onBulkUpload}
                        disabled={file ? false : true}
                    />
                    <Button 
                        className="primary"
                        type="button"
                        label="Cancel"
                        onClick={() => {
                            setFile(false);
                            modalClose();
                        }}
                    />
                    </>
                :(
                    <Loader></Loader>
                )}
            </Modal>
            <ContainerHeader formName="Add Godown"/>
            <Paper>
                <div className="fields">
                    <div className="field">
                        <Input
                        label="Godown Name"
                        type="text"
                        size="wide"
                        onChange={e => inputHandler(e, "godownName")} />
                    </div>
                    <div className="field">
                        <Input
                        label="Godown Code"
                        type="text"
                        size="wide"
                        onChange={e => inputHandler(e, "godownCode")} />
                    </div>
                    <div className="field">
                        <Dropdown
                        label="Godown Incharge"
                        options={techList}
                        size="wide"
                        onChange={e => inputHandler(e, "incharge")} />
                    </div>                  
                </div>
                <Button
                    className="primary"
                    label="Add Godown"
                    onClick={modalVisible} />
                <Button 
                    className="primary"
                    label="Bulk Upload"
                    onClick={modalBulkUpload}
                />
                {formErrors.map((error) => {
                    return <Alert severity="info">{error}</Alert>;
                })}
            </Paper>   
        </div>
         
    )
}

export default AddGodown
