import React, { useEffect, useState } from "react";
import "./ContractJobs.css";
import ContainerHeader from "../../../components/containerHeader/ContainerHeader";
import Paper from "../../../components/whiteCard/whiteCard";
import * as requests from "../../../api/requests";
import MaterialTables from "../../../components/materialTable/MaterialTables";
import Axios from "axios";
import Modal from "../../../components/ui/modal/Modal";
import Loader from "../../../components/spinner/Spinner";
import authCheck from "../../../helpers/auth.helpers";

const ProjectList = () => {
  const { areaAndZone, designation } = authCheck(localStorage.getItem('authCode'));

  const [tableContents, setTableContents] = React.useState(null);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const connLinks = [
    {
      name: "All Reports",
      path: "/reports",
    },
  ];
  const headings = [
    "Project Type",
    "Project Id",
    "Zone",
    "Area",
    "Notification Number",
    "State",
    "Subcontractor",
    "Subcontractor Name",
    "Technician",
    "Vendor",
    "Jr. Engineer",
    "Date of Start",
    "Date of Completion",
    "Extended DOC",
  ];
  const formattedDate = (dateObj) => {
    const date = new Date(dateObj);
    const year = date.getFullYear();
    let month = date.getMonth() + 1;
    let dt = date.getDate();

    if (dt < 10) {
      dt = "0" + dt;
    }
    if (month < 10) {
      month = "0" + month;
    }

    return dt + "/" + month + "/" + year;
  };

  React.useEffect(() => {
    setLoading(true);
    setShow(true);
    requests
      .get({
        url: "/projects/getProjectList",
      })
      .then((response) => {
        let tempProjectList = [];
        let areaList = [];
        areaAndZone.map((area) => {
          areaList.push(area.area._id);
        });
        let data = response.project.filter(
          (project) =>
            project.projectType === "Contract" &&
            (designation == "Engineer" ||
            designation == "GM/DGM"
              ? areaList.includes(project.area._id)
              : true)
        );
        data.map((project) => {
          const path = "/project-details/" + project._id;
          tempProjectList.push({
            "Project Type": project.projectType,
            "Project Id": project.projectId,
            Zone: project.zone ? project.zone.zoneName : "",
            Area: project.area ? project.area.areaName : "",
            "Notification Number": project.notificationNo,
            State: project.state,
            Subcontractor: project.subcontractorName ? "Yes" : "No",
            "Subcontractor Name": project.subcontractorName
              ? project.subcontractorName
              : "-",
            Technician: project.technician ? project.technician.name : null,
            Vendor: project.vendor ? project.vendor.name : null,
            "Jr. Engineer": project.jrEngineer.name,
            "Date of Start": formattedDate(project.dos),
            "Date of Completion": formattedDate(project.doc),
            "Extended DOC": formattedDate(project.adc),
          });
        });
        setTableContents(tempProjectList);
        modalClose();
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setError(true);
        setLoading(false);
      });
  }, []);

  const modalClose = () => {
    setShow(false);
  };

  return (
    <div id="project-list">
      {loading || error ? (
        <Modal show={show} noHandler={modalClose}>
          {error ? <p>Something went wrong!</p> : <Loader></Loader>}
        </Modal>
      ) : null}
      <ContainerHeader
        formName="Contract Jobs List"
        connLinks={connLinks}
      ></ContainerHeader>
      <Paper>
        <div className="project-list-table">
          {tableContents && tableContents.length > 0 ? (
            <MaterialTables
              headings={headings}
              data={tableContents}
              title="Contract Jobs"
            />
          ) : (
            "No projects"
          )}
        </div>
      </Paper>
    </div>
  );
};

export default ProjectList;
