import React,{useEffect,useState} from 'react'
import Paper from '../../../components/whiteCard/whiteCard'
import * as requests from '../../../api/requests'
import MaterialTables from "../../../components/materialTable/MaterialTables";
import { PDFViewer } from "@react-pdf/renderer";
import Modal from "../../../components/ui/modal/Modal";
import Loader from "../../../components/spinner/Spinner";
import Button from '../../../components/inputComponents/Button'
import TallyReportPrint from '../../../components/tallyReport/TallyReportPrint';
import ContainerHeader from '../../../components/containerHeader/ContainerHeader';
import authCheck from "../../../helpers/auth.helpers";

const headings = [
    "Project Id",
    "Download"
  ];

const TallyBookReport = () =>{
    const { areaAndZone, designation } = authCheck(localStorage.getItem('authCode'));
    
    const [tableContents, setTableContents] = useState([]);
    const [currentProject,setCurrentProject] = useState(null);
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [showPdf,setShowPdf] = useState(false)
    const connLinks = [
        {
          name: "All Reports",
          path: '/reports'
        }
      ]
    useEffect(() => {
        setLoading(true);
        setShow(true);
        requests
        .get({ url: "/projects/getProjectList" })
        .then((response) => {
            let tempProjectList = [];
            let areaList = []
            areaAndZone.map(area=>{
                areaList.push(area.area._id)
            })
            let data = response.project.filter(
                (project) => (designation=="Engineer"||designation=="GM/DGM"?areaList.includes(project.area._id):true)
            );
            data.map((project) => {
            if(project.workOrder){
                tempProjectList.push({
                  "Project Id": project.projectId,
                  "Download": <Button
                    label="Download report"
                    onClick={()=>{
                        setCurrentProject(project)
                        setShowPdf(true)
                    }}
                  ></Button>
                })
            }
  
          });
          setTableContents(tempProjectList);
          modalClose();
          setLoading(false);
      }).catch((e) => {
          setError(true);
          setLoading(false);
        });
    },[])
    const modalClose = () => {
        setShow(false);
    };
    return(
        <>
        <ContainerHeader formName="Tally book Reports" connLinks={connLinks}/>
        {showPdf ? (
                <Paper>
                    <PDFViewer height="800" width="1200" className="app">
                    <TallyReportPrint projectDetails={currentProject}/>
                    </PDFViewer>
                </Paper>
            ) :
            <Paper>
                <div className="project-list-table">
                    {tableContents && tableContents.length > 0 ? (
                        <MaterialTables headings={headings} data={tableContents} title="Tally Book Reports"/>
                    ) : (
                        "No projects"
                    )}
                </div>
            </Paper>}
        </>
    )
}

export default TallyBookReport