import React, { useState } from "react";
import "./ChangePassword.css";
import Paper from "../../components/whiteCard/whiteCard";
import Input from "../../components/inputComponents/Input";
import ContainerHeader from "../../components/containerHeader/ContainerHeader";
import Button from "../../components/inputComponents/Button";
import Axios from "axios";
import url from "../../constants/url";
import Modal from "../../components/ui/modal/Modal";
import ConfirmContent from "../../components/ui/confirmContent/ConfirmContent";
import Loader from "../../components/spinner/Spinner";

const ChangePassword = () => {
  const [show, setShow] = useState(false);
  const [ques, setQues] = useState("");
  const [pass, setPass] = useState({
    password: "",
    confirmPassword: "",
  });
  const inputChangeHandler = (event, value) => {
    setPass({
      ...pass,
      [value]: event.target.value,
    });
  };
  const changePassClickHandler = () => {
    if (pass.password == pass.confirmPassword && pass.password) {
      Axios.post(
        url + "/users/changePassByUser",
        { password: pass.password },
        { withCredentials: true }
      )
        .then((result) => window.location.reload(true))
    } else {
      alert("passwords do not match");
    }
  };
  const modalVisible = () => {
    setQues("Do you want to change your password?");
    setShow(true);
  };

  const modalClose = () => {
    setShow(false);
  };
  return (
    <div id="change-password">
      <Modal show={show} noHandler={modalClose}>
        <ConfirmContent
          yesHandler={changePassClickHandler}
          noHandler={modalClose}
          question={ques}
        ></ConfirmContent>
      </Modal>
      <ContainerHeader formName="Change Password" />
      <Paper>
        <div className="fields">
          <div className="field">
            <Input
              label="Enter New Password"
              size="wide"
              type="text"
              onChange={(e) => inputChangeHandler(e, "password")}
            />
          </div>
          <div className="field">
            <Input
              label="Confirm New Password"
              size="wide"
              type="text"
              onChange={(e) => inputChangeHandler(e, "confirmPassword")}
            />
          </div>
        </div>
        <Button
          type="submit"
          onClick={modalVisible}
          label="Change Password"
          className="primary"
        />
      </Paper>
    </div>
  );
};

export default ChangePassword;
