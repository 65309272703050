import React, { Fragment, useState, useEffect } from "react";
import "./ShowCause.css";
import WhiteCard from "../../../components/whiteCard/whiteCard";
import letterHead from "../../../assets/letterHead.png";
import axios from "axios";
import Button from "../../../components/inputComponents/Button";
import url from "../../../constants/url";
import TextArea from "../../../components/inputComponents/TextArea";
import WorkOrderPrint from "../../../components/workOrderPrint/WorkOrderPrint";
import { PDFViewer } from "@react-pdf/renderer";
import { Redirect } from "react-router-dom";
import Input from "../../../components/inputComponents/Input";
import ContainerHeader from "../../../components/containerHeader/ContainerHeader";

const ShowCause = (props) => {
  const [details, setDetails] = useState({});
  const [showPdf, setshowPdf] = useState(false);
  const [showCauseDate, setShowCauseDate] = useState(Date);
  const [ifTrue, setIfTrue] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [value, setValue] = useState("");
  const connLinks = [
    {
      name: "Project list",
      path: "/projects",
    },
  ];
  useEffect(() => {
    axios
      .get(url + "/projects/projectDetails?projectId=" + props.match.params.id)
      .then((res) => {
        let obj = {
          vendorCode: res.data.vendor ? res.data.vendor.code : "none",
          vendorName: res.data.vendor ? res.data.vendor.name : "none",
          vendorAddress: res.data.vendor.address,
          date: formattedDateInput(res.data.showcauseDate),
          notificationNo: res.data.notificationNo
            ? res.data.notificationNo
            : "none",
          description: res.data.description ? res.data.description : "",
          name: res.data.engineer ? res.data.engineer.name : "none",
          showcause: res.data.showcause,
          showcauseDate: res.data.showcauseDate,
          showCauseData: res.data.showcauseData
            ? res.data.showcauseData
            : "None",
          showcauseId:
            "CIV/SC/" +
            res.data["projectId"].substr(res.data["projectId"].length - 5),
          dos: formattedDate(res.data.dos),
          doc: formattedDate(res.data.doc),
        };
        setDetails(obj);
        setValue(
          `You are hereby required to show cause in ` +
            `writing within 14 days from the date of issuance of this notice as to why ` +
            `there should not be a banning of business with you/your firm ${obj.vendorName} ` +
            `(vendor code: ${obj.vendorCode}) and you/your firm to be debarred from entering ` +
            `into any business with Oil India Limited for the following reasons:` +
            `
         
        i.The above mentioned job was allotted ` +
            `to you with commencement and completion date as ${obj.dos} & ${obj.doc} ` +
            `respectively. Now, it appears to the undersigned that by reason of your wrongful ` +
            `delay or suspension of work or slow progress, the work entrusted to you has ` +
            `not been completed within the stipulated date of completion.
         
        ` +
            `ii.You have miserably failed in performing the obligations as mandated in the Contract,` +
            ` thereby causing loss to the Company. Yours above acts of non-performance warrant debarment` +
            ` of your firm from carrying out any business with OIL in future for a period of 02 (two) years.` +
            `
         
2.0 In view of the above, Company had cancelled the ` +
            `above mentioned job allotted to you, due to the unjustifiable delay in completion of ` +
            `the job as per the terms and conditions of the Contract agreement.` +
            `
      
` +
            `3.0 Your reply on the above, if any, along with the relevant documents, if any, should` +
            ` reach us within the stipulated time as mentioned above. Your reply, if any, and the documents /documentary ` +
            `evidence given in support shall be taken into consideration prior to arriving at a decision in this regard.
         
4.0 Should you fail to reply to this Show Cause notice within the time and manner aforesaid, it will be presumed that ` +
            `you have nothing to say, and we shall proceed accordingly. Further, pending completion of the instant proceedings, you` +
            ` and your firm are hereby placed under suspension with immediate effect, thereby restricting your firm from allotment of ` +
            `further works under this contract until further order.`
        );
        setIfTrue(res.data.showcause);
      })
      .catch((e) => {});
  }, []);

  const formattedDate = (dateObj) => {
    const date = new Date(dateObj);
    const year = date.getFullYear();
    let month = date.getMonth() + 1;
    let dt = date.getDate();

    if (dt < 10) {
      dt = "0" + dt;
    }
    if (month < 10) {
      month = "0" + month;
    }
    return dt + "-" + month + "-" + year;
  };
  const formattedDateInput = (dateObj) => {
    const date = new Date(dateObj);
    const year = date.getFullYear();
    let month = date.getMonth() + 1;
    let dt = date.getDate();

    if (dt < 10) {
      dt = "0" + dt;
    }
    if (month < 10) {
      month = "0" + month;
    }
    return year + "-" + month + "-" + dt;
  };
  function submitShowCause() {
    axios
      .patch(url + "/projects/showCause/" + props.match.params.id, {
        showcause: true,
        showcauseDate: showCauseDate,
        showcauseData: value,
      })
      .then((res) => {
        alert("saved!");
        setRedirect(true);
      })
      .catch((e) => {});
  }

  function printDiv() {
    setshowPdf(true);
  }

  function editShowCause() {
    setIfTrue(false);
    setValue("");
  }

  return (
    <div>
      {redirect ? <Redirect to="/projects" /> : null}
      <ContainerHeader formName="Show Cause Notice" connLinks={connLinks} />
      {showPdf ? (
        <WhiteCard>
          <Fragment>
            <PDFViewer height="800" width="1200" className="app">
              <WorkOrderPrint
                projectDetails={details}
                docName="Show Cause Notice"
              />
            </PDFViewer>
          </Fragment>
        </WhiteCard>
      ) : (
        <WhiteCard>
          <div id="" className="showcause">
            <div className="sc-1">
              <img className="lh-forms" src={letterHead} />
            </div>
            <div className="sc-3"></div>
            <div className="sc-1 sc-11">
              <span>SC: {details.showcauseId}</span>
              {ifTrue ? (
                <div className="datediv">
                  <Input
                    label="Date"
                    type="date"
                    disabled="true"
                    size="wide"
                    value={details.date}
                  />
                </div>
              ) : (
                <div>
                  <Input
                    label="Date"
                    type="date"
                    size="wide"
                    onChange={(e) => setShowCauseDate(e.target.value)}
                  />
                </div>
              )}
            </div>
            <div className="sc-1">
              <div className="sc-col">
                <span>Vendor Name : {details.vendorName}</span>
                <span>Vendor Code : {details.vendorCode}</span>
                <span>Address : {details.vendorAddress}</span>
              </div>
            </div>
            <h3 className="sc-2">Sub: Show Cause Notice</h3>
            <div className="sc-1">
              <div className="sc-col">
                <span>
                  Notification No. / Contract No. : {details.notificationNo}
                </span>
                <span>Description of Work: {details.description}</span>
              </div>
            </div>
            <div className="sc-1">
              <div className="an-row-in">
                Dear Sir/Madam, <br />
                <br />
                <div className="show-cause-text">
                  <TextArea
                    value={ifTrue ? details.showCauseData : value}
                    disabled={ifTrue ? "true" : null}
                    size="wide"
                    rows="300px"
                    onChange={(e) => setValue(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="sc-5">
              <br />
              <br />
              Yours faithfully
              <br />
              <br /> <br />
              <br />
              <p>{details.name}</p>
              <p>Engineer</p>
              <p>Oil India Limited</p>
            </div>
          </div>

          <div className="sc-button">
            {ifTrue ? (
              <div>
                <Button
                  className="success"
                  label="Print Show Cause Notice"
                  onClick={printDiv}
                />
                <Button
                  className="success"
                  label="Edit Show Cause"
                  onClick={editShowCause}
                />
              </div>
            ) : (
              <Button
                className="success"
                label="Issue Show Cause Notice"
                onClick={submitShowCause}
              />
            )}
          </div>
        </WhiteCard>
      )}
    </div>
  );
};

export default ShowCause;
