import React from "react";

const styles = {
  point: {
    height: "7px",
    width: "7px",
    background: "purple",
    borderRadius: "5px",
    zIndex: -50,
    border: "1px solid white",
  },
  box: {
    position: "absolute",
    height: "100px",
    width: "150px",
    background: "white",
    border: "1px solid grey",
    borderRadius: "5px",
    boxShadow: "0 0 5px rgba(50,50,50,0.4)",
    zIndex: 99999,
    transform: "rotate(0deg)",
  },
};

const Pin = (props) => {
  const [box, setBox] = React.useState();

  return (
    <>
      <div
        style={{
          border: "2px solid purple",
          height: "10px",
          width: "10px",
          borderRadius: "5px",
        }}
      >
        <div
          style={box ? styles.box : styles.point}
          onMouseEnter={() =>
            props.setCurrentPoint({
              long: props.long,
              lat: props.lat,
              name: props.name,
              desc: props.desc,
            })
          }
          onMouseLeave={() => props.setCurrentPoint(null)}
        ></div>
      </div>
      <span style={{ top: "5px", fontWeight: 600 }}>{props.name}</span>
    </>
  );
};

export default Pin;
