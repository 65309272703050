import React, { useState, useEffect } from "react";
import ContainerHeader from "../../components/containerHeader/ContainerHeader";
import Paper from "../../components/whiteCard/whiteCard";
import Input from "../../components/inputComponents/Input";
import Table from "../../components/table/unsortedTable/UnsortedTable";
import DropDown from "../../components/inputComponents/DropDown";
import IconButton from "../../components/inputComponents/IconButton";
import "./MaterialIssue.css";
import BlankTable from "../../components/table/blankTable/BlankTable";
import Axios from "axios";
import ConfirmContent from "../../components/ui/confirmContent/ConfirmContent";
import Loader from "../../components/spinner/Spinner";
import Modal from "../../components/ui/modal/Modal";
import MaterialTables from "../../components/materialTable/MaterialTables";
import url from "../../constants/url";
import { Link } from "react-router-dom";
import Button from "../../components/inputComponents/Button";

const MaterialIssue = (props) => {
  const [tableContents, setTableContents] = useState([]);
  const [materials, setMaterials] = useState([]);
  const [update, setUpdate] = useState(false);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [ques, setQues] = useState("");
  const [details, setDetails] = useState({});
  const [error, setError] = useState(false);
  const [tableObj, setTableObj] = useState({
    material: "",
    quantity: 0,
  });
  const connLinks = [
    {
      name: "Project list",
      path: '/projects'
    }
  ]
  const headings = ["Material", "Material Code", "Unit", "Cummulative"];

  useEffect(() => {
    setLoading(true);
    setShow(true);
    Axios.get(
      url + "/projects/projectDetails?projectId=" + props.match.params.projectId
    )
      .then((project) => {
        let tempDetails = {};
        tempDetails["projectType"] = project.data["projectType"];
        tempDetails["projectId"] = project.data["projectId"];
        tempDetails["vendor"] = project.data["vendor"]["name"];
        setDetails(tempDetails);
        let tempMats = [];
        project.data.materials.map((p) => {
          let tempMat = {};
          tempMat["Material"] = p["material"]["description"];
          tempMat["Code"] = p["material"]["code"];
          tempMat["Unit"] = p["material"]["unit"];
          tempMat["cummulative"] = p["cummulative"];
          tempMats = [...tempMats, tempMat];
        });
        setTableContents(tempMats);
        Axios.get(url + "/material/getMaterialList")
          .then((resp) => {
            let tempArr = [];
            resp.data.map((material) => {
              let tempObj = {};
              tempObj["name"] =
                material["description"] + " (" + material["code"] + ")";
              tempObj["_id"] = material["_id"];
              tempArr = [...tempArr, tempObj];
            });
            tempArr.unshift({ name: "Select", _id: "Select" });
            setMaterials(tempArr);
            modalClose();
            setLoading(false);
          })
          .catch((err) => {
            setError(true);
            setLoading(false);
          });
      })
      .catch((err) => {
        setError(true);
        setLoading(false);
      });
  }, [update]);

  const inputChangeHandler = (event) => {
    let tempObj = { ...tableObj };
    tempObj[event.target.name] = event.target.value;
    setTableObj(tempObj);
  };

  const addRowHandler = () => {
    setLoading(true);
    Axios.post(url + "/projects/addMaterial", {
      projectId: props.match.params.projectId,
      materialId: tableObj.material,
      quantity: parseInt(tableObj.quantity),
      reservationNo: tableObj.reservationNo,
      date: tableObj.date,
    })
      .then((resp) => {
        modalClose();
        setLoading(false);
        setUpdate(!update);
        setTableObj({
          ...tableObj,
          quantity: 0,
        });
      })
      .catch((err) => {
        setError(true);
        setLoading(false);
      });
  };

  const modalVisible = () => {
    setError(false);
    setQues("Do you want to add this material?");
    setShow(true);
  };

  const modalClose = () => {
    setShow(false);
  };
  return (
    <div className="materialIssue">
      <Modal show={show} noHandler={modalClose}>
        {loading ? (
          <Loader></Loader>
        ) : !error ? (
          <ConfirmContent
            yesHandler={addRowHandler}
            noHandler={modalClose}
            question={ques}
          ></ConfirmContent>
        ) : (
          <p>Something went wrong!</p>
        )}
      </Modal>
      <ContainerHeader formName="Material Issue" connLinks={connLinks}></ContainerHeader>
      <Paper>
        <div className="materialForm">
          <div className="formField">
            <Input
              name="project-id"
              label="Project ID"
              size="wide"
              value={details.projectId}
              disabled
            />
          </div>
          <div className="formField">
            <Input
              name="project-type"
              label="Project ID"
              size="wide"
              value={details.projectType}
              disabled
            />
          </div>
          <div className="formField">
            <Input
              name="vendor-name"
              label="Vendor Name"
              size="wide"
              value={details.vendor}
              disabled
            />
          </div>
        </div>
        <Link to={"/material-list/" + props.match.params.projectId}>
          <div style={{ marginBottom: "20px" }}>
            <Button
              label="View Detailed Material List"
              className="success"
            ></Button>
          </div>
        </Link>
        <div className="materialTable">
          {tableContents.length != 0 ? (
            <Table contents={tableContents}></Table>
          ) : (
            <BlankTable headings={headings}></BlankTable>
          )}
        </div>
        <div className="addMaterialForm">
          <div className="field">
            <Input
              name="reservationNo"
              label="Reservation No."
              size="wide"
              value={tableObj["reservationNo"]}
              onChange={(e) => inputChangeHandler(e)}
            />
          </div>
          <div className="field">
            <Input
              name="date"
              label="Date"
              size="wide"
              type="date"
              value={tableObj["date"]}
              onChange={(e) => inputChangeHandler(e)}
            />
          </div>
          <div className="field">
            <DropDown
              name="material"
              size="wide"
              label="Material"
              options={materials}
              onChange={(e) => inputChangeHandler(e)}
            />
          </div>
          <div className="field">
            <Input
              name="quantity"
              label="Quantity"
              size="wide"
              value={tableObj["quantity"]}
              onChange={(e) => inputChangeHandler(e)}
            />
          </div>
          <center style={{ marginTop: "3vh" }}>
            <IconButton
              className="field success"
              iconClass="fas fa-plus"
              onClick={modalVisible}
            ></IconButton>
          </center>
        </div>
      </Paper>
    </div>
  );
};

export default MaterialIssue;
