import React from "react";
import "./BlankTable.css";

const BlankTable = (props) => {
  return (
    <table className="blankTable" id="tableBlank">
      <thead>
        <tr>
          {props.headings.map((heading) => {
            return <th>{heading}</th>;
          })}
        </tr>
      </thead>
      <tbody>
        <tr>
          {props.headings.map((heading) => {
            return <td></td>;
          })}
        </tr>
      </tbody>
    </table>
  );
};

export default BlankTable;
