import React, { useEffect, useState } from "react";
import Input from "../../components/inputComponents/Input";
import Button from "../../components/inputComponents/Button";
import Paper from "../../components/whiteCard/whiteCard";
import Axios from "axios";
import url from "../../constants/url";
import Modal from "../../components/ui/modal/Modal";
import ConfirmContent from "../../components/ui/confirmContent/ConfirmContent";
import Loader from "../../components/spinner/Spinner";
import ContainerHeader from "../../components/containerHeader/ContainerHeader";
import Checkbox from "@material-ui/core/Checkbox";
import Spinner from "./5.gif";

const ChangeBg = () => {
  const [file, setFile] = useState();
  const [show, setShow] = useState(false);
  const [functionToPass, setFunctionToPass] = useState(null);
  const [ques, setQues] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [checked, setChecked] = React.useState(true);
  const [showCheckbox, setShwoCheckbox] = React.useState(false);

  useEffect(() => {
    Axios.get(url + "/users/mapVis")
      .then((res) => {
        setChecked(res.data[0].map);
        setShwoCheckbox(true);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  const handleChange = (event) => {
    setShwoCheckbox(false);
    Axios.post(url + "/users/mapVis", { map: !checked })
      .then((res) => {
        setChecked(!checked);
        setShwoCheckbox(true);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  function send() {
    setLoading(true);
    const data = new FormData();
    data.append("file", file);
    setLoading(true);
    Axios.post(url + "/users/changeBg", data)
      .then((res) => {
        modalClose();
        setLoading(false);
        setFile("");
      })
      .catch((err) => {
        console.log(err);
        setError(true);
        setLoading(false);
      });
  }
  function deleteBg() {
    Axios.post(url + "/users/deleteBg")
      .then((res) => {
        alert("Deleted!");
      })
      .catch((err) => {
        alert("Error!");
        console.log(err);
      });
  }
  const modalVisibleForSubmit = () => {
    setFunctionToPass(() => () => send());
    setQues("Do you want to upoad this Photo?");
    setShow(true);
  };
  const modalClose = () => {
    setShow(false);
  };
  return (
    <>
      <ContainerHeader formName="Change Login Background" />
      <Paper>
        <Modal show={show} noHandler={modalClose}>
          {!loading ? (
            !error ? (
              <ConfirmContent
                yesHandler={functionToPass}
                noHandler={modalClose}
                question={ques}
              ></ConfirmContent>
            ) : (
              <p>Something went wrong!</p>
            )
          ) : (
            <Loader></Loader>
          )}
        </Modal>
        <Input
          type="file"
          label="Upload photo"
          onChange={(event) => {
            const file = event.target.files[0];
            setFile(file);
          }}
        />
        <br />
        <Button
          label="Submit"
          className="success"
          onClick={modalVisibleForSubmit}
        />
        <Button label="Delete" className="danger" onClick={deleteBg} />
        <br />
        <p>
          Display map:{" "}
          {showCheckbox ? (
            <Checkbox
              checked={checked}
              onClick={handleChange}
              color="primary"
              inputProps={{ "aria-label": "secondary checkbox" }}
            />
          ) : (
            <img
              src={Spinner}
              style={{ position: "relative", top: "5px", left: "5px" }}
              alt="loader"
              height="20px"
              width="20px"
            />
          )}
        </p>
      </Paper>
    </>
  );
};

export default ChangeBg;
