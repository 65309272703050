const jwt = require('jsonwebtoken');
var authDetails;

function authCheck(token){
    jwt.verify(token, process.env.REACT_APP_USER_SIGN, function(err, decoded) {
        if(err){
            console.log("Error", err);
            authDetails = {};
        } else {
            authDetails = decoded
        }
    })
    return authDetails;
}

module.exports = authCheck;