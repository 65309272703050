import React, { useState } from "react";
import "./Navbar.css";
import menu from "../../assets/icons/menu.svg";
import dashboard from "../../assets/icons/dashboard.svg";
import user from "../../assets/icons/user.svg";
import users from "../../assets/icons/users.svg";
import vendor from "../../assets/icons/vendor.svg";
import project from "../../assets/icons/project.svg";
import material from "../../assets/icons/material.svg";
import vendor1 from "../../assets/icons/vendor1.svg";
import installation from "../../assets/icons/installation.svg";
import machinery from "../../assets/icons/machinery.svg";
import logoIcon from "../../assets/logo/logoicon.png";
import logoText from "../../assets/logo/logotext.png";
import Button from "../inputComponents/Button";
import document from "../../assets/icons/document.svg";
import bell from "../../assets/icons/bell.svg";
import notifications from "../../assets/icons/notifications.svg";
import { Link } from "react-router-dom";
import * as requests from "../../api/requests";
import url, {url_upload} from "../../constants/url";
import Loader from "../../components/spinner/Spinner";
import Modal from "../../components/ui/modal/Modal";
import ConfirmContent from "../../components/ui/confirmContent/ConfirmContent";
import inventory from "../../assets/icons/inventory.svg";
import picture from "../../assets/icons/picture.svg";
import map from "../../assets/icons/map.svg";

const authCheck = require('../../helpers/auth.helpers');

const Navbar = (props) => {
  const {name, designation, photo} = authCheck(localStorage.getItem('authCode'));

  const [openNav, setOpenNav] = React.useState(true);
  const [refresh, setRefresh] = React.useState(false);
  const [show, setShow] = useState(false);
  const [ques, setQues] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const sapNavElements = [

    { key: 1, path: "/dashboard", fieldName: "Dashboard", icon: dashboard },
    { key: 18, path: "/profile", fieldName: "Profile", icon: user },
    { key: 2, path: "/user", fieldName: "Add User", icon: user },
    { key: 3, path: "/users", fieldName: "User List", icon: users },
    { key: 17, path: "/completedProjects", fieldName: "Completed Projects", icon: machinery},
    { key: 4, path: "/vendor", fieldName: "Add Vendor", icon: vendor1 },
    { key: 5, path: "/vendor-list", fieldName: "Vendor List", icon: vendor },
    { key: 6, path: "/godownList", fieldName: "Godown List", icon: machinery },
    { key: 7, path: "/addArea", fieldName: "Add Area", icon: dashboard },
    { key: 8,
      path: "/installation",
      fieldName: "Installation view and add",
      icon: installation,
    },
    { key: 9,
      path: "/add-material",
      fieldName: "Material view and add",
      icon: material,
    },
    { key: 10,
      path: "/add-machinery",
      fieldName: "Machinery view and add",
      icon: machinery,
    },
    { key: 11, path: "/addGodown", fieldName: "Add Godown", icon: machinery },
    { key: 12, path: "/godown", fieldName: "Inventory", icon: inventory },
    { key: 13, path: "/reports", fieldName: "Reports", icon: dashboard },
    { key: 14, path: "/techDocs", fieldName: "Technical Documents", icon: document },
    { key: 15, path: "/map", fieldName: "Map", icon: map },
    { kwy: 16, path: "/change-bg", fieldName: "Utils", icon: picture },
  ];
  const gmdgmNavElements = [

    { key: 1, path: "/dashboard", fieldName: "Dashboard", icon: dashboard },
    { key: 18, path: "/profile", fieldName: "Profile", icon: user },
    { key: 2, path: "/notification", fieldName: "Add notification", icon: bell },
    { key: 3,
      path: "/notifications",
      fieldName: "Notification List",
      icon: notifications,
    },
    { key: 11, path: "/nonAssignedNotifications", fieldName: "N.A Notifications", icon: notifications},
    { key: 4, path: "/currentProjects", fieldName: "Current Projects", icon: machinery },
    { key: 12, path: "/completedProjects", fieldName: "Completed Projects", icon: machinery},
    { key: 5, path: "/godown", fieldName: "Inventory", icon: inventory },
    { key: 6, path: "/vendor-list", fieldName: "Vendor List", icon: vendor },
    { key: 7, path: "/techDocs", fieldName: "Technical Documents", icon: document },
    { key: 8, path: "/reports", fieldName: "Reports", icon: document },
    { key: 9, path: "/map", fieldName: "Map", icon: map },
    { key: 10, path: "/changePass", fieldName: "Change Password", icon: user },
  ];
  const jeNavElements = [

    { key: 1, path: "/dashboard", fieldName: "Dashboard", icon: dashboard },
    { key: 12, path: "/profile", fieldName: "Profile", icon: user },
    { key: 2, path: "/notification", fieldName: "Add notification", icon: bell },
    { key: 3, 
      path: "/notifications",
      fieldName: "Notification List",
      icon: notifications,
    },
    { key: 10, path: "/currentProjects", fieldName: "Current Projects", icon: machinery },
    { key: 11, path: "/completedProjects", fieldName: "Completed Projects", icon: machinery},
    { key: 4, path: "/projects", fieldName: "Project List", icon: machinery },
    { key: 5, path: "/godown", fieldName: "Inventory", icon: inventory },
    { key: 6, path: "/vendor-list", fieldName: "Vendor List", icon: vendor },
    { key: 7, path: "/techDocs", fieldName: "Technical Documents", icon: document },
    { key: 8, path: "/map", fieldName: "Map", icon: map },
    { key: 9, path: "/changePass", fieldName: "Change Password", icon: user },
  ];
  const engineerNavElements = [

    { key: 1, path: "/dashboard", fieldName: "Dashboard", icon: dashboard },
    { key: 11, path: "/profile", fieldName: "Profile", icon: user },
    { key: 2, path: "/notification", fieldName: "Add notification", icon: bell },
    { key: 3,
      path: "/notifications",
      fieldName: "Notification List",
      icon: notifications,
    },
    { key: 4, path: "/currentProjects", fieldName: "Current Projects", icon: machinery },
    { key: 11, path: "/completedProjects", fieldName: "Completed Projects", icon: machinery},
    { key: 5, path: "/godown", fieldName: "Inventory", icon: inventory },
    { key: 6, path: "/vendor-list", fieldName: "Vendor List", icon: vendor },
    { key: 7, path: "/techDocs", fieldName: "Technical Documents", icon: document },
    { key: 8, path: "/reports", fieldName: "Reports", icon: document },
    { key: 9, path: "/map", fieldName: "Map", icon: map },
    { key: 10, path: "/changePass", fieldName: "Change Password", icon: user },
  ];
  const technicianNavElements = [

    { key: 1, path: "/dashboard", fieldName: "Dashboard", icon: dashboard },
    { key: 11, path: "/profile", fieldName: "Profile", icon: user },
    { key: 2, path: "/notification", fieldName: "Add notification", icon: bell },
    { key: 3, 
      path: "/notifications",
      fieldName: "Notification List",
      icon: notifications,
    },
    { key: 4, path: "/currentProjects", fieldName: "Current Projects", icon: machinery },
    { key: 10, path: "/completedProjects", fieldName: "Completed Projects", icon: machinery},
    { key: 5, path: "/godown", fieldName: "Inventory", icon: inventory },
    { key: 6, path: "/vendor-list", fieldName: "Vendor List", icon: vendor },
    { key: 7, path: "/techDocs", fieldName: "Technical Documents", icon: document },
    { key: 8, path: "/map", fieldName: "Map", icon: map },
    { key: 9, path: "/changePass", fieldName: "Change Password", icon: user },
  ];

  let navElements;
  switch (designation) {
    case "SAP":
      navElements = sapNavElements;
      break;
    case "GM/DGM":
      navElements = gmdgmNavElements;
      break;
    case "Engineer":
      navElements = engineerNavElements;
      break;
    case "Jr. Engineer":
      navElements = jeNavElements;
      break;
    case "Technician":
      navElements = technicianNavElements;
      break;
    default:
      break;
  }
  const logout = () => {
    setLoading(true);
    requests
      .get({ url: "/users/logout" })
      .then((data) => {
        modalClose();
        setLoading(false);
        localStorage.clear();
        window.location.pathname = "/login";
      })
      .catch((e) => {
        setError(true);
        setLoading(false);
      });
  };

  const modalVisible = () => {
    setError(false);
    setQues("Do you want to logout?");
    setShow(true);
  };

  const modalClose = () => {
    setShow(false);
  };
  return (
    <>
      <Modal show={show} noHandler={modalClose}>
        {!loading ? (
          !error ? (
            <ConfirmContent
              yesHandler={logout}
              noHandler={modalClose}
              question={ques}
            ></ConfirmContent>
          ) : (
            <p>Something went wrong!</p>
          )
        ) : (
          <Loader></Loader>
        )}
      </Modal>
      <div
        id="navbar"
        style={{
          gridTemplateColumns: openNav
            ? "240px calc(100% - 240px)"
            : window.innerWidth > 800
            ? "60px calc(100% - 60px)"
            : "0px calc(100% - 0px)",
        }}
      >
        <div>
          <div className="nav-logo-container">
            <div id="nav-logo">
              <img src={logoIcon} alt="logo" height="35px" />
              {openNav ? <img src={logoText} alt="logo" height="35px" /> : null}
            </div>
          </div>
        </div>
        <div>
          <div id="top-nav">
            <img
              src={menu}
              alt="hamburger"
              onClick={() => setOpenNav(!openNav)}
            />
            {window.innerWidth > 800 ? (
              <div
                className="button"
                style={{
                  right: openNav
                    ? "240px"
                    : window.innerWidth > 800
                    ? "60px"
                    : "0px",
                }}
              >
                <>
                  <span className="img">{name}</span>
                  <Button label="Logout" onClick={modalVisible}></Button>
                </>
              </div>
            ) : null}
          </div>
        </div>
        <div>
          <div className="side-nav-container">
            <div id="side-nav">
              {openNav ? (
                <div className="side-nav-usr">
                  <div className="user">
                    <div style={{ position: "relative" }}>
                      <img
                        src={url_upload + "/userPics/" + photo}
                        alt="DP"
                        width="90%"
                        height="60%"
                        style={{
                          borderRadius: "50%",
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%,-50%)",
                        }}
                      />
                    </div>
                    <div style={{ padding: "10px", boxSizing: "border-box" }}>
                      <p>{name}</p>
                      <p>{designation}</p>
                    </div>
                  </div>
                </div>
              ) : null}
              {navElements.map((el) => {
                return (
                  <>
                    {el.path === "/map" ? (
                      <a href="/map" key="map">
                        <div
                          className="nav-element"
                          key="key"
                          style={{
                            backgroundColor: el.path.localeCompare(
                              window.location.pathname
                            )
                              ? "#2c3e50"
                              : "#1abc9c",
                          }}
                        >
                          <div className="nav-element-content">
                            <p>
                              <img
                                src={el.icon}
                                alt="dashboard icon"
                                height="16px"
                              />{" "}
                              {openNav ? el.fieldName : null}
                            </p>
                          </div>
                          {el.path.localeCompare(
                            window.location.pathname
                          ) ? null : (
                            <div className="selected-nav-el"></div>
                          )}
                        </div>
                      </a>
                    ) : (
                      <Link
                        to={el.path}
                        onClick={() => setRefresh(!refresh)}
                        key={el.key}
                      >
                        <div
                          className="nav-element"
                          key={el.fieldName}
                          style={{
                            backgroundColor: el.path.localeCompare(
                              window.location.pathname
                            )
                              ? "#2c3e50"
                              : "#1abc9c",
                          }}
                        >
                          <div className="nav-element-content">
                            <p>
                              <img
                                src={el.icon}
                                alt="dashboard icon"
                                height="16px"
                              />{" "}
                              {openNav ? el.fieldName : null}
                            </p>
                          </div>
                          {el.path.localeCompare(
                            window.location.pathname
                          ) ? null : (
                            <div className="selected-nav-el"></div>
                          )}
                        </div>
                      </Link>
                    )}
                  </>
                );
              })}
              {window.innerWidth > 800 ? null : (
                <div style={{ marginLeft: "20px", marginTop: "20px" }}>
                  <Button label="Logout" onClick={modalVisible}></Button>
                </div>
              )}
            </div>
          </div>
        </div>
        <div id="body">{props.children}</div>
      </div>
    </>
  );
};

export default Navbar;
