import React from "react";
import { Switch } from "react-router-dom";
import ProtectedRoute from "../components/protectedRoute/ProtectedRoute";
import AddUser from "../containers/addUser/AddUser";
import UserList from "../containers/userList/UserList";
import AddVendor from "../containers/addVendor/AddVendor";
import VendorList from "../containers/vendorList/VendorList";
import AddArea from "../containers/addArea/AddArea";
import AddInstallation from "../containers/addInstallation/AddInstallation";
import AddMaterial from "../containers/addMaterial/AddMaterial";
import AddMachinery from "../containers/addMachinery/AddMachinery";
import ProjectList from "../containers/projectList/ProjectList";
import ProjectDetails from "../containers/projectDetails/ProjectDetails";
import EditProject from "../containers/editProject/EditProject";
import TechnicalDocs from "../containers/technicalDocs/TechnicalDocs";
import ShowCause from "../containers/forms/showCause/ShowCause";
import VendorListReport from "../containers/reports/vendorList/VendorList";
import ShowCauseReport from "../containers/reports/vendorShowCauseList/VendorShowCause";
import EditUser from "../containers/editUser/EditUser";
import Reports from "../containers/reports/Reports";
import JobComplete from "../containers/reports/jobComplete/JobComplete";
import VendorJobs from "../containers/reports/vendorJobs/VendorJobs";
import UserJobs from "../containers/reports/userJobs/UserJobs";
import ContractJobs from "../containers/reports/contractJobs/ContractJobs";
import ExtendedJobs from "../containers/reports/extendedJobs/ExtendedJobs";
import JobCertificate from "../containers/reports/jobCertificate/JobCertificate";
import InProgressProjects from "../containers/reports/inProgressProjects/InProgressProjects";
import HaltedProjectsList from "../containers/reports/haltedProjectsList/HaltedProjectsList";
import RemovedProjectsList from "../containers/reports/removedProjectsList/RemovedProjectsList";
import NoPr from "../containers/reports/allProjectsNoPr/AllProjectsNoPr";
import Dashboard from "../containers/dashboard/Dashboard";
import TallyBookReport from "../containers/reports/tallyBookReport/TallyBookReport";
import AllProjects from "../containers/reports/allProjects/AllProjects";
import JobsJE from "../containers/reports/jobsJE/JobsJE";
import JobsEng from "../containers/reports/jobsEng/JobsEng";
import JobsTech from "../containers/reports/jobsTech/JobsTech.js";
import JobsInst from "../containers/reports/jobsInst/JobsInst";
import SubcontractedJobs from "../containers/reports/subcontractedJobs/SubcontractedJobs";
import DelayedJobs from "../containers/reports/delayedJobs/DelayedJobs";
import JobAgainstNotification from "../containers/reports/jobAgainstNotification/JobAgainstNotification";
import ProjectsShowCause from "../containers/reports/projectsShowCause/projectsShowCause";
import CumulativeMaterialTotal from "../containers/reports/cumulativeMaterialTotal/CumulativeMaterialTotal";
import CumulativeMaterialJob from "../containers/reports/cumulativeMaterialJob/CumulativeMaterialJob";
import ChangeBg from "../containers/changeBg/ChangeBg";
import AddGodown from "../containers/addGodown/AddGodown";
import GodownList from "../containers/godownList/GodownList";
import Godown from "../containers/godown/Godown";
import Map from "../containers/map/Map";
import CProjects from '../containers/completedProjects/cprojects';
import NonEstimateProjects from "../containers/reports/nonEstimateProject/nonEstimateProject";
import UserProfile from "../containers/userProfile/UserProfile";

const SapRoutes = (props) => {
  const [isAuth, setIsAuth] = React.useState(true);
  return (
    <Switch>
      <ProtectedRoute
        path="/profile"
        component={UserProfile}
        isAuth={isAuth}
      />
      <ProtectedRoute
        path="/dashboard"
        component={Dashboard}
        isAuth={props.isAuth}
      />
      
      <ProtectedRoute path="/user" component={AddUser} isAuth={isAuth} />
      <ProtectedRoute path="/users" component={UserList} isAuth={isAuth} />
      <ProtectedRoute path="/vendor" component={AddVendor} isAuth={isAuth} />
      <ProtectedRoute
        path="/vendor-list"
        component={VendorList}
        isAuth={isAuth}
      />
      <ProtectedRoute path="/addArea" component={AddArea} isAuth={isAuth} />
      <ProtectedRoute
        path="/installation"
        component={AddInstallation}
        isAuth={isAuth}
      />
      <ProtectedRoute
        path="/show-cause/:id"
        component={ShowCause}
        isAuth={isAuth}
      />
      <ProtectedRoute
        path="/add-material"
        component={AddMaterial}
        isAuth={isAuth}
      />
      <ProtectedRoute
        path="/add-machinery"
        component={AddMachinery}
        isAuth={isAuth}
      />
      <ProtectedRoute
        path="/projects"
        component={ProjectList}
        isAuth={isAuth}
      />
      <ProtectedRoute
        path="/project-details/:id"
        component={ProjectDetails}
        isAuth={isAuth}
      />
      <ProtectedRoute
        path="/editProject/:projectId"
        component={EditProject}
        isAuth={isAuth}
      />
      <ProtectedRoute
        path="/techdocs"
        component={TechnicalDocs}
        isAuth={isAuth}
      />
      <ProtectedRoute
        path="/edit-user/:email"
        component={EditUser}
        isAuth={isAuth}
      />
      <ProtectedRoute
        path="/reports"
        component={Reports}
        exact={true}
        isAuth={isAuth}
      />
      <ProtectedRoute
        path="/reports/report-vendor-list"
        component={VendorListReport}
        isAuth={isAuth}
      />
      <ProtectedRoute
        path="/reports/report-show-cause"
        component={ShowCauseReport}
        isAuth={isAuth}
      />
      <ProtectedRoute
        path="/reports/all-projects"
        component={AllProjects}
        exact={true}
        isAuth={isAuth}
      />
      <ProtectedRoute
        path="/reports/project-show-cause"
        component={ProjectsShowCause}
        isAuth={isAuth}
      />
      <ProtectedRoute
        path="/reports/in-progress-projects"
        component={InProgressProjects}
        exact={true}
        isAuth={isAuth}
      />
      <ProtectedRoute
        path="/reports/jobComplete"
        component={JobComplete}
        exact={true}
        isAuth={isAuth}
      />
      <ProtectedRoute
        path="/reports/contract-jobs"
        component={ContractJobs}
        exact={true}
        isAuth={isAuth}
      />
      <ProtectedRoute
        path="/reports/JobsJE"
        component={JobsJE}
        exact={true}
        isAuth={isAuth}
      />
      <ProtectedRoute
        path="/reports/JobsEng"
        component={JobsEng}
        exact={true}
        isAuth={isAuth}
      />
      <ProtectedRoute
        path="/reports/JobsTech"
        component={JobsTech}
        exact={true}
        isAuth={isAuth}
      />
      <ProtectedRoute
        path="/reports/JobsInstallations"
        component={JobsInst}
        isAuth={isAuth}
      />
      <ProtectedRoute
        path="/reports/halted-projects"
        component={HaltedProjectsList}
        exact={true}
        isAuth={isAuth}
      />
      <ProtectedRoute
        path="/reports/vendorJobs"
        component={VendorJobs}
        exact={true}
        isAuth={isAuth}
      />
      <ProtectedRoute
        path="/reports/extended-jobs"
        component={ExtendedJobs}
        exact={true}
        isAuth={isAuth}
      />
      <ProtectedRoute
        path="/reports/removed-projects"
        component={RemovedProjectsList}
        exact={true}
        isAuth={isAuth}
      />
      <ProtectedRoute
        path="/reports/all-projects-nopr"
        component={NoPr}
        exact={true}
        isAuth={isAuth}
      />
      <ProtectedRoute
        path="/reports/userJobs"
        component={UserJobs}
        exact={true}
        isAuth={isAuth}
      />
      <ProtectedRoute
        path="/reports/job-certificate"
        component={JobCertificate}
        exact={true}
        isAuth={isAuth}
      />
      <ProtectedRoute
        path="/reports/tallyBookReport"
        component={TallyBookReport}
        isAuth={isAuth}
      ></ProtectedRoute>
      <ProtectedRoute
        path="/reports/subcontracted-jobs"
        component={SubcontractedJobs}
        exact={true}
        isAuth={isAuth}
      />
      <ProtectedRoute
        path="/reports/delayed-jobs"
        component={DelayedJobs}
        exact={true}
        isAuth={isAuth}
      />
      <ProtectedRoute
        path="/reports/work-against-notification"
        component={JobAgainstNotification}
        exact={true}
        isAuth={isAuth}
      />
      <ProtectedRoute 
        path="/reports/non-estimate-projects"
        component={NonEstimateProjects}
        exact={true}
        isAuth={isAuth}
      />
      <ProtectedRoute
        path="/reports/cumulative-total"
        component={CumulativeMaterialTotal}
        exact={true}
        isAuth={isAuth}
      />
      <ProtectedRoute
        path="/reports/cumulative-total-job"
        component={CumulativeMaterialJob}
        exact={true}
        isAuth={isAuth}
      />
      <ProtectedRoute
        path="/change-bg"
        component={ChangeBg}
        exact={true}
        isAuth={isAuth}
      />
      <ProtectedRoute
        path="/addGodown"
        component={AddGodown}
        exact={true}
        isAuth={isAuth}
      />
      <ProtectedRoute
        path="/godownList"
        component={GodownList}
        exact={true}
        isAuth={isAuth}
      />
      <ProtectedRoute path="/godown" component={Godown} isAuth={isAuth} />
      <ProtectedRoute exact path="/map" component={Map} isAuth={isAuth} />
      <ProtectedRoute exact path="/completedProjects" component={CProjects} isAuth={isAuth} />
    </Switch>
  );
};

export default SapRoutes;
