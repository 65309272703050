import React, { useState, useEffect } from "react";
import "./AddInstallation.css";
import Paper from "../../components/whiteCard/whiteCard";
import ContainerHeader from "../../components/containerHeader/ContainerHeader";
import Input from "../../components/inputComponents/Input";
import DropDown from "../../components/inputComponents/DropDown";
import Button from "../../components/inputComponents/Button";
import EditDelete from "../../components/editDelete/EditDelete";
import Axios from "axios";
import Modal from "../../components/ui/modal/Modal";
import ConfirmContent from "../../components/ui/confirmContent/ConfirmContent";
import Loader from "../../components/spinner/Spinner";
import MaterialTables from "../../components/materialTable/MaterialTables";
import url from "../../constants/url";
import Alert from "@material-ui/lab/Alert";
import Typography from "@material-ui/core/Typography";
import axios from "axios";

const AddInstallation = () => {
  const [formErrors, setFormErrors] = useState([]);
  const [tableContents, setTableContents] = useState([]);
  const [show, setShow] = useState(false);
  const [functionToPass, setFunctionToPass] = useState(null);
  const [ques, setQues] = useState("");
  const [loading, setLoading] = useState(false);
  const [update, setUpdate] = useState(true);
  const [error, setError] = useState(false);
  const [upload, setUpload] = useState(false);
  const [file, setFile] = useState(null);
  var [isSubmit, setSubmit] = useState(false);

  const [areaOp, setAreaOp] = useState([
    {
      _id: null,
      name: "Select",
    },
  ]);
  const [edit, setEdit] = useState(false);
  const headings = ["SL No", "Name of the Installation", "Area", "Actions"];
  useEffect(() => {
    setLoading(true);
    setShow(true);
    Axios.all([
      Axios.get(url + "/installations/allInstallations"),
      Axios.get(url + "/areas/getAreas"),
    ])
      .then((responseArr) => {
        let apiData = [];
        let contents = [];
        let areaArray = [
          {
            _id: null,
            name: "Select",
          },
        ];
        let areaData = [];
        apiData = responseArr[0].data;
        areaData = responseArr[1].data;
        let i = 1;
        apiData.map((data) => {
          let tempObj = {};
          tempObj["SL No"] = i;
          i = i + 1;
          tempObj["Name of the Installation"] = data["name"];
          tempObj["Area"] = data["area"] ? data["area"]["areaName"] : null;
          tempObj["Actions"] = (
            <EditDelete
              deleteHandler={() => {
                modalVisibleForDelete(data["_id"]);
              }}
              editHandler={() => {
                editInstallation(data);
              }}
            ></EditDelete>
          );
          contents = [...contents, tempObj];
        });

        areaData.map((area) => {
          areaArray.push({
            _id: area._id,
            name: area.areaName,
          });
        });

        setTableContents(contents);
        setAreaOp(areaArray);
        modalClose();
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setError(true);
        setLoading(false);
      });
  }, [update]);

  const [formData, setFormData] = useState({
    id: "",
    name: "",
    area: "",
  });

  function handleChange(event) {
    const { name, value } = event.target;
    setFormData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  }

  const onBulkUpload = () => {
    const data = new FormData();
    data.append("name", "bulkUpload");
    data.append("file", file);
    modalClose();
    axios.post(`${url}/bulkUpload/installationUpload`, data)
    .then((res) => {
      setUpdate(!update);
    })
    .catch((err) => {
      setShow(true);
      setLoading(false);
      setUpload(false);
      setError(true);
    })
  }

  const modalBulkUpload = () => {
      setUpload(true);
      setFormErrors([]);  
      setShow(true);
  }

  const modalVisibleForEdit = () => {
    setError(false);
    let errors = [];
    const toCheck = {
      name: "NAME",
      area: "AREA",
    };
    Object.keys(toCheck).map((key) => {
      if (!formData[key]) {
        errors.push(toCheck[key] + " is required");
      }
    });
    if (errors.length > 0) {
      setFormErrors(errors);
      return;
    }
    setFormErrors([]);
    setFunctionToPass(edit ? () => () => editForm() : () => () => submitForm());
    setQues(
      edit
        ? "Do you want to edit this installation?"
        : "Do you want to submit this installation?"
    );
    setShow(true);
  };

  const modalVisibleForDelete = (id) => {
    setError(false);
    setFunctionToPass(() => () => deleteInstallation(id));
    setQues("Do you want to delete this installation?");
    setShow(true);
  };

  const modalClose = () => {
    setShow(false);
    setUpload(false);
  };

  function editInstallation(data) {
    setEdit(true);
    setFormData({
      name: data["name"],
      area: data["area"]["_id"],
      id: data["_id"],
    });
  }
  function deleteInstallation(id) {
    setLoading(true);
    Axios.delete(url + `/installations/deleteInstallation/${id}`)
      .then((_) => {
        modalClose();
        setLoading(false);
        setFormData({
          name: "",
          area: "",
          id: "",
        });
        setUpdate(!update);
      })
      .catch((err) => {
        setError(true);
        setLoading(false);
      });
  }
  function editForm() {
    setLoading(true);
    Axios.patch(url + `/installations/updateInstallation/${formData.id}`, {
      name: formData.name,
      area: formData.area,
    })
      .then((result) => {
        modalClose();
        setLoading(false);
        setFormData({
          name: "",
          area: "",
          id: "",
        });
        setUpdate(!update);
      })
      .catch((err) => {
        setError(true);
        setLoading(false);
      });
  }

  function submitForm() {
    setLoading(true);
    Axios.post(url + "/installations/createInstallation", {
      name: formData.name,
      area: formData.area,
    })
      .then((result) => {
        modalClose();
        setLoading(false);
        setFormData({
          name: "",
          id: "",
        });
        setUpdate(!update);
      })
      .catch((err) => {
        setError(true);
        setLoading(false);
      });
  }

  return (
    <div id="add-installation">
      <Modal show={show} noHandler={modalClose}>
        {!loading ? 
          !upload ? (
          !error ? (
            <ConfirmContent
              yesHandler={functionToPass}
              noHandler={modalClose}
              question={ques}
            ></ConfirmContent>
          ) : (
            <p>Something went wrong!</p>
          )
        )
        :
        <>
          <Typography>Select a .xlsx file of not more than 2MB that contains the vendor details.</Typography>
            <Input
                type="file"
                value={isSubmit ? "" : null }
                onChange={(e) => {
                    setSubmit(false);
                    setFile(e.target.files[0])}
                }
                style={{marginBottom: '10px'}}
            />
            <Button 
                className="primary"
                type="button"
                label="Bulk Upload"
                onClick={onBulkUpload}
                disabled={file ? false : true}
            />
            <Button 
                className="primary"
                type="button"
                label="Cancel"
                onClick={() => {
                    setFile(false);
                    modalClose();
                }}
            />
        </>
         : (
          <Loader></Loader>
        )}
      </Modal>
      <ContainerHeader formName="Add Installation"> </ContainerHeader>
      <Paper>
        <div className="add-installation-form">
          <div className="fields">
            <div className="field">
              <Input
                name="name"
                label="Name of the Installation*"
                size="wide"
                onChange={handleChange}
                value={formData.name}
              />
            </div>
            <div className="field">
              <DropDown
                label="Area*"
                name="area"
                options={areaOp}
                size="wide"
                onChange={handleChange}
                value={formData.area}
              />
            </div>
          </div>
          <br />
          <hr />
          {formErrors.map((error) => {
            return <Alert severity="info">{error}</Alert>;
          })}
          {edit ? (
            <Button
              className="primary"
              type="submit"
              label="Edit"
              onClick={modalVisibleForEdit}
            />
          ) : (
            <Button
              className="success"
              type="submit"
              label="Submit"
              onClick={modalVisibleForEdit}
            />
          )}
          <Button 
              className="primary"
              label="Bulk Upload"
              onClick={modalBulkUpload}
          />
        </div>
        <br />
        <br />
        {/* <div className="installationListTable">
          {tableContents.length !== 0 ? (
            <Table
              contents={tableContents}
              setContents={setTableContents}
            ></Table>
          ) : (
            <BlankTable headings={headings}></BlankTable>
          )}
        </div> */}
        <MaterialTables
          data={tableContents}
          headings={headings}
          title="Installation Database"
        />
      </Paper>
    </div>
  );
};

export default AddInstallation;
