import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import letterHead from "./Capture.PNG";
const styles = StyleSheet.create({
  body: {
    paddingBottom: 30,
    paddingTop: 30,
    paddingHorizontal: 35,
    // justifyContent: 'center',
    flexDirection: "column",
    backgroundColor: "#eeece1",
  },
  image: {
    justifyContent: "center",
    marginBottom: 20,
  },
  topHr: {
    width: "100%",
    borderBottom: "1 solid black",
    fontSize: "9",
    flexDirection: "row",
    justifyContent: "space-between",
    height: 18,
  },
  halfWidth: {
    width: "50%",
  },
  heading: {
    fontSize: 11,
    marginTop: 10,
    marginBottom: 10,
    fontWeight: 600,
  },
  para: {
    fontSize: 9,
    fontWeight: 200,
    marginLeft: 20,
  },
  thirdWidth: {
    width: "33.33%",
    fontSize: 9,
    borderTop: "1 solid black",
    borderBottom: "1 solid black",
    height: 18,
    paddingTop: 3,
    paddingLeft: 10,
  },
  row: {
    flexDirection: "row",
    backgroundColor: "#d3dfee",
    boxSizing: "border-box",
  },
  row2: {
    flexDirection: "row",
    boxSizing: "border-box",
  },
  tableHead: {
    borderTop: "1 solid black",
    borderBottom: "1 solid black",
    flexDirection: "row",
    height: 18,
  },
  fifthWidth: {
    width: "20%",
    fontSize: 9,
    borderTop: "1 solid black",
    borderBottom: "1 solid black",
    height: 18,
    paddingTop: 3,
    paddingLeft: 10,
  },
  headingRed: {
    color: "red",
    fontSize: 9,
    marginTop: 10,
    marginBottom: 10,
    fontWeight: 600,
  },
  signHolder: {
    flexDirection: "row",
    boxSizing: "border-box",
    justifyContent: "space-between",
    marginTop: 150,
  },
  sign: {
    alignItems: "center",
    width: "33.33%",
    fontSize: 9,
    height: 16,
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 10,
    left: 0,
    right: 10,
    textAlign: "right",
    color: "black",
  },
});
const formattedDate = (dateObj) => {
  const date = new Date(dateObj);
  const year = date.getFullYear();
  let month = date.getMonth() + 1;
  let dt = date.getDate();

  if (dt < 10) {
    dt = "0" + dt;
  }
  if (month < 10) {
    month = "0" + month;
  }
  return dt + "/" + month + "/" + year;
};
const cummulativeProgress = (days, id) => {
  let total = 0;
  for (let i = 0; i < days.length; i++) {
    total = days[i].progress + total;
    if (days[i]._id == id) {
      break;
    }
  }
  return total;
};

const getConsumed = (name, days, dayId) => {
  let total = 0;
  for (let i = 0; i < days.length; i++) {
    for (let j = 0; j < days[i].materialUsed.length; j++) {
      if (name == days[i].materialUsed[j].name) {
        total = days[i].materialUsed[j].consumed + total;
      }
    }
    if (days[i]._id == dayId) {
      break;
    }
  }
  return total;
};

const TallyReportPrint = (props) => {
  return (
    <Document>
      <Page size="A4" style={styles.body} wrap>
        {props.projectDetails.progress.perDayProgress.map((day) => {
          return (
            <>
              <Image style={styles.image} src={letterHead}></Image>
              <Text style={{ textAlign: "right", fontSize: "10" }}>
                Date: {formattedDate(day.dateOfEntry)}
              </Text>
              <View style={styles.topHr}>
                <Text style={styles.halfWidth}>
                  Notification No/Contract No:{" "}
                  {props.projectDetails.notificationNo}
                </Text>
                <Text style={styles.halfWidth}>
                  Vendor: {props.projectDetails.vendor.name}
                </Text>
              </View>
              <View>
                <Text style={styles.heading}>1.0 Job Description</Text>
                <View
                  style={{
                    width: "100%",
                    minHeight: "100",
                    border: "1 solid black",
                    marginBottom: "20",
                  }}
                >
                  <Text style={{ ...styles.para, paddingBottom: 20 }}>
                    {props.projectDetails.description}
                  </Text>
                </View>
                <View style={styles.row}>
                  <Text style={styles.thirdWidth}>
                    {" "}
                    Project ID: {props.projectDetails.projectId}
                  </Text>
                  <Text style={styles.thirdWidth}>
                    {" "}
                    Job Type: {props.projectDetails.projectType}
                  </Text>
                  <Text style={styles.thirdWidth}>
                    {" "}
                    Technician: {props.projectDetails.technician.name}
                  </Text>
                </View>
                <View style={styles.row2}>
                  <Text style={styles.thirdWidth}>
                    {" "}
                    Area: {props.projectDetails.area.areaName}
                  </Text>
                  <Text style={styles.thirdWidth}>
                    {" "}
                    Location/Installation:{" "}
                    {props.projectDetails.installation.name}
                  </Text>
                  <Text style={styles.thirdWidth}>
                    {" "}
                    Validity of LCC/LCA:{" "}
                    {formattedDate(props.projectDetails.validityLLC)}
                  </Text>
                </View>
                <View style={styles.row}>
                  <Text style={styles.thirdWidth}>
                    {" "}
                    DOS: {formattedDate(props.projectDetails.dos)}
                  </Text>
                  <Text style={styles.thirdWidth}>
                    {" "}
                    DOC: {formattedDate(props.projectDetails.doc)}
                  </Text>
                  <Text style={styles.thirdWidth}>
                    {" "}
                    Actual DOC: {formattedDate(props.projectDetails.adc)}
                  </Text>
                </View>
                <View style={styles.row2}>
                  <Text></Text>
                </View>
                <View style={styles.topHr}>
                  <Text style={styles.halfWidth}>Weather: {day.weather}</Text>
                  <Text style={styles.halfWidth}>Status: {day.status}</Text>
                </View>
              </View>
              <View>
                <Text style={styles.heading}>2.0 Details of work executed</Text>
                <Text style={{ ...styles.para, paddingBottom: 20 }}>
                  {day.detailsOfWork}
                </Text>
                <View style={styles.row}>
                  <Text style={styles.thirdWidth}>
                    {" "}
                    Today's Progress: {day.progress}%
                  </Text>
                  <Text style={styles.thirdWidth}>
                    {" "}
                    Cummulative Progress:{" "}
                    {cummulativeProgress(
                      props.projectDetails.progress.perDayProgress,
                      day._id
                    )}
                    %
                  </Text>
                  <Text style={styles.thirdWidth}>
                    {" "}
                    No. of Labours Engaged: {day.numberOfLabours}
                  </Text>
                </View>
              </View>
              <View>
                <Text style={styles.heading}>3.0 Machinery Utilization</Text>
                <View style={styles.tableHead}>
                  <Text style={styles.thirdWidth}> Type of Machinery</Text>
                  <Text style={styles.thirdWidth}>
                    {" "}
                    Description of Machinery
                  </Text>
                  <Text style={styles.thirdWidth}> No. of Units Used</Text>
                </View>
                {day.machineryUsed
                  ? day.machineryUsed.map((machine) => {
                      return (
                        <View style={styles.row}>
                          <Text style={styles.thirdWidth}>
                            {" "}
                            {machine.typeOfMachine}
                          </Text>
                          <Text style={styles.thirdWidth}> {machine.name}</Text>
                          <Text style={styles.thirdWidth}>
                            {" "}
                            {machine.quantity}
                          </Text>
                        </View>
                      );
                    })
                  : null}
              </View>
              <View>
                <Text style={styles.heading}>
                  4.0 Material Consumption Details
                </Text>
                <View style={styles.tableHead}>
                  <Text style={styles.fifthWidth}> Material</Text>
                  <Text style={styles.fifthWidth}> Unit</Text>
                  <Text style={styles.fifthWidth}> Total Quantity Issued</Text>
                  <Text style={styles.fifthWidth}> Consumed Quantity</Text>
                  <Text style={styles.fifthWidth}> Balance </Text>
                </View>
                {day.machineryUsed
                  ? day.materialUsed.map((material) => {
                      return (
                        <View style={styles.row}>
                          <Text style={styles.fifthWidth}>
                            {" "}
                            {material.name}
                          </Text>
                          <Text style={styles.fifthWidth}>
                            {" "}
                            {material.unit}
                          </Text>
                          <Text style={styles.fifthWidth}>
                            {" "}
                            {material.quantity}
                          </Text>
                          <Text style={styles.fifthWidth}>
                            {" "}
                            {material.consumed}
                          </Text>
                          <Text style={styles.fifthWidth}>
                            {" "}
                            {material.quantity -
                              getConsumed(
                                material.name,
                                props.projectDetails.progress.perDayProgress,
                                day._id
                              )}
                          </Text>
                        </View>
                      );
                    })
                  : null}
              </View>
              <View>
                <Text style={styles.heading}>5.0 Other Details:</Text>
                <Text style={styles.headingRed}>Saftey Remarks:</Text>
                <View
                  style={{
                    width: "100%",
                    minHeight: "50",
                    border: "1 solid black",
                  }}
                >
                  <Text style={{ ...styles.para, paddingBottom: 20 }}>
                    {day.saftey}
                  </Text>
                </View>
                <Text style={styles.headingRed}>Instructions to Vendor:</Text>
                <View
                  style={{
                    width: "100%",
                    minHeight: "50",
                    border: "1 solid black",
                  }}
                >
                  <Text style={{ ...styles.para, paddingBottom: 20 }}>
                    {day.instructionToContractor}
                  </Text>
                </View>
                <Text style={styles.headingRed}>Hindrance:</Text>
                <View
                  style={{
                    width: "100%",
                    minHeight: "50",
                    border: "1 solid black",
                  }}
                >
                  <Text style={{ ...styles.para, paddingBottom: 20 }}>
                    {day.hinderance}
                  </Text>
                </View>
              </View>
              <View>
                <View style={styles.signHolder}>
                  <View style={styles.sign}>
                    <Text>Signature of Technician</Text>
                    <Text>({props.projectDetails.technician.name})</Text>
                  </View>
                  <View style={styles.sign}>
                    <Text>Signature of JE</Text>
                    <Text>({props.projectDetails.jrEngineer.name})</Text>
                  </View>
                  <View style={styles.sign}>
                    <Text>Signature of Engineer</Text>
                    <Text style={{ marginLeft: "0 auto" }}>
                      ({props.projectDetails.engineer.name})
                    </Text>
                  </View>
                </View>
              </View>
              <Text break></Text>
            </>
          );
        })}
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) => `${pageNumber}`}
          fixed
        />
      </Page>
    </Document>
  );
};

export default TallyReportPrint;
