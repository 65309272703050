import React from 'react'
import Home from '../../containers/dashboardAnalytics/dasboardAnalytics';
import {
    Route
  } from "react-router-dom";

const ProtectedRoute = (props) =>{
    return(
        <Route path = {props.path} component = {props.isAuth?props.component:Home} exact={props.exact}>  
            
        </Route>
    )
}

export default ProtectedRoute