import React, { useState, useEffect } from "react";
import ContainerHeader from "../../components/containerHeader/ContainerHeader";
import Paper from "../../components/whiteCard/whiteCard";
import Button from "../../components/inputComponents/Button";
import "./VendorList.css";
import EditDelete from "../../components/editDelete/EditDelete";
import Axios from "axios";
import Input from "../../components/inputComponents/Input";
import DropDown from "../../components/inputComponents/DropDown";
import Modal from "../../components/ui/modal/Modal";
import ConfirmContent from "../../components/ui/confirmContent/ConfirmContent";
import Loader from "../../components/spinner/Spinner";
import MaterialTables from "../../components/materialTable/MaterialTables";
import url, { url_upload } from "../../constants/url";
import Pagination from '@material-ui/lab/Pagination';

const VendorList = () => {
  const [flag, setFlag] = useState(false);
  const [tableContents, setTableContents] = useState([]);
  const [show, setShow] = useState(false);
  const [update, setUpdate] = useState(false);
  const [functionToPass, setFunctionToPass] = useState(false);
  const [loading, setLoading] = useState(false);
  const [ques, setQues] = useState("");
  var [isSubmit, setSubmit] = useState(false);
  const [error, setError] = useState(false);

  const [paginationCount, setPaginationCount] = useState(1);
  const [page, setPage] = React.useState(1);
  const [formData, setFormData] = useState({
    name: "",
    id: "",
    code: "",
    status: "",
    zoneCode: 0,
    email: "",
    class: "",
    phone: 0,
    photo: "noimage.jpg",
    signature: "noimage.jpg",
    cumulativeAmt: 0,
  });
  const status = [
    { name: "Select", _id: "Select" },
    { name: "Active", _id: "Active" },
    { name: "Inactive", _id: "Inactive" },
    { name: "Removed", _id: "Removed" },
  ];

  const headings = [
    "Name",
    "Code",
    "Address",
    "Status",
    "Zone Code",
    "Email",
    "Vendor Class",
    "Phone No.",
    "Cumulative Amt.",
    "Photo",
    "Signature",
    "Actions",
  ];

  useEffect(() => {
    setLoading(true);
    setShow(true);
    let tempArr = [];
    Axios.post(url + "/vendors/getVendors", {designation: "SAP"}, { params: { page: page } })
      .then((response) => {
        setPaginationCount(response.data.count);

        response.data.vendor.map((vendor) => {
          let tempObj = {};
          tempObj["Name"] = vendor["name"];
          tempObj["Code"] = vendor["code"];
          tempObj["Address"] = vendor["address"];
          tempObj["Status"] = vendor["status"];
          tempObj["Zone Code"] = vendor["zoneCode"];
          tempObj["Email"] = vendor["email"];
          tempObj["Vendor Class"] = vendor["class"];
          tempObj["Phone No."] = vendor["phone"];
          tempObj["Photo"] =
            vendor["photo"] === "no-photo" ? (
              vendor["photo"]
            ) : (
              <img
                src={`${url_upload}/vendors/${vendor["photo"]}`}
                alt="no-photo"
                style={{ width: "60px", height: "60px" }}
              />
            );
          tempObj["Signature"] =
            vendor["signature"] === "no-signature" ? (
              vendor["signature"]
            ) : (
              <img
                src={`${url_upload}/vendors/${vendor["signature"]}`}
                alt="no-signature"
                style={{ width: "60px", height: "60px" }}
              />
            );
          tempObj["Cumulative Amt."] = vendor["cumulativeAmt"];
          tempObj["Actions"] = (
            <EditDelete
              deleteHandler={() => modalVisibleForDelete(vendor["_id"])}
              editHandler={() => editHandler(vendor)}
            ></EditDelete>
          );
          tempArr = [...tempArr, tempObj];
        });
        const sortedData = [...tempArr].sort(
          (a, b) => a["Cumulative Amt."] - b["Cumulative Amt."]
        );
        setTableContents(sortedData);
        setFlag(true);
        modalClose();
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setError(true);
        setLoading(false);
      });
  }, [update, page]);
  const editHandler = (data) => {
    setFormData({
      id: data._id,
      name: data.name,
      code: data.code,
      status: data.status,
      zoneCode: data.zoneCode,
      email: data.email,
      class: data.class,
      phone: data.phone,
      cumulativeAmt: data.cumulativeAmt,
    });
  };

  function handleChange(event) {
    const { name, value } = event.target;
    setFormData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  }

  const deleteHandler = (id) => {
    setLoading(true);
    Axios.patch(url + "/vendors/" + id, { status: "Removed" })
      .then((response) => {
        setUpdate(!update);
        setLoading(false);
        modalClose();
      })
      .catch((err) => {
        setError(true);
        setLoading(false);
      });
  };

  const modalVisibleForEdit = () => {
    setError(false);
    setFunctionToPass(() => () => updateHandler());
    setQues("Do you want to edit this vendor?");
    setShow(true);
  };

  const modalVisibleForDelete = (id) => {
    setError(false);
    setFunctionToPass(() => () => deleteHandler(id));
    setQues("Do you want to delete this vendor?");
    setShow(true);
  };

  const modalClose = () => {
    setShow(false);
  };
  const updateHandler = () => {
    setLoading(true);
    const data = new FormData();
    data.append("name", formData.name);
    data.append("code", formData.code);
    data.append("status", formData.status);
    data.append("zoneCode", formData.zoneCode);
    data.append("email", formData.email);
    data.append("class", formData.class);
    data.append("phone", formData.phone);
    if (formData.cumulativeAmt) {
      data.append("cumulativeAmt", formData.cumulativeAmt);
    } else {
      data.append("cumulativeAmt", " ");
    }
    if (formData.photo) {
      data.append("photo", formData.photo);
    }
    if (formData.signature) {
      data.append("signature", formData.signature);
    }
    Axios.patch(url + "/vendors/" + formData.id, data)
      .then((response) => {
        setFormData({
          id: "",
          name: "",
          code: "",
          status: "",
          zoneCode: 0,
          email: "",
          class: "",
          phone: 0,
          photo: "no-photo",
          signature: "no-photo",
          cumulativeAmt: 0,
        });
        setSubmit(true);
        setUpdate(!update);
        setLoading(false);
        modalClose();
      })
      .catch((err) => {
        setError(true);
        setLoading(false);
      });
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  return (
    <div className="vendorList">
      <Modal show={show} noHandler={modalClose}>
        {loading ? (
          <Loader></Loader>
        ) : !error ? (
          <ConfirmContent
            yesHandler={functionToPass}
            noHandler={modalClose}
            question={ques}
          ></ConfirmContent>
        ) : (
          <p>Something went wrong!</p>
        )}
      </Modal>
      <ContainerHeader formName="Vendor List"></ContainerHeader>
      <Paper>
        <div className="fields">
          <div className="field">
            <Input
              name="name"
              label="Name"
              size="wide"
              onChange={handleChange}
              value={formData.name}
            />
          </div>
          <div className="field">
            <Input
              name="code"
              label="Code"
              size="wide"
              onChange={handleChange}
              value={formData.code}
            />
          </div>
          <div className="field">
            <DropDown
              name="status"
              size="wide"
              label="Status"
              options={status}
              onChange={handleChange}
            />
          </div>
          <div className="field">
            <Input
              name="zoneCode"
              label="Zone Code"
              size="wide"
              onChange={handleChange}
              value={formData.zoneCode}
            />
          </div>
          <div className="field">
            <Input
              name="email"
              label="Email"
              size="wide"
              onChange={handleChange}
              value={formData.email}
            />
          </div>
          <div className="field">
            <Input
              name="class"
              label="Vendor Class"
              size="wide"
              onChange={handleChange}
              value={formData.class}
            />
          </div>
          <div className="field">
            <Input
              name="phone"
              label="Phone"
              size="wide"
              onChange={handleChange}
              value={formData.phone}
            />
          </div>
          <div className="field">
            <Input
              name="cumulativeAmt"
              label=" Cumulative Amt"
              size="wide"
              onChange={handleChange}
              value={formData.cumulativeAmt}
            />
          </div>
          <div className="field">
            <Input
              name="photo"
              label="Upload Photo"
              size="wide"
              type="file"
              onChange={(event) => {
                setSubmit(false);
                const file = event.target.files[0];
                setFormData((prev) => {
                  return {
                    ...prev,
                    photo: file,
                  };
                });
              }}
              value={isSubmit ? "" : null}
            />
          </div>
          <div className="field">
            <Input
              name="upload-signature"
              label="Upload Signature"
              size="wide"
              type="file"
              onChange={(event) => {
                setSubmit(false);
                const file = event.target.files[0];
                setFormData((prev) => {
                  return {
                    ...prev,
                    signature: file,
                  };
                });
              }}
              value={isSubmit ? "" : null}
            />
          </div>
          <div className="field">
            <Button
              className="primary"
              type="submit"
              label="Update"
              onClick={modalVisibleForEdit}
            />
          </div>
        </div>
        {/* {flag ? (
          <div class="vendorListTable">
            <Table
              contents={tableContents}
              setContents={setTableContents}
            ></Table>
          </div>
        ) : null} */}

        <MaterialTables
          data={tableContents}
          headings={headings}
          title="Vendors Database"
        />
      <div style={{alignItems:'center', display:'flex', justifyContent:'center', marginTop:'10px'}}>
      <Pagination count={paginationCount} page={page} onChange={handlePageChange} sx={{width:'100%'}} />
      </div>
      </Paper>
    </div>
  );
};

export default VendorList;
