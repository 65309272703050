// let dev = false;
let url = "";
const hostname = window.location.href;
console.log('Hostname:', hostname);
export let url_upload = "";

if (hostname === 'http://localhost:3000/login') {
  url = "http://localhost:3001";
  url_upload = "http://localhost:3001";
} else if(hostname === 'https://www.oilcp2.com/login') {
  url = "https://www.oilcp2.com:443/server";
  url_upload = "https://www.oilcp2.com:443/uploads";
} else {
  url = "https://oilcp2.com:443/server";
  url_upload = "https://oilcp2.com:443/uploads";
}

export default url;