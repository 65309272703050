import React, { useEffect, useState, useCallback } from "react";
import Paper from "../../components/whiteCard/whiteCard";
import MUIPaper from '@material-ui/core/Paper';
import url, {url_upload} from "../../constants/url";
import ContainerHeader from "../../components/containerHeader/ContainerHeader";
import axios from "axios";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

const authCheck = require('../../helpers/auth.helpers');
const sort = require('../../helpers/item.sort');

const UserProfile = () => {
  const { _id, name, email, mobileNo, status, designation, userCode, areaAndZone, photo } 
    = authCheck(localStorage.getItem('authCode'));
    
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState([]);
  const [error, setError] = useState(false);
  
    
  const [areas, setAreas] = useState([]);
  

   let userAreaList = [];
   areaAndZone.map((area) => {
     userAreaList.push(area.area.areaName);
   });
   let zonesArr = [];
   areaAndZone.map((area) => {
     area.zones.map((zone) => {
       zonesArr.push(zone.zoneName);
     });
   });
 
  
  useEffect(() => {
    setLoading(true);
    axios
      .get(url + "/areas/getAreas")
      .then((res) => {
        res.data.map((area) => {
          area = { ...area, checked: false };
        });
        setAreas(res.data);
        
        setLoading(false);
      })
      .catch((e) => {
        setError(true);
        setLoading(false);
      });
  }, []);
 
  const arr = areas.map((area) => (
    <div
      name="area selected"
      key={area._id}
      value={area.areaName}
      className="au-allareas"
    >
      <div className="au-area-head">{area.areaName}</div>
      <div className="au-zones">
        {
          area.zones.map(function (zone) {
            return (
              <FormControlLabel
                control={
                  <Checkbox
                    name={area._id}
                    value={zone._id}
                    disabled={true}
                    checked={ zonesArr.find(element=>element==zone.zoneName) ? true : false }
                  />
                  }
                label={zone.zoneName}
              />
            );
          })
        }
      </div>
    </div>
  ));
  return (<>
    <div>
      <ContainerHeader formName="Profile" />
      <Paper style={{minHeight:null}}>
        <div id="dashboard" style={styles.structure}>
          {window.innerWidth > 800 ? (
           <MUIPaper elevation={4}>
            <div style={{ padding: "20px" }}>
              <img
                src={url_upload + "/userPics/" + photo}
                alt="User Photo"
                width="250px"
              />
            </div>
           </MUIPaper>
          ) : null}
          <div style={{ padding: "20px", ...styles.structureIn }}>
            <div>
              <p><b>Name:</b></p>
              <p><b>Mobile Number:</b></p>
              <p><b>Email:</b></p>
              <p><b>Status:</b></p>
              <p><b>User Code:</b></p>
              <p><b>Designation:</b></p>             
             </div>
            <div>
              <p>{name}</p>
              <p>{mobileNo}</p>
              <p>{email}</p>
              <p>{status}</p>
              <p>{userCode}</p>
              <p>{designation}</p>           
            </div>
          </div>
        </div>       
      </Paper>
    </div>
    <div id="add-user">
      
      <Paper>
        <div className="add-user-form">          
       
          <span className="au-text">Selected Areas & Zones</span>
          <div className="au-areascontainer">{arr}</div>
          <hr />         
        </div>
      </Paper>
    </div>

    </>

  );
};

const styles = {
  card: {
    backgroundColor: "white",
    boxShadow: "0px 0px 2px rgba(30,30,30,0.3)",
    height: "90px",
    width: "350px",
    display: "grid",
    gridTemplateColumns: "2fr 5fr",
    borderRadius: "3px",
    overflow: "hidden",
  },
  centerIcon: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
  },
  structure: {
    display: "grid",
    gridTemplateColumns: window.innerWidth > 800 ? "1fr 2fr" : "1fr",
  },
  structureIn: {
    display: "grid",
    gridTemplateColumns: window.innerWidth > 800 ? "2fr 1fr" : "2fr 1fr",
  },
  cardNumber: {
    color: "white",
    marginBottom: 0,
    marginTop: "10px",
    marginLeft: "20px",
  },
  cardName: {
    marginTop: 0,
    color: "white",
    marginLeft: "20px",
  }
};
export default UserProfile;
