import React, { useEffect, useState } from "react";
import MapGL, { Marker } from "react-map-gl";
import Pin from "./pins/Pin";
import { Data } from "./data";
import Box from "./pins/Box";
import Axios from "axios";
import url from "../../constants/url";
import Checkbox from "@material-ui/core/Checkbox";
import authCheck from "../../helpers/auth.helpers";
const MAPBOX_TOKEN =
  "pk.eyJ1IjoiamF5cGVlb2NwMiIsImEiOiJja2duc3Y4bm4wejl5MzBvNnVndXc0Z2piIn0.p78YkYq2ZtzFmFOdR2j1Aw";

const Map = () => {

  const { designation } = authCheck(localStorage.getItem('authCode'));

  const [viewport, setViewport] = React.useState({
    width: "100vm",
    height: "100vh",
    latitude: 27.5937,
    longitude: 95.9626,
    zoom: 8.3,
    bearing: 0,
    pitch: 0,
  });
  const [show, setShow] = useState(false);
  const [currentPoint, setCurrentPoint] = React.useState(null);
  const [satellite, setSatellite] = useState(true);
  const [points, setPoints] = useState(Data);
  const [toSearch, setToSearch] = useState("");

  const find = (str) => {
    let temp = [];
    temp = Data.filter((point) => {
      return point.properties.Name.toLowerCase().search(str.toLowerCase()) >= 0;
    });
    setPoints(temp);
  };
  const handleChange = (event) => {
    setSatellite(!satellite);
  };
  useEffect(() => {
    Axios.get(url + "/users/mapVis")
      .then((res) => {
        if (res.data[0].map && designation) {
          setShow(true);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);
  return (
    <>
      {show ? (
        <>
          <div style={{ display: "flex", flexDirection: "row", margin: "5px" }}>
            <div style={{ marginTop: "5px", marginLeft: "5px" }}>
              <button
                onClick={() =>
                  (window.location.href = "https://www.oilcp2.com")
                }
                className="primary"
              >
                Go To OCP2
              </button>
            </div>
            <div style={{ marginLeft: "20px" }}>
              <input
                style={{ margin: 0, width: "150px" }}
                placeholder="Search well"
                type="text"
                value={toSearch}
                onChange={(e) => {
                  const temp = e.target.value;
                  setToSearch(temp);
                  find(temp);
                }}
              />
            </div>
            <div style={{ marginLeft: "20px" }}>
              <p style={{ margin: 0 }}>
                Satellite View:{" "}
                <Checkbox
                  checked={satellite}
                  onClick={handleChange}
                  color="primary"
                  inputProps={{ "aria-label": "secondary checkbox" }}
                />
              </p>
            </div>
          </div>
          {/* <iframe
            src="https://drive.google.com/file/d/1CbSrLx0o85XNdQEovKt4a2k9oHTlvtVg/preview"
            width="640"
            height="480"
          ></iframe> */}
          <MapGL
            {...viewport}
            width="100vw"
            height="80vh"
            mapStyle={
              satellite
                ? "mapbox://styles/mapbox/satellite-v9"
                : "mapbox://styles/mapbox/streets-v11"
            }
            onViewportChange={(nextViewport) => setViewport(nextViewport)}
            mapboxApiAccessToken={MAPBOX_TOKEN}
          >
            {currentPoint ? (
              <>
                {/* <Marker
          longitude={currentPoint.long}
          latitude={currentPoint.lat}
        > */}
                <Box val={currentPoint} />
                {/* </Marker> */}
              </>
            ) : null}
            {points.map((unit) => {
              return (
                <Marker
                  longitude={unit.geometry.coordinates[0]}
                  latitude={unit.geometry.coordinates[1]}
                >
                  <Pin
                    setCurrentPoint={setCurrentPoint}
                    long={unit.geometry.coordinates[0]}
                    lat={unit.geometry.coordinates[1]}
                    name={unit.properties.Name}
                    desc={unit.properties.description}
                  />
                </Marker>
              );
            })}
          </MapGL>
        </>
      ) : (
        <div style={{ marginTop: "20px", marginLeft: "20px" }}>
          <button
            onClick={() => (window.location.href = "https://www.oilcp2.com")}
            className="primary"
          >
            Go To OCP2
          </button>
        </div>
      )}
    </>
  );
};

export default Map;
