import React, { useState } from "react";
import "./ProjectList.css";
import ContainerHeader from "../../components/containerHeader/ContainerHeader";
import Paper from "../../components/whiteCard/whiteCard";
import Input from "../../components/inputComponents/Input";
import IconButton from "../../components/inputComponents/IconButton";
import Button from "../../components/inputComponents/Button";
import { Link } from "react-router-dom";
import axios from "axios";
import MaterialTables from "../../components/materialTable/MaterialTables";
import Axios from "axios";
import Modal from "../../components/ui/modal/Modal";
import ConfirmContent from "../../components/ui/confirmContent/ConfirmContent";
import Loader from "../../components/spinner/Spinner";
import url from "../../constants/url";
import Pagination from '@material-ui/lab/Pagination';
import Typography from "@material-ui/core/Typography";

const authCheck = require("../../helpers/auth.helpers");

const ProjectList = () => {

  const { _id, areaAndZone, designation } = authCheck(localStorage.getItem('authCode'));

  const [tableContents, setTableContents] = React.useState(null);
  const [show, setShow] = useState(false);
  const [update, setUpdate] = useState(false);
  const [functionToPass, setFunctionToPass] = useState(false);
  const [loading, setLoading] = useState(false);
  const [ques, setQues] = useState("");
  const [error, setError] = useState(false);

  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = React.useState(1);
  const [paginationCount, setPaginationCount] = useState(1);

  const headings = [
    "Project Type",
    "Project Id",
    "Project Description",
    "Zone",
    "Area",
    "PO Number",
    "PR Number",
    "Notification Number",
    "Status",
    "Subcontractor",
    "Technician",
    "Vendor",
    "Upload Estimate",
    "Upload Drawing",
    "Progress",
    "Actions",
  ];

  const uploadEstimateHandler = (file, id) => {
    setLoading(true);
    const data = new FormData();
    data.append("file", file);
    data.append("projectId", id);
    data.append("name", "pic");
    Axios.post(url + "/projects/uploadEstimate", data)
      .then((res) => {
        modalClose();
        setLoading(false);
      })
      .catch((err) => {
        setError(true);
        setLoading(false);
      });
  };

  const uploadDrawingHandler = (file, id) => {
    setLoading(true);
    const data = new FormData();
    data.append("file", file);
    data.append("projectId", id);
    data.append("name", "pic");
    Axios.post(url + "/projects/uploadDrawing", data)
      .then((res) => {
        modalClose();
        setLoading(false);
      })
      .catch((err) => {
        setError(true);
        setLoading(false);
      });
  };

  const modalVisibleForEstimate = (file, id) => {
    setError(false);
    setFunctionToPass(() => () => uploadEstimateHandler(file, id));
    setQues("Do you want to upload this estimate?");
    setShow(true);
  };

  const modalVisibleForDrawing = (file, id) => {
    setError(false);
    setFunctionToPass(() => () => uploadDrawingHandler(file, id));
    setQues("Do you want to upload this drawing?");
    setShow(true);
  };
  React.useEffect(() => {
    setLoading(true);
    setShow(true);
    let file;
    if (designation === "GM/DGM") {
      let areas = [];
      areaAndZone.map((area) => {
        areas.push(area.area._id);
      });
      axios
        .post(url + "/projects/getByAreas", { areaIds: areas, state: 'Current' }, { params: {page: page} })
        .then((data) => {
          setTotalCount(data.data.total);
          setPaginationCount(data.data.count);

          let tempProjectList = [];
          data.data.project.map((project) => {

            const path = "/project-details/" + project._id;
            tempProjectList.push({
              "Project Type": project.projectType,
              "Project Id": project.projectId,
              "Project Description":
                project.description.toString().length > 100
                  ? project.description.slice(0, 100)
                  : project.description,
              Zone: project.zone ? project.zone.zoneName : "",
              Area: project.area ? project.area.areaName : "",
              "PO Number": project.poNumber,
              "PR Number": project.prNumber,
              "Notification Number": project.notificationNo,
              Status:
                project.progress && project.progress.totalProgress >= 100
                  ? "completed"
                  : project.state,
              Subcontractor: project.subcontractorName ? "Yes" : "No",
              Technician: project.technician ? project.technician.name : null,
              Vendor: project.vendor ? project.vendor.name : null,
              "Upload Estimate": (
                <div>
                  <Input
                    name="estimate"
                    size="wide"
                    type="file"
                    onChange={(event) => {
                      file = event.target.files[0];
                    }}
                  />
                  <Button
                    className="success"
                    hint="Upload estimate"
                    label="Upload"
                    onClick={() => modalVisibleForEstimate(file, project._id)}
                  ></Button>
                </div>
              ),
              "Upload Drawing": (
                <div>
                  <Input
                    name="drawing"
                    size="wide"
                    type="file"
                    onChange={(event) => {
                      file = event.target.files[0];
                    }}
                  />
                  <Button
                    className="success"
                    hint="Upload drawing"
                    label="Upload"
                    onClick={() => modalVisibleForDrawing(file, project._id)}
                  ></Button>
                </div>
              ),
              Progress: project.progress
                ? project.progress.totalProgress >= 100
                  ? 100
                  : project.progress.totalProgress.toFixed(2)
                : 0,
              Actions: (
                <div className="action-buttons">
                  <Link to={path}>
                    <IconButton
                      className="success"
                      hint="Project details"
                      iconClass="fa fa-info"
                    ></IconButton>
                  </Link>
                  {project.state !== "Removed" &&
                  project.state !== "Completed" ? (
                    <Link to={"/work-order/" + project._id}>
                      <IconButton
                        className={project.workOrder ? "danger" : "success"}
                        hint={
                          project.workOrder
                            ? "Edit Word order"
                            : "Issue work order"
                        }
                        iconClass="fa fa-file-word-o"
                      ></IconButton>
                    </Link>
                  ) : null}
                  {project.progress && project.state !== "Removed" ? (
                    <Link
                      to={
                        "/daily-progress/" +
                        project._id +
                        "/" +
                        project.progress._id
                      }
                    >
                      <IconButton
                        className="success"
                        hint="Tally book"
                        iconClass="fa fa-book"
                      ></IconButton>
                    </Link>
                  ) : null}

                  {project.progress && project.state !== "Removed" ? (
                    <Link to={"/materials/" + project._id}>
                      <IconButton
                        className="success"
                        hint="Issue material"
                        iconClass="fa fa-file-text"
                      ></IconButton>
                    </Link>
                  ) : null}
                  {project.state !== "Completed" ? (
                    <>
                      {(designation === "GM/DGM" ||
                        designation === "Engineer") &&
                      project.state !== "Removed" ? (
                        <Link to={"/show-cause/" + project._id}>
                          <IconButton
                            className="success"
                            hint="Issue show cause"
                            iconClass="fa fa-sticky-note-o"
                          ></IconButton>
                        </Link>
                      ) : null}
                      <Link to={"/editProject/" + project._id}>
                        <IconButton
                          className="danger"
                          hint="Edit"
                          iconClass="fa fa-pencil"
                        ></IconButton>
                      </Link>
                    </>
                  ) : null}
                  {project.state !== "Completed" &&
                  project.state !== "Removed" ? (
                    <IconButton
                      className="danger"
                      hint="Delete"
                      iconClass="fa fa-trash"
                      onClick={() => modalVisibleForDelete(project._id)}
                    ></IconButton>
                  ) : null}
                  {(designation === "GM/DGM" ||
                    designation === "Engineer") &&
                  project.state !== "Completed" &&
                  project.progress &&
                  project.state !== "Removed" ? (
                    <Link to={"/submitJob/" + project._id}>
                      <IconButton
                        className="success"
                        hint="Submit"
                        iconClass="fa fa-check"
                      ></IconButton>
                    </Link>
                  ) : null}
                  {project.state === "Completed" &&
                  project.state !== "Removed" ? (
                    <Link to={"/job-completion/" + project._id}>
                      <IconButton
                        className="success"
                        hint="Issue job completion"
                        iconClass="fa fa-check-circle-o"
                      ></IconButton>
                    </Link>
                  ) : null}
                </div>
              ),
            });
          });
          setTableContents(tempProjectList);
          modalClose();
          setLoading(false);
        })
        .catch((e) => {
          setError(true);
          setLoading(false);
        });
    } else {
      axios
        .post(url + "/projects/getUserProjects", { userId: _id }, {params: {page: page}})
        .then((data) => {
          setPaginationCount(data.data.count)
          setTotalCount(data.data.total);

          let tempProjectList = [];
          data.data.project.map((project) => {
            const path = "/project-details/" + project._id;
            tempProjectList.push({
              "Project Type": project.projectType,
              "Project Id": project.projectId,
              "Project Description":
                project.description.toString().length > 100
                  ? project.description.slice(0, 100)
                  : project.description,
              Zone: project.zone ? project.zone.zoneName : "",
              Area: project.area ? project.area.areaName : "",
              "PO Number": project.poNumber,
              "PR Number": project.prNumber,
              "Notification Number": project.notificationNo,
              Status:
                project.progress && project.progress.totalProgress >= 100
                  ? "completed"
                  : project.state,
              Subcontractor: project.subcontractorName ? "Yes" : "No",
              Technician: project.technician ? project.technician.name : null,
              Vendor: project.vendor ? project.vendor.name : null,
              "Upload Estimate": (
                <div>
                  <Input
                    name="estimate"
                    size="wide"
                    type="file"
                    onChange={(event) => {
                      file = event.target.files[0];
                    }}
                  />
                  <Button
                    className="success"
                    hint="Upload estimate"
                    label="Upload"
                    onClick={() => modalVisibleForEstimate(file, project._id)}
                  ></Button>
                </div>
              ),
              "Upload Drawing": (
                <div>
                  <Input
                    name="drawing"
                    size="wide"
                    type="file"
                    onChange={(event) => {
                      file = event.target.files[0];
                    }}
                  />
                  <Button
                    className="success"
                    hint="Upload drawing"
                    label="Upload"
                    onClick={() => modalVisibleForDrawing(file, project._id)}
                  ></Button>
                </div>
              ),
              Progress: project.progress
                ? project.progress.totalProgress >= 100
                  ? 100
                  : project.progress.totalProgress.toFixed(2)
                : 0,
              Actions: (
                <div className="action-buttons">
                  <Link to={path}>
                    <IconButton
                      className="success"
                      hint="Project details"
                      iconClass="fa fa-info"
                    ></IconButton>
                  </Link>
                  {project.state !== "Removed" &&
                  project.state !== "Completed" &&
                  !(designation === "Jr. Engineer") ? (
                    <Link to={"/work-order/" + project._id}>
                      <IconButton
                        className={project.workOrder ? "danger" : "success"}
                        hint={
                          project.workOrder
                            ? "Edit Word order"
                            : "Issue work order"
                        }
                        iconClass="fa fa-file-word-o"
                      ></IconButton>
                    </Link>
                  ) : null}
                  {project.progress && project.state !== "Removed" ? (
                    <Link
                      to={
                        "/daily-progress/" +
                        project._id +
                        "/" +
                        project.progress._id
                      }
                    >
                      <IconButton
                        className="success"
                        hint="Tally book"
                        iconClass="fa fa-book"
                      ></IconButton>
                    </Link>
                  ) : null}

                  {project.progress && project.state !== "Removed" ? (
                    <Link to={"/materials/" + project._id}>
                      <IconButton
                        className="success"
                        hint="Issue material"
                        iconClass="fa fa-file-text"
                      ></IconButton>
                    </Link>
                  ) : null}
                  {project.state !== "Completed" ? (
                    <>
                      {(designation === "GM/DGM" ||
                        designation === "Engineer") &&
                      project.state !== "Removed" ? (
                        <Link to={"/show-cause/" + project._id}>
                          <IconButton
                            className="success"
                            hint="Issue show cause"
                            iconClass="fa fa-sticky-note-o"
                          ></IconButton>
                        </Link>
                      ) : null}
                      <Link to={"/editProject/" + project._id}>
                        <IconButton
                          className="danger"
                          hint="Edit"
                          iconClass="fa fa-pencil"
                        ></IconButton>
                      </Link>
                    </>
                  ) : null}
                  {(designation === "GM/DGM" ||
                    designation === "Engineer") &&
                  project.state === "Completed" &&
                  project.state !== "Removed" ? (
                    <Link to={"/job-completion/" + project._id}>
                      <IconButton
                        className="success"
                        hint="Issue job completion"
                        iconClass="fa fa-check-circle-o"
                      ></IconButton>
                    </Link>
                  ) : null}
                  {project.state !== "Completed" &&
                  project.state !== "Removed" ? (
                    <IconButton
                      className="danger"
                      hint="Delete"
                      iconClass="fa fa-trash"
                      onClick={() => modalVisibleForDelete(project._id)}
                    ></IconButton>
                  ) : null}
                  {(designation === "GM/DGM" ||
                    designation === "Engineer") &&
                  project.state !== "Completed" &&
                  project.progress &&
                  project.state !== "Removed" ? (
                    <Link to={"/submitJob/" + project._id}>
                      <IconButton
                        className="success"
                        hint="Submit"
                        iconClass="fa fa-check"
                      ></IconButton>
                    </Link>
                  ) : null}
                  {/* this is extra */}
                  {/* {project.state === "Completed" &&
                  project.state !== "Removed" ? (
                    <Link to="/job-completion">
                      <IconButton
                        className="success"
                        hint="Issue job completion"
                        iconClass="fa fa-check-circle-o"
                      ></IconButton>
                    </Link>
                  ) : null} */}
                </div>
              ),
            });
          });
          setTableContents(tempProjectList);
          modalClose();
          setLoading(false);
        })
        .catch((e) => {
          console.log(e);
          setError(true);
          setLoading(false);
        });
    }
  }, [update, page]);

  const modalVisibleForDelete = (id) => {
    setError(false);
    setFunctionToPass(() => () => deleteHandler(id));
    setQues("Do you want to delete this project?");
    setShow(true);
  };

  const modalClose = () => {
    setShow(false);
  };
  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const deleteHandler = (id) => {
    setLoading(true);
    Axios.delete(url + "/projects/deleteProject/" + id)
      .then((resp) => {
        setLoading(false);
        modalClose();
        setUpdate(!update);
      })
      .catch((err) => {});
  };
  return (
    <div id="project-list">
      <Modal show={show} noHandler={modalClose}>
        {loading ? (
          <Loader></Loader>
        ) : !error ? (
          <ConfirmContent
            yesHandler={functionToPass}
            noHandler={modalClose}
            question={ques}
          ></ConfirmContent>
        ) : (
          <p>Something went wrong!</p>
        )}
      </Modal>
      <ContainerHeader formName="Project List"></ContainerHeader>
      <Paper>
        <div className="project-list-table">
          {tableContents && tableContents.length > 0 ? (
            <MaterialTables
              headings={headings}
              data={tableContents}
              title="Projects Database"
              options={{
                paging:true,
                pageSize:15,       // make initial page size
                emptyRowsWhenPaging: false,   // To avoid of having empty rows
                pageSizeOptions:[20,30,40,50],    // rows selection options
              }}
            />
          ) : (
            "No projects"
          )}
        </div>
        <div style={{
            display: 'flex',
            alignItems: "start",
            marginTop: "10px"
        }}>
          <Typography variant="p">Current Projects: {totalCount}</Typography>
        </div>
        <div style={{alignItems:'center', display:'flex', justifyContent:'center', marginTop:'10px'}}>
          <Pagination count={paginationCount} page={page} onChange={handlePageChange} sx={{width:'100%'}} />
        </div>
      </Paper>

    </div>
  );
};

export default ProjectList;
