import React, { Fragment } from "react";
import "./Inputs.css";

const Input = ({
  name,
  type,
  placeholder,
  onChange,
  className,
  size,
  value,
  label,
  disabled,
  required,
  ...props
}) => {
  return (
    <Fragment>
      <label htmlFor={name}>{label}</label>
      <br />
      <input
        id={name}
        name={name}
        type={type}
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        className={`${size} ${className}`}
        disabled={disabled}
        readOnly={disabled}
        required={required}
      />
    </Fragment>
  );
};

Input.defaultProps = {
  type: "text",
};

export default Input;
