import React from "react";
import { Switch } from "react-router-dom";
import ProtectedRoute from "../components/protectedRoute/ProtectedRoute";
import Dashboard from "../containers/dashboard/Dashboard";
import ProjectDetails from "../containers/projectDetails/ProjectDetails";
import TallyBook from "../containers/tallyBook/TallyBook";
import AddNotification from "../containers/addNotification/AddNotification";
import DailyProgressDate from "../containers/dailyProgressDate/DailyProgressDate";
import EditProject from "../containers/editProject/EditProject";
import ChangePassword from "../containers/changePassword/ChangePassword";
import TechnicalDocs from "../containers/technicalDocs/TechnicalDocs";
import ShowCause from "../containers/forms/showCause/ShowCause";
import TallyDocs from "../containers/tallyBook/tallydocs/Tallydocs";
import CurrentMandatoryList from "../containers/projectList/currentmandatoryList/CurrentMandatoryList";
import CompletedMandatoryList from "../containers/projectList/completedmandatoryList/CompletedMandatoryList";
import NotificationList from "../containers/notificationList/NotificationList";
import Godown from "../containers/godown/Godown";
import VendorList from "../containers/reports/vendorList/VendorList";
import UserProfile from "../containers/userProfile/UserProfile";

const TechnicianRoutes = (props) => {
  const [isAuth, setIsAuth] = React.useState(true);
  return (
    <Switch>
      <ProtectedRoute 
        path="/profile"
        component={UserProfile}
        isAuth={isAuth}
      />
      <ProtectedRoute
        path="/dashboard"
        component={Dashboard}
        isAuth={props.isAuth}
      />
      <ProtectedRoute
        path="/currentProjects"
        component={CurrentMandatoryList}
        isAuth={isAuth}
      />
      <ProtectedRoute
        path="/completedProjects"
        component={CompletedMandatoryList}
        isAuth={isAuth}
      />
      <ProtectedRoute
        path="/show-cause/:id"
        component={ShowCause}
        isAuth={isAuth}
      />
      <ProtectedRoute
        path="/project-details/:id"
        component={ProjectDetails}
        isAuth={isAuth}
      />
      <ProtectedRoute
        path="/notification"
        component={AddNotification}
        isAuth={isAuth}
      />
      <ProtectedRoute
        path="/tally/:projectId/:dateId"
        component={TallyBook}
        isAuth={isAuth}
      />
      <ProtectedRoute
        path="/daily-progress/:projectId/:progressId"
        component={DailyProgressDate}
        isAuth={isAuth}
      />
      <ProtectedRoute
        path="/editProject/:projectId"
        component={EditProject}
        isAuth={isAuth}
      />
      <ProtectedRoute
        path="/changePass"
        component={ChangePassword}
        isAuth={isAuth}
      />
      <ProtectedRoute
        path="/techdocs"
        component={TechnicalDocs}
        isAuth={isAuth}
      />
      <ProtectedRoute
        path="/tallyDocs"
        component={TallyDocs}
        isAuth={isAuth}
      ></ProtectedRoute>
      <ProtectedRoute
        path="/notifications"
        component={NotificationList}
        isAuth={isAuth}
      />
      <ProtectedRoute path="/godown" component={Godown} isAuth={isAuth} />
      <ProtectedRoute
        path="/vendor-list"
        component={VendorList}
        isAuth={isAuth}
      />
    </Switch>
  );
};

export default TechnicianRoutes;
