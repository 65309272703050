import React from "react";
import IconButton from "../../components/inputComponents/IconButton";

const EditDelete = (props) => {
  return (
    <div style={{ display: "flex", flexFlow: "row", justifyContent: "center" }}>
      <IconButton
        iconClass="fas fa-trash-alt"
        className="danger"
        onClick={props.deleteHandler}
        hint="Delete"
      ></IconButton>

      <IconButton
        iconClass="far fa-edit"
        className="success"
        hint="Edit"
        onClick={props.editHandler}
      ></IconButton>
    </div>
  );
};

export default EditDelete;
