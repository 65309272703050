import React, { useState, useEffect } from "react";
import "./EditNotification.css";
import ContainerHeader from "../../components/containerHeader/ContainerHeader";
import Whitecard from "../../components/whiteCard/whiteCard";
import Input from "../../components/inputComponents/Input";
import TextArea from "../../components/inputComponents/TextArea";
import Axios from "axios";
import Modal from "../../components/ui/modal/Modal";
import Loader from "../../components/spinner/Spinner";
import url from "../../constants/url";

const NotificationListing = (props) => {
  const [details, setDetails] = useState({
    projectType: "",
    projectId: "",
    notificationNumber: 0,
    area: "",
    areaName: "",
    installation: "",
    nameRequisitioner: "",
    departmentRequisitioner: "",
    phNumberRequisitioner: 0,
    description: "",
    drawing: "",
  });
  const [flag, setFlag] = useState(false);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const connLinks = [
    {
      name: "Notifications List",
      path: '/notifications'
    }
  ]
  useEffect(() => {
    setLoading(true);
    setShow(true);
    Axios.get(url + "/notifications/" + props.match.params.id)
      .then((response) => {
        let tempObj = { ...details };
        let apiObj = {};
        apiObj = response.data[0];
        tempObj["projectType"] = apiObj["projectType"];
        tempObj["areaName"] = apiObj["area"]["areaName"];
        tempObj["installationName"] = apiObj["installation"]["name"];
        tempObj["notificationNumber"] = apiObj["notificationNumber"];
        tempObj["projectId"] = apiObj["projectId"];
        tempObj["drawing"] = "noimage.jpg";
        tempObj["description"] = apiObj["description"];
        tempObj["departmentRequisitioner"] = apiObj["departmentRequisitioner"];
        tempObj["nameRequisitioner"] = apiObj["nameRequisitioner"];
        tempObj["phNumberRequisitioner"] = apiObj["phNumberRequisitioner"];
        setDetails(tempObj);
        setLoading(false);
        modalClose();
        setFlag(true);
      })
      .catch((err) => {
        setError(true);
        setLoading(false);
      });
  }, []);

  const modalClose = () => {
    setShow(false);
  }

  return (
    <div>
      <Modal show={show} noHandler={modalClose}>
        {!loading ? (
          !error ? (
            <></>
          ) : (
            <p>Something went wrong!</p>
          )
        ) : (
          <Loader></Loader>
        )}
      </Modal>
      <ContainerHeader formName="View Notification" connLinks={connLinks}></ContainerHeader>
      {flag ? (
        <Whitecard>
          <div id="an-col">
            <div className="an-row">
              <div className="an-row-in">
                {" "}
                <Input
                  label="Project Type"
                  size="wide"
                  value={details.projectType}
                  diasbled
                />
              </div>

              <div className="an-row-in">
                {" "}
                <Input
                  name="notification-no"
                  label="Notification No."
                  placeholder="Enter No."
                  size="wide"
                  value={details.notificationNumber}
                  diasbled
                />
              </div>
            </div>
            <div className="an-row">
              <div className="an-row-in">
                {" "}
                <Input
                  value={details.areaName}
                  label="Area"
                  size="wide"
                  diasbled
                />
              </div>
              <div className="an-row-in">
                {" "}
                <Input
                  value={details.installationName}
                  label="Installation"
                  size="wide"
                  diasbled
                />
              </div>
            </div>
            <div className="an-row">
              <div className="an-row-in">
                {" "}
                <Input
                  name="req-name"
                  label="Name of Requisitioner"
                  placeholder="Enter Name"
                  size="wide"
                  value={details.nameRequisitioner}
                  diasbled
                />
              </div>
              <div className="an-row-in">
                {" "}
                <Input
                  name="req-department"
                  label="Requisitioner Department"
                  placeholder="Enter Department"
                  size="wide"
                  value={details.departmentRequisitioner}
                  diasbled
                />
              </div>
              <div className="an-row-in">
                {" "}
                <Input
                  name="req-no"
                  label="Phone No. of Requisitioner"
                  placeholder="Enter Number"
                  size="wide"
                  value={details.phNumberRequisitioner}
                  diasbled
                />
              </div>
            </div>
            <div className="an-row">
              <div className="an-row-in">
                <TextArea
                  rows="30"
                  cols="10"
                  label="Project Description"
                  value={details.description}
                  size="wide"
                  diasbled
                />
              </div>
            </div>
          </div>
        </Whitecard>
      ) : null}
    </div>
  );
};

export default NotificationListing;
