import React,{useState} from 'react'
import ContainerHeader from "../../../components/containerHeader/ContainerHeader";
import Paper from "../../../components/whiteCard/whiteCard";
import Input from '../../../components/inputComponents/Input'
import Button from '../../../components/inputComponents/Button'
import Modal from "../../../components/ui/modal/Modal";
import ConfirmContent from "../../../components/ui/confirmContent/ConfirmContent";
import Loader from "../../../components/spinner/Spinner";
import url from '../../../constants/url'
import Alert from '@material-ui/lab/Alert';
import Axios from 'axios';
import {Redirect} from 'react-router-dom'
const SubmitJob = (props) =>{
    const [formErrors,setFormErrors] = useState([])
    const [date,setDate] = useState(null);
    const [show, setShow] = useState(false);
    const [functionToPass, setFunctionToPass] = useState(null);
    const [ques, setQues] = useState("");
    const [loading, setLoading] = useState(false);
    const [redirect, setRedirect] = useState(false);
    const [error,setError] = useState(false)
    const connLinks = [
        {
          name: "Project list",
          path: '/projects'
        }
      ]
    const modalVisible = () =>{
        let errors = []
        if(!date){
            errors.push("DATE is required")
        }
        if(errors.length>0){
        setFormErrors(errors)
        return;
        }
        setFormErrors([])
        setError(false)
        setFunctionToPass(()=>()=>submitProject())
        setQues("Do you want to submit?")
        setShow(true)
    }
    const modalClose = () => {
        setShow(false);
    };
    const submitProject = () =>{
        setLoading(true);
        Axios.patch(url + "/projects/editProject/" + props.match.params.projectId, {
            adc:new Date(date),
            state:"Completed"
          }).then(()=>{
            setLoading(false);
            modalClose();
            setRedirect(true)
          }).catch(e=>{
            setError(true)
            setLoading(false)
          })
    }
    return(
        <>
            {redirect ? <Redirect to="/projects" /> : null}
            <Modal show={show} noHandler={modalClose}>
                {!loading ? (
                    !error ? (
                    <ConfirmContent
                        yesHandler={functionToPass}
                        noHandler={modalClose}
                        question={ques}
                    ></ConfirmContent>
                    ) : (
                        <p>Something went wrong!</p>
                      )
                ) : (
                <Loader></Loader>
                )}
            </Modal>
            <ContainerHeader formName="Submit Project" connLinks={connLinks}></ContainerHeader>
            <Paper>
                <Input
                    type="date"
                    label="Enter date of completion of Project"
                    onChange={(event)=>{setDate(event.target.value)}}
                />
                <span style={{marginLeft:"10px"}}></span>
                <Button
                    label="success"
                    className="success"
                    onClick={modalVisible}
                />
                {formErrors.map(error=>{
                    return(
                    <Alert severity="info">{error}</Alert>
                    )
                })}
            </Paper>
        </>
    )
}

export default SubmitJob