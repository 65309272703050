import React, { useState, useEffect } from "react";
import Paper from "../../../components/whiteCard/whiteCard";
import Axios from "axios";
import MaterialTables from "../../../components/materialTable/MaterialTables";
import { Link } from "react-router-dom";
import IconButton from "../../../components/inputComponents/IconButton";
import url from "../../../constants/url";
import Modal from "../../../components/ui/modal/Modal";
import Loader from "../../../components/spinner/Spinner";
import ContainerHeader from "../../../components/containerHeader/ContainerHeader";
import authCheck from "../../../helpers/auth.helpers";

const VendorShowCause = () => {
  const { areaAndZone, designation } = authCheck(localStorage.getItem('authCode'));
  const [tableContents, setTableContents] = useState([]);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const connLinks = [
    {
      name: "All Reports",
      path: '/reports'
    }
  ]
  const headings = [
    "Project Id",
    "Name",
    "Code",
    "Address",
    "Status",
    "ZoneCode",
    "Email",
    "Vendor Class",
    "Phone No.",
    "View Show Cause Notice",
  ];

  useEffect(() => {
    setLoading(true);
    setShow(true);
    let tempArr = [];
    Axios.get(url + "/projects/getProjectList")
      .then((response) => {
        let areaList = []
        areaAndZone.map(area=>{
          areaList.push(area.area._id)
        })
        response.data.project.map((obj) => {
          if (obj.showcause && (designation=="Engineer"||designation=="GM/DGM"?areaList.includes(obj.area._id):true)) {
            let tempObj = {
              "Project Id": obj.projectId,
              Name: obj.vendor.name,
              Code: obj.vendor.code,
              Address: obj.vendor.address,
              Status: obj.state,
              ZoneCode: obj.vendor.code,
              Email: obj.vendor.email,
              "Vendor Class": obj.vendor.class,
              "Phone No.": obj.vendor.phone,
              "View Show Cause Notice": (
                <div>
                  <Link to={"/show-cause/" + obj._id}>
                    <IconButton
                      className="success"
                      hint="View show cause"
                      iconClass="fa fa-sticky-note-o"
                    ></IconButton>
                  </Link>
                </div>
              ),
            };
            tempArr = [...tempArr, tempObj];
          }
        });
        setTableContents(tempArr);
        modalClose();
        setLoading(false);
      })
      .catch((err) => {
        setError(true);
        setLoading(false);
      });
  }, []);

  const modalClose = () => {
    setShow(false);
  };

  return (
    <div className="vendorList">
      {loading || error ? (
        <Modal show={show} noHandler={modalClose}>
          {error ? <p>Something went wrong!</p> : <Loader></Loader>}
        </Modal>
      ) : null}
      <ContainerHeader formName="Vendor List (Show Cause Notice Issued)" connLinks={connLinks}></ContainerHeader>
      <Paper>
        <MaterialTables data={tableContents} headings={headings} />
      </Paper>
    </div>
  );
};

export default VendorShowCause;
