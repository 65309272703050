import React, { useState } from "react";
import ContainerHeader from "../../../components/containerHeader/ContainerHeader";
import Paper from "../../../components/whiteCard/whiteCard";
import * as requests from "../../../api/requests";
import MaterialTables from "../../../components/materialTable/MaterialTables";
import Modal from "../../../components/ui/modal/Modal";
import Loader from "../../../components/spinner/Spinner";
import authCheck from "../../../helpers/auth.helpers";

const DelayedJobs = () => {
  const { areaAndZone, designation } = authCheck(localStorage.getItem('authCode'));

  const [tableContents, setTableContents] = React.useState(null);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const connLinks = [
    {
      name: "All Reports",
      path: '/reports'
    }
  ]
  const headings = [
    "Project Type",
    "Project Id",
    "Zone",
    "Area",
    "PO Number",
    "PR Number",
    "Notification Number",
    "State",
    "Subcontractor",
    "Technician",
    "Vendor",
    "Date of Completion",
    "Actual Date of completion",
    "Delay",
  ];
  React.useEffect(() => {
    setLoading(true);
    setShow(true);
    requests
      .get({
        url: "/projects/getProjectList",
      })
      .then((response) => {

        let tempProjectList = [];
        let areaList = []
        areaAndZone.map(area => {
          areaList.push(area.area._id)
        })
        let data = response.project.filter(
          (project) =>
            (new Date(project.adc) - new Date(project.doc)) /
            (1000 * 3600 * 24) >
            0 && (designation == "Engineer" || designation == "GM/DGM" ? areaList.includes(project.area._id) : true)
        );
        data.map((project) => {
          tempProjectList.push({
            "Project Type": project.projectType,
            "Project Id": project.projectId,
            Zone: project.zone ? project.zone.zoneName : "",
            Area: project.area ? project.area.areaName : "",
            "PO Number": project.poNumber,
            "PR Number": project.prNumber,
            "Notification Number": project.notificationNo,
            State: project.state,
            Subcontractor: project.subcontractorName ? "Yes" : "No",
            Technician: project.technician ? project.technician.name : null,
            Vendor: project.vendor ? project.vendor.name : null,
            "Date of Completion": formattedDate(project.doc),
            "Actual Date of completion": formattedDate(project.adc),
            Delay:
              ((new Date(project.adc) - new Date(project.doc)) / (1000 * 3600 * 24) === 1) ?
                ((new Date(project.adc) - new Date(project.doc)) / (1000 * 3600 * 24) + " day") :
                ((new Date(project.adc) - new Date(project.doc)) / (1000 * 3600 * 24) + " days")
          });
        });

        setTableContents(tempProjectList);
        modalClose();
        setLoading(false);
      })
      .catch((e) => {
        setError(true);
        setLoading(false);
        console.log(e);
      });
  }, []);

  const formattedDate = (dateObj) => {
    const date = new Date(dateObj);
    const year = date.getFullYear();
    let month = date.getMonth() + 1;
    let dt = date.getDate();

    if (dt < 10) {
      dt = "0" + dt;
    }
    if (month < 10) {
      month = "0" + month;
    }

    return dt + "/" + month + "/" + year;
  };

  const modalClose = () => {
    setShow(false);
  };

  return (
    <div id="project-list">
      {loading || error ? (
        <Modal show={show} noHandler={modalClose}>
          {error ? <p>Something went wrong!</p> : <Loader></Loader>}
        </Modal>
      ) : null}
      <ContainerHeader formName="Delayed Jobs List" connLinks={connLinks}></ContainerHeader>
      <Paper>

        <div className="project-list-table">
          {tableContents && tableContents.length > 0 ? (
            <MaterialTables
              headings={headings}
              data={tableContents}
              title="Delayed Jobs"
            />
          ) : (
              "No projects"
            )}
        </div>
      </Paper>
    </div>
  );
};

export default DelayedJobs;
