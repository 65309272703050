import React, { useEffect, useState } from "react";
import MaterialTables from "../../../components/materialTable/MaterialTables";
import Button from "../../../components/inputComponents/Button";
import Axios from "axios";
import url from "../../../constants/url";
import Modal from "../../../components/ui/modal/Modal";
import Loader from "../../../components/spinner/Spinner";

const Report = (props) => {
  const [tableContents, setTableContents] = useState([]);
  const [details, setDetails] = useState([]);
  const [registersTable, setRegistersTable] = useState([]);
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [show, setShow] = useState(false);
  let godownData = {};
  useEffect(() => {
    setLoading(true);
    setShow(true);
    Axios.post(url + "/godowns/getGowdownById", { id: props.godown._id })
      .then((data) => {
        let godown = data.data;
        let map = new Map();

        setName(godown.godownName);
        godown.register.map((item) => {
          if (item.material) {
            if (map.has(item.material._id)) {
              let quan = map.get(item.material._id);
              if (item.quantityIn) quan = quan + parseInt(item.quantityIn);
              else if (item.quantityOut)
                quan = quan - parseInt(item.quantityOut);
              map.set(item.material._id, quan);
            } else {
              map.set(
                item.material._id,
                item.quantityIn
                  ? parseInt(item.quantityIn)
                  : -parseInt(item.quantityOut)
              );
            }
          }
          if (item.machinery) {
            if (map.has(item.machinery._id)) {
              let quan = map.get(item.machinery._id);
              if (item.quantityIn) quan = quan + parseInt(item.quantityIn);
              else if (item.quantityOut)
                quan = quan - parseInt(item.quantityOut);
              map.set(item.machinery._id, quan);
            } else {
              map.set(
                item.machinery._id,
                item.quantityIn
                  ? parseInt(item.quantityIn)
                  : -parseInt(item.quantityOut)
              );
            }
          }
        });
        let set = new Set();
        let tempArr = [];
        let i = 0;
        godown.register.map((item) => {
          let tempObj = {};
          if (item.machinery) {
            if (!set.has(item.machinery._id)) {
              i = i + 1;
              set.add(item.machinery._id);
              tempObj["Sl No."] = i;
              tempObj["Material/Machinery"] = item.machinery.description;
              tempObj["Unit"] = item.unit;
              tempObj["Stock Quantity"] = map.get(item.machinery._id);
              tempObj["Action"] = (
                <Button
                  label="View Details"
                  className="success"
                  onClick={() =>
                    viewDetailsHandler(item.machinery._id, "machinery")
                  }
                ></Button>
              );
              tempArr = [...tempArr, tempObj];
            }
          }
          if (item.material) {
            if (!set.has(item.material._id)) {
              i = i + 1;
              set.add(item.material._id);
              tempObj["Sl No."] = i;
              tempObj["Material/Machinery"] = item.material.description;
              tempObj["Unit"] = item.unit;
              tempObj["Stock Quantity"] = map.get(item.material._id);
              tempObj["Action"] = (
                <Button
                  label="View Details"
                  className="success"
                  onClick={() =>
                    viewDetailsHandler(item.material._id, "material")
                  }
                ></Button>
              );
              tempArr = [...tempArr, tempObj];
            }
          }
        });
        let regArr = [];
        let regMap = new Map();
        let prevQuantity = 0;
        godown.register.map((item) => {
          let regObj = {};
          regObj["Date"] = formattedDate(item.date);
          regObj["Material/Machinery"] = item.material
            ? item.material.description
            : item.machinery.description;
          regObj["Reservation No."] = item["reservationNumber"];
          regObj["Delivery Note No."] = item["deliveryNoteNumber"];
          regObj["Gate Pass No."] = item["gatePassNumber"];
          if (
            regMap.has(item.material ? item.material._id : item.machinery._id)
          )
            prevQuantity = regMap.get(
              item.material ? item.material._id : item.machinery._id
            );
          else {
            prevQuantity = 0;
            regMap.set(
              item.material ? item.material._id : item.machinery._id,
              0
            );
          }
          regObj["Opening qty"] = prevQuantity;
          regObj["Quantity In"] = item.quantityIn ? item.quantityIn : 0;
          regObj["Quantity Out"] = item.quantityOut ? item.quantityOut : 0;
          regObj["Stock Quantity"] =
            parseInt(prevQuantity) +
            (item.quantityIn ? parseInt(item.quantityIn) : 0) -
            (item.quantityOut ? parseInt(item.quantityOut) : 0);
          regMap.set(
            item.material ? item.material._id : item.machinery._id,
            regObj["Stock Quantity"]
          );
          regObj["Unit"] = item.unit;
          regObj["Job"] = item.job;
          regObj["Remark"] = item.remark;
          regObj["Vendor Code"] = item.vendor.code;
          regObj["Vendor Name"] = item.vendor.name;
          regArr = [...regArr, regObj];
        });
        setRegistersTable(regArr);
        setTableContents(tempArr);
        godownData = { ...godown };
        modalClose();
        setLoading(false);
      })
      .catch((err) => {
        setError(true);
        setLoading(false);
      });
  }, []);

  const modalClose = () => {
    setShow(false);
  };

  const headings = [
    "Sl No.",
    "Material/Machinery",
    "Unit",
    "Stock Quantity",
    "Action",
  ];
  const headings2 = [
    "Date",
    "Material/Machinery",
    "Reservation No.",
    "Delivery Note No.",
    "Gate Pass No.",
    "Opening qty",
    "Quantity In",
    "Quantity Out",
    "Stock Quantity",
    "Unit",
    "Job",
    "Vendor Code",
    "Vendor Name",
  ];
  const headings3 = [
    "Date",
    "Material/Machinery",
    "Reservation No.",
    "Delivery Note No.",
    "Gate Pass No.",
    "Opening qty",
    "Quantity In",
    "Quantity Out",
    "Stock Quantity",
    "Unit",
    "Job",
    "Remark",
    "Vendor Code",
    "Vendor Name",
  ];

  const formattedDate = (dateObj) => {
    const date = new Date(dateObj);
    const year = date.getFullYear();
    let month = date.getMonth() + 1;
    let dt = date.getDate();

    if (dt < 10) {
      dt = "0" + dt;
    }
    if (month < 10) {
      month = "0" + month;
    }

    return dt + "/" + month + "/" + year;
  };
  const viewDetailsHandler = (id, type) => {
    let tempArr = [];
    let prev = 0;
    godownData.register.map((item) => {
      let tempObj = {};
      if (item[type]) {
        if (item[type]._id === id) {
          tempObj["Date"] = formattedDate(item.date);
          tempObj["Material/Machinery"] = item[type].description;
          tempObj["Reservation No."] = item["reservationNumber"];
          tempObj["Delivery Note No."] = item["deliveryNoteNumber"];
          tempObj["Gate Pass No."] = item["gatePassNumber"];
          tempObj["Opening qty"] = prev;
          tempObj["Quantity In"] = item.quantityIn ? item.quantityIn : 0;
          tempObj["Quantity Out"] = item.quantityOut ? item.quantityOut : 0;
          tempObj["Stock Quantity"] =
            parseInt(prev) +
            (item.quantityIn ? parseInt(item.quantityIn) : 0) -
            (item.quantityOut ? parseInt(item.quantityOut) : 0);
          prev = tempObj["Stock Quantity"];
          tempObj["Unit"] = item.unit;
          tempObj["Job"] = item.job;
          tempObj["Vendor Code"] = item.vendor.code;
          tempObj["Vendor Name"] = item.vendor.name;
          tempArr = [...tempArr, tempObj];
        }
      }
      setDetails(tempArr);
    });
  };
  return (
    <>
      {loading || error ? (
        <Modal show={show} noHandler={modalClose}>
          {error ? <p>Something went wrong!</p> : <Loader></Loader>}
        </Modal>
      ) : null}
      <div>
        <MaterialTables
          headings={headings}
          data={tableContents}
          title={`Stock Register (${name})`}
        ></MaterialTables>
      </div>
      <div>
        <MaterialTables
          headings={headings2}
          data={details}
          title={`Specific Material Report (${name})`}
        ></MaterialTables>
      </div>
      <div>
        <MaterialTables
          headings={headings3}
          data={registersTable}
          title={`All Materials Report (${name})`}
        ></MaterialTables>
      </div>
    </>
  );
};

export default Report;
