import React, {useState} from 'react'
import url,{ url_upload} from "../../../constants/url";
import Paper from "../../../components/whiteCard/whiteCard";
import image from '../../../assets/icons/img.svg'
import pdf from '../../../assets/icons/pdf.svg'
import doc from '../../../assets/icons/doc.svg'
import Button from '../../../components/inputComponents/Button'
import * as requests from '../../../api/requests'
import ConfirmContent from '../../../components/ui/confirmContent/ConfirmContent';
import Modal from '../../../components/ui/modal/Modal';
import Loader from "../../../components/spinner/Spinner";

const styles = {
    container:{
        display: "flex",
        flexDirection: "row"
    },
    card:{
        minHeight: "70px",
        width: "120px",
        borderRadius: "4px",
        // boxShadow: "0 0 5px rgba(60,60,60,0.4)",
        transitionDuration: "0.4s",
        padding: "15px",
        boxSizing: "border-box"
    },
    buttonDiv:{
        paddingTop: "10px"
    }
}
const TallyDocs = (props) =>{
    const [today,setToday] = React.useState(props.location.state)
    const [show, setShow] = useState(false);
    const [ques, setQues] = useState("");
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [functionToPass, setFunctionToPass] = useState(null);

    const modalDelSite = (progressId, dayId, url) => {
        setFunctionToPass(() => () => deleteSitePhoto(progressId, dayId, url));
        setQues("Do you want to delete this Site Photo?");
        setShow(true);
      };
    
      const modalDelTechDoc = (progressId, dayId, url) => {
        setFunctionToPass(() => () => deleteTechDoc(progressId, dayId, url));
        setQues("Do you want to delete this Document?");
        setShow(true);
      };

    const modalClose = () => {
        setShow(false);
      };

    const deleteTechDoc = (progressId,dayId,url) =>{
        setLoading(true)
        requests.post({url:`/workProgress/tally/techDoc/${progressId}/${dayId}`,body:{url:url}})
        .then(data=>{
            setToday(data[0])
            setLoading(false)
            setShow(false)
        }).catch(e=>{
            console.log(e)
            setError(true)
        })
    }
    const deleteSitePhoto = (progressId,dayId,url) =>{
        setLoading(true)
        requests.post({url:`/workProgress/tally/sitePhoto/${progressId}/${dayId}`,body:{url:url}})
        .then(data=>{
            setToday(data[0])
            setLoading(false)
            setShow(false)
        }).catch(e=>{
            console.log(e)
            setError(true)
        })
    }
    return(
        <>
            <Modal show={show} noHandler={modalClose}>
                    {!loading ? (
                    !error ? (
                        <ConfirmContent
                        yesHandler={functionToPass}
                        noHandler={modalClose}
                        question={ques}
                        ></ConfirmContent>
                    ) : (
                        <p>Something went wrong!</p>
                    )
                    ) : (
                    <Loader></Loader>
                    )}
            </Modal>
            <Paper>
                <h1>Technical Documents</h1>
                <div style = {styles.container}>
                    {today.technicalDoc && today.technicalDoc.length>0?today.technicalDoc.map(link=>{
                        return (
                                <div style = {styles.card}>
                                    <a href={url_upload+link.url} target="_blank" style={{margin:"20px"}}>
                                    {
                                        link.url.split(".")[link.url.split(".").length-1]==="png" ||
                                        link.url.split(".")[link.url.split(".").length-1]==="jpg" ||
                                        link.url.split(".")[link.url.split(".").length-1]==="jpeg"?
                                    <img src={image} alt="image" height="40px"/>:
                                        link.url.split(".")[link.url.split(".").length-1]==="pdf"?
                                    <img src={pdf} alt="pdf" height="40px"/>:<img src={doc} alt="Document" height="40px"/>
                                    }
                                    </a>
                                    <br/>
                                    <div style={styles.buttonDiv}>
                                        <Button 
                                        label="Delete" 
                                        className="danger" 
                                        onClick={
                                            ()=>modalDelTechDoc(props.location.state.progressId,
                                                            props.location.state._id,link.url)}
                                        />
                                    </div>
                                </div>
                        )
                    }):"Technical documents are not uploaded"}
                </div>
                <h1>Site Photos</h1>
                <div style = {styles.container}>
                    {today.sitePhoto && today.sitePhoto.length>0?today.sitePhoto.map(link=>{
                        return (
                            <div style = {styles.card}>
                                <a href={url_upload+link.url} target="_blank" style={{margin:"20px"}}>
                                    {
                                        link.url.split(".")[link.url.split(".").length-1]==="png" ||
                                        link.url.split(".")[link.url.split(".").length-1]==="jpg" ||
                                        link.url.split(".")[link.url.split(".").length-1]==="jpeg"?
                                    <img src={image} alt="image" height="40px"/>:
                                        link.url.split(".")[link.url.split(".").length-1]==="pdf"?
                                    <img src={pdf} alt="pdf" height="40px"/>:<img src={doc} alt="Document" height="40px"/>
                                    }
                                </a>
                                <br/>
                                <div style={styles.buttonDiv}>
                                    <Button 
                                    label="Delete" 
                                    className="danger" 
                                    onClick={
                                        ()=>modalDelSite(props.location.state.progressId,
                                                            props.location.state._id,link.url)}
                                    />
                                </div>
                                
                            </div>
                        )
                    }):"Site photos are not uploaded"}
                </div>
            </Paper>
        </>
    )
}

export default TallyDocs