import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import "./AddUser.css";
import ContainerHeader from "../../components/containerHeader/ContainerHeader";
import Paper from "../../components/whiteCard/whiteCard";
import Input from "../../components/inputComponents/Input";
import Button from "../../components/inputComponents/Button";
import DropDown from "../../components/inputComponents/DropDown";
import axios from "axios";
import Modal from "../../components/ui/modal/Modal";
import ConfirmContent from "../../components/ui/confirmContent/ConfirmContent";
import Loader from "../../components/spinner/Spinner";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import url from "../../constants/url";
import Alert from "@material-ui/lab/Alert";
import Typography from "@material-ui/core/Typography";

const AddUser = () => {
  const [formErrors, setFormErrors] = useState([]);
  const [redirect, setRedirect] = useState(false);
  const userTypes = [
    {
      _id: "Select User Type",
      name: "Select User Type",
    },
    {
      _id: "SAP",
      name: "SAP",
    },
    {
      _id: "GM/DGM",
      name: "GM/DGM",
    },
    {
      _id: "Engineer",
      name: "Engineer",
    },
    {
      _id: "Jr. Engineer",
      name: "Jr. Engineer",
    },
    {
      _id: "Technician",
      name: "Technician ",
    },
  ];
  const status = [
    {
      _id: "Select Status",
      name: "Select Status",
    },
    {
      _id: "Active",
      name: "Active",
    },
    {
      _id: "Inactive",
      name: "Inactive",
    },
    {
      _id: "Removed",
      name: "Removed",
    },
  ];
  const [file, setFile] = useState();
  const [otherIpts, setOtherIpts] = useState({});
  const [areas, setAreas] = useState([]);
  const [show, setShow] = useState(false);
  const [ques, setQues] = useState("");
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState([]);
  const [error, setError] = useState(false);
  const [functionToPass, setFunctionToPass] = useState(null);

  const [upload, setUpload] = useState(false);
  const [Bulkfile, setBulkFile] = useState("");
  const [update, setUpdate] = useState(false);
  var [isSubmit, setSubmit] = useState(false);

  let final = [];
  let res = [];
  const arr = areas.map((area) => (
    <div
      name="area selected"
      key={area._id}
      value={area.areaName}
      className="au-allareas"
    >
      <div className="au-area-head">{area.areaName}</div>
      <div className="au-zones">
        {area.zones.map(function (zone) {
          return (
            <FormControlLabel
              control={
                <Checkbox
                  name={area._id}
                  onChange={(e) => {
                    let selectArr = [...selected];
                    const obj = selected.find((o) => o.zone === e.target.value);
                    if (obj) {
                      selectArr.splice(selected.indexOf(obj), 1);
                      setSelected(selectArr);
                    } else {
                      selectArr.push({
                        zone: e.target.value,
                        area: e.target.name,
                        checked: true,
                      });
                      setSelected(selectArr);
                    }
                  }}
                  value={zone._id}
                  
                />
              }
              label={zone.zoneName}
            />
          );
        })}
      </div>
    </div>
  ));

  //sending data
  const onClickHandle = () => {
    setLoading(true);
    console.log(selected);
    for (var i = 0; i < selected.length; i++) {
      var search = selected[i].area;
      const w = res.find((p) => p.area === search);
      if (!w) {
        selected.forEach((func) => {
          if (func.area === search) {
            final.push(func.zone);
          }
        });
        res.push({ area: search, zones: final });
        final = [];
      }
    }
	console.log(res);
    const data = new FormData();
    data.append("email", otherIpts.email);
    data.append("password", otherIpts.pas);
    data.append("name", otherIpts.name);
    data.append("designation", otherIpts.type);
    // salaryCode: otherIpts.sal,
    data.append("file", file);
    data.append("areaAndZone", JSON.stringify(res));
    data.append("status", otherIpts.status);
    data.append("userCode", otherIpts.ucode);
    data.append("mobileNo", otherIpts.mobile);
    axios
      .post(url + "/users/signup", data)
      .then((resp) => {
        modalClose();
        setLoading(false);
        setRedirect(true);
      })
      .catch((e) => {
        setError(true);
        setLoading(false);
      });
    res = [];
    final = [];
  };

  const onBulkUpload = () => {
    const data = new FormData();
    data.append("name", "bulkUpload");
    data.append("file", Bulkfile);
    modalClose();
    axios.post(`${url}/bulkUpload/userUpload`, data)
    .then((res) => {
      setUpdate(!update);
    })
    .catch((err) => {
      setShow(true);
      setLoading(false);
      setUpload(false);
      setError(true);
    })
  }

  useEffect(() => {
    setLoading(true);
    setShow(true);
    axios
      .get(url + "/areas/getAreas")
      .then((res) => {
        res.data.map((area) => {
          area = { ...area, checked: false };
        });
        setAreas(res.data);
        modalClose();
        setLoading(false);
      })
      .catch((e) => {
        setError(true);
        setLoading(false);
      });
  }, []);

  const modalVisible = () => {
    let errors = [];
    const toCheck = {
      name: "NAME",
      email: "EMAIL",
      pas: "PASSWORD",
      name: "NAME",
      type: "DESIGNATION",
      status: "STATUS",
      ucode: "USER CODE",
      mobile: "MOBILE NUMBER",
    };
    Object.keys(toCheck).map((key) => {
      if (!otherIpts[key]) {
        errors.push(toCheck[key] + " is required");
      }
    });
    if (errors.length > 0) {
      setFormErrors(errors);
      return;
    }
    setFormErrors([]);
    setFunctionToPass(() => () => onClickHandle());
    setQues("Do you want to add this user?");
    setShow(true);
  };

  const modalBulkUpload = () => {
    setUpload(true);
    setFormErrors([]);  
    setShow(true);
  }

  const modalClose = () => {
    setShow(false);
    setUpload(false);
  };

  return (
    <div id="add-user">
      {redirect ? <Redirect to="/users" /> : null}
      <Modal show={show} noHandler={modalClose}>
        {!loading ? 
          !upload ? (
            !error ? (
              <ConfirmContent
                yesHandler={functionToPass}
                noHandler={modalClose}
                question={ques}
              ></ConfirmContent>
            ) : (
              <p>Something went wrong!</p>
            )
        ):
        <>
          <Typography>Select a .xlsx file of not more than 2MB that contains the user details.</Typography>
          <Input
              type="file"
              value={isSubmit ? "" : null }
              onChange={(e) => {
                  setSubmit(false);
                  setBulkFile(e.target.files[0])}
              }
              style={{marginBottom: '10px'}}
          />
          <Button 
              className="primary"
              type="button"
              label="Bulk Upload"
              onClick={onBulkUpload}
              disabled={Bulkfile ? false : true}
          />
          <Button 
              className="primary"
              type="button"
              label="Cancel"
              onClick={() => {
                  setFile(false);
                  modalClose();
              }}
          />
        </> 
        : (
          <Loader></Loader>
        )}
      </Modal>
      <ContainerHeader formName="Add User"></ContainerHeader>
      <Paper>
        <div className="add-user-form">
          <div className="fields">
            <div className="field">
              <DropDown
                size="wide"
                label="User Type*"
                options={userTypes}
                onChange={(e) =>
                  setOtherIpts({ ...otherIpts, type: e.target.value })
                }
              />
            </div>
            <div className="field">
              <Input
                name="user-name"
                label="User Name*"
                placeholder="Enter name"
                size="wide"
                onChange={(e) =>
                  setOtherIpts({ ...otherIpts, name: e.target.value })
                }
              />
            </div>
            {/* <div className="field"> */}
            {/* <Input
                name="user-sal-code"
                label="Salary Code"
                placeholder="Salary Code"
                size="wide"
                onChange={(e) =>
                  setOtherIpts({ ...otherIpts, sal: e.target.value })
                }
              /> */}
            {/* </div> */}
            <div className="field">
              <Input
                name="user-code"
                label="User Code*"
                placeholder="User Code"
                size="wide"
                onChange={(e) =>
                  setOtherIpts({ ...otherIpts, ucode: e.target.value })
                }
              />
            </div>
            <div className="field">
              <Input
                name="email"
                label="Email Id*"
                placeholder="Enter Email Id"
                size="wide"
                onChange={(e) =>
                  setOtherIpts({ ...otherIpts, email: e.target.value })
                }
              />
            </div>
            <div className="field">
              <Input
                name="password"
                label="Password*"
                placeholder="Enter Password"
                type="password"
                size="wide"
                onChange={(e) =>
                  setOtherIpts({ ...otherIpts, pas: e.target.value })
                }
              />
            </div>
            <div className="field">
              <Input
                name="confirm-password"
                label="Confirm password*"
                type="password"
                placeholder="Re-enter password"
                size="wide"
                onChange={(e) =>
                  setOtherIpts({ ...otherIpts, repas: e.target.value })
                }
              />
            </div>
            <div className="field">
              <Input
                name="mobile"
                label="Mobile number*"
                placeholder="Enter mobile number"
                size="wide"
                onChange={(e) =>
                  setOtherIpts({ ...otherIpts, mobile: e.target.value })
                }
              />
            </div>
            <div className="field">
              <DropDown
                size="wide"
                label="Status*"
                options={status}
                onChange={(e) =>
                  setOtherIpts({ ...otherIpts, status: e.target.value })
                }
              />
            </div>
            <div className="field">
              <Input
                name="upload-photo"
                label="Upload photo"
                size="wide"
                type="file"
                onChange={(event) => {
                  const file = event.target.files[0];
                  setFile(file);
                }}
              />
            </div>
          </div>
          <span className="au-text">Select Areas & Zones *</span>
          <div className="au-areascontainer">{arr}</div>
          <hr />
          {formErrors.map((error) => {
            return <Alert severity="info">{error}</Alert>;
          })}
          <Button
            onClick={modalVisible}
            className="success"
            label="Submit"
          ></Button>
          <Button 
            className="primary"
            label="User Bulk Upload"
            onClick={modalBulkUpload}
          />
        </div>
      </Paper>
    </div>
  );
};

export default AddUser;
