import React, { useState, useEffect } from "react";

import "./AddVendor.css";
import Paper from "../../components/whiteCard/whiteCard";
import ContainerHeader from "../../components/containerHeader/ContainerHeader";
import Input from "../../components/inputComponents/Input";
import Button from "../../components/inputComponents/Button";
import DropDown from "../../components/inputComponents/DropDown";
import TextArea from "../../components/inputComponents/TextArea";
import axios from "axios";
import Modal from "../../components/ui/modal/Modal";
import ConfirmContent from "../../components/ui/confirmContent/ConfirmContent";
import Loader from "../../components/spinner/Spinner";
import url from "../../constants/url";
import Alert from "@material-ui/lab/Alert";
import Typography from "@material-ui/core/Typography";

const authCheck = require("../../helpers/auth.helpers");

const AddVendor = (props) => {

  const { _id } = authCheck(localStorage.getItem("authCode"));

  const [formErrors, setFormErrors] = useState([]);
  const [form, setform] = useState({});
  const [zoneOp, setzoneOp] = useState([]);
  const [show, setShow] = useState(false);
  const [ques, setQues] = useState("");
  const [loading, setLoading] = useState(false);
  const [functionToPass, setFunctionToPass] = useState(null);
  const [error, setError] = useState(false);
  var [isSubmit, setSubmit] = useState(false);

  const [upload, setUpload] = useState(false);
  const [file, setFile] = useState(null);
  const [update, setUpdate] = useState(false);

  const vendorOp = [
    {
      _id: "Select Class",
      name: "Select Class",
    },
    {
      _id: "A",
      name: "A",
    },
    {
      _id: "B",
      name: "B",
    },
    {
      _id: "C",
      name: "C",
    },
    {
      _id: "D",
      name: "D",
    },
    {
      _id: "E",
      name: "E",
    },
  ];
  const statusOp = [
    {
      _id: "Select Status",
      name: "Select Status",
    },
    {
      _id: "Active",
      name: "Active",
    },
    {
      _id: "Inactive",
      name: "Inactive",
    },
  ];

  useEffect(() => {
    setLoading(true);
    setShow(true);
    axios
      .get(url + "/zones/getzones")
      .then((result) => {
        let zoneOpArray = [
          {
            _id: "Select Zone Code",
            name: "Select Zone Code",
          },
        ];
        result.data.map((zone) => {
          zoneOpArray.push({
            _id: zone.zoneCode,
            name: zone.zoneCode + " (" + zone.zoneName + ")",
          });
        });
        setzoneOp(zoneOpArray);
        modalClose();
        setLoading(false);
      })
      .catch((error) => {
        setError(true);
        setLoading(false);
      });
  }, [update]);

  const onClickHandler = () => {
    setLoading(true);
    const data = new FormData();
    data.append("name", form.vendorName);
    data.append("address", form.address);
    data.append("status", form.status);
    data.append("zoneCode", form.zoneCode);
    data.append("email", form.email);
    data.append("class", form.vendorClass);
    data.append("phone", form.phone);
    data.append("code", form.vendorCode);
    data.append("photo", form.photo);
    data.append("signature", form.signature);
    axios
      .post(url + "/vendors/createVendors", data)
      .then((result) => {
        setLoading(false);
        modalClose();
        setSubmit(true);
        window.location.reload();
      })
      .catch((error) => {
        setFunctionToPass(() => () => modalClose());
        setError(true);
        setLoading(false);
      });
  };

  const modalVisible = () => {
    setError(false);
    let errors = [];
    const toCheck = {
      vendorName: "VENDOR NAME",
      address: "ADDRESS",
      status: "STATUS",
      zoneCode: "ZONE CODE",
      email: "EMAIL",
      vendorClass: "VENDOR CLASS",
      phone: "PHONE",
      vendorCode: "VENDOR CODE",
    };
    Object.keys(toCheck).map((key) => {
      if (!form[key]) {
        errors.push(toCheck[key] + " is required");
      }
    });
    if (errors.length > 0) {
      setFormErrors(errors);
      return;
    }
    setFormErrors([]);
    setFunctionToPass(() => () => onClickHandler());
    setQues("Do you want to add this vendor?");
    setShow(true);
  };

  const onBulkUpload = () => {
    const data = new FormData();
    data.append("name", "bulkUpload");
    data.append("file", file);
    modalClose();
    axios.post(`${url}/bulkUpload/vendorUpload`, data)
    .then((res) => {
      setUpdate(!update);
    })
    .catch((err) => {
      setShow(true);
      setLoading(false);
      setUpload(false);
      setError(true);
    })
  }

  const deleteAllVendors = (e) => {
    setUpload(false);
    setFunctionToPass(() => () => ondeleteConfirm());
    setQues("Do you want to delete all vendors?");
    setShow(true);
  }

  const ondeleteConfirm = () => {
    axios.post(`${url}/vendors/deleteAll`, {
      id: _id
    })
    .then((ers) => {
      setShow(false);
    })
    .catch((err) => {
      setLoading(false);
      setUpload(false);
      setError(true);
    })
  }

  const modalBulkUpload = () => {
    setUpload(true);
    setFormErrors([]);  
    setShow(true);
  }

  const modalClose = () => {
    setShow(false);
    setUpload(false);
  };

  return (
    <div id="add-vendor">
      <Modal show={show} noHandler={modalClose}>
        {!loading ? 
          !upload ? (
          !error ? (
            <ConfirmContent
              yesHandler={functionToPass}
              noHandler={modalClose}
              question={ques}
            ></ConfirmContent>
          ) : (
            <p>Something went wrong!</p>
          )
        ) :
        <>
          <Typography>Select a .xlsx file of not more than 2MB that contains the vendor details.</Typography>
            <Input
              type="file"
              value={isSubmit ? "" : null }
              onChange={(e) => {
                setSubmit(false);
                setFile(e.target.files[0])}
              }
              style={{marginBottom: '10px'}}
            />
            <Button 
              className="primary"
              type="button"
              label="Bulk Upload"
              onClick={onBulkUpload}
              disabled={file ? false : true}
            />
            <Button 
              className="primary"
              type="button"
              label="Cancel"
              onClick={() => {
                setFile(false);
                modalClose();
              }}
            />
        </>
        : (
          <Loader></Loader>
        )}
      </Modal>
      <ContainerHeader formName="Add Vendor Form" />
      <Paper>
        <div className="add-vendor-form">
          <div className="fields">
            <div className="field">
              <Input
                name="vendor-code"
                label="Vendor Code*"
                placeholder="Enter Vendor Code"
                size="wide"
                onChange={(event) =>
                  setform({ ...form, vendorCode: event.target.value })
                }
              />
            </div>
            <div className="field">
              <Input
                name="vendor-name"
                label="Vendor Name*"
                placeholder="Enter Vendor Name"
                size="wide"
                onChange={(event) =>
                  setform({ ...form, vendorName: event.target.value })
                }
              />
            </div>

            <div className="field">
              <DropDown
                size="wide"
                label="Vendor Class*"
                options={vendorOp}
                onChange={(event) =>
                  setform({ ...form, vendorClass: event.target.value })
                }
              />
            </div>
            <div className="field">
              <DropDown
                name="zone-code"
                label="Zone Code*"
                size="wide"
                options={zoneOp}
                onChange={(event) =>
                  setform({ ...form, zoneCode: event.target.value })
                }
              />
            </div>
            <div className="field">
              <Input
                name="mobile-no"
                label="Mobile No*"
                placeholder="Enter Mobile Number"
                size="wide"
                type="tel"
                onChange={(event) =>
                  setform({ ...form, phone: event.target.value })
                }
              />
            </div>
            <div className="field">
              <Input
                name="email"
                label="Email ID*"
                placeholder="Enter Email ID"
                size="wide"
                type="email"
                onChange={(event) =>
                  setform({ ...form, email: event.target.value })
                }
              />
            </div>
          </div>
          <div className="text-area">
            <TextArea
              name="vendor-address"
              label="Vendor Address*"
              placeholder="Enter the Address"
              size="wide"
              onChange={(event) =>
                setform({ ...form, address: event.target.value })
              }
            />
          </div>
          <div className="fields">
            <div className="field">
              <Input
                name="upload-photo"
                label="Upload Photo"
                size="wide"
                type="file"
                onChange={(event) => {
                  setSubmit(false);
                  const file = event.target.files[0];
                  setform((prev) => {
                    return {
                      ...prev,
                      photo: file,
                    };
                  });
                }}
                value={isSubmit ? "" : null}
              />
            </div>
            <div className="field">
              <Input
                name="upload-signature"
                label="Upload Signature"
                size="wide"
                type="file"
                onChange={(event) => {
                  setSubmit(false);
                  const file = event.target.files[0];
                  setform((prev) => {
                    return {
                      ...prev,
                      signature: file,
                    };
                  });
                }}
                value={isSubmit ? "" : null}
              />
            </div>
            <div className="field">
              <DropDown
                name="Status"
                size="wide"
                options={statusOp}
                label="Status*"
                onChange={(event) =>
                  setform({ ...form, status: event.target.value })
                }
              />
            </div>
          </div>
        </div>
        <hr />
        {formErrors.map((error) => {
          return <Alert severity="info">{error}</Alert>;
        })}
        <Button
          className="primary"
          type="submit"
          label="Submit"
          onClick={modalVisible}
        />
        <Button 
          className="primary"
          type="button"
          label="Bulk Upload"
          onClick={modalBulkUpload}
        />
        <Button
          className="primary"
          type="button"
          label="Delete All Vendors"
          onClick={deleteAllVendors}
        />
      </Paper>
    </div>
  );
};

export default AddVendor;
