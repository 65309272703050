import React, { Fragment, useState, useEffect } from "react";
import "./WorkOrder.css";
import ContainerHeader from "../../components/containerHeader/ContainerHeader";
import Whitecard from "../../components/whiteCard/whiteCard";
import Input from "../../components/inputComponents/Input";
import Button from "../../components/inputComponents/Button";
import TextArea from "../../components/inputComponents/TextArea";
import { PDFViewer } from "@react-pdf/renderer";
import WorkOrderPrint from "../../components/workOrderPrint/WorkOrderPrint";
import Axios from "axios";
import Modal from "../../components/ui/modal/Modal";
import ConfirmContent from "../../components/ui/confirmContent/ConfirmContent";
import Loader from "../../components/spinner/Spinner";
import { Redirect } from "react-router-dom";
import url from "../../constants/url";
import IconButton from "../../components/inputComponents/IconButton";
import Alert from "@material-ui/lab/Alert";
import authCheck from "../../helpers/auth.helpers";

const WorkOrder = (props) => {
  const { areaAndZone, designation } = authCheck(localStorage.getItem('authCode'));

  const [showPdf, setshowPdf] = useState(false);
  const [ques, setQues] = useState("");
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [redirect, setredirect] = useState(false);
  const [edit, setEdit] = useState(false);
  const [error, setError] = useState(false);
  const [formErrors, setFormErrors] = useState([]);
  const [precont, setprecont] = useState('')
  const connLinks = [
    {
      name: "Project list",
      path: '/projects'
    }
  ]
  const modalClose = () => {
    setShow(false);
  };
  const modalVisible = (e) => {
    e.preventDefault();
    setError(false);
    let errors = [];
    let toCheck = {};
    toCheck = {
      workContent: "WORK ORDER DESCRIPTION",
      workOrderDate: "WORK ORDER DATE",
      workOrderNumber: "WORK ORDER NUMBER",
    };
    Object.keys(toCheck).map((key) => {
      if (!workOrderDetails[key]) {
        errors.push(toCheck[key] + " is required");
      }
    });
    if (errors.length > 0) {
      setFormErrors(errors);
      return;
    }
    setFormErrors([]);
    setQues("Do you want to issue this Work Order?");
    setShow(true);
  };

  const [workOrderDetails, setworkOrderDetails] = useState({
    workContent: "",
    workOrderDate: "",
    workOrderNumber: "",
  });
  const [details, setdetails] = useState({
    projectId: "",
    vendor: "",
    notificationNum: "",
    dos: "",
    doc: "",
    vendorAddress: "",
    description: "",
    zoneCode: "",
    workOrder: {},
  });

  const processDate = (date) => {
    var parts = date.split("-");
    return new Date(parts[0], parts[1] - 1, parts[2]);
  };
  const formattedDate = (dateObj) => {
    const date = new Date(dateObj);
    const year = date.getFullYear();
    let month = date.getMonth() + 1;
    let dt = date.getDate();

    if (dt < 10) {
      dt = "0" + dt;
    }
    if (month < 10) {
      month = "0" + month;
    }
    return year + "-" + month + "-" + dt;
  };
  useEffect(() => {
    setLoading(true);
    setShow(true);
    Axios.get(
      url + "/projects/projectDetails?projectId=" + props.match.params.id
    )
      .then((result) => {
        let data = result.data;
        let tempobj = {};
        tempobj["_id"] = data["_id"];
        tempobj["projectId"] = data["projectId"];
        tempobj["vendor"] = data["vendor"]["name"];
        tempobj["notificationNum"] = data["notificationNo"];
        tempobj["dos"] = formattedDate(data["dos"]);
        tempobj["doc"] = formattedDate(data["doc"]);
        tempobj["vendorAddress"] = data["vendor"]["address"];
        tempobj["vendorCode"] = data["vendor"]["code"];
        tempobj["description"] = data["description"];
        tempobj["workOrder"] = data["workOrder"];
        tempobj["zoneCode"] = data["zone"]["zoneCode"];
        tempobj["issuerName"] = localStorage.getItem("Name");
        tempobj["designation"] = designation;
        tempobj["vendor"] = data["vendor"]["name"];
        setdetails(tempobj);
        setworkOrderDetails({
          ...workOrderDetails,
          workOrderNumber:
            "CIV/WO/" + data["projectId"].substr(data["projectId"].length - 5),
        });
        modalClose();
        setLoading(false);
      })
      .catch((error) => {
        console.log(error)
        setError(true);
        setLoading(false);
      });
  }, []);

  const submitHandler = () => {
    setLoading(true);
    Axios.patch(url + "/projects/editProject/" + details._id, {
      workOrder: workOrderDetails,
      description:details.description, 
    })
      .then((result) => {
        Axios.post(url + "/workProgress/addWorkProgress", {
          projectId: details._id,
          dos: processDate(details.dos),
          doc: processDate(details.doc),
        })
          .then((result) => {
            setredirect(true);
            modalClose();
            setLoading(false);
          })
          .catch((error) => {
            setError(true);
            setLoading(false);
          });
      })
      .catch((error) => {
        setError(true);
        setLoading(false);
      });
  };
  return (
    <div>
      {redirect ? <Redirect to="/projects" /> : null}
      <Modal show={show} noHandler={modalClose}>
        {!loading ? (
          !error ? (
            <ConfirmContent
              yesHandler={submitHandler}
              noHandler={modalClose}
              question={ques}
            ></ConfirmContent>
          ) : (
            <p>Something went wrong!</p>
          )
        ) : (
          <Loader></Loader>
        )}
      </Modal>

      <ContainerHeader formName="Provisional Work Order" connLinks={connLinks}></ContainerHeader>
      {showPdf ? (
        <Whitecard>
          <Fragment>
            <PDFViewer height="800" width="1200" className="app">
              <WorkOrderPrint projectDetails={details} docName="Work Order" />
            </PDFViewer>
          </Fragment>
        </Whitecard>
      ) : (
        <Whitecard>
          <form>
            <div id="an-col">
              <div className="an-row">
                <div className="an-row-in">
                  {" "}
                  <Input
                    disabled="true"
                    name="project-id"
                    label="Project ID"
                    value={details.projectId}
                    size="wide"
                  />
                </div>
                <div className="an-row-in">
                  {" "}
                  <Input
                    disabled="true"
                    name="vendor-name"
                    label="Vendor Name"
                    value={details.vendor}
                    size="wide"
                  />
                </div>
                <div className="an-row-in">
                  {" "}
                  <Input
                    disabled="true"
                    name="work-order-no"
                    label="Work Order No."
                    value={workOrderDetails.workOrderNumber}
                    size="wide"
                  />
                </div>
                <div className="an-row-in">
                  {" "}
                  {edit ? (
                    <Input
                      type="date"
                      name="workorder-date"
                      label="Work Order Date"
                      placeholder="Select Date"
                      size="wide"
                      onChange={(e) =>
                        setworkOrderDetails({
                          ...workOrderDetails,
                          workOrderDate: e.target.value,
                        })
                      }
                    />
                  ) : (
                    <Input
                      type="date"
                      name="workorder-date"
                      label="Work Order Date"
                      placeholder="Select Date"
                      size="wide"
                      disabled={details.workOrder ? true : false}
                      value={
                        details.workOrder
                          ? formattedDate(details.workOrder.workOrderDate)
                          : null
                      }
                      onChange={(e) =>
                        setworkOrderDetails({
                          ...workOrderDetails,
                          workOrderDate: e.target.value,
                        })
                      }
                    />
                  )}
                </div>
              </div>
              <div className="an-row">
                <div className="an-row-in">
                  {" "}
                  <Input
                    disabled="true"
                    name="notification-no"
                    label="Notification No/Contract No"
                    value={details.notificationNum}
                    size="wide"
                  />
                </div>
                <div className="an-row-in">
                  {" "}
                  <Input
                    disabled="true"
                    name="dos"
                    label="DOS"
                    value={details.dos}
                    size="wide"
                  />
                </div>
                <div className="an-row-in">
                  {" "}
                  <Input
                    disabled="true"
                    name="doc"
                    label="DOC"
                    value={details.doc}
                    size="wide"
                  />
                </div>
              </div>
              <div className="an-row">
                <div className="an-row-in">
                  <TextArea
                    value={details.vendorAddress}
                    disabled="true"
                    size="wide"
                    label="Vendor Address"
                  />
                </div>
              </div>
              <h3>Dear Sir/Madam</h3>
              <div className="an-row">
                {edit ? (
                  <div className="an-row-in">
                  <TextArea
                    value={details.description}
                    onChange={e => setdetails({...details, description: e.target.value})}
                    size="wide"
                    label="Project Description"
                  />
                </div>
                ) : (
                  <div className="an-row-in">
                  <TextArea
                    value={details.description}
                    disabled="true"
                    size="wide"
                    label="Project Description"
                  />
                </div>
                )}
              </div>
              <div className="wo-static">

                <div>
                  {edit ? (
                    <TextArea
                      size="wide"
                      label="Special Instructions"
                      onChange={(e) =>
                        setworkOrderDetails({
                          ...workOrderDetails,
                          workContent: e.target.value,
                        })
                      }
                    />
                  ) : (
                    <TextArea
                      size="wide"
                      label="Special Instructions"
                      disabled={details.workOrder ? true : false}
                      value={
                        details.workOrder ? details.workOrder.workContent : precont
                      }
                      onChange={(e) =>
                       {  setprecont(e.target.value)
                          setworkOrderDetails({
                          ...workOrderDetails,
                          workContent: precont,
                        })}
                      }
                    />
                  )}
                </div>
              </div>
              <div className="wo-static"></div>
              <div className="an-row">
                <div className="an-row-in">
                  <div id="wo-border" className="wo-center">
                    Signature
                  </div>
                  <div className="wo-center"> {details.vendor} </div>
                  <div className="wo-center">
                    Vendor code: {details.vendorCode}
                  </div>
                </div>
                <div className="wo-space"></div>
                <div className="an-row-in">
                  <div id="wo-border" className="wo-center">
                    Signature
                  </div>
                  <div className="wo-center"> {details.issuerName} </div>
                  <div className="wo-center"> {details.designation} </div>
                </div>
              </div>
              <div className="wo-static"></div>
              <br />
              {formErrors.map((error) => {
                return <Alert severity="info">{error}</Alert>;
              })}
              <div className="an-row-2">
                {details.workOrder && !edit ? (
                  <>
                    <Button
                      type="submit"
                      className="danger"
                      label="Print"
                      onClick={() => setshowPdf(true)}
                    />
                    <IconButton
                      iconClass="fa fa-pencil"
                      className="success"
                      hint="Edit User"
                      onClick={() => setEdit(true)}
                    />
                  </>
                ) : (
                  <Button
                    label="Save"
                    className="danger"
                    onClick={(e) => modalVisible(e)}
                  />
                )}
              </div>
            </div>
          </form>
        </Whitecard>
      )}
    </div>
  );
};

export default WorkOrder;
