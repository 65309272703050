import React, { useState, useEffect } from "react";
import Checkbox from "../../components/inputComponents/MultiInput";
import Button from "../../components/inputComponents/Button";
import Table from "../../components/table/unsortedTable/UnsortedTable";
import ContainerHeader from "../../components/containerHeader/ContainerHeader";
import Paper from "../../components/whiteCard/whiteCard";
import "./DailyProgressDate.css";
import Axios from "axios";
import Modal from "../../components/ui/modal/Modal";
import ConfirmContent from "../../components/ui/confirmContent/ConfirmContent";
import Loader from "../../components/spinner/Spinner";
import { Link } from "react-router-dom";
import Input from "../../components/inputComponents/Input";
import url from "../../constants/url";
import autCheck from "../../helpers/auth.helpers";

const DailyProgressDate = (props) => {

  const { designation } = autCheck(localStorage.getItem('authCode'));

  const [contents, setContents] = useState([]);
  const [update, setUpdate] = useState(false);
  const [show, setShow] = useState(false);
  const [ques, setQues] = useState(true);
  const [functionToPass, setFunctionToPass] = useState(null);
  const [loading, setLoading] = useState(false);
  const [extendUpto, setExtendUpto] = useState();

  const connLinks = [
    {
      name: "Project list",
      path: '/projects'
    },
  ]
  const modalVisible = (role, dayId, type) => {
    setFunctionToPass(() => () => checkHandler(role, dayId, type));
    setQues(
      "Are you sure you have verfied the tally for this day? You cannot change it later. Continue?"
    );
    setShow(true);
  };

  const modalVisibleForExtend = () => {
    setFunctionToPass(() => () => extendDateFunc());
    setQues("Do you want to extend the date?");
    setShow(true);
  };

  const modalClose = () => {
    setShow(false);
  };

  const processDate = (date) => {
    var parts = date.split("/");
    return new Date(parts[2], parts[1] - 1, parts[0]);
  };

  const formattedDate = (dateObj) => {
    const date = new Date(dateObj);
    const year = date.getFullYear();
    let month = date.getMonth() + 1;
    let dt = date.getDate();

    if (dt < 10) {
      dt = "0" + dt;
    }
    if (month < 10) {
      month = "0" + month;
    }

    return dt + "/" + month + "/" + year;
  };

  useEffect(() => {
    Axios.get(
      url + "/projects/projectDetails?projectId=" + props.match.params.projectId
    )
      .then((resp) => {
        let tempArr = [];
        resp.data.progress.perDayProgress.map((progress) => {
          let tempObj = {};
          let dayId = progress["_id"];
          let verify=false;
          if (designation === "Engineer")
            verify = progress["approvedByEngineer"];
          else if (designation === "Jr. Engineer")
            verify = progress["approvedByJe"];
          else if (designation === "Technician")
            verify = progress["approvedByTech"];
          tempObj["Date of Entry"] = formattedDate(progress["dateOfEntry"]);
          let op = progress["approvedByTech"] ? (
            <Checkbox
              options={["Yes"]}
              type="checkbox"
              checkedIndex={0}
            ></Checkbox>
          ) : (
            <Checkbox
              options={["Yes"]}
              type="checkbox"
              onChange={() =>
                modalVisible("Technician", dayId, "approvedByTech")
              }
            ></Checkbox>
          );
          tempObj["Verified by Technician"] = op;
          op = progress["approvedByJe"] ? (
            <Checkbox
              options={["Yes"]}
              type="checkbox"
              checkedIndex={0}
            ></Checkbox>
          ) : (
            <Checkbox
              options={["Yes"]}
              type="checkbox"
              onChange={() =>
                modalVisible("Jr. Engineer", dayId, "approvedByJe")
              }
            ></Checkbox>
          );
          tempObj["Verified by Jr. Engineer"] = op;
          op = progress["approvedByEngineer"] ? (
            <Checkbox
              options={["Yes"]}
              type="checkbox"
              checkedIndex={0}
            ></Checkbox>
          ) : (
            <Checkbox
              options={["Yes"]}
              type="checkbox"
              onChange={() =>
                modalVisible("Engineer", dayId, "approvedByEngineer")
              }
            ></Checkbox>
          );
          tempObj["Verified by Engineer"] = op;
          tempObj["Open"] = (
            new Date(progress["dateOfEntry"])<= new Date()?
              <Link
                to={
                  "/tally/" + props.match.params.projectId + "/" + progress["_id"]
                }
              >
                <Button
                  label={verify || resp.data.state === "Completed"? "View" : "Edit"}
                  className="success"
                ></Button>
              </Link>
            :
            <Button
              label={verify || resp.data.state === "Completed" ? "View" : "Edit"}
              className="disabled"
              >{resp.data}</Button>
          );
          tempArr = [...tempArr, tempObj];
        });
        setContents(tempArr);
      })
      .catch((err) => {
      });
  }, [update]);

  const checkHandler = (role, dayId, type) => {
    if (designation !== role) {
      setShow(false);
      alert("You can only verify for your designation!");
      setUpdate(!update);
    } else {
      setLoading(true);
      Axios.post(url + "/workProgress/updateVerified", {
        progressId: props.match.params.progressId,
        dayId: dayId,
        type: type,
      })
        .then((resp) => {
          setLoading(false);
          modalClose();
          setUpdate(!update);
        })
        .catch((err) => {
          setLoading(false);
          modalClose();
          setUpdate(!update);
        });
    }
  };

  const extendDateFunc = () => {
    setLoading(true);
    Axios.post(url + "/workProgress/extend", {
      progressId: props.match.params.progressId,
      projectId: props.match.params.projectId,
      from: processDate(contents[contents.length - 1]["Date of Entry"]),
      to: new Date(extendUpto),
    })
      .then((data) => {
        setLoading(false);
        modalClose();
        setUpdate(!update)
      })
      .catch((e) => {
      });
  };

  return (
    <div className="dailyprogress">
      <Modal show={show} noHandler={modalClose}>
        {!loading ? (
          <ConfirmContent
            yesHandler={functionToPass}
            noHandler={modalClose}
            question={ques}
          ></ConfirmContent>
        ) : (
          <Loader></Loader>
        )}
      </Modal>
      <ContainerHeader formName="Daily Progress Date List" connLinks={connLinks}></ContainerHeader>
      <Paper>
        <p>
          * Current expected date of completion{" "}
          {contents[contents.length - 1]
            ? contents[contents.length - 1]["Date of Entry"]
            : null}
        </p>
        <Input
          type="date"
          onChange={(event) => setExtendUpto(event.target.value)}
        />
        <span style={{ marginLeft: "20px" }}></span>
        <button
          label="Extend"
          className="success"
          disabled={
            extendUpto &&
            processDate(contents[contents.length - 1]["Date of Entry"]).setHours(0,0,0,0) >=
          new Date(extendUpto).setHours(0,0,0,0)
          }
          style={{
            cursor:
              extendUpto &&
              processDate(contents[contents.length - 1]["Date of Entry"]).setHours(0,0,0,0) >=
          new Date(extendUpto).setHours(0,0,0,0)
                ? "no-drop"
                : "pointer",
          }}
          onClick={() => modalVisibleForExtend()}
        >
          Extend
        </button>
        {extendUpto &&
        processDate(contents[contents.length - 1]["Date of Entry"]).setHours(0,0,0,0) >=
          new Date(extendUpto).setHours(0,0,0,0) ? (
          <p style={{ marginTop: "-10px", color: "red" }}>
            Please enter a valid date of extension
          </p>
        ) : null}
        <div className="dailytable">
          {contents.length != 0 ? <Table contents={contents}></Table> : null}
        </div>
      </Paper>
    </div>
  );
};

export default DailyProgressDate;
