import React, { Fragment, useState, useEffect } from "react";
import WhiteCard from "../../../components/whiteCard/whiteCard";
import letterHead from "../../../assets/letterHead.png";
import axios from "axios";
import Button from "../../../components/inputComponents/Button";
import url from "../../../constants/url";
import TextArea from "../../../components/inputComponents/TextArea";
import Input from "../../../components/inputComponents/Input";
import WorkOrderPrint from "../../../components/workOrderPrint/WorkOrderPrint";
import "./JobComplete.css";
import { PDFViewer } from "@react-pdf/renderer";
import { Redirect } from "react-router-dom";
import ContainerHeader from "../../../components/containerHeader/ContainerHeader";

const JobComplete = (props) => {
  const [details, setDetails] = useState({});
  const [showPdf, setshowPdf] = useState(false);
  const [valueRec, setValueRec] = useState("");
  const [valueRecCom, setValueRecCom] = useState("");
  const [ifTrue, setIfTrue] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const connLinks = [
    {
      name: "Project list",
      path: '/projects'
    }
  ]
  const formattedDate = (dateObj) => {
    const date = new Date(dateObj);
    const year = date.getFullYear();
    let month = date.getMonth() + 1;
    let dt = date.getDate();

    if (dt < 10) {
      dt = "0" + dt;
    }
    if (month < 10) {
      month = "0" + month;
    }
    return dt + "-" + month + "-" + year;
  };

  useEffect(() => {
    axios
      .get(url + "/projects/projectDetails?projectId=" + props.match.params.id)
      .then((res) => {
        let obj = {
          engineer: res.data.engineer.name,
          description: res.data.description,
          dos: formattedDate(new Date(res.data.dos).toDateString()),
          doc: formattedDate(new Date(res.data.doc).toDateString()),
          vendorName: res.data.vendor ? res.data.vendor.name : "",
          notificationNo: res.data.notificationNo
            ? res.data.notificationNo
            : "",
          projectType: res.data.projectType ? res.data.projectType : "",
          adc: formattedDate(new Date(res.data.adc).toDateString()),
          recName: res.data.recName ? res.data.recName : "",
          recCom: res.data.recCom ? res.data.recCom : "",
          jcId:
            "CIV/JC/" +
            res.data["projectId"].substr(res.data["projectId"].length - 5),
        };
        setDetails(obj);
        setIfTrue(res.data.jobComp);
      })
      .catch((e) => { });
  }, []);

  function printDiv() {
    setshowPdf(true);
  }

  function editShowCause() {
    setIfTrue(false);
    setValueRecCom("");
    setValueRec("");
  }

  function submitShowCause() {
    axios
      .patch(url + "/projects/showCause/" + props.match.params.id, {
        recName: valueRec,
        recCom: valueRecCom,
        jobComp: true,
      })
      .then((res) => {
        setRedirect(true);
      })
      .catch((e) => { });
  }
  return (
    <div>
      {redirect ? <Redirect to="/projects" /> : null}
      <ContainerHeader formName="Job Completion Certificate" connLinks={connLinks} />
      {showPdf ? (
        <WhiteCard>
          <Fragment>
            <PDFViewer height="800" width="1200" className="app">
              <WorkOrderPrint
                projectDetails={details}
                docName="Job Completion Certificate"
              />
            </PDFViewer>
          </Fragment>
        </WhiteCard>
      ) : (
          <WhiteCard>
            <div id="" className="showcause">
              <div className="sc-1">
                <img className="lh-forms" src={letterHead} />
              </div>
              <div className="sc-3"></div>
              <h1 className="sc-2">To Whom It May Concern</h1>
              <h3>Work Completion Certificate</h3>
              <div className="jc-col">
                <p>
                  <b>JC No. : {details.jcId}</b>
                </p>
                <p>
                  This is to certify that {details.vendorName} has successfully
                completed the job as per our job notification no:{" "}
                  {details.notificationNo}
                </p>
                <p>The work is completed on {details.adc} successfully.</p>
                <p>Thanking you and assuring you for our best services always.</p>
              </div>
              <div className="jc-col">
                <p>Name of Work/ Project: {details.projectType}</p>
                <p>Project Description: {details.description} </p>
                <p>Vendor: {details.vendorName}</p>
                <p>Engineer: {details.engineer}</p>
                <p>
                  Work Period: {details.doc} To {details.dos}
                </p>
              </div>
              <div className="sc-1 job-complete-res">
                <div className="jc-col">
                  <Input
                    value={ifTrue ? details.recName : valueRec}
                    size="wide"
                    disabled={ifTrue ? "true" : null}
                    label="With Regards"
                    onChange={(e) => setValueRec(e.target.value)}
                  />
                  <p className="jc-p">Signature</p>
                </div>
                <div className="jc-col">
                  <TextArea
                    value={ifTrue ? details.recCom : valueRecCom}
                    disabled={ifTrue ? "true" : null}
                    size="wide"
                    label="Requisitioner Comment"
                    onChange={(e) => setValueRecCom(e.target.value)}
                  />
                  <p className="jc-p">Signature</p>
                </div>
              </div>
            </div>
            <div className="sc-button">
              {ifTrue ? (
                <div>
                  <Button
                    className="success"
                    label="Print Certificate"
                    onClick={printDiv}
                  />
                  <Button
                    className="success"
                    label="Edit Certificate"
                    onClick={editShowCause}
                  />
                </div>
              ) : (
                  <Button
                    className="success"
                    label="Issue Certificate"
                    onClick={submitShowCause}
                  />
                )}
            </div>
          </WhiteCard>
        )}
    </div>
  );
};

export default JobComplete;
