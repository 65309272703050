import React from "react";
import IconButton from "../../inputComponents/IconButton";

const ConfirmContent = (props) => {
  return (
    <center>
      <p>{props.question}</p>
      <div>
        <IconButton
          iconClass="fas fa-check-circle"
          className="success"
          onClick={props.yesHandler}
        ></IconButton>
        <IconButton
          iconClass="fas fa-times-circle"
          className="danger"
          onClick={props.noHandler}
        ></IconButton>
      </div>
    </center>
  );
};

export default ConfirmContent;
