import React, { useState, useEffect } from "react";
import ContainerHeader from "../../../components/containerHeader/ContainerHeader";
import Paper from "../../../components/whiteCard/whiteCard";
import Axios from "axios";
import MaterialTables from "../../../components/materialTable/MaterialTables";
import Modal from "../../../components/ui/modal/Modal";
import Loader from "../../../components/spinner/Spinner";
import url from "../../../constants/url";

const JobAgainstNotification = () => {
  const [tableContents, setTableContents] = useState([]);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const connLinks = [
    {
      name: "All Reports",
      path: '/reports'
    }
  ]
  const headings = [
    "SL No",
    "Project Type",
    "Project ID",
    "Notification/Contract No",
    "Area",
    "Installation",
    "Action",
  ];

  const modalClose = () => {
    setShow(false);
  };

  useEffect(() => {
    setLoading(true);
    setShow(true);
    Axios.get(url + "/notifications/getAllNotifications")
      .then((response) => {
        let apiData = [];
        let contents = [];
        apiData = response.data;

        let i = 1;
        apiData.notif.map((data) => {

          let tempObj = {};
          tempObj["SL No"] = i;
          i = i + 1;
          let actions = data["isJob"] ? "JOB ASSIGNED" : "JOB NOT ASSIGNED" 
          tempObj["Project Type"] = data["projectType"];
          tempObj["Project ID"] = data["projectId"];
          tempObj["Notification/Contract No"] = data["notificationNumber"];
          tempObj["Area"] = data["area"]["areaName"];
          tempObj["Installation"] = data["installation"]["name"];
          tempObj["Action"] = actions;

          contents = [...contents, tempObj];
        });
        setTableContents(contents);

        modalClose();
        setLoading(false);
      })
      .catch((err) => {
        setError(true);
        setLoading(false);
        console.log(err);
      });
  }, []);
  
  return (
    <div className="notificationList">
      {loading || error ? (
        <Modal show={show} noHandler={modalClose}>
          {error ? <p>Something went wrong!</p> : <Loader></Loader>}
        </Modal>
      ) : null}
      <ContainerHeader formName="Notifications List" connLinks={connLinks}></ContainerHeader>
      <Paper>
        <div class="notificationListTable">
          <MaterialTables 
          headings={headings}
          data={tableContents}
           />
        </div>
      </Paper>
    </div>
  );
};

export default JobAgainstNotification;