import React, { useState } from 'react';
import { makeStyles} from '@material-ui/core/styles';
import ContainerHeader from "../../components/containerHeader/ContainerHeader";
import Paper from "../../components/whiteCard/whiteCard";
import MaterialTables from "../../components/materialTable/MaterialTables";
import Modal from "../../components/ui/modal/Modal";
import Loader from "../../components/spinner/Spinner";
import Pagination from "@material-ui/lab/Pagination";
import axios from "axios";
import Button from '@material-ui/core/Button';
import url from "../../constants/url";
import Axios from "axios";
import ConfirmContent from '../../components/ui/confirmContent/ConfirmContent';
import { useHistory } from 'react-router-dom';
import authCheck from '../../helpers/auth.helpers';

const useStyles = makeStyles((theme) => ({
    completedProjectDeleteButton: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        marginTop: "25px"
    }
}))

function Cprojects (){

    const { _id } = authCheck(localStorage.getItem('authCode'));

    const classes = useStyles();
    const history = useHistory();

    const [page, setPage] = React.useState(1);
    const [paginationCount, setPaginationCount] = useState(1);

    const [tableContents, setTableContents] = React.useState(null);
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [update, setUpdate] = useState(false);

    const [functionToPass, setFunctionToPass] = useState(false);
    const [ques, setQues] = useState("");

    const headings = [
        "Project Type",
        "Project Id",
        "Project Description",
        "Zone",
        "Area",
        "PO Number",
        "PR Number",
        "Notification Number",
        "Subcontractor",
        "Engineer",
        "JrEngineer",
        "Technician",
        "Vendor",
        "Action"
    ];

    const modalClose = () => {
        setShow(false);
    };

    const modalVisibleForDelete = () => {
        setError(false);
        setFunctionToPass(() => () => deleteHandler());
        setQues("Do you want to delete all the completed projects?");
        setShow(true);
    };

    const modalVisibleForDeleteAll = () => {
        setError(false);
        setFunctionToPass(() => () => deleteHandlerAll());
        setQues("Do you want to delete all the projects?");
        setShow(true);
    }

    const handleEditProject = (projectId) => {
        history.push(`editProject/${projectId}`);
    }
    const handleRevertProject = (projectId) => {
        setLoading(true);
        Axios.post(url + "/projects/revertProject", {
            projectId: projectId,
            userId: _id
        })
        .then((res) => {
            setLoading(false);
            modalClose();
            setUpdate(!update);
        })
        .catch((err) => {
            setError(true);
            setLoading(false);
        })
    }

    const deleteHandlerAll = () => {
        setLoading(true);
        Axios.post(url + "/projects/deleteAllProjects", {userId: _id})
        .then((res) => {
            setLoading(false);
            modalClose();
            setUpdate(!update);
        })
        .catch((err) => {
            setError(true);
            setLoading(false);
        })
    }

    const deleteHandler = () => {
        setLoading(true);
        Axios.post(url + "/projects/deleteCompletedProjects", {userId: _id})
          .then((resp) => {
            setLoading(false);
            modalClose();
            setUpdate(!update);
          })
          .catch((err) => {
              setError(true);
              setLoading(false);
          });
      };

    React.useEffect(() => {
        setShow(true);
        setLoading(true);

        axios
            .post( url + "/projects/getCompletedProjects", { userId: _id }, {params: {page: page}})
            .then((data) => {
                let tempProjectList = [];
                setPaginationCount(data.data.count);

                data.data.project.map((project) => {
                    tempProjectList.push({
                        "Project Type": project.projectType,
                        "Project Id": project.projectId,
                        "Project Description":
                        project.description.toString().length > 100
                            ? project.description.slice(0, 100)
                            : project.description,
                        Zone: project.zone ? project.zone.zoneName : "",
                        Area: project.area ? project.area.areaName : "",
                        "PO Number": project.poNumber,
                        "PR Number": project.prNumber,
                        "Notification Number": project.notificationNo,
                        Subcontractor: project.subcontractorName ? "Yes" : "No",
                        Technician: project.technician ? project.technician.name : null,
                        Vendor: project.vendor ? project.vendor.name : null,
                        Engineer: project.engineer ? project.engineer.name : null,
                        JrEngineer: project.jrEngineer ? project.jrEngineer.name: null,
                        Action: <div style={{display: 'flex', flexDirection: 'row'}}>
                            <Button variant="contained" onClick={() => handleEditProject(project._id)} style={{color: 'white', backgroundColor: '#2C3E50'}}>
                                Edit
                            </Button>
                            <Button variant="contained" onClick={() => handleRevertProject(project._id)} style={{color: 'white', backgroundColor: '#2C3E50'}}>
                                Revert
                            </Button>
                        </div>
                    })
                })
                setTableContents(tempProjectList);
                modalClose();
                setLoading(false);
            })
            .catch((e) => {
                setLoading(false);
                setError(true);
            })
    }, [update, page])

    return(
        <div className="completedProjects">
            <div id="project-list">
                <Modal show={show} noHandler={modalClose}>
                    {loading ? (
                    <Loader></Loader>
                    ) : !error ? (
                    <ConfirmContent
                        yesHandler={functionToPass}
                        noHandler={modalClose}
                        question={ques}
                    >
                    </ConfirmContent>
                    ) : (
                    <p>Something went wrong!</p>
                    )}
                </Modal>
            </div>
            <ContainerHeader formName = "Completed Projects"></ContainerHeader>
            <Paper>
                <div className="project-list-table">
                    {tableContents && tableContents.length > 0 ? (
                        <MaterialTables
                        headings={headings}
                        data={tableContents}
                        title="Completed Projects Database"
                        />
                    ) : (
                        "No projects"
                    )}
                </div>
                <div style={{alignItems:'center', display:'flex', justifyContent:'center', marginTop:'10px'}}>
                    <Pagination count={paginationCount} page={page} onChange={(e, v) => setPage(v)} sx={{width:'100%'}} />
                </div>
                <div className={classes.completedProjectDeleteButton}>
                    <Button variant="contained" onClick={modalVisibleForDelete} style={{color: 'white', backgroundColor: '#2C3E50'}}>
                        Delete Completed Projects
                    </Button>
                    <Button variant="contained" onClick={modalVisibleForDeleteAll} style={{color: 'white', backgroundColor: '#2C3E50'}}>
                        Delete All Projects
                    </Button>
                </div>
            </Paper>
        </div>
    )
}

export default Cprojects;