import React, { useEffect, useState, useCallback } from "react";
import {
  BarChart,
  Bar,
  PieChart,
  Pie,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import DonutChart from "react-donut-chart";
import url, {url_upload} from "../../constants/url";
import * as requests from "../../api/requests";
import Axios from "axios";
import notificationsIcon from "../../assets/icons/notifications.svg";
import projectsIcon from "../../assets/icons/machinery.svg";
import installationIcon from "../../assets/icons/installation.svg";
import Grid from "@material-ui/core/Grid";
import Typography from '@material-ui/core/Typography';
import DropDown from "../../components/inputComponents/DropDown";
import Paper from "@material-ui/core/Paper";

const authCheck = require('../../helpers/auth.helpers');
const sort = require('../../helpers/item.sort');

const Home = () => {
  const { _id, name, email, mobileNo, status, designation, userCode, areaAndZone, photo } 
    = authCheck(localStorage.getItem('authCode'));
  const vendorPie = ["SAP", "GM/DGM"];

  const [projects, setProjects] = useState(null);
  const [notifications, setNotifications] = useState(null);
  const [installationsData, setInstallations] = useState([]);
  const [vendors, setVendors] = useState([]);
  
  const [selVendor, setSelVendor] = useState(0);
  const [vendorData, setVendorData] = useState([{
    name: 'Ongoing', value: 0
  },{
    name: 'Completed', value: 0
  }]);

  let userAreaList = [];
  areaAndZone.map((area) => {
    userAreaList.push(area.area.areaName);
  });
  let zonesArr = [];
  areaAndZone.map((area) => {
    area.zones.map((zone) => {
      zonesArr.push(zone.zoneName);
    });
  });

  useEffect(() => {
    let areaList = [];
    areaAndZone.map((area) => {
      areaList.push(area.area._id);
    });
    Axios.post(url + "/notifications/getNotificationByArea", {
      area: areaList,
      graphData: true
    })
      .then((data) => {
        let jobs = 0;
        data.data.notif.map((notif) => {
          if (notif.isJob) jobs++;
        });
        let total = data.data.notif.length;
        setNotifications({ jobs, total });
      })
      .catch((e) => {});
    
    Axios.post(url + '/vendors/getVendors', {useGraph: true})
    .then((data) => {
      sort(data.data.vendor, "name");
      data.data.vendor.unshift({name: "Select", _id: "null"});
      setVendors(data.data.vendor);
    })
    .catch((err) => {})

    requests
      .post({
        url: "/projects/getByAreas",
        body: { areaIds: areaList, state: "All"},
      })
      .then((data) => {

        let installations = [];
        data.map((project) => {
          let flag = false;
          installations.map((installation) => {
            if (installation.name == project.installation.name) {
              flag = true;
              if (project.state === "Ongoing") {
                installation.Ongoing = installation.Ongoing + 1;
              } else if (project.state === "Halt") {
                installation.Halt = installation.Halt + 1;
              } else if (project.state === "Removed") {
                installation.Removed = installation.Removed + 1;
              } else if (project.state === "Completed") {
                installation.Completed = installation.Completed + 1;
              }
            }
          });
          if (!flag) {
            installations.push({
              name: project.installation.name,
              Ongoing: project.state === "Ongoing" ? 1 : 0,
              Halt: project.state === "Halt" ? 1 : 0,
              Removed: project.state === "Removed" ? 1 : 0,
              Completed: project.state === "Completed" ? 1 : 0,
            });
          }
        });
        setInstallations(installations);
        let halt = 0;
        let ongoing = 0;
        let completed = 0;
        let removed = 0;
        data.map((project) => {
          if (project.state == "Completed") completed++;
          if (project.state == "Halt") halt++;
          if (project.state == "Ongoing") ongoing++;
          if (project.state == "Removed") removed++;
        });
        setProjects({
          completed,
          halt,
          removed,
          ongoing,
        });
      })
      .catch((e) => {});
  }, []);

  useEffect(() => {
    if(selVendor !== 0){
      Axios.post(url + "/vendors/getVendorWiseCount", {
        vendorCode: selVendor
      })
      .then((res) => {
        setVendorData(res.data.graphData);
      })
      .catch((e) => {}) 
    }
  }, [selVendor])

  return (
      <Paper elevation={12} style={{}}>
        <Grid container direction="row" justifyContent="space-around" alignItems="center" spacing={1} style={{padding: "15px"}}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
          {projects && window.innerWidth > 800 ? (
            <div>
              <DonutChart
                height={300}
                width={400}
                legend={true}
                colors={["#f1c40f", "#2ecc71", "#1abc9c", "#e74c3c"]}
                data={[
                  {
                    label: "Ongoing",
                    value: projects.ongoing,
                  },
                  {
                    label: "Halt",
                    value: projects.halt,
                  },
                  {
                    label: "Removed",
                    value: projects.removed,
                  },
                  {
                    label: "Completed",
                    value: projects.completed,
                  },
                ]}
              />
            </div>
          ) : null}
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
          {notifications && window.innerWidth > 800 ? (
            <div>
              <DonutChart
                height={300}
                width={400}
                legend={true}
                colors={["#e74c3c", "#2ecc71"]}
                data={[
                  {
                    label: "Jobs",
                    value: notifications.jobs,
                  },
                  {
                    label: "Unassigned",
                    value: notifications.total - notifications.jobs,
                    isEmpty: true,
                  },
                ]}
              />
            </div>
          ) : null}
          </Grid>
        </Grid>
        <Grid container direction="row" justifyContent="space-around" alignItems="center" spacing={1} style={{padding: "15px"}}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
        {vendorPie.includes(designation) && 
          <div style={styles.vendorData}>
            {/* <div>
              <Typography variant="h5" style={{marginBottom: "15px"}}>Vendor-wise Ongoing And Completed Projects</Typography>
            </div> */}
            <div style={styles.vendorDetails}>
              <div style={styles.vendorSelect}>
                <DropDown
                  name="selVendor"
                  label="Select a vendor"
                  options={vendors}
                  onChange={e=>setSelVendor(e.target.value)}
                  size="wide"
                >
                </DropDown>
              </div>
            </div>  
            <div style={styles.vendorDataHolder}>
              <div style={styles.vendorGraph}>
              {vendorData[0].value === 0 && vendorData[1].value === 0 ? 
                <Typography variant="p" style={{marginBottom: "15px"}}>No data available to show in the graph</Typography>
                :
                <PieChart width={250} height={250}>
                <Pie
                  dataKey="value"
                  isAnimationActive={false}
                  data={vendorData}
                  cx="50%"
                  cy="50%"
                  outerRadius={120}
                  fill="#2C3E50"
                  label
                />
                <Tooltip />
              </PieChart>
              } 
              </div>
            </div>
          </div>
        }
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
          <div
            style={{
              padding: window.innerWidth < 800 ? "0px" : "30px",
              display: "grid",
              gridTemplateRows: "1fr 1fr 1fr",
            }}
          >
            <div style={styles.card}>
              <div style={{ backgroundColor: "#148f77", position: "relative" }}>
                <img
                  style={styles.centerIcon}
                  src={notificationsIcon}
                  alt="Notifications"
                  width="50px"
                />
              </div>
              <div style={{ backgroundColor: "#1abc9c" }}>
                <h1 style={styles.cardNumber}>
                  {notifications ? notifications.total : 0}
                </h1>
                <p style={styles.cardName}>Notifications</p>
              </div>
            </div>
            <div style={styles.card}>
              <div style={{ backgroundColor: "#25a25a", position: "relative" }}>
                <img
                  style={styles.centerIcon}
                  src={projectsIcon}
                  alt="Projects"
                  width="50px"
                />
              </div>
              <div style={{ backgroundColor: "#2ecc71" }}>
                <h1 style={styles.cardNumber}>
                  {projects
                    ? projects.completed +
                      projects.halt +
                      projects.ongoing +
                      projects.removed
                    : 0}
                </h1>
                <p style={styles.cardName}>Projects</p>
              </div>
            </div>
            <div style={styles.card}>
              <div
                style={{
                  backgroundColor: "rgb(10 98 64)",
                  position: "relative",
                }}
              >
                <img
                  style={styles.centerIcon}
                  src={installationIcon}
                  alt="Installations"
                  width="50px"
                />
              </div>
              <div style={{ backgroundColor: "rgb(43 121 62)" }}>
                <h1 style={styles.cardNumber}>
                  {installationsData ? installationsData.length : 0}
                </h1>
                <p style={styles.cardName}>installations</p>
              </div>
            </div>
          </div>
          </Grid>
        </Grid>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: window.innerWidth > 800 ? "100%" : "100%",
          }}
        >
          {window.innerWidth > 800 ? (
            <div
              style={{
                display: "grid",
                gridAutoFlow: "row",
                backgroundColor: "white",
                borderLeft: "3px solid #1abc9c",
                borderTopLeftRadius: "3px",
                borderBottomLeftRadius: "3px",
                padding: "20px",
                boxShadow: "0px 0px 2px rgba(30,30,30,0.3)",
                width: "75vw",
                height: "350px",
              }}
            >
              <BarChart
                width={Number((window.innerWidth * 0.75))}
                height={300}
                data={installationsData}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="Ongoing" fill="#8884d8" />
                <Bar dataKey="Halt" fill="#82ca9d" />
                <Bar dataKey="Completed" fill="rgb(10 98 64)" />
                <Bar dataKey="Removed" fill="Red" />
              </BarChart>
            </div>
          ) : null}
        </div>
      </Paper>
  );
};

const styles = {
  card: {
    backgroundColor: "white",
    boxShadow: "0px 0px 2px rgba(30,30,30,0.3)",
    height: "90px",
    width: "350px",
    display: "grid",
    gridTemplateColumns: "2fr 5fr",
    borderRadius: "3px",
    overflow: "hidden",
  },
  centerIcon: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
  },
  structure: {
    display: "grid",
    gridTemplateColumns: window.innerWidth > 800 ? "2fr 1fr" : "1fr",
  },
  structureIn: {
    display: "grid",
    gridTemplateColumns: window.innerWidth > 800 ? "2fr 1fr" : "1fr 1fr",
  },
  cardNumber: {
    color: "white",
    marginBottom: 0,
    marginTop: "10px",
    marginLeft: "20px",
  },
  cardName: {
    marginTop: 0,
    color: "white",
    marginLeft: "20px",
  },
  vendorData: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    width: '100%',
    flexDirection: 'column'
  },
  vendorDetails: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  vendorSelect: {
    display: "flex",
    alignItems: 'center',
    justifyContent: 'start',
    width: '100%'
  },
  vendorDataHolder: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '35px'
  },
  vendorGraph: {
    display: 'flex',
    width: '55%',
    placeContent: 'center'
  },
  vendorGraphData: {
    display: 'flex',
    flexDirection: 'column',
    width: '40%',
  }
};
export default Home;