export const Data = [
  {
    type: "Feature",
    properties: { Name: "MRN-7", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.978906127637501, 27.166523891062599],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MRN-90", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.906718031493682, 27.164318635273801],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MRN-92", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.961492225545996, 27.157424028790899],
    },
  },
  {
    type: "Feature",
    properties: { Name: "SAROJANI-3", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.106778745149498, 27.206645089201601],
    },
  },
  {
    type: "Feature",
    properties: { Name: "DIKHARIPATHAR-3", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.881204259858094, 27.207315771430402],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MRN-59", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.941058730355493, 27.146144259339898],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MRN-24", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.936926027308104, 27.162680227387899],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MRN-44", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.906776149889112, 27.144522903238901],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MRN-71", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.928770904456002, 27.160725584476999],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MRN-65", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.952593093789105, 27.130506219722999],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MRN-15", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.745441838582906, 27.083720224213799],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MRN-9", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.868914325256199, 27.171719150755798],
    },
  },
  {
    type: "Feature",
    properties: { Name: "BORBHUILBIL-1", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.811111363549202, 27.1873078901734],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MRN-67", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.927904145432805, 27.178197149190709],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MRN-10", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.953146562264095, 27.178083835898001],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MRN-101", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.868761580066007, 27.171760980946701],
    },
  },
  {
    type: "Feature",
    properties: { Name: "WATER WELL-8", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.928901023217705, 27.1557918835008],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MRN-82", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.954818601525801, 27.143206852955501],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MRN-13", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.810934892927307, 27.187344012360501],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MRN-106", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.958412320383104, 27.1462171462532],
    },
  },
  {
    type: "Feature",
    properties: { Name: "WATER WELL-115", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.936917080811298, 27.162981375566801],
    },
  },
  {
    type: "Feature",
    properties: { Name: "RJH-3", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.108759223002721, 27.1635107502746],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MRN-80", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.945358296258107, 27.145766908326699],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NOAHOLA-1", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.035338149516093, 27.245964872142299],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MRN-40", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.950804056560514, 27.141910277518299],
    },
  },
  {
    type: "Feature",
    properties: { Name: "BATUYA-1", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.020103669579498, 27.1227053994972],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MRN-35", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.921172955755509, 27.164737241196601],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MRN-1", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.956924833236613, 27.1396640235163],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MRN-93", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.950387752265385, 27.144351236094401],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MRN-62", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.934210087565589, 27.160520657738601],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MRN-113", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.892819614618304, 27.1360365755387],
    },
  },
  {
    type: "Feature",
    properties: { Name: "THOWRA-1", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.82153726981781, 27.106870028598099],
    },
  },
  {
    type: "Feature",
    properties: { Name: "DIKHARIPATHAR-1", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.880951386839598, 27.2075439453969],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MRN-60", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.855972400440706, 27.130664316111499],
    },
  },
  {
    type: "Feature",
    properties: { Name: "WATER WELL-161", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.921450192288717, 27.159338341584],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MRN-27", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.919696873888611, 27.1497525922696],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MRN-50", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.942510807767803, 27.135484342856699],
    },
  },
  {
    type: "Feature",
    properties: { Name: "WATER WELL-164", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.938824673571602, 27.1656536815292],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MRN-52", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.959509146742505, 27.1663411959805],
    },
  },
  {
    type: "Feature",
    properties: { Name: "WATER WELL-5", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.930913386300091, 27.1633487576444],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MRN-79", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.884205915676816, 27.169228103676001],
    },
  },
  {
    type: "Feature",
    properties: { Name: "BARBIL-1", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.05600235850909, 27.230153668332601],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MRN-33", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.900342987630097, 27.165755813039599],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MRN-102", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.945036781480198, 27.128812915717699],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MRN-116", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.856313439973988, 27.2226151170318],
    },
  },
  {
    type: "Feature",
    properties: { Name: "SLM-6", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.122332896719598, 27.256926566100599],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MRN-87", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.946111667408104, 27.151958859663701],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MRN-83", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.961443920729707, 27.141505880258201],
    },
  },
  {
    type: "Feature",
    properties: { Name: "KHOWANG-1", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.892072959012211, 27.252177149858699],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MRN-108", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.936678493131197, 27.171457661348001],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MRN-115", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.908219714793404, 27.162420219160399],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MRN-72", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.946104638775495, 27.133012013021901],
    },
  },
  {
    type: "Feature",
    properties: { Name: "SLM-17", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.120902058233597, 27.263728000713101],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MRN-41", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.941617490450781, 27.142389328867999],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MRN-25", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.932446083616895, 27.167701895827101],
    },
  },
  {
    type: "Feature",
    properties: { Name: "SAROJANI-1", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.103754080521497, 27.220143805064801],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MRN-74", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.956902919142294, 27.154589157065001],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MRN-114", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.900038117404605, 27.164382779091898],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MRN-21", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.9259186902013, 27.153652404537599],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MRN-8", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.915859898849504, 27.155413113318708],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MRN-75", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.955975569957502, 27.1492289727382],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MRN-47", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.944143044553499, 27.148620473572301],
    },
  },
  {
    type: "Feature",
    properties: { Name: "BARBIL-2", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.063525715973014, 27.228209423185199],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MRN-97", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.953096537077911, 27.1389209399062],
    },
  },
  {
    type: "Feature",
    properties: { Name: "SLM-9", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.1188396551035, 27.256878091670199],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MRN-34", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.938196606747297, 27.166716128326598],
    },
  },
  {
    type: "Feature",
    properties: { Name: "RJH-10", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.10679181698589, 27.160718295314101],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MRN-4", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.818208082165313, 27.111602273350901],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MRN-81", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.963753089548604, 27.152965224543301],
    },
  },
  {
    type: "Feature",
    properties: { Name: "SLM-32", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.118954714152594, 27.2569650435961],
    },
  },
  {
    type: "Feature",
    properties: { Name: "SLM-42", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.111484521211196, 27.247885131495401],
    },
  },
  {
    type: "Feature",
    properties: { Name: "DHOLIA-1", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.021761219351106, 27.228376717544599],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MRN-42", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.951194472977406, 27.147427229242599],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MRN-85", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.938492678340594, 27.139919653412001],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MRN-95", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.938353375698696, 27.1364168331082],
    },
  },
  {
    type: "Feature",
    properties: { Name: "DIROI-2", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.055831775787496, 27.2051647803994],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MRN-53", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.90033180753602, 27.1416761000607],
    },
  },
  {
    type: "Feature",
    properties: { Name: "LAKWAGAON-2", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.774197247125798, 27.140002821529698],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MRN-17", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.02353787256979, 27.123319319358199],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MRN-68", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.94524043378361, 27.169310132195498],
    },
  },
  {
    type: "Feature",
    properties: { Name: "RJH-11", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.117723074397702, 27.170240955809],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MRN-56", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.939502135005398, 27.170404575065302],
    },
  },
  {
    type: "Feature",
    properties: { Name: "DIROI-3", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.060122768704801, 27.212134062042399],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MRN-100", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.935441381045806, 27.1555991141974],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MRN-18", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.934524544882493, 27.174584714303698],
    },
  },
  {
    type: "Feature",
    properties: { Name: "SLM-24", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.123387866254404, 27.264106875375798],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MRN-118", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.856038028207095, 27.130573838690101],
    },
  },
  {
    type: "Feature",
    properties: { Name: "WATER WELL-171", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.122554773347503, 27.2568197088858],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MRN-70", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.952942659599813, 27.1521484489116],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MRN-5", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.907973419155795, 27.1675133355232],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MRN-22", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.925566239414707, 27.163306135741799],
    },
  },
  {
    type: "Feature",
    properties: { Name: "SLM-43", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.122796337474711, 27.256881369366301],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MRN-37", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.927987489148791, 27.175072868400001],
    },
  },
  {
    type: "Feature",
    properties: { Name: "BAMUNPUKHURI-1", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.052259068944593, 27.2646106011561],
    },
  },
  {
    type: "Feature",
    properties: { Name: "SLM-35", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.111700192979981, 27.247860042166799],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MRN-61", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.928780905291902, 27.155744627393801],
    },
  },
  {
    type: "Feature",
    properties: { Name: "TILOINAGAR-1", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.8929153144758, 27.219712120304798],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MRN-45", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.93123704732939, 27.176785570780002],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MRN-94", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.957370191039999, 27.157936027897801],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MRN-104", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.946227952413096, 27.132980706100199],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MRN-11", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.913820535771094, 27.145343534174501],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MRN-66", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.916501297906692, 27.159658704298401],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MRN-98", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.991713728231801, 27.119050747247201],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MRN-31", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.926294228225501, 27.158475665964001],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MRN-57", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.883585898029494, 27.2033089519323],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MRN-69", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.948548483218602, 27.1498235543812],
    },
  },
  {
    type: "Feature",
    properties: { Name: "TNL-3", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.115142626040196, 27.284650497530802],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MRN-107", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.905775271459007, 27.165770805167501],
    },
  },
  {
    type: "Feature",
    properties: { Name: "RJH-12", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.121685967768201, 27.1888476184374],
    },
  },
  {
    type: "Feature",
    properties: { Name: "DIPLING-1", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.028158643591283, 27.118475194567701],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MRN-43", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.951358382493098, 27.1360711324396],
    },
  },
  {
    type: "Feature",
    properties: { Name: "SLM-30", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.120679831839198, 27.263545229076499],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MRN-84", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.960283673810508, 27.151067745659301],
    },
  },
  {
    type: "Feature",
    properties: { Name: "TILAIJAN-1", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.963987330469806, 27.218159324886901],
    },
  },
  {
    type: "Feature",
    properties: { Name: "RJH-2", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.0880568323511, 27.182278529337101],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MRN-89", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.913111055490589, 27.168889737097],
    },
  },
  {
    type: "Feature",
    properties: { Name: "DIROI-4", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.0611043936659, 27.222570038126399],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MRN-39", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.927713128747101, 27.167668856366401],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MRN-99", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.972603889324901, 27.156414239037009],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MRN-19", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.916398692173402, 27.1642911001681],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MRN-28", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.921526576728979, 27.159222064238801],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MRN-86", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.924406851173501, 27.173622252732599],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MRN-117", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.856286743850404, 27.222456112352099],
    },
  },
  {
    type: "Feature",
    properties: { Name: "BORBHUIBIL-2", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.810863834761506, 27.187360453783398],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MRN-20", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.907920389057296, 27.154929186273598],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MRN-46", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.941071249202395, 27.1629384747893],
    },
  },
  {
    type: "Feature",
    properties: { Name: "DIROI-1", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.056564432705002, 27.210072240434201],
    },
  },
  {
    type: "Feature",
    properties: { Name: "DIROI-5", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.061264666548297, 27.222648435530701],
    },
  },
  {
    type: "Feature",
    properties: { Name: "SLM-41", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.111520836295597, 27.2478814256033],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MRN-111", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.933896544483702, 27.1363425995326],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MRN-103", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.921251321245805, 27.154174978137299],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MRN-32", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.929169002305301, 27.1722173633141],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MRN-16", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.905508039434494, 27.149388799032],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MRN-109", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.81853888282059, 27.111887658849501],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MRN-112", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.876950434483604, 27.140464401983198],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MRN-29", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.911947206677993, 27.150202661052699],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MRN-105", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.945179903016779, 27.128854606506099],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MADHAKALI-1", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.86176360902391, 27.234933813206808],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MRN-63", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.946646495372903, 27.142517814054301],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MRN-88", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.953641875903699, 27.156879107467699],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MRN-58", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.935489873689093, 27.155680469075801],
    },
  },
  {
    type: "Feature",
    properties: { Name: "SLM-25", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.1207919014482, 27.2636365553305],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MRN-36", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.930993284905583, 27.153552510803401],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MRN-30", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.931100908515219, 27.1631591179958],
    },
  },
  {
    type: "Feature",
    properties: { Name: "WATER WELL-163", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.9355594101728, 27.155650544136101],
    },
  },
  {
    type: "Feature",
    properties: { Name: "SAROJANI-2", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.121694408606388, 27.226754170621309],
    },
  },
  {
    type: "Feature",
    properties: { Name: "HALDHIBARI-1", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.827698905871699, 27.2489335218234],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MRN-73", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.945512382639492, 27.138122394535401],
    },
  },
  {
    type: "Feature",
    properties: { Name: "SLM-22", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.123309082824207, 27.264220404972701],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MRN-6", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.94576635923282, 27.173126897855798],
    },
  },
  {
    type: "Feature",
    properties: { Name: "SLM-12", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.122800704549505, 27.2567825033783],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MRN-78", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.933123698165119, 27.171182224792599],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MRN-26", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.941801476285789, 27.175718182257299],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MRN-64", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.956560844631696, 27.135118914836902],
    },
  },
  {
    type: "Feature",
    properties: { Name: "DIKHARIPATHAR-2", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.881076999621399, 27.207430365826401],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MRN-14", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.92378705424612, 27.169942290752601],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MRN-55", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.925503581276203, 27.1474309369857],
    },
  },
  {
    type: "Feature",
    properties: { Name: "DIPLING-2", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.0282143772193, 27.118541597673001],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MRN-49", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.958525072642317, 27.146128225317799],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MRN-38", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.951165363494496, 27.1745754880696],
    },
  },
  {
    type: "Feature",
    properties: { Name: "SLM-20", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.123258864953002, 27.264290281783801],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MRN-23", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.94280321845828, 27.166927967150201],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MRN-51", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.953308961686304, 27.181139259623809],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MRN-54", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.921101730585505, 27.154242898791001],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MRN-2", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.931463561796903, 27.158434181671701],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MRN-76", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.930682783713095, 27.149879867115001],
    },
  },
  {
    type: "Feature",
    properties: { Name: "LAKWAGAON-1", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.77411505195461, 27.139963929739199],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MRN-77", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.934482345863003, 27.165396736711099],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MRN-48", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.949006551727805, 27.1544101506678],
    },
  },
  {
    type: "Feature",
    properties: { Name: "SLM-37", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.119094178038594, 27.257069942133899],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MRN-96", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.961864537473801, 27.148859716489302],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MRN-91", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.941603837419407, 27.139130759922701],
    },
  },
  {
    type: "Feature",
    properties: { Name: "BARBAM-1", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.947328592793184, 27.272514235673299],
    },
  },
  {
    type: "Feature",
    properties: { Name: "DIMOWKINAR-1", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.881333264398094, 27.207200973031899],
    },
  },
  {
    type: "Feature",
    properties: { Name: "WATER WELL-6", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.926255206522399, 27.158238070102701],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MRN-3", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.955896813158205, 27.127879775886299],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MRN-12", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.845738154265703, 27.202132747260801],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MRN-110", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.938799508161296, 27.1652133211227],
    },
  },
  {
    type: "Feature",
    properties: { Name: "SOUTH TNL-3", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.177654344531902, 27.232725670017899],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-412", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.370382340508996, 27.272411799498901],
    },
  },
  {
    type: "Feature",
    properties: { Name: "TARAJAN-3", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.436176679918503, 27.2869550728009],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NORTH TNL-3", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.17548904687348, 27.251258454466399],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-364", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.38062402542738, 27.269833148973401],
    },
  },
  {
    type: "Feature",
    properties: { Name: "SOUTH RAJGARH-1", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.124270457751507, 27.1330427477873],
    },
  },
  {
    type: "Feature",
    properties: { Name: "TARAJAN-4", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.436262690880184, 27.286843690479699],
    },
  },
  {
    type: "Feature",
    properties: { Name: "WATER WELL-84", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.1255315039691, 27.262379571471499],
    },
  },
  {
    type: "Feature",
    properties: { Name: "RJH-5", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.132108114870405, 27.184097461983299],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-456", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.376650295509407, 27.262324066858799],
    },
  },
  {
    type: "Feature",
    properties: { Name: "GHUGULONI-1", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.181258685591501, 27.2960055696403],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-583", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.367439620375706, 27.274355343724899],
    },
  },
  {
    type: "Feature",
    properties: { Name: "SLM-26", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.14391455199592, 27.263301077910199],
    },
  },
  {
    type: "Feature",
    properties: { Name: "BORUAHNAGAR-3", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.2225874020411, 27.135151048512501],
    },
  },
  {
    type: "Feature",
    properties: { Name: "BALIMARA-3", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.358573256485784, 27.224828052082799],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-381", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.376431313932002, 27.266711519592],
    },
  },
  {
    type: "Feature",
    properties: { Name: "OLD WELL", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.181402329900905, 27.296050425810002],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-294", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.396439046083813, 27.285535779953399],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NORTH TNL-1", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.170253010988802, 27.250732893214099],
    },
  },
  {
    type: "Feature",
    properties: { Name: "TNL-4", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.160404009252602, 27.246243388114799],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-445", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.36676845676331, 27.266470185199498],
    },
  },
  {
    type: "Feature",
    properties: { Name: "SLM-1", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.148985666065698, 27.257761823805001],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-368", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.367234508959996, 27.264007164340001],
    },
  },
  {
    type: "Feature",
    properties: { Name: "SOUTH TNL-2", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.177738622966388, 27.232774344531201],
    },
  },
  {
    type: "Feature",
    properties: { Name: "SLM-33", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.126015216485598, 27.262527774487101],
    },
  },
  {
    type: "Feature",
    properties: { Name: "SLM-15", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.14881733592442, 27.261076196475901],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-451", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.367424016584806, 27.274424535240399],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-454", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.359473830455514, 27.269754715369],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-358", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.37067936502531, 27.272431304387901],
    },
  },
  {
    type: "Feature",
    properties: { Name: "TNL-8", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.1674636974668, 27.203784714561198],
    },
  },
  {
    type: "Feature",
    properties: { Name: "RJH-6", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.1285170145579, 27.184612204373799],
    },
  },
  {
    type: "Feature",
    properties: { Name: "WATER WELL", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.167314882004405, 27.204184376949001],
    },
  },
  {
    type: "Feature",
    properties: { Name: "TNL-7", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.157582870932785, 27.223833981056899],
    },
  },
  {
    type: "Feature",
    properties: { Name: "SLM-2", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.152781432530801, 27.2573701743482],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-397", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.36349715615701, 27.270058306563399],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-299", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.371529771040485, 27.267861280536799],
    },
  },
  {
    type: "Feature",
    properties: { Name: "SOUTH TNL-5", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.190876313194792, 27.2335943371009],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-265", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.376726520831909, 27.262408118791399],
    },
  },
  {
    type: "Feature",
    properties: { Name: "SLM-16", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.128888076909206, 27.262999468703601],
    },
  },
  {
    type: "Feature",
    properties: { Name: "SLM-23", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.152909863161099, 27.257371897558301],
    },
  },
  {
    type: "Feature",
    properties: { Name: "SLM-18", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.128955388488507, 27.262944127262401],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-373", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.375626995273208, 27.2706708120329],
    },
  },
  {
    type: "Feature",
    properties: { Name: "BORUAHNAGAR-1", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.222374163386718, 27.135547178878902],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-617", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.376183271231994, 27.274241422174299],
    },
  },
  {
    type: "Feature",
    properties: { Name: "TARAJAN-5", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.436344914193782, 27.286730479580701],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NORTH TNL-2", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.176905553372706, 27.254194313045801],
    },
  },
  {
    type: "Feature",
    properties: { Name: "UMATARA-2", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.28131296336781, 27.237511006958499],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NEW WELL", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.176952038570988, 27.2439514631787],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-301", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.380175308965903, 27.2698417784894],
    },
  },
  {
    type: "Feature",
    properties: { Name: "SLM-19", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.1614532893703, 27.253695982280298],
    },
  },
  {
    type: "Feature",
    properties: { Name: "SLM-36", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.132036691123673, 27.25430165541658],
    },
  },
  {
    type: "Feature",
    properties: { Name: "TNL-1", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.180556673724595, 27.224929688837499],
    },
  },
  {
    type: "Feature",
    properties: { Name: "SAPKAINT-2", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.16078966689328, 27.210336532246099],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-393", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.366684246340384, 27.266500299787602],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-362", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.381424778729397, 27.275094685130298],
    },
  },
  {
    type: "Feature",
    properties: { Name: "WEST BALIMARA-1", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.307251589992887, 27.208666319395299],
    },
  },
  {
    type: "Feature",
    properties: { Name: "SLM-31", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.146148569570698, 27.258472149497798],
    },
  },
  {
    type: "Feature",
    properties: { Name: "SLM-27", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.161351669436883, 27.253241906706201],
    },
  },
  {
    type: "Feature",
    properties: { Name: "SLM-10", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.146032847703594, 27.258542587447501],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-210", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.376281193053103, 27.2670093562249],
    },
  },
  {
    type: "Feature",
    properties: { Name: "SOUTH TNL-4", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.177789231823198, 27.232671420974199],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-288", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.391080700866098, 27.2634664300985],
    },
  },
  {
    type: "Feature",
    properties: { Name: "SLM-3", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.149272754416018, 27.260886581243],
    },
  },
  {
    type: "Feature",
    properties: { Name: "SLM-39", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.132182842855002, 27.260893815662499],
    },
  },
  {
    type: "Feature",
    properties: { Name: "BALIMARA-6", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.347827863199683, 27.2215686679592],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-330", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.367163659544204, 27.264097950965301],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-224", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.381991079836496, 27.261424415414702],
    },
  },
  {
    type: "Feature",
    properties: { Name: "TARAJAN-1", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.433371081219093, 27.284869687379199],
    },
  },
  {
    type: "Feature",
    properties: { Name: "TNL-9", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.178373018170802, 27.239334037926],
    },
  },
  {
    type: "Feature",
    properties: { Name: "WATER WELL-83", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.156807066844394, 27.2592651086945],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-398", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.370228011191003, 27.264582863528801],
    },
  },
  {
    type: "Feature",
    properties: { Name: "RJH-9", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.136654336170707, 27.189242987048502],
    },
  },
  {
    type: "Feature",
    properties: { Name: "SLM-40", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.154572940812201, 27.252676509578599],
    },
  },
  {
    type: "Feature",
    properties: { Name: "TNL-10", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.182355929569994, 27.2473039310327],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-439", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.370282998732094, 27.2645176957943],
    },
  },
  {
    type: "Feature",
    properties: { Name: "SLM-21", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.145957788910721, 27.258588491412699],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-383", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.366245661845582, 27.271797179081499],
    },
  },
  {
    type: "Feature",
    properties: { Name: "TNL-2", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.183175208259698, 27.2420686851701],
    },
  },
  {
    type: "Feature",
    properties: { Name: "TNL-12", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.165629748622607, 27.2293095208232],
    },
  },
  {
    type: "Feature",
    properties: { Name: "BALIMARA-1", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.346633133383193, 27.220673193535202],
    },
  },
  {
    type: "Feature",
    properties: { Name: "BALIMARA-9", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.376442316741517, 27.238119084868099],
    },
  },
  {
    type: "Feature",
    properties: { Name: "RJH-8", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.136731434426906, 27.189266895155299],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-403", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.378983356668101, 27.272614809864901],
    },
  },
  {
    type: "Feature",
    properties: { Name: "SOUTH TNL-6", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.190917069801046, 27.23371889652941],
    },
  },
  {
    type: "Feature",
    properties: { Name: "WATER WELL-102", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.152636249360199, 27.2572725267372],
    },
  },
  {
    type: "Feature",
    properties: { Name: "TARAJAN-2", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.430358584881105, 27.282334047398901],
    },
  },
  {
    type: "Feature",
    properties: { Name: "BALIMARA-2", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.346723397992804, 27.220595715284301],
    },
  },
  {
    type: "Feature",
    properties: { Name: "SLM-5", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.131595193924113, 27.2611725510891],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-579", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.381648651257606, 27.276712550034699],
    },
  },
  {
    type: "Feature",
    properties: { Name: "RJH-7", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.136481995186699, 27.186667248554802],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-388", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.366369300987898, 27.2610470053075],
    },
  },
  {
    type: "Feature",
    properties: { Name: "SOUTH TNL-1", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.177691064337793, 27.2327944829856],
    },
  },
  {
    type: "Feature",
    properties: { Name: "SLM-7", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.12578804036481, 27.262457149478099],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-260", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.375464674647702, 27.271006115586601],
    },
  },
  {
    type: "Feature",
    properties: { Name: "WATER WELL-103", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.129304587575604, 27.2627758614532],
    },
  },
  {
    type: "Feature",
    properties: { Name: "UMATARA-1", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.273417814362602, 27.224488692698799],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-615", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.376056622252619, 27.2741674294743],
    },
  },
  {
    type: "Feature",
    properties: { Name: "SLM-28", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.154676642086088, 27.2527735309747],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-407", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.375930039406384, 27.274092623558602],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-467", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.368369757677385, 27.2699848112455],
    },
  },
  {
    type: "Feature",
    properties: { Name: "SLM-11", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.156536790288001, 27.259183739816098],
    },
  },
  {
    type: "Feature",
    properties: { Name: "BALIMARA-5", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.358586162529804, 27.224503429044599],
    },
  },
  {
    type: "Feature",
    properties: { Name: "SLM-14", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.1258723836728, 27.2624828460104],
    },
  },
  {
    type: "Feature",
    properties: { Name: "SLM-8", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.154788910628497, 27.2528787212457],
    },
  },
  {
    type: "Feature",
    properties: { Name: "RJH-1", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.132922663538693, 27.186921493509701],
    },
  },
  {
    type: "Feature",
    properties: { Name: "SLM-29", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.133145115891494, 27.267959128903598],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-614", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.368372040164715, 27.270202380983001],
    },
  },
  {
    type: "Feature",
    properties: { Name: "TNL-5", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.190748356468603, 27.233288037340699],
    },
  },
  {
    type: "Feature",
    properties: { Name: "TNL-11", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.186482967627796, 27.246712594605199],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-325", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.381316175803406, 27.265397677215809],
    },
  },
  {
    type: "Feature",
    properties: { Name: "SAPKAINT-3", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.160880699476493, 27.210477687097612],
    },
  },
  {
    type: "Feature",
    properties: { Name: "SLM-38", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.139132906315581, 27.259120049552099],
    },
  },
  {
    type: "Feature",
    properties: { Name: "SLM-4", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.146106708020596, 27.258499318417599],
    },
  },
  {
    type: "Feature",
    properties: { Name: "TNL-13", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.160412287945789, 27.2289665971132],
    },
  },
  {
    type: "Feature",
    properties: { Name: "SAPEKHATI-1", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.174676287093106, 27.1256150603371],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-584", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.366318335866794, 27.261101638081101],
    },
  },
  {
    type: "Feature",
    properties: { Name: "BALIMARA-4", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.358579709613096, 27.224665740429501],
    },
  },
  {
    type: "Feature",
    properties: { Name: "DII", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.376292926769906, 27.238031335219201],
    },
  },
  {
    type: "Feature",
    properties: { Name: "SLM-13", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.144049167026196, 27.263364339429799],
    },
  },
  {
    type: "Feature",
    properties: { Name: "SAPKAINT-1", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.167338062805982, 27.204109220072802],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-573", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.323141387827704, 27.281017270663199],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-414", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.384890871334605, 27.276254827635999],
    },
  },
  {
    type: "Feature",
    properties: { Name: "SLM-34", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.125834358388801, 27.250919446909499],
    },
  },
  {
    type: "Feature",
    properties: { Name: "TITLAGARH-1", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.316868996644899, 27.163604027132909],
    },
  },
  {
    type: "Feature",
    properties: { Name: "BORUAHNAGAR-2", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.222672136682704, 27.135007149587899],
    },
  },
  {
    type: "Feature",
    properties: { Name: "RJH-4", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.129990560351018, 27.186946592178199],
    },
  },
  {
    type: "Feature",
    properties: { Name: "TNL-6", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.176990423517296, 27.254336632809501],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-463", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.368369901290606, 27.2700661864124],
    },
  },
  {
    type: "Feature",
    properties: { Name: "WATER WELL-54", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.077097129359203, 27.435360432355299],
    },
  },
  {
    type: "Feature",
    properties: { Name: "DKM-16", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.102812609590202, 27.441371719127002],
    },
  },
  {
    type: "Feature",
    properties: { Name: "KLN-23", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.07709931433341, 27.371638705207801],
    },
  },
  {
    type: "Feature",
    properties: { Name: "KLN-24", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.086017898855104, 27.371837421361398],
    },
  },
  {
    type: "Feature",
    properties: { Name: "WATER WELL-63", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.086708763730485, 27.4475697110719],
    },
  },
  {
    type: "Feature",
    properties: { Name: "BPR-4", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.983638611772619, 27.439453540753199],
    },
  },
  {
    type: "Feature",
    properties: { Name: "DKM-23", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.102413199652204, 27.441175008987699],
    },
  },
  {
    type: "Feature",
    properties: { Name: "BPR-13", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.980194643794107, 27.4329541350467],
    },
  },
  {
    type: "Feature",
    properties: { Name: "DKM-34", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.122121560069886, 27.443826259144899],
    },
  },
  {
    type: "Feature",
    properties: { Name: "KLN-29", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.096349752787603, 27.372959833985199],
    },
  },
  {
    type: "Feature",
    properties: { Name: "WATER WELL-80", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.093971768196198, 27.377809462705201],
    },
  },
  {
    type: "Feature",
    properties: { Name: "WATER WELL-46", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.09793086694421, 27.379959292573702],
    },
  },
  {
    type: "Feature",
    properties: { Name: "KLN-25", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.076957687022798, 27.371589202879498],
    },
  },
  {
    type: "Feature",
    properties: { Name: "WATER WELL-47", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.109016535249012, 27.401781094942699],
    },
  },
  {
    type: "Feature",
    properties: { Name: "DKM-33", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.122793789034404, 27.437315862032399],
    },
  },
  {
    type: "Feature",
    properties: { Name: "KUHIARBARI-5", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.102679020819508, 27.441307238760508],
    },
  },
  {
    type: "Feature",
    properties: { Name: "WATER WELL-155", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.088924817620679, 27.388252119300599],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MADARKHAT-4", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.070402445985593, 27.4266413058964],
    },
  },
  {
    type: "Feature",
    properties: { Name: "SOUTH KATHALONI-2", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.080083536448299, 27.347102752556701],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-523", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.118173589858998, 27.405352936616701],
    },
  },
  {
    type: "Feature",
    properties: { Name: "DKM-26", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.10862876806118, 27.4434146708623],
    },
  },
  {
    type: "Feature",
    properties: { Name: "BPR-15", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.965769179732604, 27.417301797140802],
    },
  },
  {
    type: "Feature",
    properties: { Name: "KUHIARBARI-4", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.097421044834391, 27.434527824983402],
    },
  },
  {
    type: "Feature",
    properties: { Name: "BPR-14", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.980897866744201, 27.433274292748301],
    },
  },
  {
    type: "Feature",
    properties: { Name: "DKM-17", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.102546332299596, 27.441241842402601],
    },
  },
  {
    type: "Feature",
    properties: { Name: "KLN-8", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.08049125013892, 27.3778749079036],
    },
  },
  {
    type: "Feature",
    properties: { Name: "BPR-20", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.977159669675586, 27.419897059563208],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NORTH DIKOM-1", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.09641023324771, 27.492624909613401],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-536", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.102265000224321, 27.401501489495612],
    },
  },
  {
    type: "Feature",
    properties: { Name: "BPR-7", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.981038420283397, 27.433013783231509],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MADARKHAT-2", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.076823113390404, 27.4350867067919],
    },
  },
  {
    type: "Feature",
    properties: { Name: "BPR-12", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.974039052259101, 27.4406357722991],
    },
  },
  {
    type: "Feature",
    properties: { Name: "KLN-21", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.085826366331801, 27.371629421855399],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-534", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.102149620488404, 27.4014140847361],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-507", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.125521409531387, 27.4177818287516],
    },
  },
  {
    type: "Feature",
    properties: { Name: "KLN-13", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.096073468171696, 27.372908765595412],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-532", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.117477688902497, 27.405679752456699],
    },
  },
  {
    type: "Feature",
    properties: { Name: "KLN-40", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.099317737983995, 27.3846030076887],
    },
  },
  {
    type: "Feature",
    properties: { Name: "WATER WELL-148", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.977508010967597, 27.420028366185299],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-521", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.071125837619405, 27.394423551366302],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-286", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.121268238661102, 27.427659548641799],
    },
  },
  {
    type: "Feature",
    properties: { Name: "DKM-13", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.082412867474204, 27.446973096883902],
    },
  },
  {
    type: "Feature",
    properties: { Name: "WATER WELL-68", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.102066425949786, 27.401287126272202],
    },
  },
  {
    type: "Feature",
    properties: { Name: "DKM-29", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.108478375169881, 27.4434887679041],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-438", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.081774745535, 27.446834032403],
    },
  },
  {
    type: "Feature",
    properties: { Name: "KLN-35", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.094337883396605, 27.377933533820499],
    },
  },
  {
    type: "Feature",
    properties: { Name: "DKM-18", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.088956659858297, 27.438749333578301],
    },
  },
  {
    type: "Feature",
    properties: { Name: "KLN-2", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.090010953202594, 27.378362855174501],
    },
  },
  {
    type: "Feature",
    properties: { Name: "KUHIARBARI-6", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.109442684911798, 27.431512026981199],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-482", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.112637721528699, 27.3979404441946],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-489", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.112525783205683, 27.397845687666099],
    },
  },
  {
    type: "Feature",
    properties: { Name: "DKM-7", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.076282864859621, 27.450057916246799],
    },
  },
  {
    type: "Feature",
    properties: { Name: "BPR-9", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.980325150765907, 27.4327101007487],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-522", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.122520712083499, 27.399594613003799],
    },
  },
  {
    type: "Feature",
    properties: { Name: "KLN-36", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.094483025782196, 27.377969701546299],
    },
  },
  {
    type: "Feature",
    properties: { Name: "WATER WELL-93", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.1263839300764, 27.408167881525898],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-497", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.1211598622167, 27.4277182215287],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-525", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.097448666542491, 27.3946658611946],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NADUA-6", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.110533820140802, 27.4847518028431],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-493", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.071170566782911, 27.3945510111704],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-555", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.109363832982197, 27.4023755792497],
    },
  },
  {
    type: "Feature",
    properties: { Name: "KLN-6", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.11534752827292, 27.381119784938399],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-528", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.117895748633501, 27.4055178513368],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-599", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.103374779487595, 27.414712576478099],
    },
  },
  {
    type: "Feature",
    properties: { Name: "WATER WELL-134", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.109840780067898, 27.431513758412301],
    },
  },
  {
    type: "Feature",
    properties: { Name: "DKM-19", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.088899053630996, 27.438874710947701],
    },
  },
  {
    type: "Feature",
    properties: { Name: "KLN-18", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.080434800242003, 27.377749340114701],
    },
  },
  {
    type: "Feature",
    properties: { Name: "WATER WELL-52", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.077358460428101, 27.371741136119901],
    },
  },
  {
    type: "Feature",
    properties: { Name: "BPR-2", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.983930422012904, 27.439530773089],
    },
  },
  {
    type: "Feature",
    properties: { Name: "WATER WELL-82", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.125298862203607, 27.4175169802242],
    },
  },
  {
    type: "Feature",
    properties: { Name: "BPR-10", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.980972946171406, 27.433135081991502],
    },
  },
  {
    type: "Feature",
    properties: { Name: "SOUTH KATHALONI-1", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.089425581484903, 27.365629277843599],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-578", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.073024003211401, 27.4087200533986],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NADUA-1", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.124790043852101, 27.482959587737799],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-530", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.117845362482811, 27.405547128385798],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-545", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.109593235338906, 27.4314953111937],
    },
  },
  {
    type: "Feature",
    properties: { Name: "KLN-20", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.080379192279196, 27.377624210881201],
    },
  },
  {
    type: "Feature",
    properties: { Name: "KLN-30", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.072483770107581, 27.377764554129701],
    },
  },
  {
    type: "Feature",
    properties: { Name: "KUHIARBARI-7", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.109291894595216, 27.431529287598799],
    },
  },
  {
    type: "Feature",
    properties: { Name: "WATER WELL-71", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.122077544621405, 27.399221956131299],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-509", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.084571951214897, 27.394129724813808],
    },
  },
  {
    type: "Feature",
    properties: { Name: "DKM-9", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.093651316616885, 27.4511227722802],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-603", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.117962018316092, 27.417767889682299],
    },
  },
  {
    type: "Feature",
    properties: { Name: "KLN-9", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.1068329721857, 27.380552360433001],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MAKALBARI-1", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.067959090830797, 27.499811564423599],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-598", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.097607059427204, 27.394585702706401],
    },
  },
  {
    type: "Feature",
    properties: { Name: "KLN-32", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.106814600857206, 27.380414319554699],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-526", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.071080471316691, 27.394293213699701],
    },
  },
  {
    type: "Feature",
    properties: { Name: "BPR-1", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.984123131804296, 27.439584061568599],
    },
  },
  {
    type: "Feature",
    properties: { Name: "KLN-3", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.088537827022293, 27.3883673611885],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-524", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.097316689288505, 27.3947320881107],
    },
  },
  {
    type: "Feature",
    properties: { Name: "KLN-37", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.099580429218406, 27.3844491859832],
    },
  },
  {
    type: "Feature",
    properties: { Name: "DKM-11", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.093716762284402, 27.451244587095012],
    },
  },
  {
    type: "Feature",
    properties: { Name: "KUHIARBARI-3", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.097363387286407, 27.434579310351701],
    },
  },
  {
    type: "Feature",
    properties: { Name: "DKM-6", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.0761316734784, 27.450055478501401],
    },
  },
  {
    type: "Feature",
    properties: { Name: "DKM-8", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.076434811819695, 27.450060613243799],
    },
  },
  {
    type: "Feature",
    properties: { Name: "WATER WELL-43", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.082158732623412, 27.446969678362908],
    },
  },
  {
    type: "Feature",
    properties: { Name: "KLN-34", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.094190222345489, 27.377896411243899],
    },
  },
  {
    type: "Feature",
    properties: { Name: "DKM-12", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.086369358128195, 27.4475960897086],
    },
  },
  {
    type: "Feature",
    properties: { Name: "DKM-20", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.088840518191205, 27.4389994706405],
    },
  },
  {
    type: "Feature",
    properties: { Name: "WATER WELL-154", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.0852596481337, 27.380896909389801],
    },
  },
  {
    type: "Feature",
    properties: { Name: "BACHMATIA-3", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.0598030374763, 27.374922023434902],
    },
  },
  {
    type: "Feature",
    properties: { Name: "WATER WELL-118", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.981149856515898, 27.432920885181399],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-527", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.118047273801807, 27.405427397944301],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NADUA-5", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.110576490032088, 27.484593946080601],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-498", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.109431007493797, 27.401215237443999],
    },
  },
  {
    type: "Feature",
    properties: { Name: "KLN-42", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.057072497855188, 27.361036903432701],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-539", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.118403314171402, 27.430513684849299],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-574", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.085834108197886, 27.417545047058599],
    },
  },
  {
    type: "Feature",
    properties: { Name: "WEST BACHMATIA-1", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.0396432596132, 27.377527353016401],
    },
  },
  {
    type: "Feature",
    properties: { Name: "KLN-26", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.072181817334609, 27.377758753485502],
    },
  },
  {
    type: "Feature",
    properties: { Name: "KLN-1", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.089589466702989, 27.3787253885776],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-628", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.103510958225613, 27.414655843800599],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-321", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.104650900333993, 27.393211156847599],
    },
  },
  {
    type: "Feature",
    properties: { Name: "DKM-31", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.12187541088629, 27.443668685262999],
    },
  },
  {
    type: "Feature",
    properties: { Name: "KLN-39", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.084967731983497, 27.380968853536601],
    },
  },
  {
    type: "Feature",
    properties: { Name: "WATER WELL", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.095825386984103, 27.372894627256699],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-503", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.109088551360003, 27.4015955817401],
    },
  },
  {
    type: "Feature",
    properties: { Name: "BPR-18", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.976175737882187, 27.429353326295001],
    },
  },
  {
    type: "Feature",
    properties: { Name: "WATER WELL-130", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.093556214649197, 27.450844047592501],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-519", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.122416271412007, 27.3994956905407],
    },
  },
  {
    type: "Feature",
    properties: { Name: "WATER WELL-51", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.085736458546705, 27.3714088951346],
    },
  },
  {
    type: "Feature",
    properties: { Name: "BPR-5", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.977397311023495, 27.419923374058602],
    },
  },
  {
    type: "Feature",
    properties: { Name: "WATER WELL-58", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.097638913941097, 27.434868670182599],
    },
  },
  {
    type: "Feature",
    properties: { Name: "KLN-12", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.098488261382485, 27.3793126392898],
    },
  },
  {
    type: "Feature",
    properties: { Name: "KUHIARBARI-1", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.097149026464194, 27.434388639711202],
    },
  },
  {
    type: "Feature",
    properties: { Name: "WATER WELL-98", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.089064981448203, 27.438517990735299],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MADARKHAT-1", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.076937441118119, 27.435174869075599],
    },
  },
  {
    type: "Feature",
    properties: { Name: "BPR-16", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.980832900691283, 27.433396035380401],
    },
  },
  {
    type: "Feature",
    properties: { Name: "DKM-22", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.088789837980102, 27.439107330266701],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-546", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.102382632369498, 27.401588950414101],
    },
  },
  {
    type: "Feature",
    properties: { Name: "WATER WELL", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.089405391993495, 27.365788020465001],
    },
  },
  {
    type: "Feature",
    properties: { Name: "BACHMATIA-1", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.058562940683515, 27.3791099830222],
    },
  },
  {
    type: "Feature",
    properties: { Name: "KLN-17", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.096370483624696, 27.3729629511508],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-514", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.084272213568696, 27.394093241666301],
    },
  },
  {
    type: "Feature",
    properties: { Name: "BPR-3", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.983786021045702, 27.439492316040301],
    },
  },
  {
    type: "Feature",
    properties: { Name: "KLN-31", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.089403810237798, 27.365495254355999],
    },
  },
  {
    type: "Feature",
    properties: { Name: "WATER WELL-53", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.073460633436795, 27.458269435037799],
    },
  },
  {
    type: "Feature",
    properties: { Name: "KLN-14", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.097951665118003, 27.379643454098201],
    },
  },
  {
    type: "Feature",
    properties: { Name: "KLN-41", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.070516766262998, 27.380044420140599],
    },
  },
  {
    type: "Feature",
    properties: { Name: "KLN-4", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.089927481100503, 27.3783967681719],
    },
  },
  {
    type: "Feature",
    properties: { Name: "KLN-28", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.106778860250216, 27.380683736485398],
    },
  },
  {
    type: "Feature",
    properties: { Name: "DKM-14", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.086227674474898, 27.447646573951499],
    },
  },
  {
    type: "Feature",
    properties: { Name: "DKM-4", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.073531945562294, 27.4581395282143],
    },
  },
  {
    type: "Feature",
    properties: { Name: "BPR-6", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.977262476733699, 27.419863298352301],
    },
  },
  {
    type: "Feature",
    properties: { Name: "WATER WELL-69", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.10452683847042, 27.393131554031299],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NADUA-3", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.124712832649109, 27.4826406467404],
    },
  },
  {
    type: "Feature",
    properties: { Name: "WATER WELL-57", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.080595517925602, 27.3781102017503],
    },
  },
  {
    type: "Feature",
    properties: { Name: "WATER WELL-119", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.980435994879215, 27.432607376518899],
    },
  },
  {
    type: "Feature",
    properties: { Name: "KLN-5", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.098230753909505, 27.379647828183899],
    },
  },
  {
    type: "Feature",
    properties: { Name: "DKM-3", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.093587387460204, 27.451001746669501],
    },
  },
  {
    type: "Feature",
    properties: { Name: "KUHIARBARI-2", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.097255400197184, 27.434483220292101],
    },
  },
  {
    type: "Feature",
    properties: { Name: "DKM-10", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.076044152505688, 27.4498451096011],
    },
  },
  {
    type: "Feature",
    properties: { Name: "BPR-19", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.976326834676883, 27.429333137063299],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-549", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.122945971357282, 27.437318775969999],
    },
  },
  {
    type: "Feature",
    properties: { Name: "DKM-27", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.108477082743306, 27.4434164258412],
    },
  },
  {
    type: "Feature",
    properties: { Name: "WATER WELL-120", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.099728224562796, 27.384435502320301],
    },
  },
  {
    type: "Feature",
    properties: { Name: "WATER WELL-59", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.112635494467099, 27.398244181469099],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-535", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.079148784184298, 27.402142175637898],
    },
  },
  {
    type: "Feature",
    properties: { Name: "SOUTH KATHALONI-3", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.06666322773701, 27.350196871083199],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-543", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.103344704835393, 27.414815257480001],
    },
  },
  {
    type: "Feature",
    properties: { Name: "DIBRUGARH-1", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.796922646691996, 27.3105793887513],
    },
  },
  {
    type: "Feature",
    properties: { Name: "KLN-15", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.096221527746295, 27.372936406552999],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MOHANBARI-1", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.054838036518291, 27.4543506333792],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NAMDANG-1", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.971399502515098, 27.329201681051799],
    },
  },
  {
    type: "Feature",
    properties: { Name: "WATER WELL-64", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.089843851130297, 27.378484369039501],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-601", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.126658219426318, 27.408248870880801],
    },
  },
  {
    type: "Feature",
    properties: { Name: "KLN-11", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.098403143403004, 27.379422643847509],
    },
  },
  {
    type: "Feature",
    properties: { Name: "DKM-24", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.121751341393193, 27.443588018556198],
    },
  },
  {
    type: "Feature",
    properties: { Name: "DKM-2", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.086513621197895, 27.447554138261399],
    },
  },
  {
    type: "Feature",
    properties: { Name: "DKM-28", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.099910607498899, 27.439585881073999],
    },
  },
  {
    type: "Feature",
    properties: { Name: "WATER WELL-151", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.965640956520616, 27.417143625245799],
    },
  },
  {
    type: "Feature",
    properties: { Name: "KLN-27", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.072333845515999, 27.377762179636001],
    },
  },
  {
    type: "Feature",
    properties: { Name: "BPR-11", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.980260019201097, 27.432831935716699],
    },
  },
  {
    type: "Feature",
    properties: { Name: "BPR-8", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.977125452390993, 27.4198019903437],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-510", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.122312598311595, 27.3993976585718],
    },
  },
  {
    type: "Feature",
    properties: { Name: "KLN-38", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.09943559931888, 27.384491878089399],
    },
  },
  {
    type: "Feature",
    properties: { Name: "DKM-30", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.100015006705291, 27.439685902555802],
    },
  },
  {
    type: "Feature",
    properties: { Name: "KLN-16", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.072030802636903, 27.3777560339432],
    },
  },
  {
    type: "Feature",
    properties: { Name: "DKM-1", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.086737580502302, 27.451187116452999],
    },
  },
  {
    type: "Feature",
    properties: { Name: "DKM-15", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.086864416631201, 27.451171862156698],
    },
  },
  {
    type: "Feature",
    properties: { Name: "WATER WELL-147", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.974161536806207, 27.440792572110599],
    },
  },
  {
    type: "Feature",
    properties: { Name: "KLN-19", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.10676044714009, 27.380816197463901],
    },
  },
  {
    type: "Feature",
    properties: { Name: "KLN-10", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.115499550481104, 27.3811227126786],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-567", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.118359228583998, 27.430384339083499],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MADARKHAT-3", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.076708870911105, 27.434998633362699],
    },
  },
  {
    type: "Feature",
    properties: { Name: "WATER WELL-70", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.097184690828882, 27.3947662845022],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NADUA-2", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.124750510259901, 27.482799590065799],
    },
  },
  {
    type: "Feature",
    properties: { Name: "DKM-5", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.076041139065097, 27.450053850676099],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-516", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.084121451035799, 27.3940740206979],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-492", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.112438719789196, 27.397734944946301],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NADUA-4", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.124690335592305, 27.482894343415602],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-484", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.117957537424701, 27.4179307262687],
    },
  },
  {
    type: "Feature",
    properties: { Name: "DKM-21", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.108932213452107, 27.443410708144299],
    },
  },
  {
    type: "Feature",
    properties: { Name: "KLN-33", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.080322676195394, 27.3774995463009],
    },
  },
  {
    type: "Feature",
    properties: { Name: "DKM-32", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.121998855437695, 27.4437471959479],
    },
  },
  {
    type: "Feature",
    properties: { Name: "SOLOGURI-1", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.997069680440703, 27.3194816432203],
    },
  },
  {
    type: "Feature",
    properties: { Name: "BACHMATIA-2", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.058516578944605, 27.378659071730699],
    },
  },
  {
    type: "Feature",
    properties: { Name: "DKM-25", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.108780689788105, 27.443412190326999],
    },
  },
  {
    type: "Feature",
    properties: { Name: "WATER WELL-92", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.103126256634411, 27.4149661651938],
    },
  },
  {
    type: "Feature",
    properties: { Name: "KLN-7", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.098316591604799, 27.3795361887992],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-517", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.112351244950503, 27.397624479076601],
    },
  },
  {
    type: "Feature",
    properties: { Name: "BPR-17", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.976025211828116, 27.429372785423201],
    },
  },
  {
    type: "Feature",
    properties: { Name: "KLN-22", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.085922595053887, 27.371733640302001],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-553", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.126809861894301, 27.408247459633799],
    },
  },
  {
    type: "Feature",
    properties: { Name: "WATER WELL-146", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.984256149893795, 27.439657599625999],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-512", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.084421794039415, 27.394111668087501],
    },
  },
  {
    type: "Feature",
    properties: { Name: "JRN-32", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.487451819560306, 27.336880485473099],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-336", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.469615647614205, 27.356296657531299],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-216", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.364466598155104, 27.342690450370998],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-449", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.398423118810797, 27.3034033753149],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-91", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.273342092475403, 27.337313776846202],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MKM-7", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.424069907781004, 27.485580686466701],
    },
  },
  {
    type: "Feature",
    properties: { Name: "WATER WELL-44", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.472798628984904, 27.459882321848301],
    },
  },
  {
    type: "Feature",
    properties: { Name: "BORHAPJAN-6", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.4998162895441, 27.5110538770586],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-632", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.360063955913702, 27.368382298312799],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MKM-48", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.437262407684216, 27.490897180699299],
    },
  },
  {
    type: "Feature",
    properties: { Name: "JRN-43", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.48985465558232, 27.339542441094409],
    },
  },
  {
    type: "Feature",
    properties: { Name: "TAMULIKHAT-4", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.163046317304406, 27.387955674995801],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-625", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.306713316511406, 27.419461424451299],
    },
  },
  {
    type: "Feature",
    properties: { Name: "KHARIKATIYA-3", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.282679063848306, 27.440074411582099],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-460", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.476538042140703, 27.348104974155401],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-56", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.203386155589101, 27.363497851236101],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-272", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.319625795038206, 27.359420470969599],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-540", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.469074703951605, 27.376887142366201],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-406", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.433654367420417, 27.394681579163699],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-508", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.304667647356197, 27.361235427215899],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-221", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.245463404287918, 27.335007035396998],
    },
  },
  {
    type: "Feature",
    properties: { Name: "CBA-4", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.206448118782689, 27.460589451622099],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-258", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.293578162633906, 27.3078916263803],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-154", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.271624173149405, 27.333520203726501],
    },
  },
  {
    type: "Feature",
    properties: { Name: "JENGONI-4", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.418511006404401, 27.4269969357366],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-285", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.277121174474388, 27.4248372869679],
    },
  },
  {
    type: "Feature",
    properties: { Name: "HJN-34", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.443683959791414, 27.463335922499201],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-347", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.453802314140802, 27.371950811857701],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-518", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.129987793512697, 27.422250654130401],
    },
  },
  {
    type: "Feature",
    properties: { Name: "JRN-31", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.48329274541932, 27.346525216778002],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-374", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.470447376282905, 27.384962876209901],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-314", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.459901864622097, 27.396926036749502],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-231", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.259676998236884, 27.3151817906367],
    },
  },
  {
    type: "Feature",
    properties: { Name: "JRN-29", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.487805941709098, 27.3261514577379],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-581", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.454961204543693, 27.42434587052],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-80", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.292495969248506, 27.3256216503122],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-291", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.3764003587292, 27.297265939178299],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-12", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.360073597369208, 27.3693824759835],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-101", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.317824898142788, 27.3304346875181],
    },
  },
  {
    type: "Feature",
    properties: { Name: "HJN-33", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.444881055628414, 27.468941550391701],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-116", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.434981131486296, 27.397480435081501],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MKM-41", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.392359573272415, 27.4689104580827],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-49", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.347551991139795, 27.367447603540999],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-354", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.391245264376096, 27.3037107007051],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-153", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.269734271414194, 27.3241292203443],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-422", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.44433356350082, 27.398970864419599],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MKM-60", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.392663181555903, 27.468901269390699],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MKM-29", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.409702377445115, 27.472655979177802],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-544", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.297467570354698, 27.357388046569199],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MKM-31", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.415367037355296, 27.4602401566253],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-37", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.310441135864195, 27.317194276764599],
    },
  },
  {
    type: "Feature",
    properties: { Name: "CBA-2", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.162315387263305, 27.4363458274102],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-594", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.271200847512901, 27.4412689536084],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-499", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.460590433378101, 27.399864970770398],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-160", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.313380516714119, 27.3196706371535],
    },
  },
  {
    type: "Feature",
    properties: { Name: "WATER WELL-18", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.296816552425895, 27.330642838503898],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-646", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.244039610307198, 27.3392727847476],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-351", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.452827041089392, 27.3747198726584],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-76", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.288820683733704, 27.3831142581747],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-340", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.479650802083512, 27.3691814842438],
    },
  },
  {
    type: "Feature",
    properties: { Name: "KAMKHAT-2", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.161519209474818, 27.420375395865999],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-11", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.273942658923687, 27.329978972525499],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-105", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.355665142370796, 27.378910968778399],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-415", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.462814520619702, 27.375814565280098],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MKM-43", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.377978976576003, 27.439248890250202],
    },
  },
  {
    type: "Feature",
    properties: { Name: "HJN-27", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.466544691695105, 27.467323913512899],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-295", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.474680865594721, 27.355502719134101],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-32", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.321243392937205, 27.3165477744332],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-490", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.194602215660893, 27.378863786635101],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-453", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.482045688613795, 27.3658567451692],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-36", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.307546650500399, 27.309612317733599],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-622", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.269975886949894, 27.311162978472801],
    },
  },
  {
    type: "Feature",
    properties: { Name: "JRN-36", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.483244114258397, 27.346593742270201],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-249", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.449742478660198, 27.4306953515309],
    },
  },
  {
    type: "Feature",
    properties: { Name: "WATER WELL-133", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.4159629686025, 27.4599746865402],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-429", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.475087733056597, 27.3897980540243],
    },
  },
  {
    type: "Feature",
    properties: { Name: "CBA-5", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.183326815512189, 27.452334183286801],
    },
  },
  {
    type: "Feature",
    properties: { Name: "HJN-56", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.443775372593194, 27.463282715129498],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-320", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.245948783432283, 27.324412558984999],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-118", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.356197387397799, 27.349949684334302],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-177", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.312148915244606, 27.364084789045901],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-337", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.458297573909604, 27.377051694873899],
    },
  },
  {
    type: "Feature",
    properties: { Name: "WATER WELL-72", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.444212350415711, 27.3916820989879],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-424", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.484758694380702, 27.3681384900155],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-334", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.409338189575195, 27.309604426632198],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NORTH BALIJAN-3", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.265625090203201, 27.389932811453299],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MKM-4", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.4367068660396, 27.4741485469171],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-423", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.436791903416506, 27.361548081330898],
    },
  },
  {
    type: "Feature",
    properties: { Name: "HJN-15", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.47229276484461, 27.459822745297899],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-19", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.299697023937895, 27.338093788536099],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MKM-32", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.409782338204607, 27.472458737103],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-261", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.242200809499096, 27.3248011854384],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-88", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.269136437856602, 27.324175202919999],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-318", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.286204091014213, 27.309008065616201],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-343", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.442557233896807, 27.3639295536566],
    },
  },
  {
    type: "Feature",
    properties: { Name: "HJN-43", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.437130578994811, 27.4699546430491],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-119", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.275730816967894, 27.340161151015501],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-33", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.321370516883803, 27.322502908719699],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-287", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.445493766545894, 27.371425689446198],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-575", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.321058290377906, 27.343250255705399],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MKM-45", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.411750331974716, 27.472856705545048],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MKM-51", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.377853265889399, 27.439325104600801],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-408", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.481755127664499, 27.358810361107299],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-173", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.320384004359795, 27.312957746465798],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-187", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.260844454398196, 27.3212476015823],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-150", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.33208704200301, 27.3313213913152],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-70", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.328656353064318, 27.357458466620201],
    },
  },
  {
    type: "Feature",
    properties: { Name: "HJN-28", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.475902818596396, 27.454762229685802],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-608", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.214813025992001, 27.3564066342513],
    },
  },
  {
    type: "Feature",
    properties: { Name: "BISMILE-1", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.185839962050096, 27.503203153776312],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-441", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.478163561535496, 27.363475906919501],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-568", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.371622709008093, 27.353575948840501],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-327", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.481712352620704, 27.351553965919901],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-275", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.451093166927294, 27.3568653447599],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-83", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.332936650061697, 27.321324650710508],
    },
  },
  {
    type: "Feature",
    properties: { Name: "JRN-6", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.475402618139398, 27.342411132966401],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-458", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.478275744690691, 27.3737151606339],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-442", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.452825261281305, 27.374801638468],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-593", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.242609420282307, 27.348988101114401],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-215", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.317379033041789, 27.3209517143043],
    },
  },
  {
    type: "Feature",
    properties: { Name: "JRN-34", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.48698214860039, 27.345890465222801],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-640", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.353793697784695, 27.394405899612501],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-20", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.431068900308503, 27.398168037277902],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-282", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.293645697554695, 27.307813649174999],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-475", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.462685062611882, 27.393287824063709],
    },
  },
  {
    type: "Feature",
    properties: { Name: "WATER WELL-91", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.326457796013599, 27.329000544785199],
    },
  },
  {
    type: "Feature",
    properties: { Name: "BAZALONI-1", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.445063595430597, 27.427583783923101],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-345", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.471648692188793, 27.352040807216799],
    },
  },
  {
    type: "Feature",
    properties: { Name: "SEALKATI-2", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.140506326643788, 27.449814776068798],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-122", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.262886254077898, 27.3281236813799],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MKM-55", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.396384127636594, 27.4503219554278],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-199", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.250739986827597, 27.333975291246499],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-40", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.30855254641321, 27.304854593213499],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-440", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.481669872520385, 27.3588586080126],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-85", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.364106340308695, 27.377327554294201],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-304", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.428731076016518, 27.394748662351301],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-135", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.305574496534803, 27.3285828701251],
    },
  },
  {
    type: "Feature",
    properties: { Name: "JRN-39", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.478163767766802, 27.337886896631002],
    },
  },
  {
    type: "Feature",
    properties: { Name: "WATER WELL-113", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.319446775962604, 27.3594479862623],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-65", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.365079661158404, 27.381560594323201],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-106", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.336413134454119, 27.371795843357798],
    },
  },
  {
    type: "Feature",
    properties: { Name: "WATER WELL-85", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.4408525127281, 27.392272771413602],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-329", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.455334376269406, 27.356661060218109],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-84", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.263141936007187, 27.328081915844599],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-466", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.460470100293904, 27.399919599275101],
    },
  },
  {
    type: "Feature",
    properties: { Name: "HJN-32", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.444991026661313, 27.469033531233809],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MKM-47", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.437112344387003, 27.490887270248699],
    },
  },
  {
    type: "Feature",
    properties: { Name: "CBA-1", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.179457289424207, 27.4446207422932],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-639", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.432518906560901, 27.340555005386499],
    },
  },
  {
    type: "Feature",
    properties: { Name: "JRN-8", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.491519499244504, 27.3518640469734],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-183", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.304168024348684, 27.318775342089999],
    },
  },
  {
    type: "Feature",
    properties: { Name: "HJN-21", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.461640576123514, 27.432949884315601],
    },
  },
  {
    type: "Feature",
    properties: { Name: "JRN-3", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.484327778893302, 27.334823569708899],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-202", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.385231277956393, 27.362177601271298],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-86", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.267045472036713, 27.333674817354702],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-68", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.3773913665013, 27.374894140461802],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-367", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.47952317160221, 27.355942355681702],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-427", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.480567073471903, 27.3985516138052],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-270", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.253034010603599, 27.316379599418301],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-551", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.306593609464898, 27.419378689590001],
    },
  },
  {
    type: "Feature",
    properties: { Name: "CBA-14", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.183211901075893, 27.452246663473801],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-120", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.274946244664008, 27.326561442651101],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-643", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.207048849480898, 27.439339086649099],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-635", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.460991228443802, 27.386345074623499],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-313", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.313662656376707, 27.3143440307772],
    },
  },
  {
    type: "Feature",
    properties: { Name: "HJN-35", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.443831510980203, 27.463365989479598],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MKM-34", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.411585511948303, 27.446772498699499],
    },
  },
  {
    type: "Feature",
    properties: { Name: "JRN-16", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.4862949837012, 27.329700043852402],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-61", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.353742247322302, 27.382678563139098],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-428", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.4822423170869, 27.372193999895899],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-542", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.252959765959602, 27.338824420170202],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-146", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.288027170090501, 27.323846829885699],
    },
  },
  {
    type: "Feature",
    properties: { Name: "JRN-28", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.490213367963818, 27.347834967750298],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-161", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.288947082638501, 27.318804346627498],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-211", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.3037421435575, 27.306548665017701],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-618", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.243938427680405, 27.339171288812601],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MKM-2", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.436797662388898, 27.476177589673],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-479", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.280862800940184, 27.310990014935602],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-369", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.477542695924882, 27.398782213216201],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-376", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.481888666077296, 27.365893368578199],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-72", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.332118428029901, 27.3600690785151],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-206", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.290193767779201, 27.314213654344201],
    },
  },
  {
    type: "Feature",
    properties: { Name: "HJN-53", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.444847857765396, 27.469013402651601],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MKM-53", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.382322257331282, 27.444380982534099],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-554", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.139537438289381, 27.433413417387701],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-385", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.482225297164902, 27.372226779627901],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-212", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.392722309943807, 27.337088755081901],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-69", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.328977211579016, 27.353346314864599],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-54", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.3593308473706, 27.377403446311099],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MKM-15", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.424239103298703, 27.46140956512],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-552", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.282700993773801, 27.4224693581934],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-243", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.444981622196707, 27.365709347470101],
    },
  },
  {
    type: "Feature",
    properties: { Name: "HJN-69", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.460816691818493, 27.473839792365499],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-387", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.448163636815892, 27.380486142508701],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-163", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.305219286116014, 27.302745215176799],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-111", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.364358483572602, 27.350020451560699],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-171", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.28905501287089, 27.328880223750399],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MATIMEKHENA-1", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.229116035048307, 27.4979474163665],
    },
  },
  {
    type: "Feature",
    properties: { Name: "WATER WELL-48", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.481170531268006, 27.4602256404679],
    },
  },
  {
    type: "Feature",
    properties: { Name: "JRN-21", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.479010298339304, 27.342794396792499],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-41", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.335995296278995, 27.367525637447301],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-459", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.478277755716206, 27.373633391289701],
    },
  },
  {
    type: "Feature",
    properties: { Name: "WATER WELL-145", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.298694574128803, 27.3284573708446],
    },
  },
  {
    type: "Feature",
    properties: { Name: "JRN-18", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.490250157306903, 27.335436982845199],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MKM-16", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.409779766500094, 27.472731502559501],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-344", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.436939872928704, 27.364711247198599],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-372", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.440424610274306, 27.3609690152072],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-602", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.28268361359288, 27.4223338608974],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-8", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.324741651736517, 27.321105269496599],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-100", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.347734523323808, 27.371565635914308],
    },
  },
  {
    type: "Feature",
    properties: { Name: "JERAIPATHAR-1", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.213404963608781, 27.500295540136801],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MKM-13", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.423766199801904, 27.485560447391101],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-401", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.432183947324503, 27.359519593305901],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-478", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.461758851357999, 27.393151537983801],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-50", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.332511228806112, 27.381729318244499],
    },
  },
  {
    type: "Feature",
    properties: { Name: "BAZALONI-5", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.445399323518487, 27.427754982122899],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-283", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.4502352364578, 27.378189343916599],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-513", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.352335979658079, 27.399100290192401],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-435", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.321119353921119, 27.3431856353555],
    },
  },
  {
    type: "Feature",
    properties: { Name: "WATER WELL-156", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.440562796382807, 27.360912830084899],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-157", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.378395473293807, 27.338156230266708],
    },
  },
  {
    type: "Feature",
    properties: { Name: "LOC-HK", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.302916687108706, 27.3825568934862],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-23", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.2929918336296, 27.317357356747699],
    },
  },
  {
    type: "Feature",
    properties: { Name: "SOUTH KAPAHUAGAON-1", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.160478907466498, 27.358498234083001],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-226", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.365020089298795, 27.363074562708601],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-355", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.430254530098594, 27.391618526800901],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-392", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.453972107356279, 27.383814204827299],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-476", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.462595423849692, 27.393275243676101],
    },
  },
  {
    type: "Feature",
    properties: { Name: "WATER WELL-125", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.139587253636719, 27.396956313766399],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-25", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.296013403257305, 27.386236128946098],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-232", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.245919472475606, 27.324309995602899],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-323", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.279483991018296, 27.328541537323201],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-606", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.4582941374359, 27.330706749549499],
    },
  },
  {
    type: "Feature",
    properties: { Name: "HJN-14", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.472377627562992, 27.459850557299099],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-596", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.360114180200597, 27.368255143081001],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-455", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.404217681772096, 27.302756453470501],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-103", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.394431121327699, 27.347914677578899],
    },
  },
  {
    type: "Feature",
    properties: { Name: "JRN-4", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.479920111808781, 27.346383186765099],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-465", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.445860641889496, 27.377831918140501],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-290", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.441061483819595, 27.369373244855701],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-409", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.319666438546307, 27.3403915774187],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-38", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.313277614543694, 27.307257706581101],
    },
  },
  {
    type: "Feature",
    properties: { Name: "HJN-52", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.441259689666211, 27.461879192468299],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-284", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.459743363104707, 27.348961362963099],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MKM-44", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.4119115874757, 27.472859123428201],
    },
  },
  {
    type: "Feature",
    properties: { Name: "WATER WELL-137", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.317848753152305, 27.309976268635008],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MKM-25", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.396246430458802, 27.450379898311802],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-229", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.298847384584491, 27.298880707393501],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-14", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.339285470076703, 27.326509039044701],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-577", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.484761259440305, 27.3680033947124],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-123", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.292636784320507, 27.321140030780999],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-399", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.459195905077394, 27.389339834898401],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-565", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.376889682314413, 27.375029806132002],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-98", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.296806013972983, 27.331044946771001],
    },
  },
  {
    type: "Feature",
    properties: { Name: "JRN-30", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.483669573015703, 27.3381616454765],
    },
  },
  {
    type: "Feature",
    properties: { Name: "W.LOHALI-1", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.254529907210397, 27.435099916227401],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-488", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.456517980148703, 27.412868007457],
    },
  },
  {
    type: "Feature",
    properties: { Name: "HJN-39", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.440998183898202, 27.4619807588669],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MKM-NEW", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.3928128702252, 27.4688991469997],
    },
  },
  {
    type: "Feature",
    properties: { Name: "CBA-8", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.179311259212099, 27.4446554178515],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-145", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.28180826538761, 27.324348062337101],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MKM-58", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.392511625638306, 27.4689057693362],
    },
  },
  {
    type: "Feature",
    properties: { Name: "HJN-8", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.481305101851902, 27.460141509056101],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MKM-12", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.424902873467985, 27.478533758044001],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-280", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.434026423701397, 27.3652725411111],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-319", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.29398051990232, 27.299822017678601],
    },
  },
  {
    type: "Feature",
    properties: { Name: "HJN-12", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.476477462107297, 27.4541050919706],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-566", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.484540180430102, 27.3740500115656],
    },
  },
  {
    type: "Feature",
    properties: { Name: "WATER WELL-62", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.42417156334362, 27.478365349995201],
    },
  },
  {
    type: "Feature",
    properties: { Name: "JRN-19", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.487058337544397, 27.341838153202598],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-366", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.473259254342793, 27.393100293996699],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-502", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.452904012071812, 27.400450146516199],
    },
  },
  {
    type: "Feature",
    properties: { Name: "JRN-7", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.494944376721705, 27.345293344156499],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MKM-19", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.436696527354997, 27.4738797089942],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-186", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.2553805705287, 27.331446095386202],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-148", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.283814679424594, 27.331789317895009],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-419", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.447319973707408, 27.396853006006001],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-473", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.446974327621604, 27.393211421640501],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-520", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.2369305147039, 27.369786105604401],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-77", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.317229550562899, 27.3607031507768],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-5", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.313956931853596, 27.297057286614301],
    },
  },
  {
    type: "Feature",
    properties: { Name: "HJN-54", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.464674732205793, 27.436487904694001],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-67", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.303519981418319, 27.389086094514099],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-411", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.4443652387763, 27.391720466633402],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-241", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.319925675676103, 27.330172991791809],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-402", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.47829534594689, 27.363447404257698],
    },
  },
  {
    type: "Feature",
    properties: { Name: "BAZALONI-4", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.445402338008194, 27.427853894431902],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-130", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.307978351734704, 27.3406230776218],
    },
  },
  {
    type: "Feature",
    properties: { Name: "HJN-20", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.465215052264313, 27.460352966231699],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-361", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.447343056269006, 27.387790441584801],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-174", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.315016837583897, 27.336184094989001],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-136", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.272931646259707, 27.3209308239074],
    },
  },
  {
    type: "Feature",
    properties: { Name: "HJN-55", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.475316768309298, 27.454558214723999],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-205", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.369634342134702, 27.393261576521599],
    },
  },
  {
    type: "Feature",
    properties: { Name: "CBA-16", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.199207051940803, 27.456488438756899],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-504", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.195001985444904, 27.3786691988063],
    },
  },
  {
    type: "Feature",
    properties: { Name: "SINGHIBIL-1", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.198989603933896, 27.328092754492999],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-360", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.438061270812696, 27.389287491055001],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-236", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.406492215813117, 27.3066696299299],
    },
  },
  {
    type: "Feature",
    properties: { Name: "TAMULIKHAT-3", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.145639517548204, 27.389115717311],
    },
  },
  {
    type: "Feature",
    properties: { Name: "HJN-7", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.466258594028304, 27.4676989478574],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-480", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.134773115913006, 27.432266743902801],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MKM-1", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.43680315381792, 27.476313356157998],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-27", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.321410169998018, 27.356082888180001],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-450", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.484763712363204, 27.367859550694401],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-462", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.441041656853102, 27.397541801893599],
    },
  },
  {
    type: "Feature",
    properties: { Name: "BORPATHAR-2", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.446570892771007, 27.5011585513505],
    },
  },
  {
    type: "Feature",
    properties: { Name: "WATER WELL-138", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.476526279404496, 27.348246638471402],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-134", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.335213847764294, 27.3249837147935],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-370", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.293491138578403, 27.328466409067701],
    },
  },
  {
    type: "Feature",
    properties: { Name: "JRN-35", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.4781440897371, 27.337807586868799],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-331", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.47593213346039, 27.378051765235099],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-506", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.290720299823604, 27.425484909447601],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-296", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.317816574885896, 27.310255583881698],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-267", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.305711521400397, 27.2995601398469],
    },
  },
  {
    type: "Feature",
    properties: { Name: "WATER WELL-121", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.462435256597402, 27.3729791490561],
    },
  },
  {
    type: "Feature",
    properties: { Name: "HJN-26", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.482369128296398, 27.463196623331601],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-610", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.336852618778011, 27.4226774387581],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-437", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.422154779959399, 27.3407779616245],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-53", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.325231951297496, 27.3548403662796],
    },
  },
  {
    type: "Feature",
    properties: { Name: "HJN-61", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.463897789224902, 27.443497442937101],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-147", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.347194305911998, 27.316855234665901],
    },
  },
  {
    type: "Feature",
    properties: { Name: "JRN-15", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.48019476476621, 27.332586482831299],
    },
  },
  {
    type: "Feature",
    properties: { Name: "CBA-15", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.215089489864994, 27.474819623243398],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MKM-56", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.396522111833903, 27.450265721578901],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-213", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.303833652422597, 27.306563035270699],
    },
  },
  {
    type: "Feature",
    properties: { Name: "HJN-48", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.449320722227995, 27.476676362214601],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-556", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.391608409062698, 27.3061411789216],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-589", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.272963765413294, 27.308811574009901],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-626", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.470187190056905, 27.364979668961599],
    },
  },
  {
    type: "Feature",
    properties: { Name: "WATER WELL-50", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.134327038897979, 27.432293879248199],
    },
  },
  {
    type: "Feature",
    properties: { Name: "URIAMGURI-1", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.187085848744502, 27.356907118602798],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-379", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.453383889261318, 27.367850333907501],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-350", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.408535293028706, 27.303400233537399],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-1", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.326553634432102, 27.314006534835901],
    },
  },
  {
    type: "Feature",
    properties: { Name: "WATER WELL", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.275277950108901, 27.3345334580405],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-121", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.268496789563599, 27.329401877534099],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-487", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.460657596713602, 27.399984130930498],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-395", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.419345202115906, 27.3054874129643],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-250", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.4023382301136, 27.297487688735401],
    },
  },
  {
    type: "Feature",
    properties: { Name: "WATER WELL-45", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.471480551118802, 27.4631351768932],
    },
  },
  {
    type: "Feature",
    properties: { Name: "CBA-11", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.206207073867603, 27.460754409880298],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-172", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.295878643607281, 27.3275376014385],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-168", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.310155450123304, 27.3130649053384],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-297", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.308105497069405, 27.331227475819301],
    },
  },
  {
    type: "Feature",
    properties: { Name: "WATER WELL-67", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.461543723149802, 27.454256265531399],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-155", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.300995423390106, 27.32964414169],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-298", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.478280208961806, 27.373470511966602],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-203", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.322796410828701, 27.351545762445799],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-630", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.282610983384799, 27.422410836012201],
    },
  },
  {
    type: "Feature",
    properties: { Name: "HJN-3", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.464062560493616, 27.457055414453301],
    },
  },
  {
    type: "Feature",
    properties: { Name: "HJN-24", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.446166096829501, 27.470392434236299],
    },
  },
  {
    type: "Feature",
    properties: { Name: "KHARIKATIYA-2", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.271566951305999, 27.441300244290499],
    },
  },
  {
    type: "Feature",
    properties: { Name: "DIGHOLIA-1", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.230116020973497, 27.301122004379],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-194", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.310925617196105, 27.367971078795701],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-416", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.44849369555439, 27.369587445386799],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-322", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.315842975849705, 27.316216612808599],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-611", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.282667439443898, 27.422200238471],
    },
  },
  {
    type: "Feature",
    properties: { Name: "HJN-13", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.472207227972604, 27.459790885628902],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-9", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.237399531257296, 27.3447207836046],
    },
  },
  {
    type: "Feature",
    properties: { Name: "HJN-59", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.460747893192888, 27.4738678511663],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-96", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.341374994704381, 27.357642182305302],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MKM-18", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.421835502466493, 27.479256033742299],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-413", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.42945284952232, 27.388491465915799],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MKM-21", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.432601668656716, 27.481284067852499],
    },
  },
  {
    type: "Feature",
    properties: { Name: "WATER WELL", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.256717779728888, 27.318049622318512],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-529", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.482160641086296, 27.372358198475599],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-207", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.266955589071998, 27.314830743058501],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-22", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.372734671775703, 27.347950991083302],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-560", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.288133413033606, 27.3239857448159],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-333", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.388411267085587, 27.301045681465698],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-279", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.471778373689887, 27.3736983687348],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-349", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.271583672598396, 27.3276078019925],
    },
  },
  {
    type: "Feature",
    properties: { Name: "CBA-7", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.215218538376405, 27.474748007649801],
    },
  },
  {
    type: "Feature",
    properties: { Name: "JRN-11", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.483505667474205, 27.330800941057699],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-515", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.139635238414598, 27.4205782262027],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-190", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.250850242674403, 27.333988761810399],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-31", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.328958317715291, 27.314778360257101],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-278", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.432604446984996, 27.3884840667899],
    },
  },
  {
    type: "Feature",
    properties: { Name: "HJN-51", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.447978306349299, 27.453121943330899],
    },
  },
  {
    type: "Feature",
    properties: { Name: "JRN-13", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.485380211684102, 27.348931773371699],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-176", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.315741897161487, 27.316343514452509],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-208", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.256766453556907, 27.317910795327101],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-562", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.474318466661487, 27.355315365048899],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-97", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.369593739981596, 27.3701046137239],
    },
  },
  {
    type: "Feature",
    properties: { Name: "KAMKHAT-6", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.162137515709205, 27.4281541943121],
    },
  },
  {
    type: "Feature",
    properties: { Name: "HJN-62", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.464666140616998, 27.436217686626001],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-165", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.317458885445404, 27.307105782031201],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-306", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.313768027483903, 27.314287431598402],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-126", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.261774958278508, 27.331274022822502],
    },
  },
  {
    type: "Feature",
    properties: { Name: "LOHALI-4", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.271302950291698, 27.441192585751001],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-266", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.272649254854599, 27.308641658572199],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-474", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.451930149579297, 27.382967189458501],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-642", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.481779360436079, 27.359007588399301],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-253", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.280808323617691, 27.311096012716298],
    },
  },
  {
    type: "Feature",
    properties: { Name: "HJN-22", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.465207149514498, 27.460271463145499],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-24", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.440674283954294, 27.340318848457301],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-51", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.324719699052196, 27.358434233391201],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-472", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.474509694625596, 27.3553822512586],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-131", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.335892899905787, 27.317785455326199],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MKM-66", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.437055340503704, 27.4700200711005],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-240", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.291548531224507, 27.3359330699626],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-143", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.336183247819307, 27.313442561798901],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-500", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.194868456464192, 27.378732922982799],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MATIMEKHENA-4", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.244165063313304, 27.483653547741199],
    },
  },
  {
    type: "Feature",
    properties: { Name: "KAMKHAT-9", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.149539299031417, 27.417451712863699],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-256", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.394465992384809, 27.3039716737505],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-15", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.162293368607294, 27.3833195758037],
    },
  },
  {
    type: "Feature",
    properties: { Name: "CBA-12", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.199523559905401, 27.4502201418689],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-204", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.250520691533595, 27.325207546684901],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-410", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.436642483927798, 27.361563320741102],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-125", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.323053636646705, 27.3713434619944],
    },
  },
  {
    type: "Feature",
    properties: { Name: "KAMKHAT-1", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.161367222077104, 27.420385512561399],
    },
  },
  {
    type: "Feature",
    properties: { Name: "HJN-46", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.415628581468397, 27.460104896384308],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-468", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.484655962555493, 27.374136629120908],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-582", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.439219164102113, 27.420002739695502],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-605", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.379098897927193, 27.340677204413701],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-166", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.3358877104762, 27.329348552239299],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-495", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.130028001418793, 27.4223816792503],
    },
  },
  {
    type: "Feature",
    properties: { Name: "HJN-18", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.461669807684402, 27.4545593187059],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-21", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.341800750208805, 27.3625827446449],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-505", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.453053450908996, 27.400467544585709],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MKM-11", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.424755621420999, 27.478499065556399],
    },
  },
  {
    type: "Feature",
    properties: { Name: "HJN-4", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.47570536381059, 27.454756810060399],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-115", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.284899288055982, 27.319994502656701],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-616", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.241154000921199, 27.346292787451201],
    },
  },
  {
    type: "Feature",
    properties: { Name: "HJN-60", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.464669804201193, 27.4363524911012],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-303", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.310110664728697, 27.313154874207999],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-293", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.298854101090683, 27.328448249201799],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-590", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.352439377462503, 27.399065976753999],
    },
  },
  {
    type: "Feature",
    properties: { Name: "HJN-1", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.481475912251, 27.460107611437099],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-276", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.317274106091702, 27.320921697381898],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MKM-30", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.424389220161203, 27.461431398899101],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-158", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.326826660694294, 27.317807473409399],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-531", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.277031403072797, 27.424579098631298],
    },
  },
  {
    type: "Feature",
    properties: { Name: "WATER WELL-139", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.444336977816207, 27.395504842569],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-620", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.213393777167596, 27.341769118375801],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-316", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.475738235918186, 27.395767487474501],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-127", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.298061326693102, 27.322690844750898],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-181", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.369294837395401, 27.334462152588301],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-541", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.455388378918485, 27.404267402467902],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-209", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.293462520146704, 27.299720989945499],
    },
  },
  {
    type: "Feature",
    properties: { Name: "JRN-38", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.487012499331811, 27.341908434350898],
    },
  },
  {
    type: "Feature",
    properties: { Name: "CBA-10", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.206328500767398, 27.460673360032899],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-591", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.310265828525104, 27.313020766666298],
    },
  },
  {
    type: "Feature",
    properties: { Name: "WATER WELL-101", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.317751264869202, 27.3101112317546],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-389", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.481847043455886, 27.365843138269501],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NKD", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.308689814938987, 27.305304963881301],
    },
  },
  {
    type: "Feature",
    properties: { Name: "HJN-42", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.460783504976192, 27.473736141439399],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-588", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.432608698656395, 27.3406109985821],
    },
  },
  {
    type: "Feature",
    properties: { Name: "TAMULIKHAT-2", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.145488508178502, 27.389105318647999],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-35", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.320690344197601, 27.309417912677699],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-264", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.394924796287199, 27.308192133049719],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-217", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.243767431444482, 27.3292308821188],
    },
  },
  {
    type: "Feature",
    properties: { Name: "TAMULIKHAT-5", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.139762919674382, 27.396930430104899],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-246", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.253571431978202, 27.313771431173102],
    },
  },
  {
    type: "Feature",
    properties: { Name: "HJN-63", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.463831162840194, 27.4434189631938],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-34", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.314427227974392, 27.311872764678299],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-178", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.340093623826107, 27.331229859565099],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-45", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.3334051320425, 27.3744990841125],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MKM-28", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.411735702297705, 27.446771882306699],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-332", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.4401430310416, 27.366177378020101],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-104", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.340836086231803, 27.311426042359098],
    },
  },
  {
    type: "Feature",
    properties: { Name: "WATER WELL", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.317718039381887, 27.355200368520599],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-29", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.277730914376207, 27.316410665580801],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-559", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.308183313676906, 27.325766755019],
    },
  },
  {
    type: "Feature",
    properties: { Name: "WATER WELL-56", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.463768851091984, 27.456941260517102],
    },
  },
  {
    type: "Feature",
    properties: { Name: "JRN-42", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.482084206032994, 27.3484965031321],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-242", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.313282032347715, 27.319758248308599],
    },
  },
  {
    type: "Feature",
    properties: { Name: "HJN-36", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.423938230309901, 27.4613671712067],
    },
  },
  {
    type: "Feature",
    properties: { Name: "CBA-17", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.214604018796706, 27.457155545133809],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-73", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.349336091436697, 27.375758382979608],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-185", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.264784395601211, 27.318156457480399],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-18", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.277130519518906, 27.323268477791402],
    },
  },
  {
    type: "Feature",
    properties: { Name: "DULIPARA-1", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.167409933691616, 27.450565878210099],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-444", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.482314494716604, 27.372291228765199],
    },
  },
  {
    type: "Feature",
    properties: { Name: "HJN-19", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.465230071305001, 27.460514362487402],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-192", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.336110563566606, 27.329320104468099],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MAHAKALI-1", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.381321191673194, 27.429329103452702],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-137", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.314795875172081, 27.333025353266699],
    },
  },
  {
    type: "Feature",
    properties: { Name: "BAZALONI-7", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.455001299920696, 27.424194235588899],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-400", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.465359106734894, 27.4200182085991],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-380", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.481801831324788, 27.358875566940998],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-311", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.250391612107506, 27.325132034750698],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-26", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.402121631244199, 27.3118074706397],
    },
  },
  {
    type: "Feature",
    properties: { Name: "LOHALI-1", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.263652326633391, 27.413492907349699],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-198", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.371198441311094, 27.338162530163999],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-396", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.461076915753296, 27.386318753514299],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MATIMEKHENA-3", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.235614948386996, 27.480172853083399],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-491", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.234776082112006, 27.347207850247599],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-62", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.318995648588896, 27.3501739194691],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-431", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.483198549753382, 27.362750384697701],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-386", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.465510436208007, 27.3754631889806],
    },
  },
  {
    type: "Feature",
    properties: { Name: "JRN-2", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.482533089295714, 27.340397511296398],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-547", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.197746186290985, 27.3843369961353],
    },
  },
  {
    type: "Feature",
    properties: { Name: "HJN-41", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.443979399734104, 27.463396230800399],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-537", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.306469605652296, 27.4192978301304],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-359", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.445978939813116, 27.377367311755201],
    },
  },
  {
    type: "Feature",
    properties: { Name: "KAMKHAT-5", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.162287838135285, 27.428139410485802],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NORTH MAKUM-2", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.422008140938587, 27.494978984548901],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-82", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.299501558700399, 27.325910048400999],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-42", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.320225324495397, 27.363747735589399],
    },
  },
  {
    type: "Feature",
    properties: { Name: "WATER WELL-13", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.314692396006905, 27.311652925074199],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-128", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.261785486099399, 27.331189313966998],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-471", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.4388948708649, 27.379878090058501],
    },
  },
  {
    type: "Feature",
    properties: { Name: "TAMULIKHAT-6", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.162960462574205, 27.387844856556399],
    },
  },
  {
    type: "Feature",
    properties: { Name: "HJN-25", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.476675981904719, 27.453680627025999],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-621", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.243948873468796, 27.339077247817301],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-426", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.456597825168899, 27.389891191553101],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-170", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.322361876526898, 27.305396083648098],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-259", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.258525023790696, 27.311381923547401],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-511", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.348720921429404, 27.4061185201525],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-269", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.450920209852384, 27.370353433141108],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-28", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.377424583967198, 27.357823665094699],
    },
  },
  {
    type: "Feature",
    properties: { Name: "SEALKATI-4", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.148643651591001, 27.449618814382699],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-425", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.392106321673694, 27.301321160215601],
    },
  },
  {
    type: "Feature",
    properties: { Name: "SEALKATI-3", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.148794444714213, 27.449626683100998],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-117", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.277049059036017, 27.332844508501701],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-164", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.254884096656298, 27.334447607762801],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MKM-59", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.401675919795281, 27.4733424311232],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-233", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.356927771296299, 27.341614499019101],
    },
  },
  {
    type: "Feature",
    properties: { Name: "HJN-47", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.447990017927907, 27.452851726736402],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-94", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.358478791312805, 27.3729454627777],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-139", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.352847895796401, 27.367982349969701],
    },
  },
  {
    type: "Feature",
    properties: { Name: "KHARIKATIYA-4", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.2066352023935, 27.439238857684408],
    },
  },
  {
    type: "Feature",
    properties: { Name: "JRN-9", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.478115247567501, 27.3377020972442],
    },
  },
  {
    type: "Feature",
    properties: { Name: "WATER WELL-129", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.16310823946101, 27.3881046689357],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-378", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.482742582847195, 27.362748394683699],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-533", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.475784160523105, 27.378087680719599],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-634", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.470011664436399, 27.364933790123299],
    },
  },
  {
    type: "Feature",
    properties: { Name: "HJN-17", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.461701111685187, 27.4546356270952],
    },
  },
  {
    type: "Feature",
    properties: { Name: "BOGAPANI-1", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.501875652948897, 27.432235461280399],
    },
  },
  {
    type: "Feature",
    properties: { Name: "KAMKHAT-4", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.149539670940797, 27.4173159179499],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-109", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.332029136282898, 27.327839062143308],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-572", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.394898255594399, 27.342043444463499],
    },
  },
  {
    type: "Feature",
    properties: { Name: "WATER WELL-159", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.411405803118498, 27.472977409965399],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-430", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.477848134216302, 27.392500902820402],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-576", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.353668720491498, 27.394637253994901],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-89", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.359414075837989, 27.382518224085],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-341", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.462582902716306, 27.372902206113402],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MKM-37", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.415604475378899, 27.4602004917686],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-2", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.317477961571996, 27.321033797630101],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-225", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.376627761035309, 27.382446455782699],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MKM-61", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.405474425208297, 27.4395275053372],
    },
  },
  {
    type: "Feature",
    properties: { Name: "SOUTH CHANDMARI-1", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.397754946348897, 27.5136751439344],
    },
  },
  {
    type: "Feature",
    properties: { Name: "WATER WELL-88", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.436556323656802, 27.469863026660899],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-189", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.255618855255094, 27.323219537172101],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-162", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.339813436386194, 27.316515502529001],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-417", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.4600775375784, 27.370879598159998],
    },
  },
  {
    type: "Feature",
    properties: { Name: "SOUTH CHANDMARI-2", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.412981782129094, 27.5117161052288],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-99", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.34210103006879, 27.3675264899654],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-443", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.272126184486581, 27.308656931607299],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-151", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.269827031681899, 27.324122660558199],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-538", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.440928022251597, 27.3694362948209],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-141", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.299993461994106, 27.317737483606699],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-66", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.401222303567081, 27.3905561611524],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-74", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.372264502037098, 27.385403644779299],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-129", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.328477530855693, 27.330452446529399],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-300", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.269869726789807, 27.3112841574291],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MKM-24", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.421847983962905, 27.479121125808302],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-486", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.327483005781204, 27.365951737039399],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-10", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.260993696249898, 27.344267770516598],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-464", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.392560550508506, 27.337191668334],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-558", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.276999212584101, 27.4247293009836],
    },
  },
  {
    type: "Feature",
    properties: { Name: "WATER WELL-21", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.297868485698601, 27.3226111942353],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MKM-26", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.421860463468803, 27.478986127692298],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MKM-3", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.436712519859199, 27.474284130176098],
    },
  },
  {
    type: "Feature",
    properties: { Name: "SOUTH CHANDMARI-5", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.3977035347596, 27.513582567524299],
    },
  },
  {
    type: "Feature",
    properties: { Name: "JRN-45", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.489944005047903, 27.339525330853299],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MKM-14", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.424088204006694, 27.461390090388601],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-184", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.310650296604194, 27.335993066089198],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NORTH MAKUM-1", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.4221589812377, 27.494997467985101],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-292", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.469986746756305, 27.3648324697043],
    },
  },
  {
    type: "Feature",
    properties: { Name: "HJN-5", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.471564327823302, 27.463312311109998],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-75", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.292540186872415, 27.392797810407099],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-197", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.286135060986794, 27.3161059420271],
    },
  },
  {
    type: "Feature",
    properties: { Name: "JRN-14", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.49056082179932, 27.34344069322],
    },
  },
  {
    type: "Feature",
    properties: { Name: "JRN-20", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.493742588631903, 27.348537691451899],
    },
  },
  {
    type: "Feature",
    properties: { Name: "CBA-18", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.16943391675909, 27.434787315806499],
    },
  },
  {
    type: "Feature",
    properties: { Name: "JRN-37", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.475430794190004, 27.3422468946763],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-4", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.343664639541899, 27.315030981911399],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-44", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.324793341217912, 27.328893021665401],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-175", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.292845953312096, 27.330211506083],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-352", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.404186498580415, 27.3025927041637],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-477", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.419151107906799, 27.3054404324273],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-501", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.292473755871683, 27.392345814829099],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-571", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.197896217201702, 27.384323444233399],
    },
  },
  {
    type: "Feature",
    properties: { Name: "JRN-25", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.479735486145785, 27.327707651498901],
    },
  },
  {
    type: "Feature",
    properties: { Name: "BAZALONI-2", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.445176821030302, 27.427674086038401],
    },
  },
  {
    type: "Feature",
    properties: { Name: "SEALKATI-1", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.148945553903602, 27.449633644527101],
    },
  },
  {
    type: "Feature",
    properties: { Name: "JRN-40", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.485260880447981, 27.3489126077259],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-483", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.33575100121692, 27.362191001525812],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-335", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.376293463582599, 27.297420676968802],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-223", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.296814472877799, 27.331144050846099],
    },
  },
  {
    type: "Feature",
    properties: { Name: "WATER WELL-167", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.360250757349704, 27.368155938233301],
    },
  },
  {
    type: "Feature",
    properties: { Name: "WATER WELL-77", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.394776438349993, 27.308304290291499],
    },
  },
  {
    type: "Feature",
    properties: { Name: "JRN-44", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.487142845910398, 27.341693749169],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-597", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.373994323744782, 27.367021580180701],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MKM-35", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.421748846197801, 27.479180769253698],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-167", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.265248815519612, 27.3307812239577],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-612", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.284135177683495, 27.306203973957999],
    },
  },
  {
    type: "Feature",
    properties: { Name: "HJN-10", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.464140393438399, 27.4570973392008],
    },
  },
  {
    type: "Feature",
    properties: { Name: "HJN-37", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.440849006365994, 27.461997899312099],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-200", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.382878541728587, 27.3361536424378],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-310", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.456408361775999, 27.381007685054598],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-112", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.321794482989702, 27.334627319959999],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-95", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.304578288332394, 27.3322752687763],
    },
  },
  {
    type: "Feature",
    properties: { Name: "HJN-66", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.481477256397497, 27.4600112403444],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-391", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.440822594603105, 27.3971251455275],
    },
  },
  {
    type: "Feature",
    properties: { Name: "KAMKHAT-8", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.161963743115095, 27.428240890135498],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-494", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.396259984390397, 27.3622480211584],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-237", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.290294487165696, 27.314173298268901],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NORTH BALIJAN-4", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.246606522687614, 27.387849517302801],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-585", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.401058321238395, 27.390416532948109],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-356", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.452118185980382, 27.380828863626402],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-315", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.272856975333298, 27.3210031338661],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-144", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.336182438150217, 27.313360204645502],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MKM-9", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.424609796992996, 27.4784647991046],
    },
  },
  {
    type: "Feature",
    properties: { Name: "HJN-50", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.436978435791303, 27.4699512629334],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MKM-54", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.382229635400108, 27.444273946107408],
    },
  },
  {
    type: "Feature",
    properties: { Name: "CBA-3", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.192020785969902, 27.4484724444658],
    },
  },
  {
    type: "Feature",
    properties: { Name: "HJN-45", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.447994072427605, 27.452718138640201],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-309", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.457132781372593, 27.371518251045298],
    },
  },
  {
    type: "Feature",
    properties: { Name: "HJN-65", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.466344476779696, 27.4675289077122],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-564", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.31790805904852, 27.3758609159763],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-71", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.347900573618389, 27.362372432669101],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-102", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.370908792796598, 27.381296349515502],
    },
  },
  {
    type: "Feature",
    properties: { Name: "SOUTH CHANDMARI-3", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.397821148140807, 27.513522440358098],
    },
  },
  {
    type: "Feature",
    properties: { Name: "WATER WELL-136", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.317252735366921, 27.307242727278201],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MKM-27", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.436826632076205, 27.469948147349001],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-79", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.298963717926, 27.314095062677001],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-436", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.453800640194999, 27.383081251432699],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-326", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.296312370082305, 27.3117203736692],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-152", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.309315066673193, 27.322242973050699],
    },
  },
  {
    type: "Feature",
    properties: { Name: "WATER WELL-131", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.436647053373207, 27.474470666856998],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-191", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.326552338906396, 27.306108588834501],
    },
  },
  {
    type: "Feature",
    properties: { Name: "JRN-46", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.490032785614503, 27.339508862197899],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-469", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.4467572640866, 27.3928179251788],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-485", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.452262865693896, 27.388888619189899],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-375", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.459272265811606, 27.373986889372102],
    },
  },
  {
    type: "Feature",
    properties: { Name: "HJN-9", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.475985849146895, 27.4543499232737],
    },
  },
  {
    type: "Feature",
    properties: { Name: "CBA-6", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.215346596727684, 27.474676949039399],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-238", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.299381446740597, 27.332490671322208],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-13", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.303121832829589, 27.320266263708898],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-138", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.303954871798894, 27.324768624807898],
    },
  },
  {
    type: "Feature",
    properties: { Name: "WATER WELL-81", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.437185226309992, 27.394558828487298],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MKM-50", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.405343828830979, 27.439596701013599],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-481", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.451975699591202, 27.3830370267024],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-637", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.353746784218302, 27.394532726507698],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-17", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.2588654399961, 27.3336963998164],
    },
  },
  {
    type: "Feature",
    properties: { Name: "CBA-9", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.179165317598205, 27.444690362545899],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-214", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.390636580033799, 27.366471733045898],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-305", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.289898353466199, 27.308660758667099],
    },
  },
  {
    type: "Feature",
    properties: { Name: "BAZALONI-3", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.445290616363593, 27.427763746550401],
    },
  },
  {
    type: "Feature",
    properties: { Name: "JENGONI-3", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.429856722737981, 27.4388497585004],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-255", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.329636932276799, 27.308584715025098],
    },
  },
  {
    type: "Feature",
    properties: { Name: "WATER WELL-35", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.445241404246588, 27.365880714319999],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-308", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.339106870585013, 27.360251373374901],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MKM-6", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.424221617926506, 27.485589951220799],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-569", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.139687696007201, 27.433394688642402],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-254", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.302095959529609, 27.300683809638901],
    },
  },
  {
    type: "Feature",
    properties: { Name: "HJN-49", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.447984357770594, 27.452988139698601],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-382", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.426772576486101, 27.390805523214699],
    },
  },
  {
    type: "Feature",
    properties: { Name: "HJN-29", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.446269863123788, 27.470294081365999],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-633", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.434300696060603, 27.365356618490001],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-550", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.304626664753201, 27.361103660386],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-114", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.313858806318194, 27.327181785560999],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-377", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.474735694883506, 27.363189422683],
    },
  },
  {
    type: "Feature",
    properties: { Name: "HJN-11", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.472122349234695, 27.4597623520993],
    },
  },
  {
    type: "Feature",
    properties: { Name: "WATER WELL-165", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.278851052274604, 27.320211761544702],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-277", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.477006495487103, 27.351287531297299],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NORTH BALIJAN-1", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.246351536680095, 27.387846226427602],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-16", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.319550105458504, 27.340470919024199],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-580", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.455237738236605, 27.404339160065401],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-179", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.298965792176105, 27.303336234861401],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-149", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.278895633911503, 27.319898595955799],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-59", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.342100368387705, 27.372445510828499],
    },
  },
  {
    type: "Feature",
    properties: { Name: "LOHALI-2", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.263661330312303, 27.4137913037098],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-247", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.296034219498097, 27.297353938590302],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-339", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.477747978434095, 27.3598848372785],
    },
  },
  {
    type: "Feature",
    properties: { Name: "JRN-41", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.487100712652307, 27.341765768609498],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-434", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.468969043285099, 27.376869346007201],
    },
  },
  {
    type: "Feature",
    properties: { Name: "HJN-6", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.482344438601302, 27.463300808149398],
    },
  },
  {
    type: "Feature",
    properties: { Name: "KASOMARI-1", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.406146846963907, 27.424130470825101],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-338", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.419222799727592, 27.305477713787901],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-613", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.290870083883505, 27.425473695418301],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-271", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.262901405200495, 27.313012350496699],
    },
  },
  {
    type: "Feature",
    properties: { Name: "BAZALONI-6", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.445286577898287, 27.427663769633298],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MKM-52", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.415449262903806, 27.460285280945602],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-46", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.340522034515203, 27.3200740733499],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-418", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.45647315520938, 27.3733225215673],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-257", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.341076378909619, 27.383614931498499],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-432", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.457727137957704, 27.385833115959599],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-420", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.4443995777564, 27.395273607091902],
    },
  },
  {
    type: "Feature",
    properties: { Name: "WATERWELL-160", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.463435455177589, 27.443531784221499],
    },
  },
  {
    type: "Feature",
    properties: { Name: "JRN-12", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.496749774172201, 27.351629938192001],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-638", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.388276975977305, 27.3010600657047],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-371", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.479172612257415, 27.3957772769153],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-57", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.317216383419279, 27.357378129768399],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-110", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.336165657568813, 27.362470867342498],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-636", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.463201721545303, 27.393344463617399],
    },
  },
  {
    type: "Feature",
    properties: { Name: "JRN-17", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.487746121386195, 27.352771515319599],
    },
  },
  {
    type: "Feature",
    properties: { Name: "WATER WELL-74", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.474900318323407, 27.389860199856599],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-251", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.275410743840681, 27.334862859527199],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-346", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.451199694836603, 27.364857777335001],
    },
  },
  {
    type: "Feature",
    properties: { Name: "HJN-67", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.481082288046593, 27.480114666497599],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-342", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.445384949329593, 27.3680237835128],
    },
  },
  {
    type: "Feature",
    properties: { Name: "JRN-24", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.477156788669802, 27.329675610517999],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-623", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.394807411425802, 27.342150827678498],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-140", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.255482246731802, 27.331406289162601],
    },
  },
  {
    type: "Feature",
    properties: { Name: "TAMULIKHAT-1", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.145319505166498, 27.389052429567599],
    },
  },
  {
    type: "Feature",
    properties: { Name: "EAST KATHALONI-1", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.13926192461112, 27.351837018763],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-142", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.27960137005438, 27.328630474799201],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-586", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.412352118525007, 27.338461495463399],
    },
  },
  {
    type: "Feature",
    properties: { Name: "WATER WELL-61", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.398370587533705, 27.303280678843802],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-274", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.478394012318205, 27.363510876321101],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-90", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.375508185603906, 27.387275027563501],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-107", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.369134097049212, 27.373730700792802],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-3", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.329598859043301, 27.3084568763527],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-447", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.310234669762991, 27.312908054802399],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-289", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.423345329891305, 27.294034832839198],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-644", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.469023013530602, 27.3770139961428],
    },
  },
  {
    type: "Feature",
    properties: { Name: "WATER WELL-157", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.4569812278217, 27.371524077838799],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-448", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.448113501689505, 27.400317660110801],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-312", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.460132553891199, 27.361913742806301],
    },
  },
  {
    type: "Feature",
    properties: { Name: "JRN-26", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.482841237195203, 27.3270695070232],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-609", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.263651327381297, 27.4136278947874],
    },
  },
  {
    type: "Feature",
    properties: { Name: "WATER WELL-111", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.436785367567893, 27.476490667981299],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-433", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.421930852868883, 27.333780971663298],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-132", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.28522859818132, 27.327362836267799],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-607", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.231844492100706, 27.3600110451424],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MKM-5", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.436702155316894, 27.474014119926402],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MKM-62", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.382294965976897, 27.444484837710601],
    },
  },
  {
    type: "Feature",
    properties: { Name: "JRN-1", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.490161967877, 27.339484636214099],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-421", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.481842569992494, 27.3514259826418],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-248", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.401903843045503, 27.305483729288898],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MKM-20", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.432455632440707, 27.4812442203547],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-234", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.243813556908194, 27.329320273890701],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-563", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.304587474758989, 27.360973939004801],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MKM-10", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.423917766832801, 27.485570797549101],
    },
  },
  {
    type: "Feature",
    properties: { Name: "HJN-44", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.441298754326894, 27.461948603789399],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-218", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.333003897380607, 27.3215682037989],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MKM-22", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.436962296996413, 27.490878081068601],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-156", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.326502371729902, 27.328753321160601],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-353", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.377151418391705, 27.297936210833509],
    },
  },
  {
    type: "Feature",
    properties: { Name: "HJN-23", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.446062997427404, 27.470490775315099],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-220", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.332939243232602, 27.3215082047981],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-302", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.384554837266904, 27.358912282858199],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-188", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.31528039678669, 27.3401282178142],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-87", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.336182689664895, 27.3130224174392],
    },
  },
  {
    type: "Feature",
    properties: { Name: "JRN-48", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.486958787999797, 27.341835145328499],
    },
  },
  {
    type: "Feature",
    properties: { Name: "WATER WELL-123", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.444183209374302, 27.399055686204701],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-64", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.3534267485377, 27.3724104382026],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-548", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.329173977649504, 27.353299365141002],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-600", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.373811593280095, 27.3670233359178],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-404", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.471897153522505, 27.360347745531499],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-317", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.300872318114784, 27.329641839295999],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-47", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.318248795832204, 27.3538002704191],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-230", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.284504900125398, 27.312344920033599],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-405", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.477830695534806, 27.3599282950155],
    },
  },
  {
    type: "Feature",
    properties: { Name: "WATER WELL-96", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.332249761273602, 27.3314130437806],
    },
  },
  {
    type: "Feature",
    properties: { Name: "WATER WELL-89", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.483474728372101, 27.346294008918299],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-324", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.388422387343184, 27.300903758981001],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-228", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.4119271883133, 27.343330521431799],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-262", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.472013382867686, 27.360388087167099],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-624", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.394717510449297, 27.3422594577046],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-619", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.336711791966593, 27.4225749664853],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-570", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.139836550218206, 27.433376703098698],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-631", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.408519979165902, 27.3816112994643],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-195", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.367805427298904, 27.384829763912801],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-390", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.434852670780117, 27.391821675751501],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-222", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.310434705023795, 27.329283321107901],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-108", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.327119941189281, 27.370343726608802],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-452", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.283957146419098, 27.306137864166001],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-457", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.458310650162602, 27.385401800584098],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-133", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.287002746767996, 27.3371728948498],
    },
  },
  {
    type: "Feature",
    properties: { Name: "HJN-16", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.461732747215805, 27.454711839399],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-235", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.380360190093711, 27.384877225429499],
    },
  },
  {
    type: "Feature",
    properties: { Name: "WATER WELL-100", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.339358630539294, 27.326696101869501],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-113", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.327536121649402, 27.366231794766399],
    },
  },
  {
    type: "Feature",
    properties: { Name: "WATER WELL-135", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.347837222950204, 27.371795408938901],
    },
  },
  {
    type: "Feature",
    properties: { Name: "KAMKHAT-3", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.161669371620107, 27.420365758443101],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-244", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.24552846890721, 27.3276537211886],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MKM-39", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.411434049468909, 27.446772144589001],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MKM-36", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.424326984061807, 27.461350838360701],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-263", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.130083620293107, 27.423072230706101],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-595", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.243839204312494, 27.339069778443399],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MKM-57", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.396279857115402, 27.4502864907044],
    },
  },
  {
    type: "Feature",
    properties: { Name: "HJN-58", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.443923654240592, 27.463311867048802],
    },
  },
  {
    type: "Feature",
    properties: { Name: "JRN-10", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.496426227938599, 27.3389244940239],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-182", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.325236193535503, 27.3095056365215],
    },
  },
  {
    type: "Feature",
    properties: { Name: "WATER WELL-75", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.473072181291414, 27.393174339810699],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-629", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.306703181008317, 27.4193589221357],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-196", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.343856486738005, 27.3188971871331],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MKM-17", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.409782780928396, 27.472594052423499],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-394", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.455060471913797, 27.3867704140428],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MKM-33", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.401914743145099, 27.473176642840901],
    },
  },
  {
    type: "Feature",
    properties: { Name: "WATER WELL-122", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.440907403310504, 27.397251675706698],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-227", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.284537732396601, 27.312438935581799],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MKM-8", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.424463845367498, 27.478432339005501],
    },
  },
  {
    type: "Feature",
    properties: { Name: "KHARIKATIYA-1", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.271382142091596, 27.441284311957101],
    },
  },
  {
    type: "Feature",
    properties: { Name: "HJN-31", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.445100793527402, 27.469127590488],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-328", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.448785885860701, 27.367074342183098],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-48", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.332006098803305, 27.364152303020902],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-78", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.385093616036215, 27.377270342348801],
    },
  },
  {
    type: "Feature",
    properties: { Name: "JENGONI-2", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.42996970622049, 27.438906156347109],
    },
  },
  {
    type: "Feature",
    properties: { Name: "WATER WELL-73", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.423642808706404, 27.4613261251538],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-93", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.330283261567814, 27.372265739796699],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MKM-42", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.411282926338288, 27.446772055083699],
    },
  },
  {
    type: "Feature",
    properties: { Name: "JRN-5", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.487056389727101, 27.345741372540601],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-307", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.438915260814198, 27.379929877893709],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-604", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.317429802267995, 27.457444159615399],
    },
  },
  {
    type: "Feature",
    properties: { Name: "HJN-40", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.441150595240217, 27.461962749622799],
    },
  },
  {
    type: "Feature",
    properties: { Name: "HJN-30", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.445210161584896, 27.469222468578408],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-92", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.281264190805601, 27.335176241772],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-561", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.463321500171205, 27.393423089119199],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MKM-23", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.415498129835001, 27.460173829123701],
    },
  },
  {
    type: "Feature",
    properties: { Name: "WATER WELL-127", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.148713846450704, 27.449694332364899],
    },
  },
  {
    type: "Feature",
    properties: { Name: "HJN-68", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.461705939564695, 27.432870423656901],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-201", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.27055019261141, 27.340680724505301],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NEW WELL", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.409320419815799, 27.472557311244501],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-268", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.194736560825604, 27.378803388156712],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-193", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.296177008882594, 27.3017250777134],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-245", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.281508119305897, 27.3319449390352],
    },
  },
  {
    type: "Feature",
    properties: { Name: "JRN-22", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.483148063081998, 27.343793319808508],
    },
  },
  {
    type: "Feature",
    properties: { Name: "JRN-27", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.488067589069999, 27.3320136696099],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-7", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.288994826870606, 27.3331116001611],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-219", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.251297934119506, 27.320250990524102],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MATIMEKHENA-2", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.235711128434303, 27.480023344246799],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-81", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.308636557170402, 27.325976876411399],
    },
  },
  {
    type: "Feature",
    properties: { Name: "JRN-23", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.481255302935082, 27.336324784077199],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-357", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.437357798371295, 27.3944570262844],
    },
  },
  {
    type: "Feature",
    properties: { Name: "KAMKHAT-7", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.161956718712716, 27.428171435795701],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-446", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.448031640731898, 27.400283733521601],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-273", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.388297888405006, 27.3008814375982],
    },
  },
  {
    type: "Feature",
    properties: { Name: "WATER WELL-116", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.328331555414593, 27.3304162662021],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-365", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.391361028479196, 27.298640731579699],
    },
  },
  {
    type: "Feature",
    properties: { Name: "BOGAPANI-4", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.491180109953419, 27.463436680238999],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-470", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.477331742871598, 27.354166981634101],
    },
  },
  {
    type: "Feature",
    properties: { Name: "WATER WELL-132", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.409829991869103, 27.472931091269398],
    },
  },
  {
    type: "Feature",
    properties: { Name: "BORPATHAR-1", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.44658043281629, 27.501318761851799],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-63", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.313403065552393, 27.3583433231817],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-384", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.440673701535999, 27.392188934219998],
    },
  },
  {
    type: "Feature",
    properties: { Name: "DIGHOLIYAGAON-1", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.209437864211495, 27.300982883446],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-557", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.282838196179796, 27.440205274138901],
    },
  },
  {
    type: "Feature",
    properties: { Name: "HJN-2", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.467938209412608, 27.458239461827599],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-60", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.311481975029594, 27.352734853714001],
    },
  },
  {
    type: "Feature",
    properties: { Name: "JENGONI-1", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.429893410091097, 27.438739146459699],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-58", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.315095508734913, 27.3515076062208],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-124", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.261796988713499, 27.331107476438898],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MKM-49", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.382416151320385, 27.444485110194201],
    },
  },
  {
    type: "Feature",
    properties: { Name: "JRN-47", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.479013064048004, 27.342956011165398],
    },
  },
  {
    type: "Feature",
    properties: { Name: "HJN-57", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.463748523941902, 27.443483033463],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NORTH BALIJAN-2", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.246495166039381, 27.387891460030701],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-348", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.392164894941999, 27.301224977796899],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-239", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.267373769398503, 27.335480516018301],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-281", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.333047702921405, 27.370032801370101],
    },
  },
  {
    type: "Feature",
    properties: { Name: "HJN-38", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.461791775709798, 27.432931875853601],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-645", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.198071713504589, 27.384299295538401],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-6", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.310969272806119, 27.332304083156998],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-363", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.458673399153099, 27.383359898937801],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-43", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.328029636000906, 27.3607944178219],
    },
  },
  {
    type: "Feature",
    properties: { Name: "LOHALI-3", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.263733937364194, 27.413561503414101],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MKM-38", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.409702877601404, 27.472521820326001],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-159", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.326493743152909, 27.328671274466299],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-587", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.272904218464006, 27.308971604362899],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-180", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.304186933920704, 27.318855868159108],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-496", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.352394450313696, 27.3989757947875],
    },
  },
  {
    type: "Feature",
    properties: { Name: "JRN-33", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.481870465695195, 27.3489292705103],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-30", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.388047910399095, 27.3435033161762],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-39", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.303204730853693, 27.305715309695401],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-592", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.372550678817802, 27.348744248300001],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MKM-46", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.401794747836107, 27.4732595471026],
    },
  },
  {
    type: "Feature",
    properties: { Name: "CBA-13", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.199664022398593, 27.4502663730545],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-55", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.335202691939017, 27.357318499273301],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MKM-40", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.411661210368194, 27.446843991635099],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-252", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.286198241994995, 27.309116878480101],
    },
  },
  {
    type: "Feature",
    properties: { Name: "CBA-19", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.157834353075003, 27.434121615226399],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-461", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.458481001072101, 27.377118739068699],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NHK-169", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.296331250204204, 27.319434252734201],
    },
  },
  {
    type: "Feature",
    properties: { Name: "HJN-64", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.465373899213105, 27.420151102325999],
    },
  },
  {
    type: "Feature",
    properties: { Name: "BOGAPANI-2", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.544300893177706, 27.428209219511999],
    },
  },
  {
    type: "Feature",
    properties: { Name: "BOGAPANI-3", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.541273052500401, 27.388529509268],
    },
  },
  {
    type: "Feature",
    properties: { Name: "N.DUARMARA-1", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.656768104509396, 27.4975818204997],
    },
  },
  {
    type: "Feature",
    properties: { Name: "BOGAPANI-5", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.572442240139296, 27.4264359559557],
    },
  },
  {
    type: "Feature",
    properties: { Name: "BOGAPANI-6", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.554143619656401, 27.439612339112799],
    },
  },
  {
    type: "Feature",
    properties: { Name: "BOGAPANI-7", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.54709570959939, 27.421021058676899],
    },
  },
  {
    type: "Feature",
    properties: { Name: "SAMDANG-1", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.551300366900307, 27.488376684230101],
    },
  },
  {
    type: "Feature",
    properties: { Name: "SAMDANG-2", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.566894390697698, 27.461973808529098],
    },
  },
  {
    type: "Feature",
    properties: { Name: "SAMDANG-3", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.549723783841102, 27.483826820244801],
    },
  },
  {
    type: "Feature",
    properties: { Name: "SAMDANG-4", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.542663078129692, 27.4942437715175],
    },
  },
  {
    type: "Feature",
    properties: { Name: "KJN-1", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.590034919359297, 27.367787463611499],
    },
  },
  {
    type: "Feature",
    properties: { Name: "KJN-10", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.579960821311317, 27.381337419033699],
    },
  },
  {
    type: "Feature",
    properties: { Name: "KJN-11", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.568320433329603, 27.375432425778001],
    },
  },
  {
    type: "Feature",
    properties: { Name: "KJN-2", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.583323282308882, 27.382570942247199],
    },
  },
  {
    type: "Feature",
    properties: { Name: "KJN-3", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.557784654598706, 27.364999674398199],
    },
  },
  {
    type: "Feature",
    properties: { Name: "KJN-4", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.587917994848794, 27.376351978092],
    },
  },
  {
    type: "Feature",
    properties: { Name: "KJN-5", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.568291087591902, 27.375518575469808],
    },
  },
  {
    type: "Feature",
    properties: { Name: "KJN-6", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.557793013989794, 27.366160260819608],
    },
  },
  {
    type: "Feature",
    properties: { Name: "KJN-7", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.568268841393007, 27.3755873643127],
    },
  },
  {
    type: "Feature",
    properties: { Name: "KJN-8", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.579821323171288, 27.381291647298099],
    },
  },
  {
    type: "Feature",
    properties: { Name: "KJN-9", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.577535371781394, 27.3709991342139],
    },
  },
  {
    type: "Feature",
    properties: { Name: "SOUTH DIGBOI-1", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.662205826336404, 27.380755346687199],
    },
  },
  {
    type: "Feature",
    properties: { Name: "TOKLONG-1", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.7950351115594, 27.358959761430501],
    },
  },
  {
    type: "Feature",
    properties: { Name: "DUARMARA-1", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.697840164851598, 27.453197101251099],
    },
  },
  {
    type: "Feature",
    properties: { Name: "DUARMARA-2", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.706838259934699, 27.450638620304112],
    },
  },
  {
    type: "Feature",
    properties: { Name: "DUARMARA-3", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.707061550984093, 27.4501560197995],
    },
  },
  {
    type: "Feature",
    properties: { Name: "GOHAINGAON-1", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.816269738159207, 27.4332128191216],
    },
  },
  {
    type: "Feature",
    properties: { Name: "LAKHI-1", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.511793666111799, 27.319226850867],
    },
  },
  {
    type: "Feature",
    properties: { Name: "DIGBOI-1", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.623993514337201, 27.388414919103401],
    },
  },
  {
    type: "Feature",
    properties: { Name: "JAGUN-1", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.887303248388193, 27.3948814179514],
    },
  },
  {
    type: "Feature",
    properties: { Name: "KHAGORIJAN-1", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.107025282497304, 27.526657492172699],
    },
  },
  {
    type: "Feature",
    properties: { Name: "KHAGORIJAN-2", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.106975977654997, 27.526650119252199],
    },
  },
  {
    type: "Feature",
    properties: { Name: "KHAGORIJAN-3", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.106824822507718, 27.526628771307202],
    },
  },
  {
    type: "Feature",
    properties: { Name: "BAGHJAN-20", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.391803605761098, 27.603381450705299],
    },
  },
  {
    type: "Feature",
    properties: { Name: "BAGHJAN-13", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.402766008283507, 27.612338549293099],
    },
  },
  {
    type: "Feature",
    properties: { Name: "BAGHJAN-21", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.380904878264218, 27.5963441519532],
    },
  },
  {
    type: "Feature",
    properties: { Name: "DISAIJAN-1", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.457522742060192, 27.647762160857202],
    },
  },
  {
    type: "Feature",
    properties: { Name: "BAGHJAN-22", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.419784879293204, 27.615845789039099],
    },
  },
  {
    type: "Feature",
    properties: { Name: "BAREKURI-1", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.400979940269394, 27.5362850365414],
    },
  },
  {
    type: "Feature",
    properties: { Name: "BAREKURI-10", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.381494883415712, 27.545142500158001],
    },
  },
  {
    type: "Feature",
    properties: { Name: "RANGMALA-1", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.200229218584411, 27.5563822996799],
    },
  },
  {
    type: "Feature",
    properties: { Name: "BAGHJAN-1", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.393793571019103, 27.5951384115731],
    },
  },
  {
    type: "Feature",
    properties: { Name: "BAGHJAN-14", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.420244626087509, 27.615831000223899],
    },
  },
  {
    type: "Feature",
    properties: { Name: "BAGHJAN-23", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.403919805438903, 27.6043786325091],
    },
  },
  {
    type: "Feature",
    properties: { Name: "BAGHJAN-24", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.402610122805001, 27.6124138721969],
    },
  },
  {
    type: "Feature",
    properties: { Name: "BAREKURI-11", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.390107366613506, 27.543106382470501],
    },
  },
  {
    type: "Feature",
    properties: { Name: "SOUTH BAGHJAN-1", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.393611355246506, 27.595150396176599],
    },
  },
  {
    type: "Feature",
    properties: { Name: "BAREKURI-12", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.381405156388496, 27.544999783537499],
    },
  },
  {
    type: "Feature",
    properties: { Name: "SOUTH BAGHJAN-2", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.415644241646618, 27.6065954815815],
    },
  },
  {
    type: "Feature",
    properties: { Name: "SOUTH CHANDMARI-4", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.394075422688701, 27.519295737866798],
    },
  },
  {
    type: "Feature",
    properties: { Name: "BAGHJAN-10", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.415795020350203, 27.6065394491911],
    },
  },
  {
    type: "Feature",
    properties: { Name: "BAGHJAN-15", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.420064085503, 27.6158065456274],
    },
  },
  {
    type: "Feature",
    properties: { Name: "BAGHJAN-3", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.3916215583439, 27.603382604660499],
    },
  },
  {
    type: "Feature",
    properties: { Name: "BAREKURI-13", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.4130089797669, 27.529637141673899],
    },
  },
  {
    type: "Feature",
    properties: { Name: "BAREKURI-14", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.390289760104807, 27.5430988238531],
    },
  },
  {
    type: "Feature",
    properties: { Name: "WATER WELL", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.403236715279803, 27.612187314197101],
    },
  },
  {
    type: "Feature",
    properties: { Name: "WATER WELL-149", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.403409086778893, 27.6044400769234],
    },
  },
  {
    type: "Feature",
    properties: { Name: "WATER WELL-152", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.393615759995697, 27.536311232008298],
    },
  },
  {
    type: "Feature",
    properties: { Name: "WATER WELL-153", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.398203036644205, 27.5419450911306],
    },
  },
  {
    type: "Feature",
    properties: { Name: "BAREKURI-15", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.413071415241717, 27.5294845872561],
    },
  },
  {
    type: "Feature",
    properties: { Name: "EAST KHAGORIJAN-1", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.156591325589005, 27.545380593345101],
    },
  },
  {
    type: "Feature",
    properties: { Name: "BAGHJAN-4", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.416016328488197, 27.606386200870599],
    },
  },
  {
    type: "Feature",
    properties: { Name: "BAREKURI-16", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.3904710883277, 27.5430921854799],
    },
  },
  {
    type: "Feature",
    properties: { Name: "EAST KHAGORIJAN-2", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.156688029475603, 27.545241140184999],
    },
  },
  {
    type: "Feature",
    properties: { Name: "EAST KHAGORIJAN-3", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.156783445385216, 27.5451041429993],
    },
  },
  {
    type: "Feature",
    properties: { Name: "BAREKURI-17", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.413133850558793, 27.529332032818299],
    },
  },
  {
    type: "Feature",
    properties: { Name: "BAGHJAN-16", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.419884614561397, 27.615781350419599],
    },
  },
  {
    type: "Feature",
    properties: { Name: "BAGHJAN-5", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.380829568230709, 27.596279406806602],
    },
  },
  {
    type: "Feature",
    properties: { Name: "BAREKURI-18", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.390654147320504, 27.5430845247189],
    },
  },
  {
    type: "Feature",
    properties: { Name: "BAREKURI-19", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.381302731126993, 27.5448682006054],
    },
  },
  {
    type: "Feature",
    properties: { Name: "BAGHJAN-6", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.415854049155996, 27.606435757978399],
    },
  },
  {
    type: "Feature",
    properties: { Name: "BAREKURI-2", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.400828281798496, 27.536273673758799],
    },
  },
  {
    type: "Feature",
    properties: { Name: "BAGHJAN-11", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.403077794304608, 27.6122077498244],
    },
  },
  {
    type: "Feature",
    properties: { Name: "BAGHJAN-17", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.419704513394294, 27.615757880026301],
    },
  },
  {
    type: "Feature",
    properties: { Name: "BAGHJAN-7", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.415690364202206, 27.606485970877401],
    },
  },
  {
    type: "Feature",
    properties: { Name: "BAREKURI-20", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.413196202287082, 27.529179479809301],
    },
  },
  {
    type: "Feature",
    properties: { Name: "BAREKURI-21", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.381205650237106, 27.5447328271365],
    },
  },
  {
    type: "Feature",
    properties: { Name: "BAREKURI-22", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.412963947264089, 27.5295357104598],
    },
  },
  {
    type: "Feature",
    properties: { Name: "BAGHJAN-8", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.403747902746602, 27.604431319384599],
    },
  },
  {
    type: "Feature",
    properties: { Name: "BAREKURI-23", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.381375822993206, 27.5451065603872],
    },
  },
  {
    type: "Feature",
    properties: { Name: "BAREKURI-24", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.390203150738415, 27.5430311188438],
    },
  },
  {
    type: "Feature",
    properties: { Name: "BAGHJAN-18", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.420142191563301, 27.615890434250499],
    },
  },
  {
    type: "Feature",
    properties: { Name: "BAGHJAN-9", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.415959289747505, 27.606489225987708],
    },
  },
  {
    type: "Feature",
    properties: { Name: "BAREKURI-25", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.413026380786505, 27.5293830658796],
    },
  },
  {
    type: "Feature",
    properties: { Name: "BAREKURI-3", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.400677488209496, 27.536259860061001],
    },
  },
  {
    type: "Feature",
    properties: { Name: "BAREKURI-4", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.393536828336408, 27.536096977240899],
    },
  },
  {
    type: "Feature",
    properties: { Name: "BAGHJAN-19", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.419960354821498, 27.615864017447201],
    },
  },
  {
    type: "Feature",
    properties: { Name: "BAREKURI-5", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.393489223100715, 27.535968609021602],
    },
  },
  {
    type: "Feature",
    properties: { Name: "BAREKURI-6", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.397964347809605, 27.541854846277499],
    },
  },
  {
    type: "Feature",
    properties: { Name: "BAREKURI-7", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.393479893324596, 27.53594251709],
    },
  },
  {
    type: "Feature",
    properties: { Name: "BAREKURI-8", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.393422686673304, 27.535787069216401],
    },
  },
  {
    type: "Feature",
    properties: { Name: "BAGHJAN-12", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.402921366555503, 27.612273519763601],
    },
  },
  {
    type: "Feature",
    properties: { Name: "BAGHJAN-2", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.403584440866283, 27.6044804316615],
    },
  },
  {
    type: "Feature",
    properties: { Name: "BAREKURI-9", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.397818083995617, 27.541757863578599],
    },
  },
  {
    type: "Feature",
    properties: { Name: "HUKANGURI-1", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.466947184471294, 27.5343334410213],
    },
  },
  {
    type: "Feature",
    properties: { Name: "DHAKUAL-1", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.3597665607995, 27.5626271619265],
    },
  },
  {
    type: "Feature",
    properties: { Name: "BAREKURI-26", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.386479051473998, 27.5376798714647],
    },
  },
  {
    type: "Feature",
    properties: { Name: "BAREKURI-27", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.386397938485601, 27.5375342480211],
    },
  },
  {
    type: "Feature",
    properties: { Name: "BAREKURI-28", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.386310888381416, 27.537391399514199],
    },
  },
  {
    type: "Feature",
    properties: { Name: "BAREKURI-29", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.3862297758496, 27.537245775939201],
    },
  },
  {
    type: "Feature",
    properties: { Name: "KHARJAN-1", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.200210612380815, 27.556544511218799],
    },
  },
  {
    type: "Feature",
    properties: { Name: "TBC", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.135561433185003, 27.541868454102001],
    },
  },
  {
    type: "Feature",
    properties: { Name: "BORHAPJAN-1", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.510645636851905, 27.520675486410401],
    },
  },
  {
    type: "Feature",
    properties: { Name: "BORHAPJAN-2", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.510586713624207, 27.5208184524503],
    },
  },
  {
    type: "Feature",
    properties: { Name: "BORHAPJAN-3", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.510695577779003, 27.520548559716602],
    },
  },
  {
    type: "Feature",
    properties: { Name: "BORHAPJAN-4", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.510748845076819, 27.520421121867301],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MECHAKI-3", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.609170864479694, 27.725663705337301],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MECHAKI-4", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.625678812282501, 27.729407756943701],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MECHAKI-5", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.625793467357894, 27.729318352594699],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NORTH BHJ-1", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.517731037818606, 27.535863172060299],
    },
  },
  {
    type: "Feature",
    properties: { Name: "BORHAPJAN-5", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.5257279421253, 27.515655547916801],
    },
  },
  {
    type: "Feature",
    properties: { Name: "TALAP-1", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.581107213913299, 27.649519282125809],
    },
  },
  {
    type: "Feature",
    properties: { Name: "TALAP-2", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.587899390857203, 27.648001422056101],
    },
  },
  {
    type: "Feature",
    properties: { Name: "TALAP-3", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.583718429479106, 27.654887698395399],
    },
  },
  {
    type: "Feature",
    properties: { Name: "TALAP-4", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.5848893547942, 27.6539623473696],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MECHAKI-1", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.631216579465502, 27.7257012006759],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MECHAKI-2", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.619017336986502, 27.728558880890201],
    },
  },
  {
    type: "Feature",
    properties: { Name: "Loc-428", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.374233333333336, 27.213136111111108],
    },
  },
  {
    type: "Feature",
    properties: { Name: "Loc-429", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.389555555555546, 27.22430833333333],
    },
  },
  {
    type: "Feature",
    properties: { Name: "LAKWAGAON-5", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.742334588615421, 27.101670926775238],
    },
  },
  {
    type: "Feature",
    properties: { Name: "BALIMARA-8", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.347565287481729, 27.221569329474679],
    },
  },
  {
    type: "Feature",
    properties: { Name: "BAGHJAN-25", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.427183534908806, 27.62453039971799],
    },
  },
  {
    type: "Feature",
    properties: { Name: "BATUYA-2", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.020008806613006, 27.12269063119313],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MKM-67", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.411608556577562, 27.472850139163938],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MKM-68", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.411456864876101, 27.47285276783413],
    },
  },
  {
    type: "Feature",
    properties: { Name: "NADUA-7", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.150019099513102, 27.50614153700614],
    },
  },
  {
    type: "Feature",
    properties: { Name: "MRN-119", description: null },
    geometry: {
      type: "Point",
      coordinates: [94.957449131187076, 27.15781885015776],
    },
  },
  {
    type: "Feature",
    properties: { Name: "DIGHALIPATHAR-1", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.2586483490837, 27.530200871959611],
    },
  },
  {
    type: "Feature",
    properties: { Name: "SOUTH TNL-7", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.190959914220954, 27.233844568075309],
    },
  },
  {
    type: "Feature",
    properties: { Name: "BALIMARA-7", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.358575552710761, 27.224335104537939],
    },
  },
  {
    type: "Feature",
    properties: { Name: "DEOHAL-1", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.309451290973783, 27.443360769601821],
    },
  },
  {
    type: "Feature",
    properties: { Name: "BAREKURI-31", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.386359355633189, 27.537640457108839],
    },
  },
  {
    type: "Feature",
    properties: { Name: "BAREKURI-30", description: null },
    geometry: {
      type: "Point",
      coordinates: [95.413081192439464, 27.529220396966188],
    },
  },
];
