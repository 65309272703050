import React from "react";
import "./Godown.css";
import Paper from "../../components/whiteCard/whiteCard";
import Header from "../../components/containerHeader/ContainerHeader";
import DropDown from "../../components/inputComponents/DropDown";
import Button from "../../components/inputComponents/Button";
import Tab from "../../components/inputComponents/Tab";
import Entry from "./entry/Entry";
import Axios from "axios";
import url from "../../constants/url";
import Exit from "./exit/exit";
import Report from "./report/Report";
import MaterialReport from "./materialReport/MaterialReport";
import MachineryReport from "./machineryReport/MachineryReport";

const authCheck = require("../../helpers/auth.helpers");

const Godown = () => {

  const { areaAndZone, designation } = authCheck(localStorage.getItem("authCode"));

  const [activeTab, setActiveTab] = React.useState("Entry");
  const [godowns, setGodowns] = React.useState([]);
  const [godownsDropDown, setGodownsDropDown] = React.useState([]);
  const [selectedGodown, setSelectedGodown] = React.useState(null);
  const [update, setUpdate] = React.useState(false);
  const [activeSection, setActiveSection] = React.useState("Godown");
  React.useEffect(() => {
    Axios.get(url + "/godowns/getGowdown")
      .then((data) => {
        setGodowns(data.data);
        let temp = [];
        temp.push({
          name: "Select",
          id: "Select",
        });
        data.data.map((godown) => {
          let zonesArrUser = [];
          areaAndZone.map((area) => {
            area.zones.map((zone) => {
              zonesArrUser.push(zone._id);
            });
          });
          let zonesArrGodown = [];
          godown.incharge.areaAndZone.map((area) => {
            area.zones.map((zone) => {
              zonesArrGodown.push(zone._id);
            });
          });

          if (
            zonesArrUser.some((item) => zonesArrGodown.includes(item)) ||
            designation == "SAP"
          ) {
            temp.push({
              name: godown.godownName,
              id: godown.godownName,
              _id: godown._id,
              register: godown.register,
              code: godown.godownCode,
            });
          }
        });
        setGodownsDropDown(temp);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  const test = async (e) => {
    setSelectedGodown(
      [...godownsDropDown].filter((godown) => godown._id == e.target.value)[0]
    );
    setUpdate(!update);
    await setActiveTab("Exit");
    await setActiveTab("Entry");
  };
  return (
    <div className="main-ims">
      <Header formName="Inventory"></Header>
      <Paper>
        <Button
          label="Godown Entry Form and Report"
          className={activeSection === "Godown" ? "success" : ""}
          onClick={() => setActiveSection("Godown")}
        ></Button>
        <Button
          label="All Gowdown Material Report"
          className={activeSection === "R1" ? "success" : ""}
          onClick={() => setActiveSection("R1")}
        ></Button>
        <Button
          label="All Gowdown Machinery Report"
          className={activeSection === "R2" ? "success" : ""}
          onClick={() => setActiveSection("R2")}
        ></Button>
        <br />
        <br />
        {activeSection === "Godown" ? (
          <>
            <DropDown
              label="Select Godown"
              options={godownsDropDown}
              onChange={(e) => test(e)}
              size={window.innerWidth < 800 ? "wide" : ""}
            ></DropDown>
            <br />
            <br />
            {selectedGodown ? (
              <>
                <h1>Selected Godown: {selectedGodown.name}</h1>
                <div
                  className="ims-godown-holder-tabs"
                  style={{
                    marginLeft: window.innerWidth < 700 ? "0px" : "20px",
                  }}
                >
                  <Tab
                    active={activeTab === "Entry" ? true : false}
                    label={"Entry Stock"}
                    onClick={() => setActiveTab("Entry")}
                  ></Tab>
                  <Tab
                    active={activeTab === "Exit" ? true : false}
                    label={"Exit Stock"}
                    onClick={() => setActiveTab("Exit")}
                  ></Tab>
                  <Tab
                    active={activeTab === "Report" ? true : false}
                    label={"Report"}
                    onClick={() => setActiveTab("Report")}
                  ></Tab>
                </div>

                <div
                  className="ims-godown-holder"
                  style={{
                    padding: window.innerWidth < 700 ? "10px" : "20px",
                  }}
                >
                  {activeTab === "Entry" ? (
                    <Entry godown={selectedGodown} update={update}></Entry>
                  ) : null}
                  {activeTab === "Exit" ? (
                    <Exit godown={selectedGodown} update={update}></Exit>
                  ) : null}
                  {activeTab === "Report" ? (
                    <Report godown={selectedGodown} update={update}></Report>
                  ) : null}
                </div>
              </>
            ) : null}
          </>
        ) : null}
        {activeSection === "R1" ? (
          <MaterialReport godowns={godowns}></MaterialReport>
        ) : null}
        {activeSection === "R2" ? (
          <MachineryReport godowns={godowns}></MachineryReport>
        ) : null}
      </Paper>
    </div>
  );
};

export default Godown;
