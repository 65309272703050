import React, { useState, useEffect } from "react";
import ContainerHeader from "../../components/containerHeader/ContainerHeader";
import Paper from "../../components/whiteCard/whiteCard";
import Input from "../../components/inputComponents/Input";
import Table from "../../components/table/unsortedTable/UnsortedTable";
import IconButton from "../../components/inputComponents/IconButton";
import BlankTable from "../../components/table/blankTable/BlankTable";
import Axios from "axios";
import ConfirmContent from "../../components/ui/confirmContent/ConfirmContent";
import Loader from "../../components/spinner/Spinner";
import Modal from "../../components/ui/modal/Modal";
import MaterialTables from "../../components/materialTable/MaterialTables";
import url from "../../constants/url";
const contents = [];

const MaterialIssue = (props) => {
  const [tableContents, setTableContents] = useState(contents);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [ques, setQues] = useState("");
  const [update, setUpdate] = useState(false);
  const [error, setError] = useState(false);
  const [functionToPass, setFunctionToPass] = useState(null);
  const [details, setDetails] = useState({});

  const headings = [
    "Reservation No",
    "Date of issue",
    "Material",
    "Material Code",
    "Quantity",
    "Unit",
    "Action",
  ];

  useEffect(() => {
    setLoading(true);
    setShow(true);
    Axios.get(
      url + "/projects/projectDetails?projectId=" + props.match.params.projectId
    )
      .then((project) => {
        let tempDetails = {};
        tempDetails["projectType"] = project.data["projectType"];
        tempDetails["projectId"] = project.data["projectId"];
        tempDetails["vendor"] = project.data["vendor"]["name"];
        setDetails(tempDetails);
        let tempMats = [];
        project.data.detailMaterials.map((p) => {
          let tempMat = {};
          tempMat["Reservation No"] = p["reservationNo"];
          tempMat["Date of issue"] = formattedDate(p["date"]);
          tempMat["Material"] = p["material"]["description"];
          tempMat["Material Code"] = p["material"]["code"];
          tempMat["Quantity"] = p["quantity"];
          tempMat["Unit"] = p["material"]["unit"];
          tempMat["Action"] = (
            <IconButton
              iconClass="fas fa-trash-alt"
              className="success"
              onClick={() => modalVisible(p["_id"])}
              hint="Delete"
            ></IconButton>
          );
          tempMats = [...tempMats, tempMat];
        });
        setTableContents(tempMats);
        modalClose();
        setLoading(false);
      })
      .catch((err) => {
        setError(true);
        setLoading(false);
      });
  }, [update]);

  const modalClose = () => {
    setShow(false);
  };

  const modalVisible = (id) => {
    setError(false);
    setQues("Do you want to delete this material?");
    setFunctionToPass(() => () => materialDeleteHandler(id));
    setShow(true);
  };

  const materialDeleteHandler = (id) => {
    setLoading(true);
    Axios.post(url + "/projects/deleteMaterial", {
      projectId: props.match.params.projectId,
      materialId: id,
    })
      .then((resp) => {
        modalClose();
        setLoading(false);
        setUpdate(!update);
      })
      .catch((err) => {
        setError(true);
        setLoading(false);
      });
  };

  const formattedDate = (dateObj) => {
    const date = new Date(dateObj);
    const year = date.getFullYear();
    let month = date.getMonth() + 1;
    let dt = date.getDate();

    if (dt < 10) {
      dt = "0" + dt;
    }
    if (month < 10) {
      month = "0" + month;
    }

    return dt + "/" + month + "/" + year;
  };
  return (
    <div className="materialIssue">
      <Modal show={show} noHandler={modalClose}>
        {loading ? (
          <Loader></Loader>
        ) : !error ? (
          <ConfirmContent
            yesHandler={functionToPass}
            noHandler={modalClose}
            question={ques}
          ></ConfirmContent>
        ) : (
          <p>Something went wrong!</p>
        )}
      </Modal>
      <ContainerHeader formName="Detailed Material List"></ContainerHeader>
      <Paper>
        <div className="materialForm">
          <div className="formField">
            <Input
              name="project-id"
              label="Project ID"
              size="wide"
              value={details.projectId}
              disabled
            />
          </div>
          <div className="formField">
            <Input
              name="project-type"
              label="Project ID"
              size="wide"
              value={details.projectType}
              disabled
            />
          </div>
          <div className="formField">
            <Input
              name="vendor-name"
              label="Vendor Name"
              size="wide"
              value={details.vendor}
              disabled
            />
          </div>
        </div>
        <div className="materialTable">
          <MaterialTables
            headings={headings}
            data={tableContents}
            title="Detailed Material List"
          ></MaterialTables>
        </div>
      </Paper>
    </div>
  );
};

export default MaterialIssue;
