import React, { useState, useEffect } from "react";
import "./projectDetails.css";
import ProjectDetail from "../../components/projectDetail/projectDetail";
import axios from "axios";
import url, { url_upload } from "../../constants/url";
import WhiteCard from "../../components/whiteCard/whiteCard";
import IconButton from "../../components/inputComponents/IconButton";
import Modal from "../../components/ui/modal/Modal";
import Loader from "../../components/spinner/Spinner";
import MaterialTables from "../../components/materialTable/MaterialTables";
import image from "../../assets/icons/img.svg";
import pdf from "../../assets/icons/pdf.svg";
import doc from "../../assets/icons/doc.svg";
import { Link } from "react-router-dom";
import Button from "../../components/inputComponents/Button";
import ConfirmContent from "../../components/ui/confirmContent/ConfirmContent";
import ContainerHeader from "../../components/containerHeader/ContainerHeader";

const ProjectDetails = (props) => {

  const [details, setDetails] = useState({});
  const [others, setOthers] = useState({});
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [tableContents, setTableContents] = useState([]);
  const [drawings, setDrawings] = useState([]);
  const [estimate, setEstimate] = useState([]);
  const [update, setUpdate] = useState(false);
  const [ques, setQues] = useState("");
  const [functionToPass, setFunctionToPass] = useState(false);
  const connLinks = [
    {
      name: "Project list",
      path: '/projects'
    }
  ]
  const headings = [
    "Reservation No",
    "Date of issue",
    "Material",
    "Material Code",
    "Quantity",
    "Unit",
  ];

  useEffect(() => {
    setLoading(true);
    setShow(true);

    axios
      .get(url + "/projects/projectDetails?projectId=" + props.match.params.id)
      .then((res) => {
        let obj = {
          area: res.data.area ? res.data.area.areaName : "none",
          zone: res.data.zone ? res.data.zone.zoneName : "none",
          vendor: res.data.vendor ? res.data.vendor.name : "none",
          tech: res.data.technician ? res.data.technician.name : "none",
          eng: res.data.engineer ? res.data.engineer.name : "none",
          jreng: res.data.jrEngineer ? res.data.jrEngineer.name : "none",
          lcc: new Date(res.data.validityLLC).toDateString(),
          dos: new Date(res.data.dos).toDateString(),
          doc: new Date(res.data.doc).toDateString(),
        };
        let obj2 = {
          projectType: res.data.projectType ? res.data.projectType : "none",
          projectId: res.data.projectId ? res.data.projectId : "none",
          notificationNo: res.data.notificationNo
            ? res.data.notificationNo
            : "none",
          installation: res.data.installation
            ? res.data.installation.name
            : "none",
          prNumber: res.data.prNumber ? res.data.prNumber : "none",
          poNumber: res.data.poNumber ? res.data.poNumber : "none",
          description: res.data.description ? res.data.description : "none",
          remarks: res.data.remarks ? res.data.remarks : "none",
          showCause: res.data.showcause,
          state: res.data.state,
        };
        let tempMats = [];
        res.data.detailMaterials.map((p) => {
          let tempMat = {};
          tempMat["Reservation No"] = p["reservationNo"];
          tempMat["Date of issue"] = formattedDate(p["date"]);
          tempMat["Material"] = p["material"]["description"];
          tempMat["Material Code"] = p["material"]["code"];
          tempMat["Quantity"] = p["quantity"];
          tempMat["Unit"] = p["material"]["unit"];
          tempMats = [...tempMats, tempMat];
        });
        setTableContents(tempMats);
        setDetails(obj2);
        setOthers(obj);
        setDrawings(res.data.drawing);
        setEstimate(res.data.estimate);
        modalClose();
        setLoading(false);
      })
      .catch((e) => {
        setError(true);
        setLoading(false);
      });
  }, [update]);

  const formattedDate = (dateObj) => {
    const date = new Date(dateObj);
    const year = date.getFullYear();
    let month = date.getMonth() + 1;
    let dt = date.getDate();

    if (dt < 10) {
      dt = "0" + dt;
    }
    if (month < 10) {
      month = "0" + month;
    }

    return dt + "/" + month + "/" + year;
  };
  const modalClose = () => {
    setShow(false);
  };

  const modalVisibleForDrawing = (file) => {
    setError(false);
    setQues("Do you want to delete this drawing?");
    setFunctionToPass(() => () => deleteDrawing(file));
    setShow(true);
  };

  const modalVisibleForEstimate = (file) => {
    setError(false);
    setQues("Do you want to delete this estimate?");
    setFunctionToPass(() => () => deleteEstimate(file));
    setShow(true);
  };

  const deleteDrawing = (file) => {
    setLoading(true);
    let data = {
      file: file,
      projectId: props.match.params.id,
    };
    axios
      .post(url + "/projects/deleteDrawing", data)
      .then((resp) => {
        modalClose();
        setLoading(false);
        setUpdate(!update);
      })
      .catch((err) => {
        setError(true);
        setLoading(false);
        console.log(err);
      });
  };

  const deleteEstimate = (file) => {
    setLoading(true);
    let data = {
      file: file,
      projectId: props.match.params.id,
    };
    axios
      .post(url + "/projects/deleteEstimate", data)
      .then((resp) => {
        modalClose();
        setLoading(false);
        setUpdate(!update);
      })
      .catch((err) => {
        setError(true);
        setLoading(false);
        console.log(err);
      });
  };

  let workOrder = (
    <Link to={"/work-order/" + props.match.params.id}>
      <IconButton
        className="success"
        hint="Issue work order"
        iconClass="fa fa-file-word-o"
      ></IconButton>
    </Link>
  );

  let showcause = (
    <Link to={"/show-cause/" + props.match.params.id}>
      <IconButton
        className="success"
        hint="Issue show cause"
        iconClass="fa fa-sticky-note-o"
      ></IconButton>
    </Link>
  );
  return (
    <>
    <ContainerHeader formName="Project Details" connLinks={connLinks}/>
    <WhiteCard>
      <Modal show={show} noHandler={modalClose}>
        {loading ? (
          <Loader></Loader>
        ) : !error ? (
          <ConfirmContent
            yesHandler={functionToPass}
            noHandler={modalClose}
            question={ques}
          ></ConfirmContent>
        ) : (
          <p>Something went wrong!</p>
        )}
      </Modal>
      <div className="pd-bg">
        <div className="pd-sec1">
          <div className="pd-sec1-in">
            <ProjectDetail ipt="Project Type" val={details.projectType} />
            <ProjectDetail ipt="Project Id" val={details.projectId} />
            <ProjectDetail
              ipt="Notification No./Contract No."
              val={details.notificationNo}
            />
            <ProjectDetail ipt="Installation" val={details.installation} />
            <ProjectDetail ipt="Area" val={others.area} />
            <ProjectDetail ipt="Zone" val={others.zone} />
            <ProjectDetail ipt="Vendor" val={others.vendor} />
            <ProjectDetail ipt="Validity LCC" val={others.lcc} />
          </div>
          <div className="pd-sec1-in">
            <ProjectDetail ipt="DOS" val={others.dos} />
            <ProjectDetail ipt="DOC" val={others.doc} />
            <ProjectDetail ipt="Technician" val={others.tech} />
            <ProjectDetail ipt="Engineer" val={others.eng} />
            <ProjectDetail ipt="Jr. Engineer" val={others.jreng} />
            <ProjectDetail ipt="PR Number" val={details.prNumber} />
            <ProjectDetail ipt="PO Number" val={details.poNumber} />
            {!details.showCause ? (
              <ProjectDetail ipt="Work Order" val={workOrder} />
            ) : null}
            {details.showCause ? (
              <ProjectDetail ipt="Show Cause" val={showcause} />
            ) : null}
          </div>
        </div>
        <div className="pd-sec2">
          <p>Project Description:</p>
          <span>{details.description}</span>
        </div>
        <div className="pd-sec1">
          <div className="pd-sec1-in">
            <div className="pd-sec3-in">Estimate</div>
            <div className="drawing-details">
              {estimate.length !== 0 ? (
                <div className="drawing-grid">
                  {estimate.map((draw) => {
                    return (
                      <div>
                        <a
                          href={`${url_upload}/projects/estimate/${draw}`}
                          target="_blank"
                        >
                          {draw.split(".")[draw.split(".").length - 1] ===
                            "png" ||
                          draw.split(".")[draw.split(".").length - 1] ===
                            "jpg" ||
                          draw.split(".")[draw.split(".").length - 1] ===
                            "jpeg" ? (
                            <img src={image} alt="image" height="40px" />
                          ) : draw.split(".")[draw.split(".").length - 1] ===
                            "pdf" ? (
                            <img src={pdf} alt="pdf" height="40px" />
                          ) : (
                            <img src={doc} alt="Document" height="40px" />
                          )}
                        </a>
                        <Button
                          label="Delete"
                          className="danger"
                          onClick={() => modalVisibleForEstimate(draw)}
                        />
                      </div>
                    );
                  })}
                </div>
              ) : (
                <p>Nothing has been uploaded!</p>
              )}
            </div>
          </div>
          <div className="pd-sec1-in">
            <div className="pd-sec3-in">Job Drawing / Other Documents:</div>
            <div className="drawing-details">
              {drawings.length !== 0 ? (
                <div className="drawing-grid">
                  {drawings.map((draw) => {
                    return (
                      <div>
                        <a
                          href={`${url_upload}/projects/drawing/${draw}`}
                          target="_blank"
                        >
                          {draw.split(".")[draw.split(".").length - 1] ===
                            "png" ||
                          draw.split(".")[draw.split(".").length - 1] ===
                            "jpg" ||
                          draw.split(".")[draw.split(".").length - 1] ===
                            "jpeg" ? (
                            <img src={image} alt="image" height="40px" />
                          ) : draw.split(".")[draw.split(".").length - 1] ===
                            "pdf" ? (
                            <img src={pdf} alt="pdf" height="40px" />
                          ) : (
                            <img src={doc} alt="Document" height="40px" />
                          )}
                        </a>
                        <Button
                          label="Delete"
                          className="danger"
                          onClick={() => modalVisibleForDrawing(draw)}
                        />
                      </div>
                    );
                  })}
                </div>
              ) : (
                <p>Nothing has been uploaded!</p>
              )}
            </div>
          </div>
          <div className="pd-sec1-in">
            <div className="pd-sec3-in">Remarks:</div>
            <div className="pd-sec3-in2">{details.remarks}</div>
          </div>
        </div>
        <div style={{ margin: "1.5%" }}>
          <MaterialTables
            headings={headings}
            data={tableContents}
            title={
              "Materials for Project ID: " +
              details.projectId +
              " & vendor: " +
              others.vendor
            }
          ></MaterialTables>
        </div>
      </div>
    </WhiteCard>
  </>
  );
  
};

export default ProjectDetails;
