import React, {useState} from 'react'
import ContainerHeader from "../../../components/containerHeader/ContainerHeader";
import Paper from "../../../components/whiteCard/whiteCard";
import * as requests from "../../../api/requests";
import MaterialTables from "../../../components/materialTable/MaterialTables";
import Modal from "../../../components/ui/modal/Modal";
import Loader from "../../../components/spinner/Spinner";

const CumulativeMaterialTotal = () => {
    const [tableContents, setTableContents] = React.useState(null);
    const [tableContents2, setTableContents2] = React.useState(null);
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const connLinks = [
        {
          name: "All Reports",
          path: '/reports'
        }
      ]
    const headings = [
        "SL No",
        "Description of Material",
        "Material Code",
        "Unit",
        "Cumulative Issued",
        "Cumulative Consumed",
        "Balance",
      ];
      const headings2 = [
        "SL No",
        "Description of Material",
        "Material Code",
        "Unit",
        "Date of Issue",
        'Reservation Number',
        'Quantity Issued',
        
      ];
      const formattedDate = (dateObj) => {
        const date = new Date(dateObj);
        const year = date.getFullYear();
        let month = date.getMonth() + 1;
        let dt = date.getDate();
    
        if (dt < 10) {
          dt = "0" + dt;
        }
        if (month < 10) {
          month = "0" + month;
        }
        return dt + "/" + month + "/" + year;
      };
      const modalClose = () => {
        setShow(false);
      };
    React.useEffect(() => {
        setLoading(true);
        setShow(true);
        requests
            .get({url: "/material/getMaterialList"})
            .then(materialList => {
                let contents =[]
                let i = 1
                materialList.map(material => {
                    let tempObj = {}
                    tempObj["SL No"] = i
                    i = i + 1
                    tempObj["Description of Material"] = material.description
                    tempObj["Material Code"] = material.code
                    tempObj["Unit"] = material.unit
                    tempObj["Cumulative Issued"] = 0
                    tempObj["Cumulative Consumed"] = 0
                    contents = [...contents, tempObj];
                })
                let k = 1
                let contents2 =[]
                requests.get({ url: "/projects/getProjectList"})
                    .then(result => {
                        result.project.forEach(project => {
                            if(project.detailMaterials){
                                project.detailMaterials.forEach(p => { 
                                    let tempObj2 = {}
                                    tempObj2["SL No"] = k
                                    k = k + 1
                                    tempObj2["Quantity Issued"] = p.quantity
                                    tempObj2["Reservation Number"] = p.reservationNo
                                    tempObj2["Description of Material"] = p.material.description
                                    tempObj2["Material Code"] = p.material.code
                                    tempObj2["Unit"] = p.material.unit
                                    tempObj2['Date of Issue'] = formattedDate(p.date)

                                    contents2.push(tempObj2)
                                    contents.forEach(content => {
                                        if(p.material.description === content["Description of Material"]){
                                            content["Cumulative Issued"] = content["Cumulative Issued"] + p.quantity
                                        }
                                    })                                    
                                })
                            }
                            if(project.progress){
                                project.progress.perDayProgress.forEach(p => {
                                    p.materialUsed.forEach(material => {
                                        contents.forEach(content => {
                                            if(material.name === content["Description of Material"]){
                                                content["Cumulative Consumed"] = content["Cumulative Consumed"] + material.consumed
                                            }
                                        })
                                        contents.forEach(content => {
                                            
                                            if(content["Cumulative Issued"] >= content["Cumulative Consumed"]){
                                                content["Balance"] = content["Cumulative Issued"] - content["Cumulative Consumed"]
                                            }
                                        })
                                    })
                                });
                            }
                        });
                        setTableContents(contents)
                        setTableContents2(contents2)
                        modalClose();
                        setLoading(false);
                    }).catch(error => {
                        setError(true);
                        setLoading(false);  
                    })   
            }).catch(error => {
                setError(true);
                setLoading(false);
            })
        
    }, [])
    return (
        <>
            {loading || error ? (
            <Modal show={show} noHandler={modalClose}>
            {error ? <p>Something went wrong!</p> : <Loader></Loader>}
            </Modal>
             ) : null}
            <ContainerHeader formName="Cumulative Issued - Cumulative Consumed (Total)" connLinks={connLinks}/>
            <Paper>
            {tableContents && tableContents.length 
            && tableContents2 && tableContents2.length ? (
                <>
                <MaterialTables
                headings={headings}
                data={tableContents}
                title="Cumulative Material Total"
            />
                <MaterialTables
                headings={headings2}
                data={tableContents2}
                title="Cumulative Material Total"
            />
            </>
            ) : (
                "No Materials"
            )}
            
            </Paper>
        </>
    )
}

export default CumulativeMaterialTotal
