import React, { useState, useEffect } from "react";
import "./AddArea.css";
import ContainerHeader from "../../components/containerHeader/ContainerHeader";
import Whitecard from "../../components/whiteCard/whiteCard";
import Input from "../../components/inputComponents/Input";
import Button from "../../components/inputComponents/Button";
import IconButton from "../../components/inputComponents/IconButton";
import axios from "axios";
import MaterialTable1 from "../../components/materialTable/MaterialTables1";
import url from "../../constants/url";
import ConfirmContent from "../../components/ui/confirmContent/ConfirmContent";
import Loader from "../../components/spinner/Spinner";
import Modal from "../../components/ui/modal/Modal";
import Alert from "@material-ui/lab/Alert";
import Typography from "@material-ui/core/Typography";

const AddArea = () => {
  const [areaFormErrors, setAreaFormErrors] = useState([]);
  const [zoneFormErrors, setZoneFormErrors] = useState([]);
  const [areas, setAreas] = useState([]);
  const [updateArea, setUpdateArea] = useState({ name: "", id: "" });
  const [error, setError] = useState(false);
  
  // bulk Upload Mechanisms.
  const [bulkUpload, setBulkUpload] = useState(false);
  const [file, setFile] = useState(null);
  const [isSubmit, setSubmit] = useState("");
  const [type, setType] = useState(null);

  const [updateZone, setUpdateZone] = React.useState({
    name: "",
    code: "",
    id: "",
    status: false,
  });
  const [updateTrue, setUpdateTrue] = useState(false);
  const [tableContents, setTableContents] = useState([]);
  const [selected, setSelected] = useState({ value: "", id: "", zones: [] });
  var zne = selected.zones.map((zone) => (
    (zone.zoneName)?(
    <button className="aa-small-box">
      {zone.zoneName} - ({zone.zoneCode})
    </button>):null
  ));

  const arr = areas.map((area) => (
    <button
      name="area selected"
      key={area._id}
      value={area.areaName}
      onClick={(e) => {
        setDisable(false);
        setSelected({ value: e.target.value, id: area._id, zones: area.zones });
        let arrayTemp = areas;
        arrayTemp.forEach((item) => {
          if (item.areaName === e.target.value) item.active = true;
          else item.active = false;
        });
        setAreas(arrayTemp);
      }}
      className={area.active ? "aa-small-highlight" : "aa-small-box"}
    >
      {area.areaName}
    </button>
  ));
  const [Tog, setTog] = useState(true);
  const [Togtwo, setTogtwo] = useState(true);
  const [Inp, setInp] = useState("");
  const [Inptwo, setInptwo] = useState("");
  const [Inpthree, setInpthree] = useState("");
  const [disable, setDisable] = useState(true);
  const [objj, setObjj] = useState([]);
  const [test, setTest] = useState(true);
  const [show, setShow] = useState(false);
  const [functionToPass, setFunctionToPass] = useState(null);
  const [ques, setQues] = useState("");
  const [loading, setLoading] = useState(false);

  const heads = ["Area Name", "Edit Area", "Zones & Zone Codes"];

  function deleteZone(id){
    setLoading(true);
      axios.post(url+"/zones/deleteZone",{
        id: id
      })
        .then((resp)=>{
          setSelected({
            value:"",
            id: "",
            zones:[]
          });
          modalClose();
          setLoading(false);
          setTest(!test);
        })
        .catch((e)=>{
          setError(true);
          setLoading(false);
        })
  }

  const modalVisibleDelZone=(id)=>{
      setError(false);
      setFunctionToPass(() => () => deleteZone(id));
      setQues("Make sure this project is not linked to a project!");
      setShow(true);
  }
  function getRequest() {
    setLoading(true);
    setShow(true);
    axios
      .get(url + "/areas/getAreas")
      .then((res) => {
        // new --
        let temp = [];
        res.data.map((data) => {
          let objTem = {
            areaName: data.areaName,
            zones: data.zones,
            _id: data._id,
            active: false,
          };
          temp.push(objTem);
        });
        setAreas(temp);
        // new end --
        let contents = [];
        res.data.map((data) => {
          contents.push({
            "Area Name": data.areaName,
            "Edit Area": (
              <div className="action-buttons">
                <IconButton
                  onClick={() => {
                    setUpdateArea({ name: data.areaName, id: data._id });
                    setUpdateTrue(true);
                    window.scrollTo(0, 0);
                  }}
                  className="success"
                  hint="Edit"
                  iconClass="fa fa-pencil"
                ></IconButton>
              </div>
            ),
            "Zones & Zone Codes": data.zones ? (
              <div className="zone-align">
                {data.zones.map((zone) => (
                  (zone.zoneName)?(
                  <div className="aa-align-row">
                    <button className="aa-small-box">
                      {zone.zoneName} - ({zone.zoneCode})
                    </button>
                    <IconButton
                      onClick={() => {
                        setUpdateZone({
                          name: zone.zoneName,
                          code: zone.zoneCode,
                          id: zone._id,
                          status: true,
                        });
                        window.scrollTo(0, 0);
                        setDisable(false);
                        setInptwo(zone.zoneName);
                        setInpthree(zone.zoneCode);

                        setSelected({
                          value: data.areaName,
                          id: data._id,
                          zones: data.zones,
                        });
                        setObjj(zone);
                      }}
                      className="success"
                      hint="Edit"
                      iconClass="fa fa-pencil"
                    ></IconButton>
                     <IconButton
                     onClick={
                       ()=>{
                          let id=zone._id;
                          modalVisibleDelZone(id);
                       }
                     }
                      className="danger"
                      hint="Edit"
                      iconClass="fa fa-trash"
                    ></IconButton>
                  </div>):null
                ))}
              </div>
            ) : (
              ""
            ),
          });
        });
        setTableContents(contents);
        modalClose();
        setLoading(false);
      })
      .catch((e) => {
        setError(true);
        setLoading(false);
      });
  }
  // setting the areas state to contain all the areas in db
  useEffect(() => {
    getRequest();
  }, [test]);

  const modalVisibleForArea = (id) => {
    setError(false);
    let errors = [];
    if (!Inp) {
      errors.push("Area is required");
    }
    if (errors.length > 0) {
      setAreaFormErrors(errors);
      return;
    }
    setAreaFormErrors([]);
    setFunctionToPass(() => () => handleAddArea(id));
    setQues("Do you want to add this area?");
    setShow(true);
  };

  const modalVisibleForZone = (id) => {
    setError(false);
    let errors = [];
    if (!Inptwo) {
      errors.push("ZONE NAME is required");
    }
    if (!Inpthree) {
      errors.push("ZONE CODE is required");
    }
    if (errors.length > 0) {
      setZoneFormErrors(errors);
      return;
    }
    setZoneFormErrors([]);
    setFunctionToPass(() => () => handleAddZones(id));
    setQues("Do you want to add this zone?");
    setShow(true);
  };

  const modalClose = () => {
    setShow(false);
  };

  const modalVisibleForBulkUpload = () => {
    setError(false);
    setBulkUpload(true);
    setShow(true);
  }

  function handleBulkUpload() {
    setLoading(true);

    const data = new FormData();
    data.append("name", "bulkUpload");
    data.append("file", file);

    modalClose();
    axios.post(`${url}/bulkUpload/${type}Upload`, data)
    .then((res) => {
      setTest(!test);
    })
    .catch((err) => {
      setShow(true);
      setLoading(false);
      setBulkUpload(false);
      setError(true);
    })
  }

  function handleAddArea() {
    //creating area
    setLoading(true);
    if (!updateTrue) {
      axios
        .post(url + "/areas/createArea", {
          areaName: Inp,
        })
        .then(function (response) {
          setTest(!test);
          setInp("");
          modalClose();
          setLoading(false);
        })
        .catch((e) => {
          setError(true);
          setLoading(false);
        });
    }
    //updating area
    else {
      setUpdateTrue(false);
      setLoading(true);
      axios
        .patch(url + "/areas/updateArea", {
          _id: updateArea.id,
          name: Inp,
        })
        .then((res) => {
          setInp("");
          modalClose();
          setLoading(false);
          setTest(!test);
        })
        .catch((e) => {
          setError(true);
          setLoading(false);
        });
    }
  }

  function handleAddZones() {
    //updating Zone
    setLoading(true);
    if (updateZone.status) {
      axios
        .patch(url + "/zones/updateZone", {
          _id: updateZone.id,
          zoneName: Inptwo,
          zoneCode: Inpthree,
        })
        .then((res) => {
          setUpdateZone({ name: "", code: "", id: "", status: false });
          setInptwo("");
          setInpthree("");
          selected.zones.splice(selected.zones.indexOf(objj), 1);
          let arr = selected.zones;
          arr = [...arr, { _id: 123, zoneName: Inptwo, zoneCode: Inpthree }];
          let obj = {
            value: selected.value,
            id: selected.id,
            zones: arr,
          };
          setSelected(obj);
          // getRequest();
          modalClose();
          setLoading(false);
          setTest(!test);
        })
        .catch((e) => {
          setError(true);
          setLoading(false);
        });
    }
    //Creating Zone
    else {
      axios
        .post(url + "/zones/createZone", {
          zoneName: Inptwo,
          zoneCode: Inpthree,
          areaId: selected.id,
        })
        .then(function (response) {
          let arr = selected.zones;
          arr = [...arr, { _id: 123, zoneName: Inptwo, zoneCode: Inpthree }];
          let obj = {
            value: selected.value,
            id: selected.id,
            zones: arr,
          };
          setSelected(obj);
          setInptwo("");
          setInpthree("");
          // getRequest();
          modalClose();
          setLoading(false);
          setTest(!test);
        })
        .catch((e) => {
          setError(true);
          setLoading(false);
        });
    }
  }

  return (
    <div>
      <Modal show={show} noHandler={modalClose}>
        {!loading ? (
          !error ? 
            bulkUpload ? 
            <>
              <Typography>Select a .xlsx file of not more than 2MB that contains the {type} details( in the specified format).</Typography>
              <Input
                type="file"
                value={isSubmit ? "" : null }
                onChange={(e) => {
                  setSubmit(false);
                  setFile(e.target.files[0])}
                }
                style={{marginBottom: '10px'}}
              />
              <Button 
                className="primary"
                type="button"
                label="Bulk Upload"
                onClick={handleBulkUpload}
              />
              <Button 
                className="primary"
                type="button"
                label="Cancel"
                onClick={() => {
                  setFile(null);
                  modalClose();
                }}
              />
            </>
            : 
            (
            <ConfirmContent
              yesHandler={functionToPass}
              noHandler={modalClose}
              question={ques}
            ></ConfirmContent>
          ) : (
            <p>Something went wrong!</p>
          )
        ) : (
          <Loader></Loader>
        )}
      </Modal>
      <ContainerHeader formName="Add Area"></ContainerHeader>
      <Whitecard>
        <div className="aa-row">
          <div className="aa-col">
            <div className="aa-field">
              <Input
                name="area-name"
                label="Add Area"
                placeholder={updateTrue ? updateArea.name : "Enter Area Name"}
                value={Inp}
                onChange={(e) => setInp(e.target.value)}
                size="wide"
              />
              {areaFormErrors.map((error) => {
                return <Alert severity="info">{error}</Alert>;
              })}
              <Button
                className="success"
                onClick={modalVisibleForArea}
                label={updateTrue ? "Update Area" : "Create Area"}
              />
              <Button
                className="success"
                onClick={() => {
                  setTog(!Tog);
                }}
                label={!Tog ? "Show Areas" : "Hide Areas"}
              />
              <Button
                className="success"
                onClick={() => {
                  setType("area");
                  modalVisibleForBulkUpload();
                }}
                style={{marginTop: '10px'}}
                label={`Area Bulk Upload`}
              />
            </div>
            {Tog === true && <div className="aa-box">{arr}</div>}
          </div>
          <div className="aa-col">
            <div className="aa-field">
              <Input
                disabled={disable ? "disabled" : ""}
                name="zone-name"
                label="Add Zone Name"
                placeholder={
                  updateZone.status ? updateZone.name : "Enter Zone Name"
                }
                value={Inptwo}
                onChange={(e) => setInptwo(e.target.value)}
                size="wide"
              />
              <Input
                disabled={disable ? "disabled" : ""}
                name="zone-code"
                label="Add Zone Code"
                placeholder={
                  updateZone.status ? updateZone.code : "Enter Zone Code"
                }
                value={Inpthree}
                onChange={(e) => setInpthree(e.target.value)}
                size="wide"
              />
              {zoneFormErrors.map((error) => {
                return <Alert severity="info">{error}</Alert>;
              })}
              <Button
                className="success"
                onClick={disable ? "" : modalVisibleForZone}
                label={
                  updateZone && updateZone.status
                    ? "Update Zone"
                    : "Create Zone"
                }
              />
              <Button
                className="success"
                onClick={
                  disable
                    ? ""
                    : () => {
                        setTogtwo(!Togtwo);
                      }
                }
                label={!Togtwo ? "Show Zones" : "Hide Zones"}
              />
              <Button
                className="success"
                onClick={() => {
                  setType("zone");
                  modalVisibleForBulkUpload();
                }}
                label={`Zone Bulk Upload`}
                style={{marginTop:'10px'}}
              />
            </div>
            {Togtwo === true && <div className="aa-box">{zne}</div>}
          </div>
        </div>
        <p>Area And Zone List</p>
        <div class="userListTable">
          {tableContents && tableContents.length > 0 ? (
            <MaterialTable1
              headings={heads}
              data={tableContents}
              // setContents={setTableContents}
              title="Area and Zone database"
            ></MaterialTable1>
          ) : (
            "No Areas"
          )}
        </div>
      </Whitecard>
    </div>
  );
};

export default AddArea;
