import React, { useState } from "react";
import "../addGodown/AddGodown.css";
import Paper from "../../components/whiteCard/whiteCard";
import Input from "../../components/inputComponents/Input";
import Dropdown from "../../components/inputComponents/DropDown";
import * as requests from "../../api/requests";
import Button from "../../components/inputComponents/Button";
import ContainerHeader from "../../components/containerHeader/ContainerHeader";
import Alert from "@material-ui/lab/Alert";
import Modal from "../../components/ui/modal/Modal";
import ConfirmContent from "../../components/ui/confirmContent/ConfirmContent";
import Loader from "../../components/spinner/Spinner";
import Axios from "axios";
import url from "../../constants/url";

const EditGodown = ({
  name,
  code,
  setEdit,
  incharge,
  id,
  setUpdate,
  update,
}) => {
  const [formData, setFormData] = useState({
    godownName: name,
    godownCode: code,
    incharge: incharge,
  });
  const [techList, setTechList] = useState([]);
  const [show, setShow] = useState(false);
  const [ques, setQues] = useState("");
  const [loading, setLoading] = useState(false);
  const [formErrors, setFormErrors] = useState([]);
  const [error, setError] = useState(false);
  const [functionToPass, setFunctionToPass] = useState(null);

  const inputHandler = (e, property) => {
    setFormData({ ...formData, [property]: e.target.value });
  };
  React.useEffect(() => {
    setLoading(true);
    requests
      .get({ url: "/users/getAllUsers" })
      .then((result) => {
        let userArray = [
          {
            _id: "Select Godown Incharge",
            name: "Select Godown Incharge",
          },
        ];
        result.forEach((user) => {
          if (user.designation === "Technician") {
            userArray = [
              ...userArray,
              {
                _id: user._id,
                name: user.name + " (" + user.userCode + ")",
              },
            ];
          }
        });
        setTechList(userArray);
        setLoading(false);
      })
      .catch((error) => {
        setError(true);
        setLoading(false);
      });
  }, []);
  const submitHandler = () => {
    setLoading(true);

    Axios.patch(`${url}/godowns/updateGodown/${id}`, formData)
      .then((result) => {
        modalClose();
        setLoading(false);
        setEdit(false);
        setUpdate(!update);
      })
      .catch((error) => {
        console.log(error);
        setError(true);
        setLoading(false);
      });
  };
  const modalVisible = () => {
    let errors = [];
    const toCheck = {
      godownName: "GODOWN NAME",
      godownCode: "GODOWN CODE",
      incharge: "GODOWN INCHARGE",
    };
    Object.keys(toCheck).map((key) => {
      if (!formData[key]) {
        errors.push(toCheck[key] + " is required");
      }
    });
    if (errors.length > 0) {
      setFormErrors(errors);
      return;
    }
    setFormErrors([]);
    setFunctionToPass(() => () => submitHandler());
    setQues("Do you want to update this Godown?");
    setShow(true);
  };

  const modalClose = () => {
    setShow(false);
  };
  return (
    <div className="add-godown">
      <Modal show={show} noHandler={modalClose}>
        {!loading ? (
          !error ? (
            <ConfirmContent
              yesHandler={functionToPass}
              noHandler={modalClose}
              question={ques}
            ></ConfirmContent>
          ) : (
            <p>Something went wrong!</p>
          )
        ) : (
          <Loader></Loader>
        )}
      </Modal>

      <div className="fields">
        <div className="field">
          <Input
            label="Godown Name"
            type="text"
            size="wide"
            onChange={(e) => inputHandler(e, "godownName")}
            value={formData.godownName}
          />
        </div>
        <div className="field">
          <Input
            label="Godown Code"
            type="text"
            size="wide"
            onChange={(e) => inputHandler(e, "godownCode")}
            value={formData.godownCode}
          />
        </div>
        <div className="field">
          <Dropdown
            label="Godown Incharge"
            options={techList}
            size="wide"
            onChange={(e) => inputHandler(e, "incharge")}
            value={formData.incharge}
          />
        </div>
      </div>
      <Button
        className="primary"
        label="Update Godown"
        onClick={modalVisible}
      />
      {formErrors.map((error) => {
        return <Alert severity="info">{error}</Alert>;
      })}
      <br></br>
    </div>
  );
};

export default EditGodown;
