import React, { useState, useEffect } from "react";
import Paper from "../../../components/whiteCard/whiteCard";
import Axios from "axios";
import MaterialTables from "../../../components/materialTable/MaterialTables";
import url, {url_upload} from "../../../constants/url";
import Modal from "../../../components/ui/modal/Modal";
import Loader from "../../../components/spinner/Spinner";
import ContainerHeader from "../../../components/containerHeader/ContainerHeader";
import Button from "../../../components/inputComponents/Button";
import Popup from "./Popup";
import authCheck from "../../../helpers/auth.helpers";
import Pagination from '@material-ui/lab/Pagination';

const VendorList = () => {
  const { areaAndZone, designation } = authCheck(localStorage.getItem('authCode'));
  const [tableContents, setTableContents] = useState([]);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [details, setDetails] = useState({
    show: false,
    code: "",
    photo: "",
    sign: "",
  });
  const showDetails = (code, photo, sign) => {
    setDetails({ show: true, code, photo, sign });
  };
  const connLinks = [
    {
      name: "All Reports",
      path: "/reports",
    },
  ];

  const [paginationCount, setPaginationCount] = useState(1);
  const [page, setPage] = React.useState(1);

  const headings = [
    "Name",
    "Code",
    "Address",
    "Status",
    "Zone Code",
    "Email",
    "Vendor Class",
    "Phone No.",
    "Photo",
    "Signature",
    "Action",
  ];

  useEffect(() => {
    setLoading(true);
    setShow(true);
    let tempArr = [];
    let zonesArr = [];

    if (designation !== "SAP") {
      areaAndZone.map((area) => {
        area.zones.map((zone) => {
          zonesArr.push(zone.zoneCode);
        });
      });
    } else {
      // response.data.vendor.map((zone) => {
      //   zonesArr.push(zone.zoneCode);
      // });
    }

    Axios.post(url + "/vendors/getVendors", {zone: zonesArr, designation: designation}, {params: {page: page}})
      .then((response) => {
        setPaginationCount(response.data.count);

        let arr = [];
        response.data.vendor.map((vendor) => {
          let tempObj = {};
          
          tempObj["Name"] = vendor["name"];
          tempObj["Code"] = vendor["code"];
          tempObj["Address"] = vendor["address"];
          tempObj["Status"] = vendor["status"];
          tempObj["Zone Code"] = vendor["zoneCode"];
          tempObj["Email"] = vendor["email"];
          tempObj["Vendor Class"] = vendor["class"];
          tempObj["Phone No."] = vendor["phone"];
          tempObj["Photo"] = "noimage.jpg";
          tempObj["Photo"] =
            vendor["photo"] === "no-photo" ? (
              vendor["photo"]
            ) : (
              <img
                src={`${url_upload}/vendors/${vendor["photo"]}`}
                alt="no-photo"
                style={{ width: "60px", height: "60px" }}
              />
            );
          tempObj["Signature"] =
            vendor["signature"] === "no-signature" ? (
              vendor["signature"]
            ) : (
              <img
                src={`${url_upload}/vendors/${vendor["signature"]}`}
                alt="no-signature"
                style={{ width: "60px", height: "60px" }}
              />
            );
          tempObj["Action"] = (
            <Button
              onClick={() =>
                showDetails(
                  vendor["code"],
                  `${url_upload}/vendors/${vendor["photo"]}`,
                  `${url_upload}/vendors/${vendor["signature"]}`
                )
              }
              label="View"
            ></Button>
          );
          tempArr = [...tempArr, tempObj];
        });
        const sortedData = [...tempArr].sort(
          (a, b) => a["Cummulative Amt."] - b["Cummulative Amt."]
        );

        setTableContents(sortedData);
        modalClose();
        setLoading(false);
      })
      .catch((err) => {
        setError(true);
        setLoading(false);
      });
  }, [page]);
  const modalClose = () => {
    setShow(false);
  };
  return (
    <div className="vendorList">
      {details.show ? (
        <Popup setDetails={setDetails} details={details}></Popup>
      ) : null}
      {loading || error ? (
        <Modal show={show} noHandler={modalClose}>
          {error ? <p>Something went wrong!</p> : <Loader></Loader>}
        </Modal>
      ) : null}
      <ContainerHeader
        formName="Vendor List"
        connLinks={connLinks}
      ></ContainerHeader>
      <Paper>
        <MaterialTables
          title="Vendor Details List"
          data={tableContents}
          headings={headings}
        />
        <div style={{alignItems:'center', display:'flex', justifyContent:'center', marginTop:'10px'}}>
          <Pagination count={paginationCount} page={page} onChange={(e, v)=> setPage(v)} sx={{width:'100%'}} />
        </div>
      </Paper>
    </div>
  );
};

export default VendorList;
