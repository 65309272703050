import React from "react";
import "./Reports.css";
import ContainerHeader from "../../components/containerHeader/ContainerHeader";
import Paper from "../../components/whiteCard/whiteCard";
import { Link } from "react-router-dom";

const authCheck = require('../../helpers/auth.helpers');

const Reports = () => {
  const { designation } = authCheck(localStorage.getItem('authCode'));
  const reports = [
    { title: "Contract Jobs", link: "/contract-jobs", type: "PDF, CSV" },
    { title: "Vendor List", link: "/report-vendor-list", type: "PDF,CSV" },
    { title: "Vendor Wise Jobs", link: "/vendorJobs", type: "PDF,CSV" },
    {
      title: "Vendor Engaging Sub-Contractor",
      link: "/subcontracted-jobs",
      type: "PDF,CSV",
    },
    { title: "Delayed Jobs", link: "/delayed-jobs", type: "PDF,CSV" },
    designation == "SAP"
      ? {
        title: "Work Allocation Against Notification",
        link: "/work-against-notification",
        type: "PDF,CSV",
      }
      : null,
    designation == "SAP"
      ? {
        title: "Cumulative issued - Cumulative consumed (Total)",
        link: "/cumulative-total",
        type: "PDF,CSV",
      }
      : null,
    {
      title: "Cumulative issued - Cumulative consumed (Job Wise)",
      link: "/cumulative-total-job",
      type: "PDF,CSV",
    },
    {
      title: "Vendors Under Show Cause",
      link: "/report-show-cause",
      type: "PDF,CSV",
    },
    {
      title: "In Progress Project List",
      link: "/in-progress-projects",
      type: "PDF,CSV",
    },
    {
      title: "Removed Projects List",
      link: "/removed-projects",
      type: "PDF,CSV",
    },
    { title: "Halted Project List", link: "/halted-projects", type: "PDF,CSV" },
    { title: "Completed Job List", link: "/jobComplete", type: "PDF,CSV" },
    {
      title: "All projects",
      link: "/all-projects",
      type: "CSV",
    },
    {
      title: 'Non Estimate Projects', link: "/non-estimate-projects", type: "PDF, CSV"
    },
    {
      title: "Projects Under Show Cause",
      link: "/project-show-cause",
      type: "PDF,CSV",
    },
    {
      title: "All projects (without PR)",
      link: "/all-projects-nopr",
      type: "PDF,CSV",
    },
    {
      title: "User Projects (From DOS - TO DOC)",
      link: "/userJobs",
      type: "PDF,CSV",
    },
    {
      title: "Engineer Wise Jobs",
      link: "/JobsEng",
      type: "CSV"
    },
    {
      title: "JE Wise Jobs",
      link: "/JobsJE",
      type: "CSV",
    },
    {
      title: "Technician Wise Jobs",
      link: "/JobsTech",
      type: "CSV"
    },
    {
      title: "Installation Wise Jobs",
      link: "/JobsInstallations",
      type: "CSV",
    },
    {
      title: "Contract Jobs with Time Extensions",
      link: "/extended-jobs",
      type: "PDF, CSV",
    },
    {
      title: "Job Completion Certificate",
      link: "/job-certificate",
      type: "PDF, CSV",
    },
    {
      title: "Tally Book Report",
      link: "/tallyBookReport",
      type: "PDF",
    },
  ];
  return (
    <>
      <ContainerHeader formName="Reports"></ContainerHeader>
      <Paper>
        <div id="reports">
          {reports.map((report) => {
            return report ? (
              <Link to={`reports${report.link}`} key={report.link}>
                <div className="report">
                  <h3>{report.title}</h3>
                  <p>
                    Type: <span>{report.type}</span>
                  </p>
                </div>
              </Link>
            ) : null;
          })}
        </div>
      </Paper>
    </>
  );
};

export default Reports;
