import React, { Fragment } from "react";
import "./Button.css";

const Button = ({ type, className, onClick, label, disabled, style }) => {
  return (
    <Fragment>
      <button
        type={type}
        className={className}
        onClick={onClick}
        disabled={disabled}
        style={style}
      >
        {label}
      </button>
    </Fragment>
  );
};

export default Button;
