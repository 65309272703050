import React, { useState, useEffect } from "react";
import ContainerHeader from "../../components/containerHeader/ContainerHeader";
import Paper from "../../components/whiteCard/whiteCard";
import Table from "../../components/table/sortedTable/SortedTable";
import Button from "../../components/inputComponents/Button";
import EditDelete from "../../components/editDelete/EditDelete";
import Input from "../../components/inputComponents/Input";
import IconButton from "../../components/inputComponents/IconButton";
import { Link } from "react-router-dom";
import "./UserList.css";
import axios from "axios";
import Modal from "../../components/ui/modal/Modal";
import ConfirmContent from "../../components/ui/confirmContent/ConfirmContent";
import Loader from "../../components/spinner/Spinner";
import MaterialTables1 from "../../components/materialTable/MaterialTables1";
import url, { url_upload } from "../../constants/url";
const UserList = () => {
  const headings = [
    "User Type",
    "Name Of User",
    "User Image",
    "UserId",
    "Date Created",
    "Email",
    "Area",
    "Zone",
    "Mobile",
    "Status",
    "Change Password",
    "Action",
  ];
  const [tableContents, setTableContents] = React.useState(null);
  const [newData, setnewData] = useState({});
  const [ques, setQues] = useState("");
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [functionToPass, setFunctionToPass] = useState(null);
  const [error, setError] = useState(false);
  const [update, setUpdate] = useState(false);
  var data = {};
  const inputHandler = (event) => {
    const { name, value } = event.target;
    // setnewData({password:value, userId:name})
    data = { password: value, userId: name };
  };
  const changePassHandler = () => {
    setLoading(true);
    if (data.password) {
      axios
        .post(url + "/users/changePassByAdmin", data)
        .then((result) => {
          modalClose();
          setLoading(false);
          window.location.reload(true);
        })
        .catch((error) => {
          setError(true);
          setLoading(false);
        });
    }
  };
  const userDeleteHandler = (id) => {

    setLoading(true);
    axios
      .patch(url + "/users/" + id, {
        status: "Removed",
      })
      .then((result) => {
        modalClose();
        setLoading(false);
        window.location.reload(true);
      })
      .catch((error) => {
        setError(true);
        setLoading(false);
      });
  };
  const modalVisibleDelete = (_id) => {
    setError(false);
    setFunctionToPass(() => () => userDeleteHandler(_id));
    setQues("Do you want to remove this user?");
    setShow(true);
  };

  const modalVisibleForPassword = () => {
    setError(false);
    setFunctionToPass(() => () => changePassHandler());
    setQues("Do you want to change this password");
    setShow(true);
  };
  const modalClose = () => {
    setShow(false);
  };
  React.useEffect(() => {
    setLoading(true);
    setShow(true);
    axios
      .get(url + "/users/getAllUsers")
      .then((res) => {

        let contents = [];
        res.data.map((data) => {

          contents.push({
            "User Type": data.designation,
            // "Salary Code": data.salaryCode,
            "Name Of User": data.name,
            "User Image":
              data.photo === "no-photo" ? (
                data.photo
              ) : (
                <img
                  src={`${url_upload}/userPics/${data.photo}`}
                  alt="no-image"
                  style={{ width: "60px", height: "60px" }}
                />
              ),
            UserId: data.userCode,
            "Date Created": (
              <div className="minHight-ul">
                {new Date(
                  parseInt(data._id.toString().substring(0, 8), 16) * 1000
                ).toDateString()}
              </div>
            ),
            Email: data.email,
            Area: data.areaAndZone
              ? data.areaAndZone.map((area) => (
                  <button className="aa-small-box">
                    {area.area ? area.area.areaName : null}
                  </button>
                ))
              : "",
            Zone: (
              <div className="user-zones">
                {data.areaAndZone.map((obj) => {
                  return obj.zones
                    ? obj.zones.map((zone) => {
                        return (
                          <button className="aa-small-box">
                            {zone.zoneName}
                          </button>
                        );
                      })
                    : "";
                })}
              </div>
            ),
            Mobile: data.mobileNo,
            Status: (
              <Button
                label={data["status"]}
                className={
                  data["status"] === "Active"
                    ? "success"
                    : data["status"] === "Inactive"
                    ? "danger"
                    : "dark"
                }
              ></Button>
            ),
            "Change Password": (
              <div className="changePass">
                <Input
                  label="Enter New Password"
                  size="wide"
                  type="password"
                  name={data["_id"]}
                  onChange={(e) => inputHandler(e)}
                />
                <Button
                  className="primary"
                  label="Change Password"
                  onClick={modalVisibleForPassword}
                />
              </div>
            ),
            Action: (
              <div className="action-buttons">
                <div style={{ marginBottom: "10px" }}>
                  <Link to={"/edit-user/" + data["email"]}>
                    <IconButton
                      iconClass="fa fa-pencil"
                      className="success"
                      hint="Edit User"
                    />
                  </Link>
                </div>
                <div>
                  {data.designation === "SAP" ? null : (
                    <IconButton
                      iconClass="fas fa-trash-alt"
                      className="danger"
                      hint="Remove User"
                      onClick={() => modalVisibleDelete(data["_id"])}
                    />
                  )}
                </div>
              </div>
            ),
          });
        });
        setTableContents(contents);
        modalClose();
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setError(true);
        setLoading(false);
      });
  }, [update]);
  return (
    <div className="userList">
      <Modal show={show} noHandler={modalClose}>
        {!loading ? (
          !error ? (
            <ConfirmContent
              yesHandler={functionToPass}
              noHandler={modalClose}
              question={ques}
            ></ConfirmContent>
          ) : (
            <p>Something went wrong!</p>
          )
        ) : (
          <Loader></Loader>
        )}
      </Modal>
      <ContainerHeader formName="User List"></ContainerHeader>
      <Paper>
        <div class="userListTable">
          {tableContents && tableContents.length > 0 ? (
            <MaterialTables1
              data={tableContents}
              headings={headings}
              title="Users Database"
            />
          ) : (
            "No Users"
          )}
        </div>
      </Paper>
    </div>
  );
};

export default UserList;
