import React, { useState, useEffect } from "react";
import axios from "axios";
import ContainerHeader from "../../../components/containerHeader/ContainerHeader";
import Paper from "../../../components/whiteCard/whiteCard";
import MaterialTables from "../../../components/materialTable/MaterialTables";
import Modal from "../../../components/ui/modal/Modal";
import Loader from "../../../components/spinner/Spinner";
import DropDown from "../../../components/inputComponents/DropDown";
import url from "../../../constants/url";

const JobsInst = () => {
  const [tableContents, setTableContents] = React.useState([]);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [changed, setChanged] = useState("");
  const [error, setError] = useState(false);
  const [tableTitle, setTableTitle] = useState('')
  const connLinks = [
    {
      name: "All Reports",
      path: '/reports'
    }
  ]
  const headings = [
    "Installation",
    "Area",
    "Description",
    "DOC",
    "DOS",
    "Notification Number",
    "PO Number",
    "PR Number",
    "Project ID",
    "Project Type",
    "Show Cause Notice",
    "Show Cause Date of Issue",
    "Status",
    "Subcontractor Name",
    "Subcontractor Number",
    "Technician",
    "Validity LCC",
    "Vendor",
    "Zone",
  ];
  const [areaOp, setAreaOp] = useState([
    {
      _id: null,
      name: "Select",
    },
  ]);

  useEffect(() => {
    setLoading(true);
    setShow(true);
    let installation = [
      {
        _id: null,
        name: "Select",
      },
    ];
    axios
      .get(url + "/installations/allInstallations")
      .then((res) => {
        res.data.map((item) => {
          installation.push({
            _id: item.name,
            name: item.name,
          });
        });
        setAreaOp(installation);
        modalClose();
        setLoading(false);
      })
      .catch((e) => {
        setError(true);
        setLoading(false);
      });
    setTableContents(null);
  }, []);

  function handleChange(e) {
    setChanged(e.target.value);
    areaOp.forEach(inst => {
      if(inst._id === e.target.value)
        setTableTitle(inst.name)
    });
  }

  useEffect(() => {
    setLoading(true);
    setShow(true);
    axios
      .get(url + "/projects/getProjectList")
      .then((response) => {
        let tempArr = [];
        response.data.project.map((item) => {
          if (item.installation.name === changed) {
            let obj = {
              Installation: item.installation.name,
              Area: item.area.areaName,
              Description:
                item.description.length > 100
                  ? item.description.slice(0, 100)
                  : item.description,
              DOC: new Date(item.doc).toLocaleDateString("en-GB"),
              DOS: new Date(item.dos).toLocaleDateString("en-GB"),
              "Notification Number": item.notificationNo,
              "PO Number": item.poNumber,
              "PR Number": item.prNumber,
              "Project ID": item.projectId,
              "Project Type": item.projectType,
              "Show Cause Notice": item.showcause ? "Issued" : "Not Issued",
              "Show Cause Date of Issue": item.showcause ? new Date(item.showcauseDate).toLocaleDateString("en-GB"): "-",
              "Status": item.state,
              "Subcontractor Name": item.subcontractorName
                ? item.subcontractorName
                : "-",
                "Subcontractor Number": item.subcontractorNumber
                ? item.subcontractorNumber
                : "-",
              Technician: item.technician.name,
              "Validity LCC": new Date(item.validityLLC).toLocaleDateString(
                "en-GB"
              ),
              Vendor: item.vendor.name,
              Zone: item.zone.zoneName,
            };
            tempArr = [...tempArr, obj];
          }
        });
        setTableContents(tempArr);
        modalClose();
        setLoading(false);
      })
      .catch((e) => {
        setError(true);
        setLoading(false);
      });
  }, [changed]);

  const modalClose = () => {
    setShow(false);
  };

  return (
    <div className="vendorList">
      {loading || error ? (
        <Modal show={show} noHandler={modalClose}>
          {error ? <p>Something went wrong!</p> : <Loader></Loader>}
        </Modal>
      ) : null}
      <ContainerHeader formName="Installation Wise Jobs" connLinks={connLinks}></ContainerHeader>
      <Paper>
        <div className="JEselect">
          <DropDown
            label="Installation*"
            name="area"
            options={areaOp}
            size="wide"
            onChange={handleChange}
          />
        </div>
        {tableContents && tableContents.length > 0? (
          <MaterialTables
            title={`Installation Wise Jobs (${tableTitle})`}
            data={tableContents}
            headings={headings}
            ispdf={false}
          />
        ) : (
          "Please Select an Installation"
        )}
      </Paper>
    </div>
  );
};

export default JobsInst;
