import React from "react";
import { Fragment } from "react";
import "./Button.css";

const IconButton = ({
  className,
  iconClass,
  onClick,
  hint,
  disabled,
  ...props
}) => {
  return (
    <Fragment>
      <button
        className={className}
        onClick={onClick}
        style={{ padding: "8px" }}
        disabled={disabled}
        title={hint}
      >
        <i className={iconClass}></i>
      </button>
    </Fragment>
  );
};

export default IconButton;
