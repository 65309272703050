import React, { useEffect, useState } from "react";
import "./EditProject.css";
import ContainerHeader from "../../components/containerHeader/ContainerHeader";
import Paper from "../../components/whiteCard/whiteCard";
import Input from "../../components/inputComponents/Input";
import Button from "../../components/inputComponents/Button";
import DropDown from "../../components/inputComponents/DropDown";
import TextArea from "../../components/inputComponents/TextArea";
import MultiInput from "../../components/inputComponents/MultiInput";
import Axios from "axios";
import ConfirmContent from "../../components/ui/confirmContent/ConfirmContent";
import Loader from "../../components/spinner/Spinner";
import Modal from "../../components/ui/modal/Modal";
import url from "../../constants/url";

const EditProject = (props) => {
  const [details, setDetails] = useState({
    projectType: "",
    area: "",
    zone: "",
    zoneName: "",
    areaName: "",
    state: "",
    installation: "",
    installationName: "",
    notificationNo: 0,
    projectId: "",
    notification: "",
    description: "",
    technician: "",
    vendor: "5f4e2bcf4c3fc77ff89b6650",
    engineer: "",
    jrEngineer: "",
    dos: "",
    doc: "",
    validityLLC: "",
    prNumber: 0,
    poNumber: 0,
    subcontractorName: "",
    subcontractorNumber: 0,
  });
  const [flag, setFlag] = useState(false);
  const [technician, setTechnician] = useState([]);
  const [je, setJe] = useState([]);
  const [eng, setEng] = useState([]);
  const [sub, setSub] = useState(true);
  const [vendor, setVendor] = useState([]);
  const [show, setShow] = useState(false);
  const [ques, setQues] = useState("");
  const [loading, setLoading] = useState(false);
  const [zones, setZones] = useState([]);
  const [error, setError] = useState(false);
  const [subContractor, setSubContractor] = useState([
    { name: "Yes", _id: "Yes" },
    { name: "No", _id: "No" },
  ]);
  const listObj = { name: "Select", _id: "select" };
  const [states, setStates] = useState([
    { name: "Completed", _id: "Completed" },
    { name: "Halt", _id: "Halt" },
    { name: "Ongoing", _id: "Ongoing" },
  ]);
  const connLinks = [
    {
      name: "Project list",
      path: '/projects'
    }
  ]
  useEffect(() => {
    setLoading(true);
    setShow(true);
    Axios.get(
      url + "/projects/projectDetails?projectId=" + props.match.params.projectId
    )
      .then((response) => {
        let tempObj = {};
        let apiObj = {};
        apiObj = response.data;

        tempObj["projectType"] = apiObj["projectType"];
        tempObj["areaName"] = apiObj["area"]["areaName"];
        tempObj["area"] = apiObj["area"]["_id"];
        tempObj["state"] = apiObj["state"];
        tempObj["installation"] = apiObj["installation"]["_id"];
        tempObj["installationName"] = apiObj["installation"]["name"];
        tempObj["notificationNo"] = apiObj["notificationNo"];
        tempObj["projectId"] = apiObj["projectId"];
        tempObj["notification"] = props.match.params.id;
        tempObj["description"] = apiObj["description"];
        tempObj["zoneName"] = apiObj["zone"]["zoneName"];
        tempObj["zone"] = apiObj["zone"]["_id"];
        tempObj["vendor"] = apiObj["vendor"]["_id"];
        tempObj["vendorName"] = apiObj["vendor"]["name"];
        tempObj["engineer"] = apiObj["engineer"]["_id"];
        tempObj["engineerName"] = apiObj["engineer"]["name"];
        tempObj["jrEngineer"] = apiObj["jrEngineer"]["_id"];
        tempObj["jrEngineerName"] = apiObj["jrEngineer"]["name"];
        tempObj["technician"] = apiObj["technician"]["_id"];
        tempObj["technicianName"] = apiObj["technician"]["name"];
        tempObj["prNumber"] = apiObj["prNumber"];
        tempObj["poNumber"] = apiObj["poNumber"];
        tempObj["validityLLC"] = apiObj["validityLLC"];
        tempObj["subcontractorName"] = apiObj["subcontractorName"];
        tempObj["subcontractorNumber"] = apiObj["subcontractorNumber"];
        setDetails(tempObj);
        Axios.get(url + "/areas/" + apiObj["area"]["_id"])
          .then((response) => {
            let zone = response.data["zones"];
            let filteredZone = zone.filter(
              (z) => z._id != apiObj["zone"]["_id"]
            );
            filteredZone.unshift({
              name: apiObj["zone"]["zoneName"],
              _id: apiObj["zone"]["_id"],
            });
            setZones(filteredZone);
            Axios.post(url + "/users/findUsersInZone", {
              zoneId: apiObj["zone"]["_id"],
            })
              .then((resp) => {
                let tempAr = [];
                let tempArr = [];
                tempAr = resp.data["technician"];
                tempArr = tempAr.filter(
                  (a) => a._id !== apiObj["technician"]["_id"]
                );
                tempArr.unshift({
                  name: apiObj["technician"]["name"],
                  _id: apiObj["technician"]["_id"],
                });
                setTechnician(tempArr);
                tempAr = resp.data["je"];
                tempArr = tempAr.filter(
                  (a) => a._id !== apiObj["jrEngineer"]["_id"]
                );
                tempArr.unshift({
                  name: apiObj["jrEngineer"]["name"],
                  _id: apiObj["jrEngineer"]["_id"],
                });
                setJe(tempArr);
                tempAr = resp.data["engineer"];
                tempArr = tempAr.filter(
                  (a) => a._id !== apiObj["engineer"]["_id"]
                );
                tempArr.unshift({
                  name: apiObj["engineer"]["name"],
                  _id: apiObj["engineer"]["_id"],
                });
                setEng(tempArr);
                tempAr = resp.data["vendors"];
                tempArr = tempAr.filter(
                  (a) => a._id !== apiObj["vendor"]["_id"]
                );
                tempArr.unshift({
                  name: apiObj["vendor"]["name"],
                  _id: apiObj["vendor"]["_id"],
                  code: apiObj["vendor"]["code"],
                });
                tempArr.map((e) => {
                  e.name = e.name + " (" + e.code + ")";
                });
                setVendor(tempArr);
                modalClose();
                setLoading(false);
              })
              .catch((err) => {
                setError(true);
                modalClose();
              });
            let tempStates = states.filter(
              (state) => state.name !== tempObj["state"]
            );
            tempStates.unshift({
              name: tempObj["state"],
              _id: tempObj["state"],
            });
            setStates(tempStates);
            if (tempObj["subcontractorName"] === "") {
              setSubContractor([
                { name: "No", _id: "No" },
                { name: "Yes", _id: "Yes" },
              ]);
              setSub(false);
            }
            setFlag(true);
          })
          .catch((err) => {
            setError(true);
            modalClose();
          });
      })
      .catch((err) => {
        setError(true);
        modalClose();
      });
  }, []);

  const inputChangeHandler = (event, val) => {
    setDetails({
      ...details,
      [val]: event.target.value,
    });
    if (val === "zone") {
      setLoading(true);
      setShow(true);
      Axios.post(url + "/users/findUsersInZone", {
        zoneId: event.target.value,
      })
        .then((response) => {
          let tempArr = [];
          tempArr = response.data["technician"];
          tempArr.unshift(listObj);
          setTechnician(tempArr);
          tempArr = response.data["je"];
          tempArr.unshift(listObj);
          setJe(tempArr);
          tempArr = response.data["engineer"];
          tempArr.unshift(listObj);
          setEng(tempArr);
          tempArr = response.data["vendors"];
          tempArr.map((e) => {
            e.name = e.name + " (" + e.code + ")";
          });
          tempArr.unshift(listObj);
          setVendor(tempArr);
          modalClose();
          setLoading(false);
        })
        .catch((err) => {
          setError(true);
          setLoading(false);
          window.location.reload();
        });
    }
  };

  const submitHandler = () => {
    setLoading(true);
    Axios.patch(
      url + "/projects/editProject/" + props.match.params.projectId,
      details
    )
      .then((response) => {
        setLoading(false);
        modalClose();
        props.history.push("/projects");
      })
      .catch((err) => {
        setError(true);
        setLoading(false);
      });
  };

  const subcoHandler = (event) => {
    if (event.target.value == "Yes") setSub(true);
    else {
      setSub(false);
      setDetails({
        ...details,
        subcontractorName: "",
        subcontractorNumber: 0,
      });
    }
  };

  const modalVisible = () => {
    setError(false);
    setQues("Do you want to edit this project?");
    setShow(true);
  };

  const modalClose = () => {
    setShow(false);
  };

  const formattedDate = (dateObj) => {
    const date = new Date(dateObj);
    const year = date.getFullYear();
    let month = date.getMonth() + 1;
    let dt = date.getDate();

    if (dt < 10) {
      dt = "0" + dt;
    }
    if (month < 10) {
      month = "0" + month;
    }
    return year + "-" + month + "-" + dt;
  };
  return (
    <div id="add-project">
      <Modal show={show} noHandler={modalClose}>
        {!loading ? (
          !error ? (
            <ConfirmContent
              yesHandler={submitHandler}
              noHandler={modalClose}
              question={ques}
            ></ConfirmContent>
          ) : (
            <p>Something went wrong!</p>
          )
        ) : (
          <Loader></Loader>
        )}
      </Modal>
      <ContainerHeader formName="Edit Project" connLinks={connLinks}></ContainerHeader>
      {flag ? (
        <Paper>
          <div className="add-project-form">
            <div className="add-project-grid1">
              <div className="fields">
                <div className="field">
                  <Input
                    name="project-type"
                    label="Project Type"
                    disabled={true}
                    size="wide"
                    value={details.projectType}
                  />
                </div>
                <div className="field">
                  <Input
                    name="project-id"
                    label="Project ID"
                    disabled={true}
                    size="wide"
                    value={details.projectId}
                  />
                </div>
                <div className="field">
                  <Input
                    name="notification-no"
                    label="Notification No"
                    disabled={true}
                    size="wide"
                    value={details.notificationNo}
                  />
                </div>
                <div className="field">
                  <Input
                    name="installation"
                    label="Installation"
                    disabled={true}
                    size="wide"
                    value={details.installationName}
                  />
                </div>
                <div className="field">
                  <Input
                    name="area"
                    label="Area"
                    disabled={true}
                    size="wide"
                    value={details.areaName}
                  />
                </div>
              </div>
            </div>
            <div className="fields">
              <div className="field">
                <TextArea
                  label="Description of Project"
                  name="description"
                  size="wide"
                  disabled={true}
                  value={details.description}
                />
              </div>
            </div>
            <div className="add-project-grid2">
              <div className="fields">
                <div className="field">
                  <DropDown
                    size="wide"
                    label="State"
                    options={states}
                    onChange={(e) => inputChangeHandler(e, "state")}
                  />
                </div>
                <div className="field">
                  <DropDown
                    size="wide"
                    label="Zone"
                    options={zones}
                    onChange={(e) => inputChangeHandler(e, "zone")}
                  />
                </div>
                <div className="field">
                  <DropDown
                    size="wide"
                    label="Assign to Vendor"
                    options={vendor}
                    value={details.vendor}
                    onChange={(e) => inputChangeHandler(e, "vendor")}
                  />
                </div>

                <div className="field">
                  <DropDown
                    size="wide"
                    label="Sub Contractor Engaged"
                    name="is-engaged"
                    options={subContractor}
                    onChange={subcoHandler}
                  />
                </div>
                {sub ? (
                  <div className="field">
                    <Input
                      name="subName"
                      label="Sub-coordinator name"
                      size="wide"
                      value={details.subcontractorName}
                      onChange={(e) =>
                        inputChangeHandler(e, "subcontractorName")
                      }
                    />
                  </div>
                ) : null}
                {sub ? (
                  <div className="field">
                    <Input
                      name="subNo"
                      label="Sub-coordinator No."
                      size="wide"
                      value={details.subcontractorNumber}
                      onChange={(e) =>
                        inputChangeHandler(e, "subcontractorNumber")
                      }
                    />
                  </div>
                ) : null}
                <div className="field">
                  <DropDown
                    size="wide"
                    label="Assign to Technician"
                    options={technician}
                    onChange={(e) => inputChangeHandler(e, "technician")}
                  />
                </div>
                <div className="field">
                  <DropDown
                    size="wide"
                    label="Assign to Jr. Engineer"
                    options={je}
                    onChange={(e) => inputChangeHandler(e, "jrEngineer")}
                  />
                </div>
                <div className="field">
                  <DropDown
                    size="wide"
                    label="Assign to Engineer"
                    options={eng}
                    onChange={(e) => inputChangeHandler(e, "engineer")}
                  />
                </div>
                <div className="field">
                  <Input
                    name="pr-no"
                    label="PR Number"
                    value={details.prNumber}
                    size="wide"
                    onChange={(e) => inputChangeHandler(e, "prNumber")}
                  />
                </div>
                <div className="field">
                  <Input
                    name="po-no"
                    label="PO Number"
                    value={details.poNumber}
                    size="wide"
                    onChange={(e) => inputChangeHandler(e, "poNumber")}
                  />
                </div>
                <div className="field">
                  <Input
                    type="date"
                    name="lcc-date"
                    label="Validity date of LCC"
                    size="wide"
                    value={formattedDate(details.validityLLC)}
                    onChange={(e) => inputChangeHandler(e, "validityLLC")}
                  />
                </div>
              </div>
            </div>

            <hr />
            <Button
              className="success"
              label="Update"
              onClick={modalVisible}
            ></Button>
          </div>
        </Paper>
      ) : null}
    </div>
  );
};

export default EditProject;
