import React from "react";
import { Redirect } from "react-router-dom";
import "./projectDetail.css";
const ProjectDetail = (props) => {
  return (
    <div className="pd-row">
      <div className="pd-textholder">{props.ipt}</div>
      <div className="pd-ans">{props.val}</div>
    </div>
  );
};

export default ProjectDetail;
