import React from "react";

const Popup = (props) => {
  return (
    <>
      <div
        style={styles.container}
        onClick={() => props.setDetails({ ...props.details, show: false })}
      >
        <div style={styles.modal}>
          <h2 style={{ textAlign: "center" }}>{props.details.code}</h2>
          <center>
            <img src={props.details.photo} alt="photo" width="400px" />
          </center>
          <center>
            <img src={props.details.sign} alt="" width="400px" />
          </center>
        </div>
      </div>
    </>
  );
};

const styles = {
  container: {
    position: "fixed",
    height: "100vh",
    width: "100vw",
    left: "0",
    top: "0",
    backgroundColor: "rgba(20,20,20,0.6)",
    zIndex: 1000,
  },
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
    width: "450px",
    minHeight: "300px",
    backgroundColor: "white",
  },
};

export default Popup;
