import React from "react";
import DropDown from "../../../components/inputComponents/DropDown";
import MaterialTables from "../../../components/materialTable/MaterialTables";

const headings = [
  "Godown Name",
  "Machinery",
  "Unit",
  "Total In Quantity",
  "Total Out Quantity",
  "Current Stock Balance",
];

const MachineryReport = (props) => {
  const [list, setList] = React.useState([]);
  const [tableContent, setTableContent] = React.useState([]);

  React.useEffect(() => {
    let store = new Map();
    props.godowns.map((godown) => {
      godown.register.map((entry) => {
        if (entry.machinery) {
          store.set(entry.machinery._id, {
            name: entry.machinery.description,
            id: entry.machinery.description,
            _id: entry.machinery._id,
            unit: entry.unit,
          });
        }
      });
    });
    let tempList = [];
    store.forEach((value, key, map) => {
      tempList.push(value);
    });
    tempList.unshift({ name: "Select", id: "Select" });
    setList(tempList);
  }, []);

  const updateTableContent = (e) => {
    let currentMachinery = {};
    list.map((mat) => {
      if (mat._id == e.target.value) {
        currentMachinery = mat;
      }
    });
    let tempContent = [];
    props.godowns.map((godown) => {
      let total = 0;
      let consumed = 0;
      godown.register.map((entry) => {
        if (entry.machinery && entry.machinery._id == e.target.value) {
          if (entry.quantityIn) {
            total += entry.quantityIn;
          }
          if (entry.quantityOut) {
            consumed += entry.quantityOut;
          }
        }
      });
      tempContent.push({
        "Godown Name": godown.godownName,
        Machinery: currentMachinery.name,
        Unit: currentMachinery.unit,
        "Total In Quantity": total,
        "Total Out Quantity": consumed,
        "Current Stock Balance": total - consumed,
      });
    });
    setTableContent(tempContent);
  };

  return (
    <>
      <h1>All Godown Machinery Report</h1>
      <DropDown
        label="Select Machinery"
        options={list}
        size="wide"
        onChange={(e) => updateTableContent(e)}
      ></DropDown>
      <MaterialTables
        headings={headings}
        data={tableContent}
        title="Report"
      ></MaterialTables>
    </>
  );
};

export default MachineryReport;
