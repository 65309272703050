import React, { useState, useEffect } from "react";
import "./TechnicalDocs.css";
import Button from "../../components/inputComponents/Button";
import ContainerHeader from "../../components/containerHeader/ContainerHeader";
import Paper from "../../components/whiteCard/whiteCard";
import Input from "../../components/inputComponents/Input";
import MaterialTables from "../../components/materialTable/MaterialTables";
import Axios from "axios";
import IconButton from "../../components/inputComponents/IconButton";
import Modal from "../../components/ui/modal/Modal";
import ConfirmContent from "../../components/ui/confirmContent/ConfirmContent";
import Loader from "../../components/spinner/Spinner";
import url, {url_upload} from "../../constants/url";
import authCheck from "../../helpers/auth.helpers";

const TechnicalDocs = () => {
  const { areaAndZone, designation } = authCheck(localStorage.getItem('authCode'));
  const [name, setName] = useState();
  const [file, setFile] = useState();
  const [tableContents, setTableContents] = useState([]);
  const headings = ["SL No", "Document", "Actions"];
  const [show, setShow] = useState(false);
  const [functionToPass, setFunctionToPass] = useState(null);
  const [ques, setQues] = useState("");
  const [loading, setLoading] = useState(false);
  const [update, setUpdate] = useState(true);
  const [error, setError] = useState(false);

  var [isSubmit, setSubmit] = useState(false);

  useEffect(() => {
    setLoading(true);
    setShow(true);
    Axios.get(url + "/techdocs/getDocs")
      .then((response) => {
        let apiData = [];
        let contents = [];
        apiData = response.data;
        let i = 1;
        apiData.map((data) => {
          let tempObj = {};
          tempObj["SL No"] = i;
          i = i + 1;
          tempObj["Document"] = data["name"];

          tempObj["Actions"] = (
            <div
              style={{
                display: "flex",
                flexFlow: "row",
                justifyContent: "center",
              }}
            >
              <IconButton
                className="success"
                iconClass="fa fa-download"
                hint="View"
                onClick={() => window.open(`${url_upload}/${data["path"]}`)}
              />
              {designation === "SAP" && (
                <IconButton
                  className="danger"
                  iconClass="fas fa-trash-alt"
                  hint="Delete"
                  onClick={() =>
                    modalVisibleForDelete(data["_id"], data["name"])
                  }
                />
              )}
            </div>
          );

          contents = [...contents, tempObj];
        });
        setTableContents(contents);
        modalClose();
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setError(true);
        setLoading(false);
      });
  }, [update]);

  const modalVisibleForSubmit = () => {
    setFunctionToPass(() => () => send());
    setQues("Do you want to upoad this Document?");
    setShow(true);
  };

  const modalVisibleForDelete = (id, name) => {
    setFunctionToPass(() => () => deleteDoc(id, name));
    setQues("Do you want to delete this document?");
    setShow(true);
  };

  const modalClose = () => {
    setShow(false);
  };

  //delete docs
  function deleteDoc(id, name) {
    setLoading(true);
    Axios.delete(url + `/techdocs/deleteDocs/${id}/${name}`)
      .then((res) => {
        modalClose();
        setLoading(false);
        setName("");
        setFile("");
        setUpdate(!update);
      })
      .catch((err) => {
        setError(true);
        setLoading(false);
      });
  }

  function send() {
    setLoading(true);
    const data = new FormData();
    data.append("name", name);
    data.append("file", file);
    setLoading(true);
    Axios.post(url + "/uploads/techdocs", data)
      .then((res) => {
        modalClose();
        setLoading(false);
        setName("");
        setFile("");
        setSubmit(true);
        setUpdate(!update);
      })
      .catch((err) => {
        setError(true);
        setLoading(false);
      });
  }

  return (
    <div id="add-doc">
      <Modal show={show} noHandler={modalClose}>
        {!loading ? (
          !error ? (
            <ConfirmContent
              yesHandler={functionToPass}
              noHandler={modalClose}
              question={ques}
            ></ConfirmContent>
          ) : (
            <p>Something went wrong!</p>
          )
        ) : (
          <Loader></Loader>
        )}
      </Modal>
      <ContainerHeader formName="Technical Documents"> </ContainerHeader>
      <Paper>
        {designation === "SAP" && (
          <div className="add-doc-form">
            <div className="fields">
              <div className="field">
                <Input
                  name="name"
                  label="File Name"
                  size="wide"
                  onChange={(event) => {
                    setName(event.target.value);
                  }}
                  value={name}
                />
              </div>
              <div className="field">
                <Input
                  type="file"
                  label="Upload File"
                  size="wide"
                  onChange={(event) => {
                    setSubmit(false);
                    const file = event.target.files[0];
                    setFile(file);
                  }}
                  value={isSubmit ? "" : null}
                />
              </div>
            </div>
            <br />
            <hr />

            <Button
              className="success"
              type="submit"
              label="Submit"
              onClick={modalVisibleForSubmit}
            />
          </div>
        )}
        <br></br>
        <MaterialTables
          headings={headings}
          data={tableContents}
          title="Technical document database"
        />
      </Paper>
    </div>
  );
};

export default TechnicalDocs;
