import React, { useState } from "react";
import Paper from "../../components/whiteCard/whiteCard";
import MaterialTables from "../../components/materialTable/MaterialTables";
import ContainerHeader from "../../components/containerHeader/ContainerHeader";
import * as requests from "../../api/requests";
import Modal from "../../components/ui/modal/Modal";
import Loader from "../../components/spinner/Spinner";
import url from "../../constants/url";
import { Link } from "react-router-dom";
import Axios from "axios";
import ConfirmContent from "../../components/ui/confirmContent/ConfirmContent";
import IconButton from "../../components/inputComponents/IconButton";
import EditGodown from "../editGodown/editGodown";

const GodownList = () => {
  const headings = ["Godown Name", "Godown Code", "Godown Incharge", "Actions"];
  const [tableContents, setTableContents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [error, setError] = useState(false);
  const [functionToPass, setFunctionToPass] = useState(null);
  const [ques, setQues] = useState("");
  const [update, setUpdate] = useState(false);
  const [edit, setEdit] = useState(false);
  const [editData, setEditData] = useState({});

  React.useEffect(() => {
    setLoading(true);
    setShow(true);
    requests
      .get({ url: "/godowns/getAllGodowns" })
      .then((result) => {
        let contents = [];
        result.forEach((element) => {
          let tempObj = {};
          tempObj["Godown Name"] = element.godownName;
          tempObj["Godown Code"] = element.godownCode;
          tempObj["Godown Incharge"] = element.incharge.name;
          tempObj["Actions"] = (
            <div>
              <IconButton
                iconClass="fa fa-pencil"
                className="success"
                hint="Edit User"
                onClick={() => {
                  setEdit(true);
                  setEditData({
                    name: element.godownName,
                    code: element.godownCode,
                    id: element._id,
                    incharge: element.incharge,
                  });
                }}
              />

              <IconButton
                iconClass="fas fa-trash-alt"
                className="danger"
                onClick={() => modalVisible(element._id)}
                hint="Delete"
              />
            </div>
          );
          contents = [...contents, tempObj];
        });
        setTableContents(contents);
        modalClose();
        setLoading(false);
      })
      .catch((error) => {
        setError(true);
        setLoading(false);
      });
  }, [update]);

  const modalClose = () => {
    setShow(false);
  };

  const modalVisible = (id) => {
    setError(false);
    setQues("Do you want to delete this material?");
    setFunctionToPass(() => () => materialDeleteHandler(id));
    setShow(true);
  };

  const materialDeleteHandler = (id) => {
    setLoading(true);
    Axios.delete(url + `/godowns/deleteGodown/${id}`)
      .then((_) => {
        modalClose();
        setLoading(false);
        setUpdate(!update);
      })
      .catch((err) => {
        setError(true);
        setLoading(false);
      });
  };

  return (
    <div>
      <Modal show={show} noHandler={modalClose}>
        {loading ? (
          <Loader></Loader>
        ) : !error ? (
          <ConfirmContent
            yesHandler={functionToPass}
            noHandler={modalClose}
            question={ques}
          ></ConfirmContent>
        ) : (
          <p>Something went wrong!</p>
        )}
      </Modal>
      <ContainerHeader formName="Godown List" />
      <Paper>
        {edit && (
          <EditGodown
            name={editData.name}
            code={editData.code}
            incharge={editData.incharge}
            id={editData.id}
            setUpdate={setUpdate}
            update={update}
            setEdit={setEdit}
          />
        )}
        <br></br>
        {tableContents && tableContents.length > 0 ? (
          <MaterialTables
            headings={headings}
            data={tableContents}
            title="Godowns List"
          />
        ) : (
          "No Godowns"
        )}
      </Paper>
    </div>
  );
};

export default GodownList;
