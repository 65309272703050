import React, { useState } from "react";
import MaterialTable from "material-table";
import useWindowSize from "../windowSize/windowSize";

const MaterialTables = ({ headings, data, title }) => {
  const size = useWindowSize();

  const [selectedRow, setSelectedRow] = useState(null);
  const columns = [];

  headings.map((header) => {
    columns.push({
      title: header,
      field: header,
    });
  });

  if (size.width < 800) {
    title = "";
  }

  return (
    <MaterialTable
      title={title}
      columns={columns}
      data={data}
      onRowClick={(evt, selectedRow) =>
        setSelectedRow(selectedRow.tableData.id)
      }
      options={{
        headerStyle: {
          backgroundColor: "#dcd6f7",
          color: "#3e206d",
        },
        rowStyle: (rowData) => ({
          backgroundColor:
            selectedRow === rowData.tableData.id ? "#EEE" : "#FFF",
        }),
        pageSizeOptions: [5, 15, 25, 50],
        emptyRowsWhenPaging: false
      }}
    />
  );
};

export default MaterialTables;
